import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {filter, map, mergeMap} from "rxjs/operators";
import {UserService} from "src/services/user.service";
import {LogsService} from "src/services/log.service";
import {User, UserLogs} from "src/models/user.model";
import firebase from "firebase/app";
import {Logs} from "src/models/logs";
import {FormArray, FormBuilder, FormGroup} from "@angular/forms";
import {VehicleService} from "src/services/vehicle.service";
import Swal from "sweetalert2";
import {Vehicles} from "../../../../../models/vehicle.model";
import * as moment from "moment";
import {AuthService} from "../../../../../services/auth.service";
import {Account} from "../../../../../models/account.model";
import {AngularFirestore} from '@angular/fire/firestore';
import {GeotagService} from "../../../../../services/geotag.service";
import {PaymentService} from "../../../../../services/payment.service";
import {ZonesSlotService} from "../../../../../services/zones-slot.service";
import {ZoneSlotAssignment} from "../../../../../enum/enum";

@Component({
  selector: 'app-vehicles-edit',
  templateUrl: './vehicles-edit.component.html',
  styleUrls: ['./vehicles-edit.component.css']
})
export class UserVehiclesEditComponent implements OnInit {

  public userForm: FormGroup;
  public vehicleForm: FormGroup;
  userVehicles: FormArray;

  date: any;

  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }
  private _color = "light";

  user: UserLogs = <UserLogs> {};
  user_vehicle: any = {};
  vehicle: Vehicles = <Vehicles> {};
  logs: Logs[] = [];
  logDetailsSideBar: Logs = {};
  geoTagsPhotos: any = [];
  payment: any = [];

  userLogs: any = [];

  userAuth: User;
  account: Account;
  zones: any = [];
  zone: any = {};

  searchVia = '';
  searchCode = '';

  toggleButtonConfig = {
    value: false
  }

  constructor(
    private firestore: AngularFirestore,
    protected route : ActivatedRoute,
    private userService: UserService,
    private logService: LogsService,
    private vehicleService: VehicleService,
    public formBuilder: FormBuilder,
    public router: Router,
    public auth: AuthService,
    private geotagService: GeotagService,
    private paymentService: PaymentService,
    private zoneSlotService: ZonesSlotService
    ) {

    this.auth.user$.subscribe(user => this.userAuth = user);
    this.account = JSON.parse(localStorage.getItem('account'));
    this.zones = this.account.settings.zones;

  }

  async ngOnInit() {


    this.vehicleForm = this.formBuilder.group({
      plate_number: [''],
      vehicle_type:  [''],
      vehicle_model:  [''],
      vehicle_color:  [''],
    });

    this.route.paramMap
      .pipe(
        map(pm => pm.get('id')),
        filter(id => id != null),
        mergeMap(id => {

          this.searchVia = 'plate_number';
          return this.vehicleService.getVehicleByPlateNumber(id);

       }),
      ).subscribe(async (vehicle: any) => {

      if (!vehicle) {
        return;
      }

      this.vehicle = vehicle;

      this.vehicleForm = this.formBuilder.group({
        plate_number: this.vehicle.plate_number,
        vehicle_type: this.vehicle.vehicle_type,
        vehicle_model: this.vehicle.vehicle_model,
        vehicle_color: this.vehicle.vehicle_color
      });

      this.vehicleForm.patchValue(this.vehicle);

      if (this.account.account_key && this.searchVia == 'plate_number') {

        const logRef = firebase.firestore().collection('logs');

        const snapshot = await logRef
          .where('account_key', '==', this.account.account_key)
          .where('plate_number', '==', this.vehicle.plate_number)
          .orderBy('timestamp', 'desc')
          //.limit(10)
          .get();

        await this.getLogsVia(snapshot);

      }

    },error => {
      console.log("Error :", error);
    });

  }

  // createUserVehicles(): FormGroup {
  //   return this.formBuilder.group({
  //     code: this.user.code,
  //     user_id: this.user.code,
  //     plate_number: [''],
  //     vehicle_type: [''],
  //     vehicle_model: [''],
  //     vehicle_color: [''],
  //     date_registered: [new Date()],
  //   });
  // }

  // addVehicles() {
  //   this.userVehicles.push(this.createUserVehicles());
  // }

  async getLogsVia(snapshot){

    let arrayLogs = [];
    snapshot.forEach((doc) => {


      let duration;
      let data = doc.data();

      data.id = doc.id;

      const timeIn = moment.unix(doc.data().time_in.seconds);
      let timeOut;

      if (doc.data().time_in) {
        if (doc.data().actual_end_time) {
          timeOut = moment.unix(doc.data().actual_end_time?.seconds);
        } else if (doc.data().time_out) {
          timeOut = moment.unix(doc.data().time_out?.seconds);
        }
      }

      if(!timeIn && !timeOut) return;

      let startTime = moment(timeIn, "HH:mm:ss a");
      let endTime = moment(timeOut, "HH:mm:ss a");
      let min = moment.utc(moment(endTime, "HH:mm:ss").diff(moment(startTime, "HH:mm:ss"))).format("mm");
      let sec = moment.utc(moment(endTime, "HH:mm:ss").diff(moment(startTime, "HH:mm:ss"))).format("ss");
      let hour = endTime.diff(startTime, 'hours');

      let hourString = hour + "hr, ";
      let minString = min + "min and ";
      let secString = sec + "sec";

      if(hour === 0 && min !== "00"){
        duration = minString + secString;
      }else if(hour === 0 && min === "00"){
        duration = sec + " seconds";
      }else{
        duration = hourString + minString + secString;
      }

      data.duration = duration;
      arrayLogs.push(data);

    });

    Promise.all(arrayLogs).then(async (response) => {
      this.logs = response;
      this.zone = await this.getZoneByLog();
    }).catch((e) =>{
      //Handle errors
    });

  }

  getZoneByLog(): Promise<any> {
    return new Promise((resolve, reject) => {
      const zone = this.zones.find(item => this.logs[0].zone_id === item.zone_id);
      if (zone) {
        resolve(zone);
      } else {
        reject("Zone not found for the given log.");
      }
    });
  }

  calculateActualDuration(log): Promise<any>{

    const timeIn = moment.unix(log.time_in.seconds);
    let timeOut;

    if (log.time_in) {
      if (log.actual_end_time) {
        timeOut = moment.unix(log.actual_end_time?.seconds);
      } else if (log.time_out) {
        timeOut = moment.unix(log.time_out?.seconds);
      }
    }

    if(!timeIn && !timeOut) return;

    let duration;

    let startTime = moment(timeIn, "HH:mm:ss a");
    let endTime = moment(timeOut, "HH:mm:ss a");
    let min = moment.utc(moment(endTime, "HH:mm:ss").diff(moment(startTime, "HH:mm:ss"))).format("mm");
    let sec = moment.utc(moment(endTime, "HH:mm:ss").diff(moment(startTime, "HH:mm:ss"))).format("ss");
    let hour = endTime.diff(startTime, 'hours');

    let hourString = hour + "hr, ";
    let minString = min + "min and ";
    let secString = sec + "sec";

    if(hour === 0 && min !== "00"){
      duration = minString + secString;
    }else if(hour === 0 && min === "00"){
      duration = sec + " seconds";
    }else{
      duration = hourString + minString + secString;
    }

    return duration;

  }

  calculateParkingDuration(log): Promise<any>{

    const timeIn = moment.unix(log.time_in.seconds);
    const timeOut = moment.unix(log.time_out?.seconds);

    if(!timeIn && !timeOut) return;

    let duration;

    let startTime = moment(timeIn, "HH:mm:ss a");
    let endTime = moment(timeOut, "HH:mm:ss a");
    let hour = endTime.diff(startTime, 'hours');

    duration = hour + "hr/s"

    return duration;

  }

  async save(value){

    if(value){

      if(this.userForm.valid){
        await this.vehicleService.updateUsersVehicleByPlate(this.userForm.value, this.user_vehicle.plate_number).then(() =>{
          Swal.fire(
            'Success',
            'Successfully saved',
            'success'
          )

          // this.vehicleService.updateVehicle(this.vehicleForm.value, this.vehicleForm.value.plate_number).then(() =>{
          //   Swal.fire(
          //     'Success',
          //     'Successfully saved!',
          //     'success'
          //   )
          // });

        });
      }
    }else{
      if(!this.vehicleForm.value.plate_number) {
        await Swal.fire(
          'Oops',
          'Plate number is required.',
          'warning'
        )
        return;
      }

      let filteredPlateNumber = this.vehicleForm.get('plate_number').value.toUpperCase()
        .replace(/\s/g, "")
        .replace(/[^a-zA-Z0-9\-]/g, '');
      this.vehicleForm.value.plate_number = filteredPlateNumber;
    if(this.vehicle.uid === undefined){

        let vehicleDetails:any = await this.vehicleService.getVehicleByCode(this.vehicle.code)

        this.vehicle.plate_number = filteredPlateNumber;
        this.vehicle.vehicle_type = this.vehicleForm.value.vehicle_type;
        this.vehicle.vehicle_model = this.vehicleForm.value.vehicle_model;
        this.vehicle.vehicle_color = this.vehicleForm.value.vehicle_color;

            if(vehicleDetails === null){

              this.vehicle.date_registered = new Date();

              this.logService.createPlateByCode(this.vehicle).then(result => {

                this.vehicleService.createPlateByCode(this.vehicle).then(data => {

                  this.vehicleService.addVehicle(this.vehicle).then((result) =>{
                    Swal.fire(
                      'Success',
                      'Successfully saved!',
                      'success'
                    )
                  },error => {
                    console.log("Save Users Vehicle Error:",error)
                  });

                },error => {
                  console.log("2. Error:",error)
                })

              },error =>{
                console.log("3. Error:",error)
              })


            }else{

              this.vehicle.updatedAt = new Date();

              this.logService.updatePlateByCode(this.vehicle).then(result => {

                this.vehicleService.updatePlateByCode(this.vehicle).then(data => {

                  this.vehicle.id = vehicleDetails.id;

                  this.vehicleService.updateVehicleByCode(this.vehicle.code, this.vehicle).then((result) =>{
                    Swal.fire(
                      'Success',
                      'Successfully updated!',
                      'success'
                    )

                  },error => {
                    console.log("Update Users Vehicle Error:",error)
                  });

                },error=>{
                  console.log("2. updatePlateByCode:",error)

                })
              },error=>{
                console.log("3. updatePlateByCode:",error)

              })



            }


      }
    }
  }

  async delete(log: Logs){
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.isConfirmed) {
        this.logService.deleteLogs(log).then(() =>{
          const index: number = this.logs.indexOf(log);
          if (index !== -1) {
            this.logs.splice(index, 1);
            Swal.fire(
              'Deleted!',
              'Log deleted!',
              'success'
            )
          }
        });
      }
    });
  }

  completeSession(log: Logs){
    Swal.fire({
      title: 'Please confirm to complete this session',
      text: "Plate number: "+ log.plate_number +"",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      confirmButtonText: 'Complete session',
      cancelButtonText: 'Cancel',
      allowOutsideClick: false,
      allowEnterKey: false
    }).then((result) => {
      if (result.isConfirmed) {

        const setDataLog: any = {
          id: log.id,
          status: 'completed',
          updatedAt: new Date(),
          end_session_by: "admin",
          end_session_by_id: null,
          session_status: 2
        };

        if (log.id && log.slot_id) {
          const updateZonesSlotData = {
            status: 1,
            log_id: null,
            isExpired: false,
            allowanceTimeDuration: null,
            endTime: null,
            updatedAt: new Date()
          }

          this.zoneSlotService.getZoneSlotById(log.id, log.slot_id, this.account.account_key).then((res) =>{
            if(res){
              this.zoneSlotService.updateZonesSlot(log.slot_id, updateZonesSlotData);
            }
          });

        }

        this.logService.enSessionLog(setDataLog).then((doc:any) =>{

          const index: number = this.logs.indexOf(log);
          if (index !== -1) {

            this.logs[index].status = doc.status;
            this.logs[index].session_status = doc.session_status;

            Swal.fire(
              'Success',
              'Plate: '+ log.plate_number +' was successfully completed',
              'success'
            )
          }

        });
      }
    });
  }

  async endSession(log: Logs){
    Swal.fire({
      title: 'Please confirm to end this session',
      text: "Plate number: "+ log.plate_number +"",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      confirmButtonText: 'End session',
      cancelButtonText: 'Cancel',
      allowOutsideClick: false,
      allowEnterKey: false
    }).then((result) => {
      if (result.isConfirmed) {

        const setDataLog: any = {
          id: log.id,
          status: 'completed',
          updatedAt: new Date(),
          end_session_by: "admin",
          end_session_by_id: null,
          session_status: 2
        };

        if (this.zone.zone_slot_assignment === ZoneSlotAssignment.Manual) {
          setDataLog.actual_end_time = new Date();
        } else {
          setDataLog.time_out = new Date();
        }

        if (log.id && log.slot_id) {
          const updateZonesSlotData = {
            status: 1,
            log_id: null,
            isExpired: false,
            allowanceTimeDuration: null,
            endTime: null,
            updatedAt: new Date()
          }

          this.zoneSlotService.getZoneSlotById(log.id, log.slot_id, this.account.account_key).then((res) =>{
            if(res){
              this.zoneSlotService.updateZonesSlot(log.slot_id, updateZonesSlotData);
            }
          });

        }

        this.logService.enSessionLog(setDataLog).then((doc:any) =>{

          const index: number = this.logs.indexOf(log);
          if (index !== -1) {

            let time_in = (doc.time_in?.seconds * 1000);
            let dateTimeIn = moment(time_in);

            let time_out = (doc.time_out?.seconds * 1000);
            let dateTimeOut = moment(time_out);

            this.logs[index].duration = dateTimeIn.from(dateTimeOut).replace('ago','');
            this.logs[index].status = doc.status;
            this.logs[index].session_status = doc.session_status;
            this.logs[index].time_out = doc.time_out;

            Swal.fire(
              'Success',
              'Plate: '+ log.plate_number +' was successfully ended on '+ new Date(time_out),
              'success'
            )
          }

        });
      }
    });
  }

  isSidebarOpen = false;

  async openSidebar(log: any) {
    console.log(log)
    this.isSidebarOpen = true;
    this.logDetailsSideBar = log;
    this.logDetailsSideBar.actual_duration = await this.calculateActualDuration(log);
    this.logDetailsSideBar.parking_duration = await this.calculateParkingDuration(log);

    // get geotag images
    await this.getGeotagByLogId(log.id);
  }

  // updateLog(){
  //
  //   //TODO: recalculate amount due based on the updated actual end time
  //
  //   const data: any = {
  //     id: this.logDetailsSideBar.id,
  //     updatedAt: new Date(),
  //   };
  //
  //   if (this.logDetailsSideBar.actual_end_time) {
  //     data.actual_end_time = new Date(this.logDetailsSideBar.actual_end_time);
  //   } else if (this.logDetailsSideBar.time_out) {
  //     data.time_out = new Date(this.logDetailsSideBar.time_out);
  //   }
  //
  //   this.logService.updateLog(data).then((res) => {
  //     console.log(res);
  //   });
  // }

  closeSidebar() {
    this.isSidebarOpen = false;
  }

  async getGeotagByLogId(log_id){
    this.geotagService.getGeotagByLogId(log_id).then((res) => {
      this.geoTagsPhotos = res || [];
    });
  }

  // async getPaymentById(payment_id){
  //   this.paymentService.getPaymentById(payment_id).then((res) => {
  //     const payment = res[0];
  //     this.payment = payment || null;
  //     console.log("payment", this.payment);
  //   });
  // }

}
