import { Component, OnInit } from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from "@angular/forms";
import { VehicleTypes } from "src/models/vehicle-types.model";
import {AngularFirestore} from "@angular/fire/firestore";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "src/services/auth.service";
import {VehicleService} from "src/services/vehicle.service";
import {AngularFireAuth} from "@angular/fire/auth";
import {Account} from "src/models/account.model";
import {User} from "src/models/user.model";
import {finalize} from "rxjs/operators";
import {AngularFireStorage} from "@angular/fire/storage";
import {Observable} from "rxjs";
import Swal from "sweetalert2";

@Component({
  selector: 'app-vehicle-types',
  templateUrl: './vehicle-types.component.html',
  styleUrls: ['./vehicle-types.component.css']
})
export class VehicleTypesComponent implements OnInit {

  public formGroup: FormGroup;

  openTab = 1;
  toggleTabs($tabNumber: number){
    this.openTab = $tabNumber;
  }

  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }
  private _color = "light";

  vehicleTypesModel: VehicleTypes;
  account: Account;
  userAuth: User;

  vehicleTypesArray: FormArray;
  vehicleTypes: any = [];

  snapshot: Observable<any>;
  downloadURL: Observable<string>;
  imageUrl: any[] = [];

  constructor(
    private firestore: AngularFirestore,
    protected route : ActivatedRoute,
    public formBuilder: FormBuilder,
    public router: Router,
    public authService: AuthService,
    public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    private storage: AngularFireStorage,
    private vehicleService: VehicleService
  ) {

    this.authService.user$.subscribe(user => this.userAuth = user);
    this.account = JSON.parse(localStorage.getItem('account'));

  }

  ngOnInit(): void{

    this.vehicleTypesArray = this.formBuilder.array([]);

    this.formGroup = this.formBuilder.group({
      vehicleTypes: this.vehicleTypesArray,
    });

    this.vehicleService.getVehicleTypes().then((vehicleTypes) => {
      console.log(vehicleTypes);
      this.vehicleTypes = vehicleTypes;

      if (this.vehicleTypes && this.vehicleTypes.length > 0) {

        for (let index = 0; index < this.vehicleTypes.length; index++) {
          const vehicleType = this.vehicleTypes[index];
          this.vehicleTypesArray.push(this.updateVehicleType(vehicleType));
        }

      } else {
        this.vehicleTypesArray.push(this.createVehicleType());
      }

    });

  }

  ngAfterViewInit(){

  }

  addVehicleType(){
    this.vehicleTypesArray.push(this.createVehicleType());
  }

  updateVehicleType(data: any): FormGroup {
    return this.formBuilder.group({
      id: data.id,
      vehicle_type: data.vehicle_type,
      vehicle_type_name: data.vehicle_type_name,
      vehicle_type_description: data.vehicle_type_description,
      date_created: data.date_created,
      image_url: data.image_url
    });
  }
  createVehicleType(): FormGroup {
    return this.formBuilder.group({
      id: this.firestore.createId(),
      vehicle_type: [''],
      vehicle_type_name: [''],
      vehicle_type_description: [''],
      date_created: [new Date()],
      image_url: ['']
    });
  }
  async save() {

    if (this.authService.canDelete(this.userAuth)) {
      await Swal.fire({
        title: 'Saving vehicle type... please wait...',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: async () => {
          Swal.showLoading();

          console.log("this.vehicleTypesArray", this.vehicleTypesArray.value);

          for (const type of this.vehicleTypesArray.value) {
            await this.vehicleService.addVehicleType(type);
          }

          await Swal.fire({
            title: 'Vehicle type saved!.',
            timer: 2000,
            icon: 'success',
            didClose: () => {
              Swal.hideLoading();
            }
          });

        }
      });
    }

  }

  deleteVehicleType(index: number, vehicleType){

    if(vehicleType.id){
      Swal.fire({
        icon: 'warning',
        html: 'Are you sure you want to delete this vehicle type? ' +'<br/><strong>'+vehicleType.vehicle_type_name+'</strong>',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showCloseButton: true,
        confirmButtonText: 'Delete',
        denyButtonText: `Cancel`,
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {

          await this.vehicleService.deleteVehicleType(vehicleType.id);
          await Swal.fire({
            title: 'Vehicle type deleted!',
            timer: 2000,
            icon: 'success',
            didClose: () => {
              Swal.hideLoading();
              this.vehicleTypesArray.value.splice(index, 1);
              this.vehicleTypesArray.controls.splice(index, 1);
            }
          });

        }
      });
    }else{
      this.vehicleTypesArray.value.splice(index, 1);
      this.vehicleTypesArray.controls.splice(index, 1);
    }

  }

  uploadImage($event: Event, index: number) {

    if(this.authService.canDelete(this.userAuth)){
      let currentDate = Date.now();
      const element = $event.target as HTMLInputElement;
      const file: File = (element.files as FileList)[0];
      const filePath = `vehicle_types/${currentDate}`;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(`vehicle_types/${currentDate}`, file);

      task.snapshotChanges()
        .pipe(
          finalize(() => {
            this.downloadURL = fileRef.getDownloadURL();
            this.downloadURL.subscribe(url => {
              if (url) {

                // Push imageUrl to vehicleTypesArray
                if (index >= 0 && index < this.vehicleTypesArray.value.length) {
                  const vehicleType = this.vehicleTypesArray.at(index);
                  vehicleType.get('image_url').setValue(url);
                }

              }
            });
          })
        )
        .subscribe(url => {
          if (url) {
            console.log(url);
          }
        });
    }else{
      alert("You have no right to upload image. Please contact your administrator. Thank you!");
    }

  }

}
