import { Component, OnInit, AfterViewInit } from "@angular/core";
import Chart from "chart.js";
import JustGage from 'justgage';
import firebase from "firebase";
import {Account} from "src/models/account.model";

@Component({
  selector: "app-card-occupancy-chart",
  templateUrl: "./card-occupancy-chart.component.html",
})
export class CardOccupancyChartComponent implements OnInit, AfterViewInit {

  date = new Date();
  activeSession: number = 0;
  gauge: any;
  account: Account;

  constructor() {
    this.account = JSON.parse(localStorage.getItem('account'));
  }

  async ngOnInit() {
    await this.getCountLogsActiveSession();
  }

  ngAfterViewInit() {

    this.gauge = new JustGage({
      id: "gauge",
      value: this.activeSession,
      min: 0,
      max: 100,
      gaugeWidthScale: 1.2,
      gaugeColor: '#eeeeee',
      minLabelMinFontSize: 10,
      maxLabelMinFontSize: 10,
      relativeGaugeSize: true,
      valueFontColor: '#f74e64',
      levelColors: ["#f74e64", "#f74e64", "#f74e64"],
      minTxt: "Occupied",
      maxTxt: "Available",
      symbol: "%",
    });

  }

  getStartOfToday() {
    const now = new Date();
    now.setHours(0, 0, 0, 0); // +5 hours for Eastern Time
    return firebase.firestore.Timestamp.fromDate(now);
  }

  async getCountLogsActiveSession(){
    // console.log(this.account)
    let numberAvailableSpace =  this.account.settings.parkingFee.numberAvailableSpace || 0;
    const query = firebase.firestore().collection('logs');
    const observer = query
      .where('account_key', '==', this.account.account_key)
      .where('status', '==', 'incomplete')
      .where('time_out', '==', null)
      .where('timestamp', '>=', this.getStartOfToday())
      .onSnapshot(querySnapshot => {
        let parkingSpaceAvailablePercent =  (  querySnapshot.size / numberAvailableSpace ) * 100 ;
        if(parkingSpaceAvailablePercent !== Infinity){
          this.activeSession = parkingSpaceAvailablePercent
        }else{
          this.activeSession = 0;
        }
        this.gauge.refresh(this.activeSession);
      }, err => {
        console.log(`Encountered error: ${err}`);
      });

  }

}
