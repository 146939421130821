import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {User} from "../../../../../models/user.model";
import {Account, Settings} from "../../../../../models/account.model";
import {AngularFirestore} from "@angular/fire/firestore";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../../../../services/auth.service";
import {AngularFireAuth} from "@angular/fire/auth";
import {SettingsService} from "../../../../../services/settings.service";
import {AccountService} from "../../../../../services/account.service";
import Swal from "sweetalert2";
import {combineLatest, Observable, of} from "rxjs";
import {filter, map, mergeMap} from "rxjs/operators";

@Component({
  selector: 'app-update-employee',
  templateUrl: './update-employee.component.html',
  styleUrls: ['./update-employee.component.css']
})
export class UpdateEmployeeComponent implements OnInit {

  public employeeForm: FormGroup;
  userAuth: User;
  isPasswordVisible: boolean;

  settings: Settings;
  account: Account;
  zones:any = [];
  employeesArray = [];
  employee: any = {};

  constructor(
    private firestore: AngularFirestore,
    protected route : ActivatedRoute,
    public formBuilder: FormBuilder,
    public router: Router,
    public authService: AuthService,
    public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    private settingsService: SettingsService,
    private accountService: AccountService,
  ) {

    this.authService.user$.subscribe(user => this.userAuth = user);
    this.account = JSON.parse(localStorage.getItem('account'));
    this.zones = this.account.settings.zones;

  }

  ngOnInit(): void {

    this.initializeEmployees();

    this.employeeForm = this.formBuilder.group({
      employee_id: this.firestore.createId(),
      first_name: [''],
      last_name: [''],
      mobile_number: [''],
      username: [''],
      password_pin: [''],
      position: [''],
      zone_id: [''],
      date_created: [new Date()],
    });

    this.employeeForm.patchValue(this.employee);

    this.route.paramMap
      .pipe(
        map(pm => pm.get('id')),
        filter(id => id != null),
        mergeMap(id => this.getEmployeeById(id)),
      ).subscribe((employee) =>{

        this.employee = employee;

        this.employeeForm = this.formBuilder.group({
          employee_id: this.employee.employee_id,
          first_name: this.employee.first_name,
          last_name: this.employee.last_name,
          mobile_number: this.employee.mobile_number || '',
          username: this.employee.username,
          password_pin: this.employee.password_pin,
          position: this.employee.position,
          zone_id: this.employee.zone_id,
          date_created: this.employee.date_created,
        });

        this.employeeForm.patchValue(employee);

    });

    this.employeeForm.get('zone_id')?.valueChanges.subscribe(async value => {
      if (value) {
        const selectedZone = this.zones.find(zone => zone.zone_id === value);
        const message = `You want to re-assign ${this.employee.first_name} ${this.employee.last_name} to zone ${selectedZone?.zone_name}?`;
        await this.confirmAction(message, () => this.save());
      }
    });

    this.employeeForm.get('position')?.valueChanges.subscribe(async value => {
      if (value) {
        const message = `You want to change the position for ${this.employee.first_name} ${this.employee.last_name}?`;
        await this.confirmAction(message, () => this.save());
      }
    });

  }

  async confirmAction(message: string, callback: () => void) {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: message,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, confirm',
      cancelButtonText: 'No, cancel'
    });

    if (result.isConfirmed) {
      callback();
    }
  }

  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  async generateUsernamePassword(action: any) {

    if (this.authService.canEdit(this.userAuth)) {

      const employee = this.employeeForm.value;

      if( employee.first_name === '' || employee.last_name === ''){
        await Swal.fire({
          title: 'Oops!',
          html: 'Please fill-up First name and Last name to generate username and pin.',
          icon: 'warning',
          denyButtonText: `Got it!`,
        });

        return false;
      }

      if( employee.position === '' || employee.zone_id === ''){

        await Swal.fire({
          title: 'Please fill-up all the fields.',
          icon: 'warning',
          denyButtonText: `Got it!`,
        });

        return false;

      }

      if(action === 'username' && !employee.username){
        Swal.fire({
          icon: 'info',
          html: '<span>Generate Username for ' + employee.first_name + ' ' + employee.last_name + '?</span>',
          allowEscapeKey: false,
          allowOutsideClick: false,
          showCloseButton: true,
          confirmButtonText: 'Generate',
          denyButtonText: `Cancel`,
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            this.generateUsername(employee);
          }
        })
      }else if(action === 'userpin' && !employee.password_pin){
        Swal.fire({
          icon: 'info',
          html: '<span>Generate PIN for ' + employee.first_name + ' ' + employee.last_name + '?</span>',
          allowEscapeKey: false,
          allowOutsideClick: false,
          showCloseButton: true,
          confirmButtonText: 'Generate',
          denyButtonText: `Cancel`,
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            this.generateUserPin(employee);
          }
        })
      }else{

        Swal.fire({
          icon: 'warning',
          html: '<span>Do you want to regenerate user <b>PIN</b> for ' + employee.first_name + ' ' + employee.last_name + '?</span>',
          allowEscapeKey: false,
          allowOutsideClick: false,
          showCloseButton: true,
          confirmButtonText: 'Regenerate PIN',
          denyButtonText: `Cancel`,
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            this.generateUserPin(employee);
          }
        })
      }

    }else{
      await Swal.fire(
        'Oops',
        "Sorry, you don't have the right to access this function. Please contact the admin. Thank you.",
        'warning'
      )
    }
  }

  async generateUsername(employee: any){

    let username = this.createUsername(employee.first_name, employee.last_name);
    //let password = this.generatePin();

    const isUsernameAlreadyExist = (usernameToCheck) => {
      return this.employeesArray.some(employee =>
        employee.username === usernameToCheck
      );
    };

    if (isUsernameAlreadyExist(username)) {
      await Swal.fire({
        title: 'Oops!',
        html: 'Employee username is already exist. Please use another name and generate username and pin button again.',
        icon: 'warning',
        denyButtonText: `Got it!`,
      });

      return false;
    }

    await Swal.fire({

      title: 'Generating... please wait...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: async () => {
        Swal.showLoading();

        employee.username = username;
        //employee.password_pin = password;
        this.employeeForm.patchValue(employee);

        Swal.fire({
          title: 'Successfully generated!',
          html: "<strong>Username:</strong> " + username,
          icon: 'success',
          showConfirmButton: true,
          showCloseButton: true,
          allowOutsideClick: false,
          confirmButtonText: 'Save and close',
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.hideLoading();
            this.save();
          }
        });

      },});
  }

  async generateUserPin(employee: any){

    let password = this.generatePin();

    if (!employee.username) {
      await Swal.fire({
        title: 'Oops!',
        html: 'Please generate username first.',
        icon: 'warning',
        denyButtonText: `Got it!`,
      });

      return false;
    }

    await Swal.fire({

      title: 'Generating... please wait...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: async () => {
        Swal.showLoading();

        employee.password_pin = password;
        this.employeeForm.patchValue(employee);

        Swal.fire({
          title: 'Successfully generated!',
          html: "<strong>Username:</strong> " + employee.username + " " + "<strong>Password:</strong> " + password,
          icon: 'success',
          showConfirmButton: true,
          showCloseButton: true,
          allowOutsideClick: false,
          confirmButtonText: 'Save and close',
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.hideLoading();
            this.save();
          }
        });

      },});
  }

  async save() {

    const employee = this.employeeForm.value;

    if(
      employee.first_name === '' || employee.last_name === ''
      || employee.position === '' || employee.zone_id === ''){

      await Swal.fire({
        title: 'Please fill-up all the fields.',
        icon: 'warning',
        denyButtonText: `Got it!`,
      });

      return false;

    }

    if (
      employee.username === '' || employee.password_pin === '') {

      await Swal.fire({
        title: 'Oops!',
        html: 'Employee must have username and password. Please generate by clicking the Generate username and pin button.',
        icon: 'warning',
        denyButtonText: `Got it!`,
      });

      return false;

    }

    let accountId;
    if (this.account.id) {
      accountId = this.account.id;
    } else {
      accountId = this.account.account_id;
    }

    await Swal.fire({
      title: 'Save settings... please wait...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: async () => {
        Swal.showLoading();
        await this.updateEmployee(employee, accountId);
      }
    });

  }

  async updateEmployee(employee, accountId) {
    const index = this.account.settings.zones.findIndex(zone => zone.zone_id === employee.zone_id);

    if (index !== -1) {

      if(this.employee.zone_id !== employee.zone_id){
        this.removeEmployeeFromCurrentZone();
        await this.saveEmployeeToNewZone(employee, accountId);
      }else{

        // Find the index of the employee in the zone's employees array
        const employeeIndex = this.account.settings.zones[index].employees.findIndex(emp => emp.employee_id === employee.employee_id);

        if (employeeIndex !== -1) {
          // Update the existing employee with the new data
          this.account.settings.zones[index].employees[employeeIndex] = { ...this.account.settings.zones[index].employees[employeeIndex], ...employee };
          await this.settingsService.updateSettings(this.account.settings, this.account.account_key);
          combineLatest([
            this.settingsService.addSettingsToAccount(accountId, this.account.settings),
            this.accountService.getAccountByKey(this.account.account_key)
          ]).subscribe(([a, b]) => {
            localStorage.removeItem('account');
            localStorage.setItem('account', JSON.stringify(b));

            let timerInterval;
            Swal.fire({
              title: 'Settings successfully saved.',
              html: 'It will close in <strong></strong> seconds. Please wait.',
              timer: 5000,
              icon: 'success',
              showCancelButton: false,
              showConfirmButton: false,
              allowOutsideClick: false,
              didOpen: () => {
                timerInterval = setInterval(() => {
                  Swal.getHtmlContainer().querySelector('strong')
                    .textContent = (Swal.getTimerLeft() / 1000)
                    .toFixed(0)
                }, 100)
              },
              willClose: () => {
                clearInterval(timerInterval)
              },
              didClose: () => {
                Swal.hideLoading();
                this.router.navigateByUrl(this.account.account_key+'/user/employees', {replaceUrl: true});
              }
            });

          });
        }else{
          this.removeEmployeeFromCurrentZone();
          await this.saveEmployeeToNewZone(employee, accountId);
        }
      }
    } else {
      // Handle the case where the zone with the given ID is not found in the account settings
      console.error('Zone not found in account settings.');
    }
  }

  async saveEmployeeToNewZone(employee, accountId) {
    // Assuming you have a target zone ID (replace 'TARGET_ZONE_ID' with the actual target zone ID)
    const targetZoneId = employee.zone_id;

    // Find the index of the target zone
    const targetZoneIndex = this.account.settings.zones.findIndex(zone => zone.zone_id === targetZoneId);

    if (targetZoneIndex !== -1) {
      // Add the employee to the target zone
      this.account.settings.zones[targetZoneIndex].employees.push(employee);

      // Update the settings
      await this.settingsService.updateSettings(this.account.settings, this.account.account_key);
      combineLatest([
        this.settingsService.addSettingsToAccount(accountId, this.account.settings),
        this.accountService.getAccountByKey(this.account.account_key)
      ]).subscribe(([a, b]) => {
        localStorage.removeItem('account');
        localStorage.setItem('account', JSON.stringify(b));

        let timerInterval;
        Swal.fire({
          title: 'Settings successfully saved.',
          html: 'It will close in <strong></strong> seconds. Please wait.',
          timer: 5000,
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => {
            timerInterval = setInterval(() => {
              Swal.getHtmlContainer().querySelector('strong')
                .textContent = (Swal.getTimerLeft() / 1000)
                .toFixed(0)
            }, 100)
          },
          willClose: () => {
            clearInterval(timerInterval)
          },
          didClose: () => {
            Swal.hideLoading();
            this.router.navigateByUrl(this.account.account_key+'/user/employees', {replaceUrl: true});
          }
        });

      });
    } else {
      // Handle the case where the target zone is not found
      console.error('Target zone not found.');
    }
  }

  // Function to remove the employee from the current zone
  removeEmployeeFromCurrentZone() {
    const currentZoneIndex = this.account.settings.zones.findIndex(zone => zone.zone_id === this.employee.zone_id);

    if (currentZoneIndex !== -1) {
      const employeeIndexInCurrentZone = this.account.settings.zones[currentZoneIndex].employees.findIndex(emp => emp.employee_id === this.employee.employee_id);

      if (employeeIndexInCurrentZone !== -1) {
        // Remove the employee from the current zone
        this.account.settings.zones[currentZoneIndex].employees.splice(employeeIndexInCurrentZone, 1);
      } else {
        console.log('Employee not found in the current zone.');
      }
    } else {
      console.log('Current zone not found in account settings.');
    }
  }

  generatePin() {
    const min = 100000; // The minimum 6-digit number
    const max = 999999; // The maximum 6-digit number
    const pin = Math.floor(Math.random() * (max - min + 1)) + min;
    return pin.toString(); // Convert the number to a string
  }

  createUsername(firstName: string, lastName: string) {
    // Convert the first name and last name to lowercase
    const firstNameLower = firstName.toLowerCase();
    const lastNameLower = lastName.toLowerCase();

    // Remove any spaces or special characters from the first and last names
    const firstNameClean = firstNameLower.replace(/\W/g, '');
    const lastNameClean = lastNameLower.replace(/\W/g, '');

    // Combine the first and last names to create the username
    return `${firstNameClean}${lastNameClean}`;
  }

  initializeEmployees(){
    this.employeesArray = this.zones.flatMap(zone =>
      zone.employees.map(employee => ({
        employee_id: employee.employee_id,
        first_name: employee.first_name,
        last_name: employee.last_name,
        password_pin: employee.password_pin,
        position: employee.position,
        username: employee.username,
        mobile_number: employee.mobile_number || '',
        date_created: employee.date_created,
        zone_id: zone.zone_id
      }))
    );
  }

  getEmployeeById(employee_id: string): Observable<any> {

    const foundEmployee = this.employeesArray.find(employee => employee.employee_id === employee_id);
    if (foundEmployee) {
      return of(foundEmployee);
    } else {
      // Handle the case where the employee with the given ID is not found
      return of(null); // You might want to return an empty object or throw an error based on your requirements
    }

  }

}
