import { Component, OnInit } from "@angular/core";
import {filter} from "rxjs/operators";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {AuthService} from "../../../../services/auth.service";
import {User} from "../../../../models/user.model";

@Component({
  selector: "app-admin-navbar",
  templateUrl: "./admin-navbar.component.html",
})
export class AdminNavbarComponent implements OnInit {

  currentRoute: string;
  userAuth: User;

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    public auth: AuthService,
  ) {

    this.auth.user$.subscribe(user => this.userAuth = user);
    
    this.router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd)
    ).subscribe((res) => {
      this.currentRoute = res.url.split('/').slice(-1)[0];
      // console.log(this.currentRoute);
    });

  }

  ngOnInit(): void {}
}
