import {Component, OnInit, Input, ViewChild, ElementRef} from "@angular/core";
import { UserService  } from "src/services/user.service";
import { UserLogs, User } from "src/models/user.model";
import {createPopper} from "@popperjs/core";
import {Router} from "@angular/router";
import Swal from "sweetalert2";
import {AuthService} from "src/services/auth.service";
import { SettingsService } from "src/services/settings.service";
import { Account, Settings, Zones} from "src/models/account.model";
import firebase from "firebase";

@Component({
  selector: 'app-card-account-setting-table',
  templateUrl: './card-account-setting-table.component.html',
  styleUrls: ['./card-account-setting-table.component.css']
})
export class AccountSettingTableComponent implements OnInit {

  //@Input()
  // dropdownPopoverShow = false;
  @ViewChild("btnDropdownRef", { static: false }) btnDropdownRef: ElementRef;
  @ViewChild("popoverDropdownRef", { static: false })
  popoverDropdownRef: ElementRef;

  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }
  private _color = "light";

  settings: Settings[] = [];
  userAuth: User;
  account: any;

  constructor(
    protected router: Router,
    public auth: AuthService
  ) {

    this.account = JSON.parse(localStorage.getItem('account') || null);

    if(this.account && this.account.account_key){
      this.getAccountByKey(this.account.account_key);
    }

    this.auth.user$.subscribe(user => this.userAuth = user);
  }

  ngOnInit(): void {}

  async edit(zone: Zones){
    if(this.auth.canEdit(this.userAuth)){
      await this.router.navigateByUrl(this.account.account_key+'/user/settings/zone-update/' + zone.zone_id);
    }else{
      await Swal.fire(
        'Warning',
        "Sorry, access denied. Please contact admin.",
        'warning'
      )
    }
  }

  async getAccountByKey(account_key: string) {
    const query = firebase.firestore().collection('accounts');
    query
      .where("account_key", "==", account_key)
      .limit(1).onSnapshot((snapshots) => {

      if (snapshots.empty) return;

      snapshots.forEach((doc) => {
        let account = doc.data();
        account.account_id = doc.id;
        localStorage.removeItem('account');
        localStorage.setItem('account', JSON.stringify(account));
        this.account = account;
        if(this.account.settings){
          this.settings = this.account.settings.zones;
        }
      });

    });
  }

  async add(){
    if(this.auth.canEdit(this.userAuth)){
      this.router.navigate([this.account.account_key+'/user/settings/zone-add']);
    }else{
      await Swal.fire(
        'Warning',
        "Sorry, access denied. Please contact admin.",
        'warning'
      )
    }
  }

  // NOTE : TODO.
  async delete(zone: Zones){
    // if(this.auth.canDelete(this.userAuth)){
    //   if(user.uid){

    //     await Swal.fire({
    //       title: 'Are you sure?',
    //       text: 'You will not be able to recover this user!',
    //       icon: 'warning',
    //       showCancelButton: true,
    //       confirmButtonText: 'Yes, delete it!',
    //       cancelButtonText: 'No, keep it'
    //     }).then((result) => {
    //       if (result.isConfirmed) {

    //         this.userService.deleteUser(user).then(() =>{
    //           Swal.fire(
    //             'Success',
    //             'User deleted!',
    //             'success'
    //           )
    //         });

    //       } else if (result.dismiss === Swal.DismissReason.cancel) {

    //       }

    //     })

    //   }
    // }else{
    //   await Swal.fire(
    //     'Warning',
    //     "Sorry, access denied. Please contact admin.",
    //     'warning'
    //   )
    // }
  }
}
