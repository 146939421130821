import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { ScheduledEmailReport } from 'src/models/email-schedule.model';
import { User } from 'src/models/user.model';
import { AuthService } from 'src/services/auth.service';
import { EmailSchedulingService } from 'src/services/email-scheduling.service';
import Swal from "sweetalert2";

@Component({
  selector: 'app-email-automation',
  templateUrl: './email-automation.component.html',
})
export class EmailAutomationComponent implements OnInit {

  @ViewChild("btnDropdownRef", { static: false }) btnDropdownRef: ElementRef;
  @ViewChild("popoverDropdownRef", { static: false })
  popoverDropdownRef: ElementRef;

  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }
  private _color = "light";


  public emailSchedules: ScheduledEmailReport[];
  public userAuth: User;

  constructor(
    private emailSchedulingService : EmailSchedulingService,
    private firestore: AngularFirestore,
    public auth: AuthService,
    protected router: Router,
  
  ) { 
    this.auth.user$.subscribe(user => this.userAuth = user);

    this.emailSchedulingService.getEmailSchedules().subscribe((emailSchedule) =>{
      this.emailSchedules = emailSchedule;
    });
  }

  ngOnInit(): void {}

  async edit(emailSchedule: ScheduledEmailReport){
    if(this.auth.canEdit(this.userAuth)){
      await this.router.navigateByUrl('/admin/email-automation/edit/' + emailSchedule.id);
    }else{
      await Swal.fire(
        'Warning',
        "Sorry, access denied. Please contact admin.",
        'warning'
      )
    }
  }

  async add(){
    if(this.auth.canEdit(this.userAuth)){
      await this.router.navigateByUrl('/admin/email-automation/add');
    }else{
      await Swal.fire(
        'Warning',
        "Sorry, access denied. Please contact admin.",
        'warning'
      )
    }
  }

  async delete(scheduledEmailReport: ScheduledEmailReport){
    if(this.auth.canDelete(this.userAuth)){
      if(scheduledEmailReport.id){

        await Swal.fire({
          title: 'Are you sure?',
          text: 'You will not be able to recover this email schedule!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, keep it'
        }).then((result) => {
          if (result.isConfirmed) {

            this.emailSchedulingService.deleteScheduledEmailReport(scheduledEmailReport).then(() =>{
              Swal.fire(
                'Success',
                'Email schedule deleted!',
                'success'
              )
            });

          } else if (result.dismiss === Swal.DismissReason.cancel) {

          }

        })

      }
    }else{
      await Swal.fire(
        'Warning',
        "Sorry, access denied. Please contact admin.",
        'warning'
      )
    }
  }


}
