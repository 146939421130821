import {Component, OnInit} from '@angular/core';

import {ActivatedRoute, Router} from "@angular/router";
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';

import {SettingsService} from "src/services/settings.service";
import {AccountService} from "src/services/account.service";
import {VehicleService} from "src/services/vehicle.service";

import {AngularFirestore} from "@angular/fire/firestore";
import Swal from "sweetalert2";

import {AuthService} from "src/services/auth.service";
import {AngularFireAuth} from "@angular/fire/auth";

import {Account, Settings, Zones} from "src/models/account.model";
import {IDropdownSettings} from 'ng-multiselect-dropdown';
import {combineLatest} from "rxjs";
import {User} from 'src/models/user.model';
import {VehicleTypes} from 'src/models/vehicle-types.model';
import {ZoneSlotAssignment} from "src/enum/enum";
import firebase from "firebase";

@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.css']
})
export class UpdateZoneComponent implements OnInit {


  public userForm: FormGroup;
  dropdownUserSettings:IDropdownSettings;
  dropdownPlanSettings:IDropdownSettings;

  openTab = 1;
  toggleTabs($tabNumber: number){
    this.openTab = $tabNumber;
  }

  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }
  private _color = "light";

  settings: Settings;
  account: any;

  minimumDurationTime = new Date();

  zones:Zones;
  zone: any = {};
  vehicleTypes: VehicleTypes[] = [];

  zonesArray: FormArray;
  employeesArray: FormArray;
  customizeRateArray: FormArray;
  devices: any = [];

  userAuth: User;

  isPasswordVisible: boolean[] = [];

  add_zone_slots: any = [];
  deviceFetching: boolean;

  constructor(
    private firestore: AngularFirestore,
    protected route : ActivatedRoute,
    public formBuilder: FormBuilder,
    public router: Router,
    public authService: AuthService,
    public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    private settingsService: SettingsService,
    private accountService: AccountService,
    private vehicleService: VehicleService,
  ) {

    this.authService.user$.subscribe(user => this.userAuth = user);
    this.account = JSON.parse(localStorage.getItem('account') || null);

    if(this.account && this.account.account_key){
      this.getAccountByKey(this.account.account_key);

      const zone_id = this.route.snapshot.paramMap.get('zone_id');
      if(zone_id){
        this.zone = this.account.settings.zones.find(asz=> asz.zone_id == zone_id);
        console.log('this.zone', this.zone);
        if(this.zone) {
          this.zones = this.zone;

          if(this.zone.devices && this.zone.devices.length > 0){
            this.accountService.getDevicesByIds(this.zone.devices).then(devices => {
              this.devices = devices;
            }).catch(error => {
              console.error('Error fetching devices:', error);
            });
          }else{
            console.log('no device found');
          }

          this.openTab = 4
        }
      }
    }

    this.vehicleService.getVehicleTypes().then((res) =>{
      this.vehicleTypes = res;
    });

  }

  ngOnInit(): void {

    this.zonesArray = this.formBuilder.array([]);
    this.employeesArray = this.formBuilder.array([]);
    this.customizeRateArray = this.formBuilder.array([]);
    this.userForm = this.formBuilder.group({
      gracePeriod:[''],
      minimumDurationTime: ['' ],
      minimumRate: [''],
      maximumDurationTime: [''],
      startingDurationTime: [''],
      additionalRate: [''],
      overnightRate: [''],
      numberAvailableSpace: [''],
      businessHours: [''],
      zones: this.zonesArray,
      employees: this.employeesArray,
      customizeRates: this.customizeRateArray,
      weekDaysOpeningHour:[''],
      weekDaysclosingHour:[''],
      weekendsOpeningHour:[''],
      weekendsclosingHour:[''],
      rateType:[''],
      promoDailyRate:[''],
      promoOvernigthRate:['']
    });

    this.userForm = this.formBuilder.group({
      gracePeriod:this.zones.parkingFee?.gracePeriod,
      minimumDurationTime: this.zones.parkingFee?.minimumDurationTime,
      maximumDurationTime: this.zones.parkingFee?.maximumDurationTime,
      startingDurationTime: this.zones.parkingFee?.startingDurationTime,
      minimumRate: this.zones.parkingFee?.minimumRate,
      additionalRate: this.zones.parkingFee?.additionalRate,
      overnightRate: this.zones.parkingFee?.overnightRate,
      numberAvailableSpace: this.zones.parkingFee?.numberAvailableSpace,
      businessHours: this.zones.parkingFee?.businessHours,
      zones: this.zonesArray,
      employees: this.employeesArray,
      customizeRateArray: this.customizeRateArray,
      weekDaysOpeningHour: this.zones.parkingFee?.weekDaysOpeningHour,
      weekDaysclosingHour:this.zones.parkingFee?.weekDaysclosingHour,
      weekendsOpeningHour:this.zones.parkingFee?.weekendsOpeningHour,
      weekendsclosingHour:this.zones.parkingFee?.weekendsclosingHour,
      rateType: this.zones.parkingFee?.rateType == undefined ? 'default' : this.zones.parkingFee?.rateType,
      promoDailyRate: this.zone.parkingFee.promoDailyRate,
      promoOvernigthRate: this.zone.parkingFee.promoOvernightRate
    });
    this.userForm.patchValue(this.zones);
  }

  ngAfterViewInit(){


    if(this.zones){
      this.zonesArray.push(this.updateZone(this.zones));

        if(!this.zones.employees){
          this.employeesArray.push(this.createEmployee());
        }else{
          for (let index = 0; index < this.zones.employees.length; index++) {
            const employee = this.zones.employees[index];
            this.employeesArray.push(this.updateEmployee(employee));
          }
        }

        if(!this.zones.customizeRates){
          this.customizeRateArray.push(this.createCustomizeRate());
        }else{
          for (let index = 0; index < this.zones.customizeRates.length; index++) {
            const cRate = this.zones.customizeRates[index];
            this.customizeRateArray.push(this.updateCuztomizeRate(cRate));
          }
        }
    }

  }

  createStation(): FormGroup {
    return this.formBuilder.group({
      station_id: this.firestore.createId(),
      station_name: [''],
      station_description: [''],
      date_created: [new Date()],
    });
  }

  createZone(): FormGroup {
    return this.formBuilder.group({
      zone_id: this.firestore.createId(),
      zone_name: [''],
      zone_slots: [''],
      zone_description: [''],
      date_created: [new Date()],
      zone_slot_assignment: ZoneSlotAssignment.Manual
    });
  }

  createCustomizeRate() :FormGroup {
    return this.formBuilder.group({
      customize_rate_id: this.firestore.createId(),
      vehicle_type: [''],
      initial_hour_rate: [''],
      additional_rate:[''],
      date_created: [new Date()],
      vehicle_image: [''],
      vehicle_type_name: [''],
      vehicle_type_custom_name: [''],
      custom_overnight_rate: ['']
    });
  }

  updateCuztomizeRate(data):FormGroup{
    return this.formBuilder.group({
      customize_rate_id: this.firestore.createId(),
      vehicle_type: data.vehicle_type,
      vehicle_type_custom_name: data.vehicle_type_custom_name,
      initial_hour_rate: data.initial_hour_rate,
      additional_rate:data.additional_rate,
      custom_overnight_rate: data.custom_overnight_rate
    });
  }

  updateStation(data): FormGroup {
    return this.formBuilder.group({
      station_id: this.firestore.createId(),
      station_name: data.station_name,
      station_description: data.station_description,
    });
  }

  createEmployee(): FormGroup {
    return this.formBuilder.group({
      employee_id: this.firestore.createId(),
      first_name: [''],
      last_name: [''],
      username: [''],
      password_pin: [''],
      position: [''],
      zone_id: [''],
      date_created: [new Date()],
    });
  }

  deleteEmployee(index:any){
    this.employeesArray.value.splice(index, 1);
    this.employeesArray.controls.splice(index, 1);
  }

  async addEmployee() {
    if (this.authService.canDelete(this.userAuth)) {
      this.employeesArray.push(this.createEmployee());
    } else {
      await Swal.fire(
        'Oops',
        "Sorry, you don't have the right to access this function. Please contact the admin. Thank you.",
        'warning'
      )

    }
  }

  async generateUsernamePassword(index: number) {

    if (this.authService.canDelete(this.userAuth)) {

      const employee = this.employeesArray.value[index];

      if(!employee.username && !employee.password_pin){
        Swal.fire({
          icon: 'info',
          html: '<span>Generate Username and Password for ' + employee.first_name + ' ' + employee.last_name + '?</span>',
          allowEscapeKey: false,
          allowOutsideClick: false,
          showCloseButton: true,
          confirmButtonText: 'Generate',
          denyButtonText: `Cancel`,
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            this.generate(employee, index);
          }
        })
      }else{

        Swal.fire({
          icon: 'warning',
          html: '<span>Do you want to regenerate username and password for ' + employee.first_name + ' ' + employee.last_name + '?</span>'
            + '<br><br><span *ngIf='+employee.username+'><b>Username: </b>' + employee.username + '</span> ' +
            '<span *ngIf='+employee.password_pin+'><b>Password: </b>' + employee.password_pin + '</span>',
          allowEscapeKey: false,
          allowOutsideClick: false,
          showCloseButton: true,
          confirmButtonText: 'Regenerate',
          denyButtonText: `Cancel`,
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            this.generate(employee, index);
          }
        })
      }

    }else{
      await Swal.fire(
        'Oops',
        "Sorry, you don't have the right to access this function. Please contact the admin. Thank you.",
        'warning'
      )
    }
  }

  async generate(employee: any, index: number){
    let username = this.createUsername(employee.first_name, employee.last_name);
    let password = this.generatePin();

    await Swal.fire({
      title: 'Generating... please wait...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: ()=>{
        Swal.showLoading();

        employee.username = username;
        employee.password_pin = password;
        this.employeesArray.value[index] = employee;

        let accountId;
        if(this.account.id){
          accountId = this.account.id;
        }else{
          accountId = this.account.account_id;
        }

        Swal.fire({
          title: 'Successfully generated!',
          html: "<strong>Username:</strong> " + username + " " + "<strong>Password:</strong> " + password,
          icon: 'success',
          showConfirmButton: true,
          showCloseButton: true,
          allowOutsideClick: false,
          confirmButtonText: 'Save and close',
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.hideLoading();
            const index = this.employeesArray.controls.findIndex(x => x.get('employee_id').value === employee.employee_id);
            if (index >= 0) {
              this.employeesArray.controls[index].patchValue(employee);
            }

            this.save();

          }
        });

      },});
  }

  generatePin() {
    const min = 100000; // The minimum 6-digit number
    const max = 999999; // The maximum 6-digit number
    const pin = Math.floor(Math.random() * (max - min + 1)) + min;
    return pin.toString(); // Convert the number to a string
  }

  createUsername(firstName: string, lastName: string) {
    // Convert the first name and last name to lowercase
    const firstNameLower = firstName.toLowerCase();
    const lastNameLower = lastName.toLowerCase();

    // Remove any spaces or special characters from the first and last names
    const firstNameClean = firstNameLower.replace(/\W/g, '');
    const lastNameClean = lastNameLower.replace(/\W/g, '');

    // Combine the first and last names to create the username
    return `${firstNameClean}${lastNameClean}`;
  }

  updateEmployee(data): FormGroup {
    return this.formBuilder.group({
      date_updated: new Date(),
      date_created: data.date_created,
      employee_id: data.employee_id,
      first_name: data.first_name,
      last_name: data.last_name,
      position: data.position,
      username: data.username,
      zone_id: data.zone_id,
      password_pin: data.password_pin,
    });
  }

  updateZone(data): FormGroup {
    return this.formBuilder.group({
      zone_id: data.zone_id,
      zone_name: data.zone_name,
      zone_slots: data.zone_slots,
      zone_description: data.zone_description,
      zone_slot_assignment: data.zone_slot_assignment
    });
  }

  deleteZone(index:any){
    this.zonesArray.value.splice(index, 1);
    this.zonesArray.controls.splice(index, 1);
  }

  async addZone() {
    if (this.authService.canDelete(this.userAuth)) {
      this.zonesArray.push(this.createZone());
    } else {
      await Swal.fire(
        'Oops',
        "Sorry, you don't have the right to access this function. Please contact the admin. Thank you.",
        'warning'
      )

    }
  }

  async addCustomize(){
    if(this.authService.canDelete(this.userAuth)){
      this.customizeRateArray.push(this.createCustomizeRate())
    }else{
      await Swal.fire(
        'Oops',
        "Sorry, you don't have the right to access this function. Please contact the admin. Thank you.",
        'warning'
      )

    }
  }

  deleteCustomize(data){
    let vehicle = data.value;
    let index = this.customizeRateArray.value.findIndex(cra => cra.customize_rate_id === vehicle.customize_rate_id);
    this.customizeRateArray.value.splice(index, 1);
    this.customizeRateArray.controls.splice(index, 1);
  }

  isOptionDisabled(vehicleType: string, currentIndex: number): boolean {
    for (let i = 0; i < this.customizeRateArray.value.length; i++) {
      if (i !== currentIndex) {
        const control = this.customizeRateArray.at(i).get('vehicle_type');
        if (control.value === vehicleType) {
          return true;
        }
      }
    }
    return false;
  }

  zoneSlotChange(event: any) {
    const changeValue = event.target.checked;
    this.zones.zone_slot_assignment = changeValue ? 1 : 2;
  }

  async save(){

    if(
      this.customizeRateArray.value.length === 0 ||
      this.userForm.value.employees.length === 0 ||
      this.userForm.value.gracePeriod === null ||  this.userForm.value.startingDurationTime === null){

      await Swal.fire({
        title: 'Please fill-up all the settings. Thanks.',
        icon: 'warning',
        denyButtonText: `Got it!`,
      });

      return false;

    }

    if(this.authService.canDelete(this.userAuth)){

      this.customizeRateArray.value.forEach(cRate => {
        const vehicleType = cRate.vehicle_type || 'default';
        const matchedType = this.vehicleTypes.find(type => type.vehicle_type === vehicleType);
        cRate.vehicle_image = matchedType?.image_url || 'assets/img/light-vehicle.png';
        cRate.vehicle_type_name = matchedType?.vehicle_type_name || 'Light vehicle';
      });

      //Convert grace period to decimal
      // this.userForm.value.gracePeriod = GracePeriod.find(period => Number(period.key) === this.userForm.value.gracePeriod).value;

      this.zones.parkingFee = {
          account_key: this.account.account_key,
          gracePeriod: this.userForm.value.gracePeriod,
          minimumDurationTime: this.userForm.value.minimumDurationTime,
          maximumDurationTime: this.userForm.value.maximumDurationTime,
          startingDurationTime: this.userForm.value.startingDurationTime,
          minimumRate: this.userForm.value.minimumRate,
          additionalRate: this.userForm.value.additionalRate,
          overnightRate: this.userForm.value.overnightRate,
          numberAvailableSpace: this.userForm.value.numberAvailableSpace,
          businessHours: this.userForm.value.businessHours,
          weekDaysOpeningHour:this.userForm.value.weekDaysOpeningHour,
          weekDaysclosingHour:this.userForm.value.weekDaysclosingHour,
          weekendsOpeningHour:this.userForm.value.weekendsOpeningHour,
          weekendsclosingHour:this.userForm.value.weekendsclosingHour,
          rateType:this.userForm.value.rateType,
          promoDailyRate: this.userForm.value.promoDailyRate,
          promoOvernightRate : this.userForm.value.promoOvernigthRate
        };

        this.zones.employees = this.userForm.value.employees;
        this.zones.customizeRates = this.customizeRateArray.value;

       //remove empty fields
       this.zones.employees  = this.zones.employees.filter(employee => employee.first_name !== '' && employee.last_name);

       let index = this.account.settings.zones.findIndex(z => z.zone_id == this.zones.zone_id);

       if(index !== -1){
         if (this.add_zone_slots && this.add_zone_slots.length > 0) {
           this.zones.zone_slots = this.zones.zone_slots + this.add_zone_slots.length;
         }

         this.account.settings.zones[index] = { ...this.zones }; // Make sure to create a copy

         // Assuming you want to clear add_zone_slots after updating zones
         this.add_zone_slots = [];

        await Swal.fire({
          title: 'Save settings... please wait...',
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: false,
          didOpen:  async()=>{
            Swal.showLoading()


            let updateNSettings = await this.settingsService.updateSettings(this.account.settings, this.account.account_key);

              let accountId;
              if(this.account.id){
                accountId = this.account.id;
              }else{
                accountId = this.account.account_id;
              }

              let index = this.account.settings.zones.findIndex(i => i.zone_id == this.zones.zone_id);
              if(index !== -1){
                this.account.settings.zones[index] = this.zones;
              }

              console.log("this.account.settings", this.account.settings)

              await this.settingsService.addSettingsToAccount(accountId, this.account.settings);
              await this.getAccountByKey(this.account.account_key);

              setTimeout(() =>{
                Swal.fire({
                  title: 'Settings successfully saved!.',
                  timer: 2000,
                  icon: 'success',
                  didClose: ()=>{
                    //this.router.navigateByUrl('/'+this.account.account_key+'/user/dashboard/'+this.zones.zone_id,{replaceUrl: true})
                    Swal.hideLoading();
                  }
                });
              }, 1000);

          }
        });


       }else{
        await Swal.fire(
          'Oops',
          "Sorry, not zone registered to updated. Please contact the admin. Thank you.",
          'warning'
        )
       }

    }else{
      await Swal.fire(
        'Oops',
        "Sorry, you don't have the right to access this function. Please contact the admin. Thank you.",
        'warning'
      )
    }

  }

  async getAccountByKey(account_key: string) {
    const query = firebase.firestore().collection('accounts');
    query
      .where("account_key", "==", account_key)
      .limit(1).onSnapshot((snapshots) => {

      if (snapshots.empty) return;

      snapshots.forEach((doc) => {
        let account = doc.data();
        account.account_id = doc.id;
        localStorage.removeItem('account');
        localStorage.setItem('account', JSON.stringify(account));
        this.account = account;
        console.log("account", this.account)
      });

    });

  }

  togglePasswordVisibility(i) {
    this.isPasswordVisible[i] = !this.isPasswordVisible[i];
  }

  async activateZoneSlot() {
    let zone_slots: any = [];

    if(
      this.zone && this.zone.employees.length === 0 ||
      this.zone.customizeRates.length === 0 ||
      !Object.keys(this.zone.parkingFee).length ){

      await Swal.fire({
        title: 'Please fill-up all the settings. Thanks.',
        icon: 'warning',
        denyButtonText: `Got it!`,
      });

      return false;

    }

    if(this.zone.zone_status === 2) {
      await Swal.fire({
        title: 'Slots are already activated.',
        icon: 'info',
        denyButtonText: `Ok`,
      });

      return false;
    }

    if (this.zone && this.zone.zone_slots > 0 ) {

      Swal.fire({
        icon: 'info',
        title: 'Activate slot',
        html: '<span>Are you sure you want to activate all the slot on this zone?</span>',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showCloseButton: true,
        confirmButtonText: 'Yes, please',
        denyButtonText: `Cancel`,
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {

          await Swal.fire({
            title: 'Activating... please wait...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: false,
            didOpen: async () => {
              Swal.showLoading();

              //create slots base on the zones
              await Promise.all(this.account.settings.zones.map(zone => {

                  if(zone.zone_id === this.zone.zone_id){
                    let zoneNameArray = this.zone.zone_name.split(" ")
                    let slotName = "";
                    zoneNameArray.map((name: string) => {
                      slotName = slotName + name.charAt(0).toLocaleUpperCase();
                    })
                    for (let index = 1; index <= this.zone.zone_slots; index++) {

                      let counter = "";
                      if(index < 10) counter = "00"+index;
                      if(index >= 10 && index < 100) counter = "0"+index;
                      if(index >= 100) counter = index.toString();

                      zone_slots.push({
                        account_key: this.account.account_key,
                        dateCreated: new Date(),
                        slot_code: this.generateRandomString(6),
                        slot_id: this.firestore.createId(),
                        slot_name: slotName+'-'+counter,
                        status: 1,
                        zone_id: this.zone.zone_id,
                        slot_arrangement: index,
                        allowanceTimeDuration: null,
                        allowanceTimerActive: false,
                        isExpired: false,
                        endTime: null,
                        log_id: null,
                        updatedAt: new Date()
                      })
                    }
                  }

              })).then(async (res) => {
                await this.createIndependentZoneSlots(zone_slots);
                this.zones.zone_status = 2;
                await this.save();
              });

            }
          });

        }

      });

    }

  }

  createIndependentZoneSlots(zone_slots:any[]){
    zone_slots.forEach(async data => {
      await this.settingsService.addZonesSlot(data.slot_id,data);
    });
  }

  generateRandomString(length: number) {

    let result = 'PBPH' + '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return result;
  }

  addSlot(){
    Swal.fire({
      title: 'Add slot',
      html: 'How many slot do you want to add?',
      input: 'number',
      inputPlaceholder: 'Enter number of slot',
      inputAttributes: {
        autocapitalize: 'off',
      },
      showCancelButton: true,
      confirmButtonText: 'Add now',
      showLoaderOnConfirm: true,
      preConfirm: async (value) => {

        Swal.showLoading();
        this.addSlots(this.zone.zone_name, this.zone.zone_slots, value);

      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then(async (result) => {
      if (result.isConfirmed) {
        await this.createIndependentZoneSlots(this.add_zone_slots);
        await this.save();
      }
    })
  }

  addSlots(zone_name: string, zone_slot: number, numberOfSlots: number) {

    let zoneNameArray = zone_name.split(" ");
    let slotName = "";
    zoneNameArray.map((name: string) => {
      slotName = slotName + name.charAt(0).toLocaleUpperCase();
    });

    let lastSlotValue = zone_slot;

    for (let index = 1; index <= numberOfSlots; index++) {
      lastSlotValue++;

      let counter = "";
      if(lastSlotValue < 10) counter = "00"+lastSlotValue;
      if(lastSlotValue >= 10 && lastSlotValue < 100) counter = "0"+lastSlotValue;
      if(lastSlotValue >= 100) counter = lastSlotValue.toString();

      this.add_zone_slots.push({
        account_key: this.account.account_key,
        dateCreated: new Date(),
        slot_code: this.generateRandomString(6),
        slot_id: this.firestore.createId(),
        slot_name: slotName+'-'+counter,
        status: 1,
        zone_id: this.zone.zone_id,
        slot_arrangement: lastSlotValue,
        allowanceTimeDuration: null,
        allowanceTimerActive: false,
        isExpired: false,
        endTime: null,
        log_id: null,
        updatedAt: new Date()
      });

    }
  }

  async reconnectDevices() {
    this.deviceFetching = true;
    try {
      const ref = firebase.firestore().collection('devices');
      const query = await ref
        .where('account_key', '==', this.account.account_key)
        .where('zone_id', '==', this.zone.zone_id)
        .get();

      if (query.empty) {
        this.deviceFetching = false;
        console.log('No devices found for account:', this.account.account_key);
        return [];
      }

      // Extract the uuid from each document
      const devices = query.docs.map(doc => ({ uuid: doc.data().uuid }));

      // Find the index of the zone and update the devices
      let index = this.account.settings.zones.findIndex(i => i.zone_id === this.zone.zone_id);
      if (index !== -1) {
        this.zone.devices = devices; // Assuming this.zone.devices is an array of uuid strings
        this.account.settings.zones[index] = this.zone;

        await this.settingsService.addSettingsToAccount(this.account.account_id, this.account.settings);
        await this.getAccountByKey(this.account.account_key);

        if(this.zone.devices && this.zone.devices.length > 0){
          this.accountService.getDevicesByIds(this.zone.devices).then(devices => {
            this.devices = devices;
            this.deviceFetching = true;
          }).catch(error => {
            this.deviceFetching = false;
            console.error('Error fetching devices:', error);
          });
        }
      } else {
        this.deviceFetching = false;
        console.warn('Zone not found for zone_id:', this.zone.zone_id);
      }

    } catch (error) {
      console.error('Error reconnecting devices:', error);
      throw error;
    }
  }

}
