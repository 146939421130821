import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'searchFilter'
})

export class SearchPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!args) {
      return value;
    }
    return value.filter((val) => {
      if(val.plate_number){
        return (val.plate_number.replace(/\s/g, "").toLocaleLowerCase().includes(args))
          || ( val.plate_number.replace(/\s/g, " ").toLocaleLowerCase().includes(args))
          || ( val.plate_number.replace(/\s/g, " ").toLocaleUpperCase().includes(args))
          || (val.plate_number.replace(/\s/g, "").toLocaleUpperCase().includes(args));
      }else if(val.code){
        return (val.code.replace(/\s/g, "").toLocaleLowerCase().includes(args))
          || (val.code.replace(/\s/g, " ").toLocaleLowerCase().includes(args))
          || (val.code.replace(/\s/g, " ").toLocaleUpperCase().includes(args))
          || (val.code.replace(/\s/g, "").toLocaleUpperCase().includes(args));
      }
    })

  }
}
