import { Injectable } from '@angular/core';
import firebase from "firebase";
import * as moment from "moment/moment";

@Injectable({
  providedIn: 'root'
})
export class SendEmailsService {

  constructor() { }

  sendEmailReports(file: any){

    return new Promise((resolve, reject) => {

      let recipientEmail = '';
      let nowDate = moment().format("LL");

      // Check the environment URL
      if (window.location.href.includes('localhost')) {
        recipientEmail = 'jaro.navales@gmail.com';
      } else if (window.location.href.includes('https://app.parkbot.ph/')) {
        recipientEmail = file.email;
      }

      let template = {
        to: recipientEmail,
        // cc: 'alvin.galuno@gmail.com',
        // bcc: 'jaro.navales@gmail.com',
        message: {
          subject: file.accountName + ' Parking Reports ' + nowDate,
          html: `<!doctype html>
            <html lang="en-US">
            <head>
                <meta content="text/html; charset=utf-8" http-equiv="Content-Type" />
                <title>Daily Parking Reports</title>
                <meta name="description" content="Reset Password Email Template.">
                <style type="text/css">
                    a:hover {text-decoration: underline !important;}
                </style>
            </head>
            <body marginheight="0" topmargin="0" marginwidth="0" style="margin: 0px; background-color: #f2f3f8;" leftmargin="0">
                <!--100% body table-->
                <table cellspacing="0" border="0" cellpadding="0" width="100%" bgcolor="#f2f3f8"
                    style="@import url(https://fonts.googleapis.com/css?family=Rubik:300,400,500,700|Open+Sans:300,400,600,700); font-family: 'Open Sans', sans-serif;">
                    <tr>
                        <td>
                            <table style="background-color: #f2f3f8; max-width:670px;  margin:0 auto;" width="100%" border="0"
                                align="center" cellpadding="0" cellspacing="0">
                                <tr>
                                    <td style="height:80px;">&nbsp;</td>
                                </tr>
                                <tr>
                                    <td style="text-align:center;">
                                      <a href="http://www.parkbot.ph" title="logo" target="_blank">
                                        <img width="260" src="http://www.parkbot.ph/wp-content/uploads/2022/12/Parkbot-Logo-New.png" title="logo" alt="logo">
                                      </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="height:20px;">&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>
                                        <table width="95%" border="0" align="center" cellpadding="0" cellspacing="0"
                                            style="max-width:670px;background:#fff; border-radius:3px; text-align:left;-webkit-box-shadow:0 6px 18px 0 rgba(0,0,0,.06);-moz-box-shadow:0 6px 18px 0 rgba(0,0,0,.06);box-shadow:0 6px 18px 0 rgba(0,0,0,.06);">
                                            <tr>
                                                <td style="height:40px;">&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td style="padding:0 35px;">
                                                  <h1 style="color:#1e1e2d; font-weight:500; margin:0;font-size:32px;font-family:'Rubik',sans-serif;">
                                                      Daily Parking Reports
                                                  </h1>
                                                  <span style="display:inline-block; vertical-align:middle; margin:29px 0 26px; border-bottom:1px solid #cecece; width:100px;"></span>

                                                  <p style="color:#455056; font-size:15px;line-height:24px; margin-top:20px;">
                                                        Please find your daily parking reports for zone <b>${file.selectedZone}</b>
                                                  </p>
                                                  <p style="color:#455056; font-size:15px;line-height:24px; margin-top:20px;">
                                                        File name: <i>${file.fileName}</i> <br/>
                                                        Report type: <i>${file.reportType}</i> <br/>
                                                        Date generated: <i>${file.date}</i> <br/>
                                                        <a href="${file.downloadURL}"
                                                        style="background:#f74e64;text-decoration:none !important; font-weight:500; margin-top:20px; color:#fff;
                                                        text-transform:uppercase; font-size:14px;padding:10px 24px;display:inline-block;border-radius:50px;">Click here to download</a>
                                                  </p>

                                                  <p style="color:#455056; font-size:15px;line-height:24px; margin-top:20px;">
                                                        - Team Parkbot PH
                                                  </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="height:40px;">&nbsp;</td>
                                            </tr>
                                        </table>
                                    </td>
                                <tr>
                                    <td style="height:20px;">&nbsp;</td>
                                </tr>
                                <tr>
                                    <td style="text-align:center;">
                                        <p style="font-size:14px; color:rgba(69, 80, 86, 0.7411764705882353); line-height:18px; margin:0 0 0;">&copy; <strong>www.parkbot.ph</strong></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="height:80px;">&nbsp;</td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </body>
            </html>`
        }
      }

      const emailRef = firebase.firestore().collection('emails');
      emailRef.add(template).then(() => {
        console.log('Queued email for delivery!');
        resolve(true);
      }, (error) =>{
        console.log(error);
        resolve(false);
      });
    });

  }
}
