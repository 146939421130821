import {Component, ElementRef, Input, OnInit, ViewChild} from "@angular/core";
import {UserService} from "src/services/user.service";
import {User} from "src/models/user.model";
import {Router} from "@angular/router";
import Swal from "sweetalert2";
import {AuthService} from "src/services/auth.service";
import {Account} from "src/models/account.model";
import firebase from "firebase";
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: "app-card-user-table",
  templateUrl: "./card-user-table.component.html",
})
export class CardUserTableComponent implements OnInit {

  @ViewChild("btnDropdownRef", { static: false }) btnDropdownRef: ElementRef;
  @ViewChild("popoverDropdownRef", { static: false })
  popoverDropdownRef: ElementRef;
  @Input() filterUserCode: string;

// Declare a Subject to handle user input
  private userInputSubject = new Subject<string>();

  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }
  private _color = "light";

  users: User[] = [];
  userAuth: User;
  account: Account;

  constructor(
    private userService: UserService,
    protected router: Router,
    public auth: AuthService
  ) {

    this.account = JSON.parse(localStorage.getItem('account'));
    this.auth.user$.subscribe(user => this.userAuth = user);

  }

  async ngOnInit() {
    // Subscribe to the userInputSubject and debounce the input for 300 milliseconds
    this.userInputSubject.pipe(
      debounceTime(600)
    ).subscribe((event) => {
      this.findUser(event);
    });

    this.loadUsers();

  }

  // Call this function when user input changes
  onInputChange(event: string) {
    // Emit the input value to the Subject
    this.userInputSubject.next(event);
  }

  findUser = async (event) => {
    event = event.toLowerCase();
    if (event.length >= 1) {
      //this.searchUserRequestBy(event);
      await this.searchResults(event, 'users');
    } else {
      this.loadUsers();
    }
  }

  // searchUserRequestBy(text: any) {
  //   let users = []
  //   const inputStart = text.trim();
  //   const inputEnd = inputStart + '\uf8ff'; // Append '\uf8ff' to create the upper limit
  //
  //   firebase.firestore().collection('users')
  //     .where('fullName', '>=', inputStart) // Filter documents where fullName is greater than or equal to the first 3 letters
  //     .where('fullName', '<=', inputEnd) // Filter documents where fullName is less than or equal to the upper limit
  //     .limit(10)
  //     .get()
  //     .then(querySnapshot => {
  //
  //       if(querySnapshot.empty){
  //         this.users = [];
  //       }else{
  //         querySnapshot.forEach(doc => {
  //           users.push(doc.data());
  //         });
  //
  //         this.users = this.getUniqueListBy(users, 'fullName');
  //       }
  //
  //       console.log("searched users", this.users)
  //
  //     });
  // }

  // getUniqueListBy(arr, key) {
  //   return [...new Map(arr.map(item => [item[key], item])).values()]
  // }

  async searchResults(query = null, collection = 'users', keys = ['fullName', 'email']) {

    let querySnapshot = { docs : [] };

    try {
      if (query) {
        let search = async (query)=> {
          let queryWords = query.trim().split(' ');
          return queryWords.map((queryWord) => keys.map(async (key) =>
            await firebase
              .firestore()
              .collection(collection)
              .where(key, '>=', queryWord)
              .where(key, '<=', queryWord +  '\uf8ff')
              .get())).flat();
        }

        let results = await search(query);

        await (await Promise.all(results)).forEach((search: any) => {
          querySnapshot.docs = querySnapshot.docs.concat(search.docs);
        });

      }
    } catch(err) {
      console.log(err)
    }

    // Appends id and creates clean Array
    const items = [];
    querySnapshot.docs.forEach(doc => {
      let item = doc.data();
      item.id = doc.id;
      items.push(item);
    });

    // Filters duplicates
    this.users = items.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);

    console.log("this.users", this.users)
  }

  loadUsers() {
    this.userService.getUsers().subscribe((res) => {
      // Sort the array
      this.users = res.sort((a, b) => {
        // Assuming lastActivity is in ISO string format, convert it to Date objects
        const dateA = new Date(a.lastActivity);
        const dateB = new Date(b.lastActivity);
        // Compare the dates
        return dateB.getTime() - dateA.getTime(); // Use getTime() to get numeric values for comparison
      });
    });
  }

  async edit(user: User){
    if(this.auth.canEdit(this.userAuth)){
      await this.router.navigateByUrl('/admin/users/edit/' + user.uid);
    }else{
      await Swal.fire(
        'Warning',
        "Sorry, access denied. Please contact admin.",
        'warning'
      )
    }
  }


  async viewEmployeeGeneratedReports(user:any){

    if (!this.auth.canDelete(this.userAuth)) {
      await Swal.fire({
        title: 'Ooops!',
        html: 'You have no rights to edit employee. Please contact your admin.',
        allowEscapeKey: true,
        allowOutsideClick: true,
        showConfirmButton: false,
        showCancelButton: true,
        showCloseButton: true,
        cancelButtonText: 'Ok',
        icon: 'warning',
      });

      return;
    }

    this.router.navigate(['/admin/user/activities/', user.uid]);
  }

  async add(){
    if(this.auth.canEdit(this.userAuth)){
      await this.router.navigateByUrl('/admin/users/add');
    }else{
      await Swal.fire(
        'Warning',
        "Sorry, access denied. Please contact admin.",
        'warning'
      )
    }
  }

  async delete(user: User){
    if(this.auth.canDelete(this.userAuth)){
      if(user.uid){

        await Swal.fire({
          title: 'Are you sure?',
          text: 'You will not be able to recover this user!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, keep it'
        }).then((result) => {
          if (result.isConfirmed) {

            this.userService.deleteUser(user).then(() =>{
              Swal.fire(
                'Success',
                'User deleted!',
                'success'
              )
            });

          } else if (result.dismiss === Swal.DismissReason.cancel) {

          }

        })

      }
    }else{
      await Swal.fire(
        'Warning',
        "Sorry, access denied. Please contact admin.",
        'warning'
      )
    }
  }
}
