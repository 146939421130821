import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Account, Zones } from 'src/models/account.model';
import { AccountService } from 'src/services/account.service';
import { EmailSchedulingService } from 'src/services/email-scheduling.service';
import Swal from "sweetalert2";

@Component({
  selector: 'app-email-automation-add',
  templateUrl: './email-automation-add.component.html',
})
export class EmailAutomationAddComponent implements OnInit {

  public emailSchedulingForm: FormGroup;

  public account: Account = <Account>{};
  public accounts: Account[] = [];
  public zones: Zones[] = [];

  public current_account_id: string;
  public current_zone_id: string;


  constructor(
    public formBuilder: FormBuilder,
    private accountService: AccountService,
    private emailSchedulingService : EmailSchedulingService,
    private firestore: AngularFirestore,
  ) {

    this.accountService.getAccounts().subscribe((account) => {
      this.accounts = account;
    });
  }

  ngOnInit(): void {

    this.emailSchedulingForm = this.formBuilder.group({
      account: ['', Validators.required],
      zone: ['', Validators.required],
      type: ['', Validators.required],
      time: ['', Validators.required],
      day: ['', Validators.required],
      bcc: [''],
      cc: [''],
      to: ['', Validators.required],
      id: [''],
    });
  }


  onSelectAccount(id:string){
    this.current_account_id = id;
    this.account = this.accounts.find(ac => ac.id == id);
    this.zones = this.account.settings.zones;
  }

  onSelectZone(id: string){
    this.current_zone_id = id;
  }

  save(): void {
    if (this.emailSchedulingForm.valid) {
      let value = this.emailSchedulingForm.value;

      let account = this.accounts.find(ac => ac.id == value.account);
      let zone = this.zones.find(z => z.zone_id == value.zone)

      let data = {
        email_recipient_bcc: value.bcc,
        email_recipient_cc: value.cc,
        email_recipient_to: value.to,
        scheduled: true,
        day: value.day,
        time: value.time,
        report_type: value.type,
        account_key: account.account_key,
        account_name: account.account_name,
        id: this.firestore.createId(),
        zone_id: zone.zone_id,
        zone_name: zone.zone_name,
        timestamp: new Date()
      }

      this.emailSchedulingService.addScheduleEmailReport(data).then((res: any) =>{
        console.log("success plan saved ==>", res);
        Swal.fire({
          title: 'Email schedule successfully created.',
          timer: 2000,
          icon: 'success',
          didClose: ()=>{
            this.emailSchedulingForm.reset();
            Swal.hideLoading();
          }
        });
      });

    }
  }



}
