import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {filter, map, mergeMap} from "rxjs/operators";
import { PlanService  } from "src/services/plan.service";
import { Plans } from "src/models/account.model";
import {combineLatest} from "rxjs";
import firebase from "firebase/app";
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import Swal from "sweetalert2";

@Component({
  selector: 'app-plans-edit',
  templateUrl: './plans-edit.component.html'
})
export class PlansEditComponent implements OnInit {

  public userForm: FormGroup;

  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }
  private _color = "light";

  plan: Plans = <Plans> {};

  constructor(
    protected route : ActivatedRoute,
    private planService: PlanService,
    public formBuilder: FormBuilder,
    public router: Router
    ) {

  }

  async ngOnInit() {

    this.userForm = this.formBuilder.group({
      key: [''],
      name: [''],
      description: [''],
      price: ['']
    });

    this.userForm.patchValue(this.plan);

    this.route.paramMap
      .pipe(
        map(pm => pm.get('id')),
        filter(id => id != null),
        mergeMap(id => this.planService.getPlanById(id)),
      ).subscribe((plan) =>{
      this.plan = plan;
      console.log("plan get by id =>", this.plan);

      this.userForm = this.formBuilder.group({
        key: this.plan.key,
        name: this.plan.name,
        description: this.plan.description,
        price: this.plan.price
      })

      this.userForm.patchValue(this.plan);

    });

  }

  async save(){

    if(this.userForm.valid){

      await Swal.fire({
        title: 'Saving plan... please wait...',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: ()=>{
          Swal.showLoading()
          this.planService.updatePlan(this.userForm.value, this.plan.id).then(() =>{
            Swal.fire({
              title: 'Plan successfully saved!.',
              timer: 2000,
              icon: 'success',
              didClose: ()=>{
                Swal.hideLoading();
              }
            });
          });
        }
      });

    }

  }

  delete(plan: Plans){
    this.planService.deletePlan(plan).then(() =>{
      Swal.fire(
        'Success',
        'Plan successfully deleted!',
        'success'
      )
    });
  }

}
