import { Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { Account, Plans } from 'src/models/account.model';

import firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection, DocumentReference } from '@angular/fire/firestore';

import { AuthService } from 'src/services/auth.service';
import {Observable} from "rxjs";
import {map, take} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class PlanService {

  private plans: Observable<Plans[]>;
  private planCollection: AngularFirestoreCollection<Plans>;

  public planId: any;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
    public auth: AuthService,
  ) {
    this.initializePlans();
  }


  //====> Plans ==========//

  getPlanById(id: string): Observable<Plans>{
    return this.planCollection.doc<Plans>(id).valueChanges().pipe(
      take(1),
      map(plan =>{
        plan.id = id;
        return plan
      })
    );
  }

  getPlanByCode(code: string): Observable<Plans>{
    return this.planCollection.doc<Plans>(code).valueChanges().pipe(
      take(1),
      map(plan =>{
        return plan
      })
    );
  }

  initializePlans(){
    this.planCollection = this.afs.collection<Plans>('plans');
    this.plans = this.planCollection.snapshotChanges().pipe(
      map(actions =>{
        return actions.map(a =>{
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      }));
  }

  initializePlanById(id: string) {
    if (id) {
      this.getPlanById(id).subscribe(plan => {
        console.log(plan);
        return plan
      });
    }
  }

  getPlans():Observable<Plans[]> {
    return this.plans;
  }

  addPlan(plan: Plans): Promise<DocumentReference<Plans>>{
    return this.planCollection.add(plan);
  }

  updatePlan(plan: Plans, id) {
    return this.afs
      .collection("plans")
      .doc(id)
      .update(plan);
  }

  deletePlan(plan: Plans) {
    return this.afs
      .collection("plans")
      .doc(plan.id)
      .delete();
  }

}
