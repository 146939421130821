import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {filter, map, mergeMap} from "rxjs/operators";
import { UserService  } from "src/services/user.service";
import { LogsService } from "src/services/log.service";
import { UserLogs } from "src/models/user.model";
import {combineLatest} from "rxjs";
import firebase from "firebase/app";
import {Logs} from "src/models/logs";
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {VehicleService} from "src/services/vehicle.service";
import Swal from "sweetalert2";
import {Vehicles} from "../../../../../models/vehicle.model";

@Component({
  selector: 'app-vehicles-edit',
  templateUrl: './vehicles-edit.component.html'
})
export class VehiclesEditComponent implements OnInit {

  public userForm: FormGroup;
  public vehicleForm: FormGroup;
  userVehicles: FormArray;

  plate_number = new FormControl('');
  vehicle_type = new FormControl('');
  vehicle_model = new FormControl('');
  vehicle_color = new FormControl('');

  date: any;

  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }
  private _color = "light";

  user: UserLogs = <UserLogs> {};
  vehicles: Vehicles = <Vehicles> {};
  logs: Logs[] = [];

  constructor(
    protected route : ActivatedRoute,
    private userService: UserService,
    private logService: LogsService,
    private vehicleService: VehicleService,
    public formBuilder: FormBuilder,
    public router: Router
    ) {

  }

  async ngOnInit() {

    this.userVehicles = this.formBuilder.array([

    ]);

    this.userForm = this.formBuilder.group({
      name: [''],
      code: [''],
      vehicles: this.userVehicles
    });

    this.vehicleForm = this.formBuilder.group({
      vehicles: this.userVehicles
    });

    this.userForm.patchValue(this.user);
    this.vehicleForm.patchValue(this.vehicles);

    this.route.paramMap
      .pipe(
        map(pm => pm.get('id')),
        filter(id => id != null),
        mergeMap(id => this.userService.getUserLogById(id)),
      ).subscribe((user) =>{
      this.user = user;
      console.log("user get by id =>", this.user);

      this.getLogsByCode(this.user.code);

      this.date = this.user.date_registered;

      this.userForm = this.formBuilder.group({
        name: this.user.name,
        code: this.user.code,
        userVehicle_id: this.user.userVehicle_id
      })

      this.userForm.patchValue(user);

      if(user.userVehicle_id !== ""){
        this.vehicleService.getVehicleById(user.userVehicle_id).subscribe((res: any)=>{
          console.log(res.vehicles);
          this.userVehicles.clear();
          for(let i = 0; i < res.vehicles.length; i++) {
            let userVehicle = this.createUserVehicles();
            userVehicle.patchValue(res.vehicles[i]);
            this.userVehicles.push(userVehicle);
          }
        });
      }else{
        const vehicles = [{}];
        this.userVehicles.clear();
        for(let i = 0; i < vehicles.length; i++) {
          let userVehicle = this.createUserVehicles();
          userVehicle.patchValue(vehicles[i]);
          this.userVehicles.push(userVehicle);
        }
      }

    });

  }

  createUserVehicles(): FormGroup {
    return this.formBuilder.group({
      code: this.user.code,
      plate_number: [''],
      vehicle_type: [''],
      vehicle_model: [''],
      vehicle_color: [''],
      date_registered: [new Date()],
    });
  }

  addVehicles() {
    this.userVehicles.push(this.createUserVehicles());
  }

  async getLogsByCode(code){

    const logRef = firebase.firestore().collection('logs');
    const snapshot = await logRef.where('code', '==', code).get();

    snapshot.forEach(doc => {
      this.logs.push(doc.data());
      console.log("user logs =>",this.logs );
    })

  }

  async save(){

    if(this.userForm.valid){
      await this.userService.updateUser(this.userForm.value, this.user.code).then(() =>{
        if(this.userForm.value.vehicle_id !== ""){
          this.vehicleService.updateVehicle(this.vehicleForm.value, this.userForm.value.vehicle_id).then(() =>{
            Swal.fire(
              'Success',
              'User saved!',
              'success'
            )
          });
        }else{
          this.vehicleService.addVehicle(this.vehicleForm.value).then((res) =>{
            console.log("vehicle =>", res.id);
            if(res.id !== null){
              this.userForm.value.vehicle_id = res.id;
              this.userService.updateUser(this.userForm.value, this.user.code).then(() =>{
                Swal.fire(
                  'Success',
                  'User saved!',
                  'success'
                )
              })
            }
          });
        }
      });
    }

  }

}
