import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {filter, map, mergeMap} from "rxjs/operators";
import firebase from "firebase/app";

import { UserService  } from "src/services/user.service";
import { VehicleService } from "src/services/vehicle.service";
import { LogsService } from "src/services/log.service";
import { UserLogs } from "src/models/user.model";
import {AngularFirestore} from "@angular/fire/firestore";

@Component({
  selector: 'app-vehicles-add',
  templateUrl: './vehicles-add.component.html'
})
export class VehiclesAddComponent implements OnInit {

  public userForm: FormGroup;

  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }
  private _color = "light";

  user: UserLogs = <UserLogs> {};
  // vehicle: Vehicle = <Vehicle> {};

  constructor(
    private firestore: AngularFirestore,
    protected route : ActivatedRoute,
    private userService: UserService,
    private logService: LogsService,
    private vehicleService: VehicleService,
    public formBuilder: FormBuilder,
    public router: Router
    ) {

    this.userForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      code: ['', [Validators.required]],
      // plate_number: [''],
      // vehicle_type: [''],
      // vehicle_model: [''],
      // vehicle_color: ['']
    })

    // this.route.paramMap
    //   .pipe(
    //     map(pm => pm.get('id')),
    //     filter(id => id != null),
    //     mergeMap(id => this.userService.getUserById(id)),
    //   ).subscribe((user) =>{
    //     this.user = user;
    //     console.log("user get by id =>", this.user);
    //
    //     this.getLogsByCode(this.user.code);
    //
    // });

  }

  ngOnInit(): void {
  }

  get getControl(){
    return this.userForm.controls;
  }

  async save(){

    const data = {
      code: this.userForm.value.code,
      name: this.userForm.value.name,
      date_registered: new Date(),
      uid: this.firestore.createId(),
      vehicle_id: ""
    };

    if(this.userForm.valid){
      await this.userService.addUser(data).then(() =>{

        this.router.navigateByUrl('/admin/users/edit/' + data.code);

        // if(res.vehicle_id !== null){
        //
        //   data.vehicle_id = res.vehicle_id
        //   this.vehicleService.addVehicle(data).then((res) =>{
        //     console.log(res);
        //   });
        //
        // }

      });
    }


  }

}
