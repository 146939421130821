import { Component, OnInit, Input, AfterViewInit, HostListener, ChangeDetectorRef } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import firebase from "firebase";
import { filter } from "rxjs/operators";
import {Account} from "src/models/account.model";

@Component({
  selector: "app-card-active-session",
  templateUrl: "./card-active-session.component.html",
})
export class CardActiveSessionComponent implements OnInit {
  account: Account;
  activeSession: number = 0;
  zone_id:string;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) {

    this.account = JSON.parse(localStorage.getItem('account') || null);
    this.zone_id = this.router.routerState.snapshot.url.split('/').slice(-1)[0]

    this.router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd)
    ).subscribe(async (res) => {
      let newZoneId = res.url.split('/').slice(-1)[0];
      this.zone_id = newZoneId;
      await this.getCountZoneSlotActiveSession();

    });

  }

  async ngOnInit() {
    if(this.zone_id){
      await this.getCountZoneSlotActiveSession();
    }

    if(this.account && this.account.account_key){
      await this.getAccountByKey(this.account.account_key);
    }
  }

  async getAccountByKey(account_key: string) {
    const query = firebase.firestore().collection('accounts');
    query
      .where("account_key", "==", account_key)
      .limit(1).onSnapshot((snapshots) => {

      if (snapshots.empty) return;

      snapshots.forEach((doc) => {
        let account = doc.data();
        account.account_id = doc.id;
        localStorage.removeItem('account');
        localStorage.setItem('account', JSON.stringify(account));
        this.account = account;
      });

    });

  }

  async getCountZoneSlotActiveSession(){

    let activeZoneSlotSession = firebase.firestore().collection('zones_slot')
      .where('account_key', '==', this.account.account_key)
      .where('zone_id','==', this.zone_id)
      .where('status', '==', 2)
      .onSnapshot((snapshot) =>{
        if(snapshot.empty){
          this.activeSession = 0;
        }else{
          this.activeSession = snapshot.size;

        }
        this.cdr.detectChanges();

      });
  }

}
