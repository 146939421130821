import {Component, ElementRef, Input, OnInit, ViewChild} from "@angular/core";
import {UserService} from "src/services/user.service";
import {User, UserLogs} from "src/models/user.model";
import {Router} from "@angular/router";
import Swal from "sweetalert2";
import {AuthService} from "src/services/auth.service";
import {AngularFirestore} from "@angular/fire/firestore";
import {BehaviorSubject, Observable} from "rxjs";
import {FormControl} from "@angular/forms";
import firebase from "firebase";
import {Account} from "src/models/account.model";

@Component({
  selector: 'app-users-logs',
  templateUrl: './users-logs.component.html'
})
export class UserLogComponent implements OnInit {

  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }
  private _color = "light";

  @ViewChild("btnDropdownRef", { static: false }) btnDropdownRef: ElementRef;
  @ViewChild("popoverDropdownRef", { static: false })
  popoverDropdownRef: ElementRef;

  @Input() filterUserCode: string;

  userLogs: UserLogs[] = [];
  logs: UserLogs[] = [];
  userAuth: User;

  account: Account;

  //Models for Input fields
  nameValue: string;
  placeValue: string;

  //Data object for listing items
  tableData: any[] = [];

  //Save first document in snapshot of items received
  firstInResponse: any = [];

  //Save last document in snapshot of items received
  lastInResponse: any = [];

  //Keep the array of first document of previous pages
  prev_start_at: any = [];

  //Maintain the count of clicks on Next Prev button
  pagination_clicked_count = 0;

  //Disable next and prev buttons
  disable_next: boolean = false;
  disable_prev: boolean = false;

  filteredOptions: Observable<UserLogs[]>;

  searchControl = new FormControl();

  startAt: BehaviorSubject<string | null> = new BehaviorSubject('');

  constructor(
    private userService: UserService,
    protected router: Router,
    public auth: AuthService,
    private firestore: AngularFirestore
  ) {

    this.account = JSON.parse(localStorage.getItem('account'));
    this.auth.user$.subscribe(user => this.userAuth = user);
  }

  ngOnInit(): void {
    this.loadItems();
  }

  private _filter(value: string): UserLogs[] {
    const filterValue = value.toLowerCase();
    return this.tableData.filter(option => option.code.toLowerCase().includes(filterValue));
  }

  async edit(user: UserLogs){
    await this.router.navigateByUrl('/user/logs/edit/' + user.code);
  }

  async add(){
    await this.router.navigateByUrl('/user/log/add');
  }

  async delete(user: UserLogs){
    if(user.uid){
      await Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this user!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.isConfirmed) {
          this.userService.deleteUserLogs(user).then(() =>{
            Swal.fire(
              'Success',
              'User deleted!',
              'success'
            )
          });

        } else if (result.dismiss === Swal.DismissReason.cancel) {

        }

      })
    }
  }

  async checkUserCurrentStatus(code):Promise<any>{
    return new Promise(async (resolve) =>{
      const logRef = firebase.firestore().collection('logs');
      await logRef.where('code', '==', code)
        .orderBy('timestamp', 'desc')
        .limit(1)
        .get()
        .then(querySnapshot => {
          if (!querySnapshot.empty) {
            const queryDocumentSnapshot = querySnapshot.docs[0].data();
            resolve(queryDocumentSnapshot);
          }
        });

    });

  }

  async loadItems() {

    const userRef = firebase.firestore().collection('users_logs');
    const query = await userRef
      .where('account_key', '==', this.account.account_key)
      .orderBy('date_registered', 'desc')
      .limit(10)

    query.get().then((documentSnapshots: any) =>{

      if (!documentSnapshots.docs.length) {
        console.log("No Data Available");
        return false;
      }

      this.firstInResponse = documentSnapshots.docs[0];
      this.lastInResponse = documentSnapshots.docs[documentSnapshots.docs.length - 1];

      this.tableData = [];
      for (let item of documentSnapshots.docs) {

        let userLog = item.data();
        this.checkUserCurrentStatus(userLog.code).then((res) =>{
          userLog.status = res.status;
        });

        this.tableData.push(userLog);

      }

      //Initialize values
      this.userLogs = this.tableData;
      console.log(this.userLogs);

      this.prev_start_at = [];
      this.pagination_clicked_count = 0;
      this.disable_next = false;
      this.disable_prev = false;

      //Push first item to use for Previous action
      this.push_prev_startAt(this.firstInResponse);

    }, error =>{

    });

  }

  //Show previous set
  async prevPage() {

    this.disable_prev = true;
    const userRef = firebase.firestore().collection('users_logs');
    const query = await userRef
      .where('account_key', '==', this.account.account_key) // today
      .orderBy('date_registered', 'desc')
      .startAt(this.get_prev_startAt())
      .endBefore(this.firstInResponse)
      .limit(10)
    query.get().then((documentSnapshots: any) =>{

      this.firstInResponse = documentSnapshots.docs[0];
      this.lastInResponse = documentSnapshots.docs[documentSnapshots.docs.length - 1];

      this.tableData = [];
      for (let item of documentSnapshots.docs) {
        let userLog = item.data();
        this.checkUserCurrentStatus(userLog.code).then((res) =>{
          userLog.status = res.status;
        });

        this.tableData.push(userLog);
      }

      this.userLogs = this.tableData;

      //Maintaining page no.
      this.pagination_clicked_count--;

      //Pop not required value in array
      this.pop_prev_startAt(this.firstInResponse);

      //Enable buttons again
      this.disable_prev = false;
      this.disable_next = false;
    }, error =>{
      this.disable_prev = false;
    });

  }

  async nextPage() {

    this.disable_prev = true;
    const userRef = firebase.firestore().collection('users_logs');
    const query = await userRef
      .where('account_key', '==', this.account.account_key) // today
      .orderBy('date_registered', 'desc')
      .startAfter(this.lastInResponse)
      .limit(10)
    query.get().then((documentSnapshots: any) =>{

      if (!documentSnapshots.docs.length) {
        this.disable_next = true;
        return;
      }

      this.firstInResponse = documentSnapshots.docs[0];
      this.lastInResponse = documentSnapshots.docs[documentSnapshots.docs.length - 1];

      this.tableData = [];
      for (let item of documentSnapshots.docs) {
        let userLog = item.data();
        this.checkUserCurrentStatus(userLog.code).then((res) =>{
          userLog.status = res.status;
        });

        this.tableData.push(userLog);
      }

      this.userLogs = this.tableData;

      this.pagination_clicked_count++;
      this.push_prev_startAt(this.firstInResponse);
      this.disable_next = false;

    }, error =>{
      this.disable_next = false;
    });

  }

  //Add document
  push_prev_startAt(prev_first_doc) {
    this.prev_start_at.push(prev_first_doc);
  }

  //Remove not required document
  pop_prev_startAt(prev_first_doc) {
    this.prev_start_at.forEach(element => {
      if (prev_first_doc.data().id == element.data().id) {
        element = null;
      }
    });
  }

  //Return the Doc rem where previous page will startAt
  get_prev_startAt() {
    if (this.prev_start_at.length > (this.pagination_clicked_count + 1))
      this.prev_start_at.splice(this.prev_start_at.length - 2, this.prev_start_at.length - 1);
    return this.prev_start_at[this.pagination_clicked_count - 1];
  }

}
