import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {User, UserLogs} from "../../../../models/user.model";
import {Account, Employees} from "../../../../models/account.model";
import {UserService} from "../../../../services/user.service";
import {Router} from "@angular/router";
import {AuthService} from "../../../../services/auth.service";
import {AngularFirestore} from "@angular/fire/firestore";
import firebase from "firebase";
import Swal from "sweetalert2";
import {BehaviorSubject, Observable} from "rxjs";
import {FormControl} from "@angular/forms";



@Component({
  selector: 'app-employee-list',
  templateUrl: './employee-list.component.html'
})
export class EmployeeListComponent implements OnInit {

  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }

  private _color = "light";


  @ViewChild("btnDropdownRef", { static: false }) btnDropdownRef: ElementRef;
  @ViewChild("popoverDropdownRef", { static: false })
  popoverDropdownRef: ElementRef;

  @Input() filterUserCode: string;

  usersEmployees: any = [];
  userAuth: User;
  account: Account;
  viewEmployee:boolean = false;
  selectedEmployee:Employees;

  constructor(
    private userService: UserService,
    protected router: Router,
    public auth: AuthService,
    private firestore: AngularFirestore) {

    this.account = JSON.parse(localStorage.getItem('account'));


  }

  async ngOnInit() {
    this.auth.user$.subscribe(user => {
      this.userAuth = user

      const zones = this.account.settings.zones;

      // Create a new array for employees with the specified properties
      const employeesArray = zones.flatMap(zone =>
        zone.employees.map(employee => ({
          employee_id: employee.employee_id,
          first_name: employee.first_name,
          last_name: employee.last_name,
          password_pin: employee.password_pin,
          position: employee.position,
          username: employee.username,
          mobile_number: employee.mobile_number,
          date_created: employee.date_created,
          zone_id: zone.zone_id,
          zone_name: zone.zone_name,
          zone_description: zone.zone_description
        }))
      );

      // Sort employeesArray by date_created
      employeesArray.sort((a, b) => {
        // Assuming date_created is in ISO string format, convert it to Date objects
        const dateA = new Date(a.date_created.seconds * 1000);
        const dateB = new Date(b.date_created.seconds * 1000);

        // Compare the dates
        return dateB.getTime() - dateA.getTime();  // Use getTime() to get numeric values for comparison
      });

      this.usersEmployees = employeesArray;

    });
  }

  async addEmployee() {

    if (!this.auth.isSuperAdmin(this.userAuth)) {
      await Swal.fire({
        title: 'Ooops!',
        html: 'You have no rights to add employee. Please contact your admin.',
        allowEscapeKey: true,
        allowOutsideClick: true,
        showConfirmButton: false,
        showCancelButton: true,
        showCloseButton: true,
        cancelButtonText: 'Ok',
        icon: 'warning',
      });

      return;
    }

    this.router.navigate([this.account.account_key + '/user/employees/add']);
  }

  async editEmployee(employee: any) {

    if (!this.auth.isAdminCanEdit(this.userAuth)) {
      await Swal.fire({
        title: 'Ooops!',
        html: 'You have no rights to edit employee. Please contact your admin.',
        allowEscapeKey: true,
        allowOutsideClick: true,
        showConfirmButton: false,
        showCancelButton: true,
        showCloseButton: true,
        cancelButtonText: 'Ok',
        icon: 'warning',
      });

      return;
    }

    this.router.navigate([this.account.account_key + '/user/employees/edit/', employee.employee_id]);
  }

}
