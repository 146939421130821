import { Component, OnInit } from "@angular/core";
import {Account} from "../../../../models/account.model";
import {AuthService} from "../../../../services/auth.service";
import {Router} from "@angular/router";

@Component({
  selector: "app-footer-admin",
  templateUrl: "./footer-admin.component.html",
})
export class FooterAdminComponent implements OnInit {
  date = new Date().getFullYear();

  public account: Account;
  constructor(
    public auth: AuthService,
    public router: Router,
    ) {}

  ngOnInit(): void {
    this.account = JSON.parse(localStorage.getItem('account'));
  }
}
