import { Injectable } from '@angular/core';

import { Router } from '@angular/router';
import {Account, AccountUser} from 'src/models/account.model';

import firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection, DocumentReference } from '@angular/fire/firestore';

import { AuthService } from 'src/services/auth.service';
import {Observable} from "rxjs";
import {map, take} from "rxjs/operators";
import {User} from "../models/user.model";

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  private accountUsers: Observable<AccountUser[]>;
  private accountUserCollection: AngularFirestoreCollection<AccountUser>;

  private accounts: Observable<Account[]>;
  private accountCollection: AngularFirestoreCollection<Account>;

  public accountId: any;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
    public auth: AuthService,
  ) {
    this.initializeAccounts();
    this.initializeAccountUsers();
  }


  //====> ACCOUNTS ==========//

  getAccountById(id: string): Observable<Account>{

    this.accountCollection = this.afs.collection<Account>('accounts');
    return this.accountCollection.doc<Account>(id).valueChanges().pipe(
      take(1),
      map(account =>{
        account.id = id;
        return account;
      })
    );

  }

  initializeAccounts(){
    this.accountCollection = this.afs.collection<Account>('accounts');
    this.accounts = this.accountCollection.snapshotChanges().pipe(
      map(actions =>{
        return actions.map(a =>{
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      }));
  }

  initializeAccountById(id: string) {
    if (id) {
      this.getAccountById(id).subscribe(account => {
        console.log(account);
        return account
      });
    }
  }

  getAccounts():Observable<Account[]> {
    return this.accounts;
  }

  addAccount(account: Account): Promise<DocumentReference<Account>> {
    return this.accountCollection.add(account);
  }

  updateAccount(account: Account, id) {
    return this.afs
      .collection("accounts")
      .doc(id)
      .update(account);
  }

  updateAccountAddress(address: any, account_id: string): Promise<void> {
    return this.afs
      .collection("accounts")
      .doc(account_id)
      .set(address, { merge: true });
  }

  updateAccountVehicles(vehicles: any[], account_id: string): Promise<void> {
    return this.afs
      .collection("accounts")
      .doc(account_id)
      .set({ vehicles: vehicles }, { merge: true });
  }

  deleteAccount(account: Account) {
    return this.afs
      .collection("accounts")
      .doc(account.uid)
      .delete();
  }

  checkIfUserIsOwner(id: string):Promise<any>{
    return new Promise((resolve, reject) => {
      const query = firebase.firestore().collection('accounts');
      const observer = query
        .where('owner_id', '==', id)
        .limit(1)
        .get();

      observer.then((snapshot) => {
        if (snapshot.empty) {
          console.log("Sorry, user has no account. Please contact your administrator.")
          resolve(false);
          return;
        }

        snapshot.forEach((doc) =>{
          console.log("doc.data() ==>", doc.data());
          let account = doc.data();
          account.account_id = doc.id;
          resolve(account);
        });

      });

    });
  }

  getAccountByKey(account_key: string):Promise<any>{
    return new Promise((resolve, reject) => {
      const query = firebase.firestore().collection('accounts');
      query
        .where("account_key", "==", account_key)
        .limit(1)
        .get().then((snapshots) =>{

        if(snapshots.empty){
          return;
        }

        snapshots.forEach((doc) =>{
          let account = doc.data();
          account.account_id = doc.id;
          resolve(account);
        });

      });
    });

  }

  checkIfUserHasAccount(id: string):Promise<any>{
    return new Promise((resolve, reject) => {
      const query = firebase.firestore().collection('account_users');
      query.get().then((snapshots) =>{

        if (snapshots.empty) {
          console.log("No user account found. Please contact the administrator.");
          reject();
          return;
        }

        const users = snapshots.docs.reduce((acc, doc) => {
          const accountUsers = doc.data().users;
          return acc.concat(accountUsers);
        }, []);

        const foundUser = users.find(a => a.id === id);

        if (foundUser) {
          resolve(foundUser);
        }else{
          reject();
        }

      });

    });

  }

  /***** ACCOUNT USER ******/

  getAccountUserById(id: string): Observable<AccountUser>{
    return this.accountUserCollection.doc<AccountUser>(id).valueChanges().pipe(
      take(1),
      map(accountUser =>{
        return accountUser
      })
    );
  }

  initializeAccountUsers(){
    this.accountUserCollection = this.afs.collection<AccountUser>('account_users');
    this.accountUsers = this.accountUserCollection.snapshotChanges().pipe(
      map(actions =>{
        return actions.map(a =>{
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      }));
  }

  initializeAccountUserById(id: string) {
    if (id) {
      this.getAccountUserById(id).subscribe(accountUser => {
        console.log(accountUser);
        return accountUser
      });
    }
  }

  getAccountUsers():Observable<AccountUser[]> {
    return this.accountUsers;
  }

  addAccountUser(accountKey: string, accountUser: any): Promise<void> {
    const userRef = this.afs.doc(`account_users/${accountKey}`);
    return userRef.set({
      users: accountUser
    }, { merge: true });
    //return this.accountUserCollection.add(accountUser);
  }

  updateAccountUser(accountUser: AccountUser, accountKey: string) {
    return this.afs
      .collection("account_users")
      .doc(accountKey)
      .update(accountUser);
  }

  deleteAccountUser(accountUser: AccountUser) {
    return this.afs
      .collection("account_users")
      .doc(accountUser.uid)
      .delete();
  }

  async getDevicesByIds(ids: string[]): Promise<any[]> {
    if (ids.length > 0) {
      const promises = ids.map((device: any) =>
        this.afs.collection('devices').doc(device.uuid).get().toPromise()
      );
      const snapshots = await Promise.all(promises);
      return snapshots.map(snapshot => snapshot.data());
    }
  }

}
