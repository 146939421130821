import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {AngularFirestore} from "@angular/fire/firestore";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../../../../services/auth.service";
import {AngularFireAuth} from "@angular/fire/auth";
import {SettingsService} from "../../../../../services/settings.service";
import {AccountService} from "../../../../../services/account.service";
import {VehicleService} from "../../../../../services/vehicle.service";
import {User} from "../../../../../models/user.model";
import Swal from "sweetalert2";
import {Account, Settings, Zones} from "../../../../../models/account.model";
import {combineLatest} from "rxjs";

@Component({
  selector: 'app-add-employee',
  templateUrl: './add-employee.component.html',
  styleUrls: ['./add-employee.component.css']
})
export class AddEmployeeComponent implements OnInit {

  public employeeForm: FormGroup;
  userAuth: User;
  isPasswordVisible: boolean;

  settings: Settings;
  account: Account;
  zones:any = [];
  employeesArray = [];

  constructor(
    private firestore: AngularFirestore,
    protected route : ActivatedRoute,
    public formBuilder: FormBuilder,
    public router: Router,
    public authService: AuthService,
    public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    private settingsService: SettingsService,
    private accountService: AccountService,
  ) {

    this.authService.user$.subscribe(user => this.userAuth = user);
    this.account = JSON.parse(localStorage.getItem('account'));
    this.zones = this.account.settings.zones;

    this.initializeEmployees();

  }

  ngOnInit(): void {

    this.employeeForm = this.formBuilder.group({
      employee_id: this.firestore.createId(),
      first_name: [''],
      last_name: [''],
      mobile_number: [''],
      username: [''],
      password_pin: [''],
      position: [''],
      zone_id: [''],
      date_created: [new Date()],
    });

  }

  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  async generateUsernamePassword() {

    if (this.authService.canEdit(this.userAuth)) {

      const employee = this.employeeForm.value;

      if( employee.first_name === '' || employee.last_name === ''){
        await Swal.fire({
          title: 'Oops!',
          html: 'Please fill-up First name and Last name to generate username and pin.',
          icon: 'warning',
          denyButtonText: `Got it!`,
        });

        return false;
      }

      if( employee.position === '' || employee.zone_id === ''){

        await Swal.fire({
          title: 'Please fill-up all the fields.',
          icon: 'warning',
          denyButtonText: `Got it!`,
        });

        return false;

      }

      if(!employee.username && !employee.password_pin){
        Swal.fire({
          icon: 'info',
          html: '<span>Generate Username and Password for ' + employee.first_name + ' ' + employee.last_name + '?</span>',
          allowEscapeKey: false,
          allowOutsideClick: false,
          showCloseButton: true,
          confirmButtonText: 'Generate',
          denyButtonText: `Cancel`,
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            this.generate(employee);
          }
        })
      }else{

        Swal.fire({
          icon: 'warning',
          html: '<span>Do you want to regenerate username and password for ' + employee.first_name + ' ' + employee.last_name + '?</span>'
            + '<br><br><span *ngIf='+employee.username+'><b>Username: </b>' + employee.username + '</span> ' +
            '<span *ngIf='+employee.password_pin+'><b>Password: </b>' + employee.password_pin + '</span>',
          allowEscapeKey: false,
          allowOutsideClick: false,
          showCloseButton: true,
          confirmButtonText: 'Regenerate',
          denyButtonText: `Cancel`,
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            this.generate(employee);
          }
        })
      }

    }else{
      await Swal.fire(
        'Oops',
        "Sorry, you don't have the right to access this function. Please contact the admin. Thank you.",
        'warning'
      )
    }
  }

  async generate(employee: any){

    let username = this.createUsername(employee.first_name, employee.last_name);
    let password = this.generatePin();

    const isUsernameAlreadyExist = (usernameToCheck) => {
      return this.employeesArray.some(employee =>
        employee.username === usernameToCheck
      );
    };

    if (isUsernameAlreadyExist(username)) {
      await Swal.fire({
        title: 'Oops!',
        html: 'Employee username is already exist. Please use another name and generate username and pin button again.',
        icon: 'warning',
        denyButtonText: `Got it!`,
      });

      return false;
    }

    await Swal.fire({

      title: 'Generating... please wait...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: async () => {
        Swal.showLoading();

        employee.username = username;
        employee.password_pin = password;
        this.employeeForm.patchValue(employee);

        Swal.fire({
          title: 'Successfully generated!',
          html: "<strong>Username:</strong> " + username + " " + "<strong>Password:</strong> " + password,
          icon: 'success',
          showConfirmButton: true,
          showCloseButton: true,
          allowOutsideClick: false,
          confirmButtonText: 'Save and close',
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.hideLoading();
            this.save();
          }
        });

      },});
  }

  async save() {

    const employee = this.employeeForm.value;

    if(
      employee.first_name === '' || employee.last_name === ''
      || employee.position === '' || employee.zone_id === ''){

      await Swal.fire({
        title: 'Please fill-up all the fields.',
        icon: 'warning',
        denyButtonText: `Got it!`,
      });

      return false;

    }

    if (
      employee.username === '' || employee.password_pin === '') {

      await Swal.fire({
        title: 'Oops!',
        html: 'Employee must have username and password. Please generate by clicking the Generate username and pin button.',
        icon: 'warning',
        denyButtonText: `Got it!`,
      });

      return false;

    }

    let accountId;
    if (this.account.id) {
      accountId = this.account.id;
    } else {
      accountId = this.account.account_id;
    }

    let index = this.account.settings.zones.findIndex(zone => zone.zone_id == employee.zone_id);

    if (index !== -1) {

      await Swal.fire({
        title: 'Save settings... please wait...',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: async () => {
          Swal.showLoading();

          this.account.settings.zones[index].employees.push(employee);
          await this.settingsService.updateSettings(this.account.settings, this.account.account_key);

          combineLatest([
            this.settingsService.addSettingsToAccount(accountId, this.account.settings),
            this.accountService.getAccountByKey(this.account.account_key)])
            .subscribe(([a, b]) => {

              localStorage.removeItem('account');
              localStorage.setItem('account', JSON.stringify(b));

              setTimeout(() =>{
                Swal.fire({
                  title: 'Settings successfully saved.',
                  icon: 'success',
                  didClose: () => {
                    Swal.hideLoading();
                    this.router.navigateByUrl(this.account.account_key+'/user/employees', {replaceUrl: true});
                  }
                });
              }, 1500);

            });

        }
      });


    } else {
      await Swal.fire(
        'Oops',
        "Sorry, zone is not registered. Please contact the admin. Thank you.",
        'warning'
      )
    }

  }

  generatePin() {
    const min = 100000; // The minimum 6-digit number
    const max = 999999; // The maximum 6-digit number
    const pin = Math.floor(Math.random() * (max - min + 1)) + min;
    return pin.toString(); // Convert the number to a string
  }

  createUsername(firstName: string, lastName: string) {
    // Convert the first name and last name to lowercase
    const firstNameLower = firstName.toLowerCase();
    const lastNameLower = lastName.toLowerCase();

    // Remove any spaces or special characters from the first and last names
    const firstNameClean = firstNameLower.replace(/\W/g, '');
    const lastNameClean = lastNameLower.replace(/\W/g, '');

    // Combine the first and last names to create the username
    return `${firstNameClean}${lastNameClean}`;
  }

  initializeEmployees(){
    this.employeesArray = this.zones.flatMap(zone =>
      zone.employees.map(employee => ({ username: employee.username }))
    );
  }

}
