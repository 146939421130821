import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Vehicles } from 'src/models/vehicle.model';
import firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection, DocumentReference } from '@angular/fire/firestore';
import { AuthService } from 'src/services/auth.service';
import {Observable} from "rxjs";
import {map, take} from "rxjs/operators";
import {UserLogs} from "../models/user.model";
import {VehicleTypes} from "../models/vehicle-types.model";

@Injectable({
  providedIn: 'root'
})
export class VehicleService {

  private usersVehicles: Observable<Vehicles[]>;
  private usersVehicleCollection: AngularFirestoreCollection<Vehicles>;
  private vehicles: Observable<Vehicles[]>;
  private vehiclesCollection: AngularFirestoreCollection<Vehicles>;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
    public auth: AuthService,
  ) {
    this.initializeVehicle();
    this.initializeUsersVehicle();
    //this.initializeVehicleTypes();
  }


  ////////////// Users Vehicles ///////////////////////

  getUsersVehicles():Observable<Vehicles[]> {
    return this.usersVehicles;
  }

  getUsersVehicleById(id: string): Observable<Vehicles>{
    return this.usersVehicleCollection.doc<Vehicles>(id).valueChanges().pipe(
      take(1),
      map(vehicle =>{
        vehicle.uid = id;
        return vehicle
      })
    );
  }

  getUserVehicleByPlateNumber(plateNumber: string, account_key: string){
    return new Promise((resolve, reject) => {
      const query = firebase.firestore().collection('users_vehicle');
      const observer = query
        .where('account_key', '==', account_key)
        .where('plate_number', '==', plateNumber)
        .limit(1)
        .get();

      observer.then((snapshot) => {
        if (snapshot.empty) {
          console.log("Sorry, no data found on this plate number. Please contact your administrator.")
          resolve(false);
          return;
        }

        snapshot.forEach((doc) =>{
          let account = doc.data();
          account.account_id = doc.id;
          resolve(account);
        });

      });

    });

  }

  getUserVehicleByCode(code: string, account_key: string){
    return new Promise((resolve, reject) => {
      const query = firebase.firestore().collection('users_vehicle');
      const observer = query
        .where('account_key', '==', account_key)
        .where('code', '==', code)
        .limit(1)
        .get();

      observer.then((snapshot) => {
        if (snapshot.empty) {
          console.log("Sorry, no data found on this plate number. Please contact your administrator.")
          resolve(false);
          return;
        }

        snapshot.forEach((doc) =>{
          let account = doc.data();
          account.account_id = doc.id;
          resolve(account);
        });

      });

    });

  }

  async getUserVehicleViaPlateNumber(plate_number:string):Promise<any>{

    return new Promise((resolve, reject) => {
      const userRef = firebase.firestore().collection('users_vehicle');
        const query = userRef
          .where("plate_number", "==", plate_number)
          .orderBy('date_registered', 'desc')
          .limit(1)
          .get();
  
        query.then((snapshots) => {
          if (snapshots.empty) {
            console.log("no data found");
            return;
          }
  
          let dataArray = [];
          snapshots.forEach((results) => {
            dataArray.push(results.data());
          });
  
          resolve(dataArray);
        });
  
      });

  }

  async getListCodesByPlateNumberWithKey(account_key: string, plate_number: string):Promise<any>{

    return new Promise((resolve, reject) => {
    const userRef = firebase.firestore().collection('users_vehicle');
      const query = userRef
        .where('account_key', '==', account_key)
        .where("plate_number", "==", plate_number)
        .orderBy('date_registered', 'desc')
        .limit(10)
        .get();

      query.then((snapshots) => {
        if (snapshots.empty) {
          console.log("no data found");
          return;
        }

        let dataArray = [];
        snapshots.forEach((results) => {
          dataArray.push(results.data());
        });

        resolve(dataArray);
      });

    });
  }

  async getListCodesByCodesWithKey(account_key: string, code: string):Promise<any> {

    return new Promise((resolve, reject) => {
    const userRef = firebase.firestore().collection('users_vehicle');

      let query = userRef
        .where('account_key', '==', account_key)
        .where("code", "==", code)
        .limit(10)
        .get();

        query.then((snapshots) => {
          if (snapshots.empty) {
            console.log("no data found");
            return;
          }

          let dataArray = [];
          snapshots.forEach((results) => {
            dataArray.push(results.data());
          });

          resolve(dataArray);

        });

      })
  }

  getStartOfToday() {
    const now = new Date();
    now.setHours(0, 0, 0, 0); // +5 hours for Eastern Time
    return firebase.firestore.Timestamp.fromDate(now);
  }

  async createPlateByCode(vehicles: any) {
    const logRef = firebase.firestore().collection('users_vehicle');
    const query = await logRef
      .where('account_key', '==', vehicles.account_key)
      .where('code', '==', vehicles.code)
      .where('plate_number', '==', "")
      .limit(1)
      .get();

    if(query.empty){
      console.log('No data found');
      return;
    }

    query.docs.map((item) =>{
      item.ref.update(vehicles).then((updateResult) =>{
        // console.log(updateResult);
      });
    });
  }

  async updatePlateByCode(vehicles: any) {
    const logRef = firebase.firestore().collection('users_vehicle');
    const query = await logRef
      .where('account_key', '==', vehicles.account_key)
      .where('code', '==', vehicles.code)
      .where('plate_number', '!=', "")
      .limit(1)
      .get();

    if(query.empty){
      console.log('No data found');
      return;
    }

    query.docs.map((item) =>{
      item.ref.update(vehicles).then((updateResult) =>{
        // console.log(updateResult);
      });
    });
  }


  initializeUsersVehicle(){
    this.usersVehicleCollection = this.afs.collection<Vehicles>('users_vehicle');
    this.usersVehicles = this.usersVehicleCollection.snapshotChanges().pipe(
      map(actions =>{
        return actions.map(a =>{
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      }));
  }

  initializeUsersVehicleById(id: string) {
    if (id) {
      this.getUsersVehicleById(id).subscribe(vehicle => {
        return vehicle
      });
    }
  }

  addUsersVehicleByVehicleId(vehicle: Vehicles): Promise<void> {
    const vehicleRef: AngularFirestoreDocument<Vehicles> = this.afs.doc(`users_vehicle/${vehicle.id}`);
    return vehicleRef.set(vehicle)
  }

  addUsersVehicle(vehicle: Vehicles): Promise<DocumentReference> {
    const vehicleRef: AngularFirestoreDocument<Vehicles> = this.afs.doc(`users_vehicle/${vehicle.uid}`);
    return this.usersVehicleCollection.add(vehicle);
  }

  updateUsersVehicle(vehicle: Vehicles, id) {
    return this.afs
      .collection("users_vehicle")
      .doc(id)
      .update(vehicle);
  }

  updateUsersVehicleByPlate(vehicle: Vehicles, plate_number) {
    return this.afs
      .collection("users_vehicle")
      .doc(plate_number)
      .update(vehicle);
  }

  deleteUsersVehicle(vehicle) {
    return this.afs
      .collection("users_vehicle")
      .doc(vehicle.id)
      .delete();
  }

  ///////////////// VEHICLE TYPES ///////////////////

  // getVehicleTypes():Observable<VehicleTypes[]> {
  //   return this.vehicleTypes;
  // }
  //
  // initializeVehicleTypes(){
  //   this.vehicleTypesCollection = this.afs.collection<VehicleTypes>('vehicle_types');
  //   this.vehicleTypes = this.vehicleTypesCollection.snapshotChanges().pipe(
  //     map(actions =>{
  //       return actions.map(a =>{
  //         const data = a.payload.doc.data();
  //         const id = a.payload.doc.id;
  //         return { id, ...data };
  //       })
  //     }));
  // }

  async getVehicleTypes():Promise<any> {

    return new Promise((resolve, reject) => {
      const vehicleTypesRef = firebase.firestore().collection('vehicle_types');

      let query = vehicleTypesRef
        //.limit(10)
        .get();

      query.then((snapshots) => {
        if (snapshots.empty) {
          console.log("no data found");
          return;
        }

        let dataArray = [];
        snapshots.forEach((results) => {
          dataArray.push(results.data());
        });

        resolve(dataArray);

      });

    })
  }

  addVehicleType(vehicleType: any): Promise<void> {
    const vehicleTypeRef: AngularFirestoreDocument<any> = this.afs.doc(`vehicle_types/${vehicleType.id}`);
    return vehicleTypeRef.set(vehicleType);
  }

  deleteVehicleType(id) {
    return this.afs
      .collection("vehicle_types")
      .doc(id)
      .delete();
  }

  // async getVehiclesArray() {
  //   return await this.afs.collection('vehicles')
  //     .get().toPromise()
  //     .then(snapshot => {
  //       if (snapshot.empty) {
  //         console.log('No Matches');
  //         return;
  //       }
  //       return this.getInfo(snapshot.docs);
  //     });
  // }
  //
  // getInfo(data) {
  //   return data.map(doc => {
  //     let info = doc.data();
  //     return info.vehicles[0]
  //   });
  // }

  ////////////////////////// VEHICLES ////////////////////////////

  getVehicles():Observable<Vehicles[]> {
    return this.vehicles;
  }

  getVehicleById(id: string): Observable<Vehicles>{
    return this.vehiclesCollection.doc<Vehicles>(id).valueChanges().pipe(
      take(1),
      map(vehicle =>{
        return vehicle
      })
    );
  }

  getVehicleByCode(code:string): Promise<unknown>{
    return new Promise((resolve, reject) => {

      const userRef = firebase.firestore().collection('vehicles');
      const query = userRef
        .where("code", "==", code)
        .get();

      query.then((snapshots) => {
        if (snapshots.empty) {
          console.log("no data found");
          resolve(null);
        }

        let data;
        snapshots.forEach((results) => {
          data = results.data();
          data.id = results.id

        });

        resolve(data);

      });

    });
  }

  getVehicleListByPlateNumberAndCode(code:string, plate_number:string): Promise<unknown>{
    return new Promise((resolve, reject) => {

      const userRef = firebase.firestore().collection('vehicles');
      const query = userRef
        .where("code", "==", code)
        .where("plate_number", "==", plate_number)
        .get();

      query.then((snapshots) => {
        if (snapshots.empty) {
          console.log("no data found");
          resolve(null);
        }

        let data;
        snapshots.forEach((results) => {
          data = results.data();
          data.id = results.id
        });

        resolve(data);

      });

    });
  }

  getVehicleByPlateNumber(plate_number: string): Observable<Vehicles>{
    return this.vehiclesCollection.doc<Vehicles>(plate_number).valueChanges().pipe(
      take(1),
      map(vehicle =>{
        return vehicle
      })
    );
  }

  initializeVehicle(){
    this.vehiclesCollection = this.afs.collection<Vehicles>('vehicles');
    this.vehicles = this.vehiclesCollection.snapshotChanges().pipe(
      map(actions =>{
        return actions.map(a =>{
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      }));
  }

  initializeVehicleById(id: string) {
    if (id) {
      this.getVehicleById(id).subscribe(vehicle => {
        return vehicle
      });
    }
  }

  addVehicleByPlateNumber(vehicle: Vehicles): Promise<void>{
    const vehicleRef: AngularFirestoreDocument<Vehicles> = this.afs.doc(`vehicles/${vehicle.plate_number}`);
    return vehicleRef.set(vehicle);
  }

  addVehicle(vehicle: Vehicles): Promise<DocumentReference> {
    const vehicleRef: AngularFirestoreDocument<Vehicles> = this.afs.doc(`vehicles/${vehicle.plate_number}`);
    return this.vehiclesCollection.add(vehicle);
  }

  updateVehicle(vehicle: Vehicles, plate_number:string) {
    return this.afs
      .collection("vehicles")
      .doc(plate_number)
      .update(vehicle);
  }

  deleteVehicle(vehicle) {
    return this.afs
      .collection("vehicles")
      .doc(vehicle.plate_number)
      .delete();
  }

  async updateVehicleByCode(code: string, vehicle:any) {

    const logRef = firebase.firestore().collection('vehicles');
    const query = await logRef
      .where('code', '==', code)
      .limit(1)
      .get();

    if(query.empty){
      console.log('No data found');
      return;
    }

    query.docs.map((item) =>{
      item.ref.update(vehicle).then(() =>{
      });
    });
  }

  async checkVehicleByPlateNumber(plate_number: string):Promise<any>{
    return new Promise(async (resolve, reject) => {
      const vehicleRef = firebase.firestore().collection('vehicles');
      const query = await vehicleRef
        .where('plate_number', '==', plate_number)
        .limit(1)
        .get()

      if(query.empty){
        console.log('No data found');
        resolve(null);
        return;
      }

      query.forEach((doc) =>{
        let vehicle = doc.data();
        resolve(vehicle);
      });

    });

  }

  // async getVehiclesArray() {
  //   return await this.afs.collection('vehicles')
  //     .get().toPromise()
  //     .then(snapshot => {
  //       if (snapshot.empty) {
  //         console.log('No Matches');
  //         return;
  //       }
  //       return this.getInfo(snapshot.docs);
  //     });
  // }
  //
  // getInfo(data) {
  //   return data.map(doc => {
  //     let info = doc.data();
  //     return info.vehicles[0]
  //   });
  // }

}
