import { Component, OnInit } from '@angular/core';

import {ActivatedRoute, Router} from "@angular/router";
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';

import {SettingsService} from "src/services/settings.service";
import {AccountService} from "src/services/account.service";

import {AngularFirestore} from "@angular/fire/firestore";
import Swal from "sweetalert2";

import {AuthService} from "src/services/auth.service";
import {AngularFireAuth} from "@angular/fire/auth";

import {Account, Settings} from "src/models/account.model";
import {IDropdownSettings} from 'ng-multiselect-dropdown';
import {combineLatest} from "rxjs";
import {GracePeriod} from 'src/app/constant/grace-period';
import { User } from 'src/models/user.model';
import {ZoneSlotAssignment} from "../../../../../enum/enum";
@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css']
})
export class AddZoneComponent implements OnInit {

  public userForm: FormGroup;
  dropdownUserSettings:IDropdownSettings;
  dropdownPlanSettings:IDropdownSettings;

  openTab = 1;
  toggleTabs($tabNumber: number){
    this.openTab = $tabNumber;
  }

  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }
  private _color = "light";

  settings: Settings;
  account: Account;

  minimumDurationTime = new Date();

  zones: any[] = [];
  zonesArray: FormArray;
  employeesArray: FormArray;
  customizeRateArray: FormArray;

  userAuth: User;

  isPasswordVisible: boolean[] = [];

  constructor(
    private firestore: AngularFirestore,
    protected route : ActivatedRoute,
    public formBuilder: FormBuilder,
    public router: Router,
    public authService: AuthService,
    public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    private settingsService: SettingsService,
    private accountService: AccountService,
  ) {

    this.authService.user$.subscribe(user => this.userAuth = user);
    this.account = JSON.parse(localStorage.getItem('account'));

  }

  ngOnInit(): void {

    this.zonesArray = this.formBuilder.array([]);

    this.userForm = this.formBuilder.group({
      zones: this.zonesArray,
    });

  }

  ngAfterViewInit(){
    this.zonesArray.push(this.createZone());
  }

  createZone(): FormGroup {
    return this.formBuilder.group({
      zone_id: this.firestore.createId(),
      zone_name: [''],
      zone_slots: [''],
      zone_description: [''],
      date_created: [new Date()],
      zone_status: 1, //not activated,
      zone_slot_assignment: ZoneSlotAssignment.Manual,
      employees: [[]],
      customizeRates: [[]],
      parkingFee: {}
    });
  }

  async saveZone(){

    let zone_slots: any = [];

    if(
      this.userForm.value.zones[0].zone_slots === "" ||
      this.userForm.value.zones[0].zone_name === ""){

      await Swal.fire({
        title: 'Please fill-up all the fields.',
        icon: 'warning',
        denyButtonText: `Got it!`,
      });

      return false;

    }

    if(this.authService.canDelete(this.userAuth)){
      let settings: Settings;
      if(this.account.settings == undefined){
        settings =  {
          zones: this.userForm.value.zones,
        }

        this.account.settings = settings;

      }else{
          this.account.settings.zones.push(this.userForm.value.zones[0]);
      }

      await Swal.fire({
        title: 'Save settings... please wait...',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: async ()=>{
          Swal.showLoading()
          let nSettings = await this.settingsService.addSettingZone(this.account.account_key, this.account.settings);

            let accountId;
            if(this.account.id){
              accountId = this.account.id;
            }else{
              accountId = this.account.account_id;
            }

            this.account.settings.zones  = this.account.settings.zones.filter(zone => zone.zone_name !== '' && zone.zone_slots);


            console.log(this.account);

            combineLatest([
              this.settingsService.addSettingsToAccount(accountId, this.account.settings),
              this.accountService.getAccountByKey(this.account.account_key)])
              .subscribe(([a, b]) =>{

                localStorage.removeItem('account');
                localStorage.setItem('account', JSON.stringify(b));

                Swal.fire({
                  title: 'Settings successfully saved!.',
                  timer: 2000,
                  icon: 'success',
                  didClose: ()=>{
                    Swal.hideLoading();
                    this.router.navigateByUrl(this.account.account_key+'/user/settings/zone-update/' + this.userForm.value.zones[0].zone_id);
                  }
                });

              });

        }
      });
    }

  }

}
