import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {Account, AccountUser} from "src/models/account.model";
import Swal from "sweetalert2";
import {AccountService} from "src/services/account.service";
import {AngularFirestore} from "@angular/fire/firestore";
import {VehicleService} from "src/services/vehicle.service";
import {
  regions,
  provinces,
  cities,
  barangays,
} from "select-philippines-address";
import {IDropdownSettings} from "ng-multiselect-dropdown";
import {AuthService} from "../../../../services/auth.service";
import {combineLatest} from "rxjs";
import {User} from "../../../../models/user.model";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html'
})
export class ProfileComponent implements OnInit {

  public userForm: FormGroup;
  public addressForm: FormGroup;
  public vehicleForm: FormGroup;
  vehiclesArray: FormArray;
  account: Account = <Account> {};
  errorMessage: string;

  dropdownSelectRegion:IDropdownSettings;
  addressRegionSelected: any = [];
  regions: any = [];

  dropdownSelectProvinces:IDropdownSettings;
  addressProvincesSelected: any = [];
  provinces: any = [];

  dropdownSelectCities:IDropdownSettings;
  addressCitySelected: any = [];
  cities: any = [];

  dropdownSelectBarangay:IDropdownSettings;
  addressBarangaySelected: any = [];
  barangays: any = [];

  userAuth: User;

  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }
  private _color = "light";

  constructor(
    private firestore: AngularFirestore,
    public formBuilder: FormBuilder,
    private accountService: AccountService,
    protected route : ActivatedRoute,
    private vehicleService: VehicleService,
    public authService: AuthService,
    public auth: AuthService,
  ){

  }

  ngOnInit(){

    this.account = JSON.parse(localStorage.getItem('account'));
    this.auth.user$.subscribe(user => this.userAuth = user);

    this.userForm = this.formBuilder.group({
      fullName: [''],
      email: [''],
      phoneNumber: [''],
      address: this.formBuilder.group({
        region: [''],
        province: [''],
        city: [''],
        barangay: [''],
        street_house_building_number: [''],
      }),
    });

    this.addressForm = this.formBuilder.group({
      address: this.formBuilder.group({
        region: [''],
        province: [''],
        city: [''],
        barangay: [''],
        street_house_building_number: [''],
      }),
    });

    this.vehiclesArray = this.formBuilder.array([]);
    this.vehicleForm = this.formBuilder.group({
      vehicles: this.vehiclesArray
    });

    this.vehicleForm.patchValue(this.account);

    let accountId;
    if(this.account.account_id){
      accountId = this.account.account_id;
    }else{
      accountId = this.account.id;
    }

    this.accountService.getAccountById(accountId).subscribe((res: any) =>{

      console.log(res);

      this.userForm = this.formBuilder.group({
        fullName: res.account_name,
        email: res.email_address,
        phoneNumber: res.phone_number,
        accountUsers: res.accountUsers
      });

      this.addressForm = this.formBuilder.group({
        address: this.formBuilder.group({
          region: res.address?.region,
          province: res.address?.province,
          city: res.address?.city,
          barangay: res.address?.barangay,
          street_house_building_number: res.address?.street_house_building_number
        }),
      });

      if(res.address && res.address.region && res.address.province && res.address.city){
        provinces(res.address.region[0].region_code).then((province) => {
          this.provinces = province;
        });

        cities(res.address.province[0].province_code).then((city) => {
          this.cities = city;
        });

        barangays(res.address.city[0].city_code).then((barangay) => {
          this.barangays = barangay;
        });
      }

      if(!res.vehicles || res.vehicles.length === 0){
        this.vehiclesArray.push(this.createVehicle());
      }else{
        for (let index = 0; index < res.vehicles.length; index++) {
          const vehicle = res.vehicles[index];
          this.vehiclesArray.push(this.updateVehicle(vehicle));
        }
      }

      this.userForm.patchValue(res);
      this.addressForm.patchValue(res);

    });

  }

  ngAfterViewInit(){

    regions().then((region) => {
      this.regions = region;
    });

    this.dropdownSelectRegion = {
      singleSelection: true,
      idField: 'region_code',
      textField: 'region_name',
      allowSearchFilter: true,
      searchPlaceholderText: "Search region",
      noDataAvailablePlaceholderText: "Region not found",
    };

    this.dropdownSelectProvinces = {
      singleSelection: true,
      idField: 'province_code',
      textField: 'province_name',
      allowSearchFilter: true,
      searchPlaceholderText: "Search province",
      noDataAvailablePlaceholderText: "Select region first",
    };

    this.dropdownSelectCities = {
      singleSelection: true,
      idField: 'city_code',
      textField: 'city_name',
      allowSearchFilter: true,
      searchPlaceholderText: "Search city",
      noDataAvailablePlaceholderText: "Select province first",
    };

    this.dropdownSelectBarangay = {
      singleSelection: true,
      idField: 'brgy_code',
      textField: 'brgy_name',
      allowSearchFilter: true,
      searchPlaceholderText: "Search barangay",
      noDataAvailablePlaceholderText: "Select city first",
    };
  }

  onSelectedRegion(item: any){
    console.log(item);
    provinces(item.region_code).then((province) => {
      this.provinces = province;
      console.log(this.provinces);
    });
  }

  onSelectedProvince(item: any){
    console.log(item);
    cities(item.province_code).then((city) => {
      this.cities = city;
      console.log(this.cities);
    });
  }

  onSelectedCity(item: any){
    console.log(item);
    barangays(item.city_code).then((barangay) => {
      this.barangays = barangay;
      console.log(this.barangays);
    });
  }

  onSelectedBarangay(item: any){
    console.log(item);
  }

  createVehicle(): FormGroup {
    return this.formBuilder.group({
      id: this.firestore.createId(),
      plate_number: [''],
      code: this.generateRandomString(6),
      vehicle_color: [''],
      vehicle_model: [''],
      vehicle_type: [''],
      date_created: new Date(),
      updatedAt: new Date()
    });
  }

  updateVehicle(data): FormGroup {
    return this.formBuilder.group({
      id: data.id,
      plate_number: data.plate_number,
      code: data.code
    });
  }

  async addVehicle() {

    if (!this.auth.canDelete(this.userAuth)) {
      await Swal.fire({
        title: 'Ooops!',
        html: 'You have no rights to update user profile. Please contact your admin.',
        allowEscapeKey: true,
        allowOutsideClick: true,
        showConfirmButton: false,
        showCancelButton: true,
        showCloseButton: true,
        cancelButtonText: 'Ok',
        icon: 'warning',
      });

      return;
    }

    this.errorMessage = "";
    this.vehiclesArray.push(this.createVehicle());
  }

  async deleteVehicle(index: any) {

    if (!this.auth.canDelete(this.userAuth)) {
      await Swal.fire({
        title: 'Ooops!',
        html: 'You have no rights to update user profile. Please contact your admin.',
        allowEscapeKey: true,
        allowOutsideClick: true,
        showConfirmButton: false,
        showCancelButton: true,
        showCloseButton: true,
        cancelButtonText: 'Ok',
        icon: 'warning',
      });

      return;
    }

    this.errorMessage = "";
    this.vehiclesArray.value.splice(index, 1);
    this.vehiclesArray.controls.splice(index, 1);
  }

  get getControl(){
    return this.userForm.controls;
  }

  async saveUser() {

    if (!this.auth.canDelete(this.userAuth)) {
      await Swal.fire({
        title: 'Ooops!',
        html: 'You have no rights to update user profile. Please contact your admin.',
        allowEscapeKey: true,
        allowOutsideClick: true,
        showConfirmButton: false,
        showCancelButton: true,
        showCloseButton: true,
        cancelButtonText: 'Ok',
        icon: 'warning',
      });

      return;
    }

    if (this.userForm.valid) {

      const sUsers: any = [];

      let accountUser = this.userForm.value.accountUsers.find(u => u.id === this.account.owner_id);
      accountUser.fullName = this.userForm.value.fullName;

      sUsers.push(accountUser);

      let account: Account = {
        account_key: this.account.account_key,
        account_name: this.userForm.value.fullName,
        email_address: this.userForm.value.email,
        email_address_verified: this.account.email_address_verified,
        phone_number: this.userForm.value.phoneNumber,
        accountUsers: sUsers,
      }

      await Swal.fire({
        title: 'Saving user... please wait...',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: async () => {

          Swal.showLoading()

          await this.accountService.addAccountUser(account.account_key, account.accountUsers);
          await this.accountService.updateAccount(account, this.account.account_id).then(() => {
            Swal.fire({
              title: 'Account successfully updated.',
              timer: 2000,
              icon: 'success',
              didClose: () => {
                Swal.hideLoading();
                this.accountService.getAccountByKey(this.account.account_key).then((res) =>{
                  localStorage.removeItem('account');
                  localStorage.setItem('account', JSON.stringify(res));
                });
              }
            });
          });

        }

      });

    } else {
      await Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please check all the required fields.'
      });
    }

  }

  async saveAddress() {

    if (!this.auth.canDelete(this.userAuth)) {
      await Swal.fire({
        title: 'Ooops!',
        html: 'You have no rights to update user profile. Please contact your admin.',
        allowEscapeKey: true,
        allowOutsideClick: true,
        showConfirmButton: false,
        showCancelButton: true,
        showCloseButton: true,
        cancelButtonText: 'Ok',
        icon: 'warning',
      });

      return;
    }

    if (this.addressForm.valid) {
      let account: Account = {
        address: {
          region: this.addressForm.value.address.region,
          province: this.addressForm.value.address.province,
          city: this.addressForm.value.address.city,
          barangay: this.addressForm.value.address.barangay,
          street_house_building_number: this.addressForm.value.address.street_house_building_number
        }
      }

      console.log(account);

      await Swal.fire({
        title: 'Saving address... please wait...',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {

          Swal.showLoading()

          this.accountService.updateAccountAddress(account, this.account.account_id).then(() => {
            Swal.fire({
              title: 'Address successfully updated.',
              timer: 2000,
              icon: 'success',
              didClose: () => {
                Swal.hideLoading();
              }
            });
          });

        }

      });

    } else {
      await Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please check all the required fields.'
      });
    }
  }

  async saveVehicle() {

    if (!this.auth.canDelete(this.userAuth)) {
      await Swal.fire({
        title: 'Ooops!',
        html: 'You have no rights to update user profile. Please contact your admin.',
        allowEscapeKey: true,
        allowOutsideClick: true,
        showConfirmButton: false,
        showCancelButton: true,
        showCloseButton: true,
        cancelButtonText: 'Ok',
        icon: 'warning',
      });

      return;
    }

    this.errorMessage = "";

    if(this.vehiclesArray.value && this.vehiclesArray.value.length !== 0){

      if(this.isDuplicate(this.vehiclesArray.value)){
        this.errorMessage = "Plate number already exist.";
        return;
      }

      await Swal.fire({
        title: 'Saving vehicle... please wait...',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {

          Swal.showLoading();

          let vehicles = [];

          for (const vehicle of this.vehiclesArray.value){
            if (vehicle.plate_number) {

              vehicle.plate_number = vehicle.plate_number.toUpperCase()
                .replace(/\s/g, "")
                .replace(/[^a-zA-Z0-9\-]/g, '');

              let vd = {
                id: vehicle.id,
                plate_number: vehicle.plate_number,
                code: vehicle.code
              }

              vehicles.push(vd);
              this.accountService.updateAccountVehicles(vehicles, this.account.account_id);
              this.vehicleService.addVehicle(vehicle);

              Swal.fire({
                title: 'Vehicle successfully added.',
                timer: 3000,
                icon: 'success',
                didClose: () => {
                  Swal.hideLoading();
                }
              });

            }else{
              Swal.close();
              this.errorMessage = "Plate number is required."
            }
          }

        }

      });

    }
  }

  async resetPassword(){

    if (!this.auth.canDelete(this.userAuth)) {
      await Swal.fire({
        title: 'Ooops!',
        html: 'You have no rights to update user profile. Please contact your admin.',
        allowEscapeKey: true,
        allowOutsideClick: true,
        showConfirmButton: false,
        showCancelButton: true,
        showCloseButton: true,
        cancelButtonText: 'Ok',
        icon: 'warning',
      });

      return;
    }

    this.authService.forgotPassword(this.userForm.get('email').value).then((res) =>{
      if(res){
        Swal.fire({
          title: 'Reset password',
          timer: 3000,
          html: '<p>If we have an account with this email address, a password reset email has been sent.</p>',
          icon: 'success',
          didClose: () => {
            Swal.hideLoading();
          }
        });
      }
    }, (error) =>{
      alert('There was an error sending email. Please try again or contact admin.')
    });
  }

  async addPaymentMethod(){

    if (!this.auth.canDelete(this.userAuth)) {
      await Swal.fire({
        title: 'Ooops!',
        html: 'You have no rights to update user profile. Please contact your admin.',
        allowEscapeKey: true,
        allowOutsideClick: true,
        showConfirmButton: false,
        showCancelButton: true,
        showCloseButton: true,
        cancelButtonText: 'Ok',
        icon: 'warning',
      });

      return;
    }

    await Swal.fire({
      title: 'Add payment method',
      html: '<h3>Payment method is not yet available at the moment.</h3>',
      icon: 'warning',
      didClose: () => {
        Swal.hideLoading();
      }
    });
  }

  isDuplicate(array) {
    let valueArr = array.map(function(item){ return item.plate_number });
    return valueArr.some(function (item, idx) {
      return valueArr.indexOf(item) != idx
    });
  }

  generateRandomString(length: number) {

    let result = 'PBPH' + '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return result;
  }

}
