import {Component, OnInit} from "@angular/core";
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import { Account } from "src/models/account.model";

@Component({
  selector: "app-admin",
  templateUrl: "./admin.component.html",
})
export class AdminComponent implements OnInit {

  // account: Account;
  currentRoute: string;
  currentRouteAdmin: string;

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute
  ) {
    this.router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd)
    ).subscribe((res) => {
      this.currentRoute = res.url.split('/').slice(-1)[0];
      this.currentRouteAdmin = res.url.split('/')[1];

      if(this.currentRouteAdmin === "admin"){
        localStorage.removeItem('account');
      }

      // console.log(this.currentRoute);
    }); 
  }

  ngOnInit() {}

}
