import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import firebase from "firebase";
import { filter, map, mergeMap } from 'rxjs/operators';
import { Account } from 'src/models/account.model';
import Swal from "sweetalert2";

@Component({
  selector: 'app-detail-employee',
  templateUrl: './detail-employee.component.html',
  styleUrls: ['./detail-employee.component.css']
})
export class DetailEmployeeComponent implements OnInit {

  account: Account;
  activities = [];

  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }
  private _color = "light";

  constructor(
    protected route : ActivatedRoute,
  ) { 
    this.account = JSON.parse(localStorage.getItem('account'));
  }

  ngOnInit(): void {
    let activity;
    let arrayActivity = [];

    this.route.paramMap
    .pipe(
      map(pm => pm.get('id')),
      filter(id => id != null),
      mergeMap(id => this.loadLogsActivites(id)),
      ).subscribe((logsActivities :any)=> {
        
        for (let log of logsActivities.docs) {
          activity = log.data();
          arrayActivity.push(activity);
        }
        console.log("test:",arrayActivity)  
        this.activities = arrayActivity;
       
      })

  }

  async loadLogsActivites(user_id:string) {
    console.log(user_id);
    return await firebase.firestore().collection('logs_monitoring')
    .where('user_id', '==', user_id).get();
  }

}
