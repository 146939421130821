import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { map, filter, mergeMap } from 'rxjs/operators';
import { Account, Zones } from 'src/models/account.model';
import { ScheduledEmailReport } from 'src/models/email-schedule.model';
import { User } from 'src/models/user.model';
import { AccountService } from 'src/services/account.service';
import { AuthService } from 'src/services/auth.service';
import { EmailSchedulingService } from 'src/services/email-scheduling.service';
import Swal from "sweetalert2";

@Component({
  selector: 'app-email-automation-edit',
  templateUrl: './email-automation-edit.component.html',
})
export class EmailAutomationEditComponent implements OnInit {

  public emailSchedulingForm: FormGroup;
  public emailSchedule: ScheduledEmailReport = <ScheduledEmailReport>{};

  public account: Account = <Account>{};
  public zones: Zones[] = [];
  public accounts: Account[] = [];
  public current_account_id: string;
  public current_zone_id: string;
  public userAuth: User;

  constructor(
    public formBuilder: FormBuilder,
    private accountService: AccountService,
    private emailSchedulingService: EmailSchedulingService,
    private firestore: AngularFirestore,
    public auth: AuthService,
    protected route: ActivatedRoute,

  ) {

    this.auth.user$.subscribe(user => this.userAuth = user);

    this.accountService.getAccounts().subscribe((account) => {
      this.accounts = account;
    });
  }

  ngOnInit(): void {

    this.emailSchedulingForm = this.formBuilder.group({
      account: ['', Validators.required],
      zone: ['', Validators.required],
      type: ['', Validators.required],
      time: ['', Validators.required],
      day: ['', Validators.required],
      bcc: [''],
      cc: [''],
      to: ['', Validators.required],
      id: ['']
    });

    this.emailSchedulingForm.patchValue(this.emailSchedule);

    this.route.paramMap
      .pipe(
        map(pm => pm.get('id')),
        filter(id => id != null),
        mergeMap(id => this.emailSchedulingService.getEmailScheduleById(id)),
      ).subscribe(async (schedule) => {
        if(schedule != undefined){
          this.accountService.getAccountByKey(schedule.account_key)
            .then(account => {
              if (account != undefined) {
                this.zones = account.settings.zones;
                let zone: Zones  = this.zones.find(z => z.zone_id == schedule.zone_id)
             
                this.current_account_id = account.id
                this.emailSchedulingForm = this.formBuilder.group({
                  account: [account.account_id],
                  zone: [zone?.zone_id??''],
                  type: [schedule.report_type],
                  time: [schedule.time],
                  day: [schedule.day],
                  bcc: [schedule.email_recipient_bcc],
                  cc: [schedule.email_recipient_cc],
                  to: [schedule.email_recipient_to],
                  id: [schedule.id]
                })
                this.emailSchedulingForm.patchValue(schedule);
              }
            })
  
        }
       

      })


  }
  
  onSelectAccount(id:string){
    this.current_account_id = id;
    this.account = this.accounts.find(ac => ac.id == id);
    this.zones = this.account.settings.zones;
    this.current_account_id = '';
    this.emailSchedulingForm.patchValue({zone: ''})
  }

  onSelectZone(id: string){
    this.current_zone_id = id;
  }

  save(): void {
    if (this.emailSchedulingForm.valid) {
      let value = this.emailSchedulingForm.value;

      let account = this.accounts.find(ac => ac.id == value.account);
      let zone = this.zones.find(z => z.zone_id == value.zone)

      let data = {
        email_recipient_bcc: value.bcc,
        email_recipient_cc: value.cc,
        email_recipient_to: value.to,
        scheduled: true,
        day: value.day,
        time: value.time,
        report_type: value.type,
        account_key: account.account_key,
        account_name: account.account_name,
        zone_id: zone.zone_id,
        zone_name: zone.zone_name,
        id: value.id,

        timestamp: new Date()
      }

      this.emailSchedulingService.updateScheduleEmailReport(data, value.id).then((res: any) => {
        Swal.fire({
          title: 'Email schedule successfully updated.',
          timer: 2000,
          icon: 'success',
          didClose: () => {
            this.emailSchedulingForm.reset();
            Swal.hideLoading();
          }
        });
      });

    }
  }

}
