import { Component, OnInit } from "@angular/core";
import { Account } from "src/models/account.model";
import {AuthService} from "../../../../services/auth.service";
import {User} from "../../../../models/user.model";
import {UserService} from "../../../../services/user.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
})
export class DashboardComponent implements OnInit {

  account: Account;
  user: User;

  constructor(
    public auth: AuthService,
    private userService: UserService
  ) {
    this.account = JSON.parse(localStorage.getItem('account'));
    this.user = JSON.parse(localStorage.getItem('user'));

  }

  async ngOnInit() {
    if(this.user){
      const userData = {
        account_key: this.account.account_key,
        id: this.user.uid
      }

      await this.userService.addUserLogActivity(userData);
    }
  }
}
