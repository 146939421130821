import {Component, OnInit} from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection} from "@angular/fire/firestore";
import firebase from "firebase";
import exportFromJSON from 'export-from-json'
import {Account} from "../../../../models/account.model";
import {Observable} from "rxjs";
import {UserService} from "../../../../services/user.service";
import {VehicleService} from "../../../../services/vehicle.service";
import {LogsService} from "../../../../services/log.service";

@Component({
  selector: 'app-export-legacy-data',
  templateUrl: './export-legacy-data.component.html',
  styleUrls: ['./export-legacy-data.component.css']
})
export class ExportLegacyDataComponent implements OnInit {

  fetching: boolean;
  private accounts: Observable<Account[]>;
  private accountCollection: AngularFirestoreCollection<Account>;

  constructor(
    private afs: AngularFirestore,
    private userService: UserService,
    private vehicleService: VehicleService,
    private logService: LogsService
    ) { }

  ngOnInit(): void {
  }

  // async logs() {
  //
  //   // const startDate = new Date('9/20/2021')
  //   // startDate.setHours(0,0,0,0);
  //   // const endDate = new Date('9/20/2021')
  //   // endDate.setHours(23,59,59,999);
  //   //
  //   // let logs = await firebase.firestore().collection('logs')
  //   //   .where('time_in', '>=', startDate)
  //   //   .where('time_in', '<=', endDate)
  //   //   //.limit(5)
  //   //   .get();
  //   //
  //   // let dataArray = [];
  //   // logs.forEach((logs) =>{
  //   //
  //   //   let data = logs.data();
  //   //
  //   //   if(
  //   //     data.entry_status ||
  //   //     data.exit_status ||
  //   //     data.plate_number ||
  //   //     data.vehicle_color ||
  //   //     data.vehicle_model ||
  //   //     data.vehicle_type
  //   //   ){
  //   //     delete data.entry_status
  //   //     delete data.exit_status
  //   //     delete data.plate_number
  //   //     delete data.vehicle_color
  //   //     delete data.vehicle_model
  //   //     delete data.vehicle_type
  //   //   }
  //   //
  //   //   data.time_in = new Date(data.time_in.seconds * 1000);
  //   //   data.timestamp = new Date(data.timestamp.seconds * 1000);
  //   //   data.account_key = "86736003532";
  //   //   data.remarks = "";
  //   //
  //   //   if(logs.data().time_out !== null){
  //   //     data.time_out = new Date(data.time_out.seconds * 1000);
  //   //   }else{
  //   //     data.time_out = null;
  //   //   }
  //   //
  //   //   dataArray.push(data);
  //   //
  //   // });
  //   //
  //   // console.log("logs ==>", dataArray);
  //   // localStorage.setItem("logs", JSON.stringify(dataArray));
  //
  //   // const data = JSON.parse(localStorage.getItem("logs"));
  //   // const fileName = 'logs'
  //   // const exportType =  exportFromJSON.types.json
  //   // exportFromJSON({ data, fileName, exportType })
  //
  //   const data = JSON.parse(localStorage.getItem("logs"));
  //   data.forEach((item) =>{
  //
  //     let dataItem = item;
  //     dataItem.time_in = new Date(item.time_in);
  //     dataItem.timestamp = new Date(item.timestamp);
  //
  //     if(item.time_out){
  //       dataItem.time_out = new Date(item.time_out);
  //     }else{
  //       dataItem.time_out = null;
  //     }
  //
  //     this.logService.addLogsById(dataItem);
  //
  //   });
  // }
  //
  // async userLogs() {
  //
  //   // const startDate = new Date('9/20/2021')
  //   // startDate.setHours(0,0,0,0);
  //   // const endDate = new Date('9/20/2021')
  //   // endDate.setHours(23,59,59,999);
  //   //
  //   // let userLogs = await firebase.firestore().collection('users_logs')
  //   //   .where('date_registered', '>=', startDate)
  //   //   .where('date_registered', '<=', endDate)
  //   //   .get();
  //   //
  //   // let dataArray = [];
  //   // userLogs.forEach((userLogs) =>{
  //   //   let data = userLogs.data();
  //   //   data.date_registered = new Date(data.date_registered.seconds * 1000);
  //   //   data.account_key = "86736003532";
  //   //   data.deleted = false;
  //   //   data.userVehicle_id = data.vehicle_id;
  //   //
  //   //   if(data.terms || data.vehicle_id){
  //   //     delete data.terms
  //   //     delete data.vehicle_id
  //   //   }
  //   //
  //   //   dataArray.push(data);
  //   // })
  //   //
  //   // console.log("userLogs ==>", dataArray);
  //   // localStorage.setItem("userLogs", JSON.stringify(dataArray));
  //
  //   // const data = JSON.parse(localStorage.getItem("userLogs"));
  //   // const fileName = 'userLogs'
  //   // const exportType =  exportFromJSON.types.json
  //   // exportFromJSON({ data, fileName, exportType })
  //
  //   const data = JSON.parse(localStorage.getItem("userLogs"));
  //   data.forEach((item) =>{
  //     item.date_registered = new Date(item.date_registered);
  //     this.userService.addUserLogs(item);
  //   });
  //
  // }

  async vehicles() {

    // let vehicles = await firebase.firestore().collection('vehicles')
    //   .get();
    //
    // let dataArray = [];
    // vehicles.forEach((vehicles) =>{
    //   let data = vehicles.data();
    //   dataArray.push(data);
    // });
    //
    // Promise.all(dataArray).then((response) =>{
    //   console.log("response =>>>>", response);
    //   console.log("vehicles ==>", dataArray);
    //   localStorage.setItem("vehicles", JSON.stringify(dataArray));
    //
    //   const data = JSON.parse(localStorage.getItem("vehicles"));
    //   const fileName = 'vehicles'
    //   const exportType =  exportFromJSON.types.json
    //   exportFromJSON({ data, fileName, exportType })
    //
    // }).catch((e) =>{
    //   //Handle errors
    // });

    // const data = JSON.parse(localStorage.getItem("vehicles"));
    // data.forEach((item) =>{
    //
    //   let data = item;
    //
    //   if(data.plate_number){
    //     data.date_created = new Date(item.date_created);
    //     data.plate_number = item.plate_number.toUpperCase()
    //       .replace(/\s/g, "")
    //       .replace(/[^a-zA-Z0-9\-]/g, '');
    //
    //     this.vehicleService.addVehicleByPlateNumber(data);
    //   }
    //
    // });
  }

  // async userVehicles() {
  //
  //   let users_vehicle = await firebase.firestore().collection('users_vehicle')
  //     .get();
  //
  //   let dataArray = [];
  //   users_vehicle.forEach((uv) =>{
  //     let data = uv.data();
  //     dataArray.push(data);
  //   });
  //
  //   Promise.all(dataArray).then((response) =>{
  //     console.log("response =>>>>", response);
  //     console.log("users_vehicle ==>", dataArray);
  //     localStorage.setItem("users_vehicle", JSON.stringify(dataArray));
  //
  //     const data = JSON.parse(localStorage.getItem("users_vehicle"));
  //     const fileName = 'users_vehicle'
  //     const exportType =  exportFromJSON.types.json
  //     exportFromJSON({ data, fileName, exportType })
  //
  //   }).catch((e) =>{
  //     //Handle errors
  //   });
  //
  //   // let userLogs = JSON.parse(localStorage.getItem("vehicles"));
  //   //
  //   // let dataArray = [];
  //   // userLogs.forEach((item) =>{
  //   //   let log = item;
  //   //   log.account_key = "86736003532";
  //   //   log.deleted = false;
  //   //   log.date_registered = item.date_created;
  //   //
  //   //   if(item.date_created){
  //   //     delete item.date_created
  //   //   }
  //   //
  //   //   dataArray.push(item);
  //   // });
  //   //
  //   // localStorage.setItem("userVehicle", JSON.stringify(dataArray));
  //
  //   // const data = JSON.parse(localStorage.getItem("userVehicle"));
  //   // console.log("userVehicle ==>", data);
  //   // const fileName = 'userVehicle'
  //   // const exportType =  exportFromJSON.types.json
  //   // exportFromJSON({ data, fileName, exportType })
  //   // this.fetching = false;
  //
  //   // const data = JSON.parse(localStorage.getItem("userVehicle"));
  //   // data.forEach((item) =>{
  //   //
  //   //   let dataItem = item;
  //   //   dataItem.date_registered = new Date(item.date_registered);
  //   //   dataItem.plate_number = item.plate_number.toUpperCase()
  //   //     .replace(/\s/g, "")
  //   //     .replace(/[^a-zA-Z0-9\-]/g, '');
  //   //
  //   //   this.vehicleService.addUsersVehicleByVehicleId(dataItem);
  //   //
  //   // });
  // }

  // async userAccounts() {
  //
  //   // let userAccounts = await firebase.firestore().collection('accounts')
  //   //   //.limit(5)
  //   //   .get();
  //   //
  //   // let dataArray = [];
  //   // userAccounts.forEach((item) =>{
  //   //   dataArray.push(item.data());
  //   // })
  //
  //   //localStorage.setItem("accounts", JSON.stringify(dataArray));
  //
  //   const accounts = JSON.parse(localStorage.getItem("accounts"));
  //
  //   this.accountCollection = this.afs.collection<Account>('accounts');
  //   accounts.forEach((account) =>{
  //     console.log("userAccounts ==>", account);
  //     this.accountCollection.add(account);
  //   });
  //
  // }
  //
  // async userRole() {
  //
  //   let userRoles = await firebase.firestore().collection('user_role')
  //     .limit(5)
  //     .get();
  //
  //   let dataArray = [];
  //   userRoles.forEach((userRoles) =>{
  //     let data = userRoles.data();
  //     data.date_assigned = new Date(data.date_assigned.seconds * 1000);
  //     data.account_key = "86736003532";
  //
  //     dataArray.push(data);
  //   })
  //
  //   console.log("userRoles ==>", dataArray);
  //   localStorage.setItem("userRoles", JSON.stringify(dataArray));
  //   // const data = JSON.parse(localStorage.getItem("userRoles"));
  //   // const fileName = 'userRoles'
  //   // const exportType =  exportFromJSON.types.json
  //   // exportFromJSON({ data, fileName, exportType })
  //   // this.fetching = false;
  //
  // }

  // async getPlateWithMultiCodes() {
  //
  //   let usersVehicle = await firebase.firestore().collection('users_vehicle')
  //     //.limit(5)
  //     .get();
  //
  //   let dataArray = [];
  //   usersVehicle.forEach((usersVehicle) =>{
  //     let data = usersVehicle.data();
  //     dataArray.push(data);
  //   });
  //
  //   let  counts = dataArray.reduce((m, { plate_number }) => m.set(plate_number, m.has(plate_number)), new Map);
  //   let  result = dataArray.filter(({ plate_number }) => counts.get(plate_number));
  //
  //   console.log("duplicate result ==>", result);
  //   localStorage.setItem("duplicate", JSON.stringify(result));
  //
  // }
}
