import { Component, OnInit, Input, ChangeDetectorRef } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import firebase from "firebase";
import { filter } from "rxjs/operators";
import {Account} from "src/models/account.model";

@Component({
  selector: "app-card-amount-collected",
  templateUrl: "./card-amount-collected.component.html",
})
export class CardAmountCollectedComponent implements OnInit {

  account: Account;
  amountCollected: number = 0;
  zone_id:string;
  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef
    ) {
      this.zone_id = this.router.routerState.snapshot.url.split('/').slice(-1)[0]

      this.account = JSON.parse(localStorage.getItem('account'));
      this.router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd)
    ).subscribe(async (res) => {

      let newZoneId = res.url.split('/').slice(-1)[0];
      this.zone_id = newZoneId;
      await this.getCountLogsAvailable();

    });
  }

  async ngOnInit() {
    if(this.zone_id) await this.getCountLogsAvailable();
  }

  getStartOfToday() {
    const now = new Date();
    now.setHours(0, 0, 0, 0); // +5 hours for Eastern Time
    return firebase.firestore.Timestamp.fromDate(now);
  }

  async getCountLogsAvailable(){

    const query = firebase.firestore().collection('logs');
    const observer = query
      .where('account_key', '==', this.account.account_key)
      .where('zone_id','==', this.zone_id)
      .where('timestamp', '>=', this.getStartOfToday())
      .onSnapshot(querySnapshot => {

        let array = [];
        querySnapshot.forEach((item) =>{
          let data = item.data();
          data.id = item.id;
          array.push(data);
        });

        let initial_payment = 0;
        let additional_payment = 0;

        array.map(logs => {
          if(logs.initial_payment_recieved_by_id){
            initial_payment += parseInt(logs.initial_payment);
          }

          if(logs.additional_payment_recieved_by_id){
            additional_payment += parseInt(logs.additional_payment);
          }
        });

        this.amountCollected = initial_payment + additional_payment;

        this.cdr.detectChanges();

      }, err => {
        console.log(`Encountered error: ${err}`);
      });

  }

}
