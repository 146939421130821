import { Component, OnInit } from '@angular/core';
import { Account, Zones } from 'src/models/account.model';
import firebase from "firebase";

@Component({
  selector: 'app-parking-details',
  templateUrl: './parking-details.component.html',
  styleUrls: ['./parking-details.component.css']
})
export class ParkingDetailsComponent implements OnInit {

  openTab:string;
  account: Account;
  zones: any;
  parkingLevels = [
    {level:1}
  ];


  constructor() {
    this.account = JSON.parse(localStorage.getItem('account'));
    this.zones = this.account.settings.zones;
    this.openTab = this.zones[0].zone_id;

    //TODO: implement dynamic zone slots into view
    for(let zone of this.zones){

      let zoneNameArray = zone.zone_name.split(" ")
      let slotName = "";
      zoneNameArray.map((name: string) => {
        slotName = slotName + name.charAt(0).toLocaleUpperCase();
      });

      zone['zone_row'] = [];
      zone['zone_count'] = [];

      for (let i = 1; i <= zone.zone_slots; i++) {
        let counter = "";
        if(i < 10) counter = "00"+i;
        if(i >= 10 && i < 100) counter = "0"+i;
        if(i >= 100) counter = i.toString();

        zone.zone_count.push(slotName+'-'+counter)
      }
      zone.zone_row = this.chunk(zone.zone_count,4)
      delete zone.zone_count;
    }

    console.log(this.zones);

  }


  async ngOnInit() {
    // this.zones = await this.getAllZoneSlots(this.account.account_key);
    // console.log("this.zones", zoneSlotUpdates);
  }

  toggleTabs($tabNumber: string){
    this.openTab = $tabNumber;
  }

  addLevel(){
    let newLevel = this.parkingLevels[this.parkingLevels.length -1].level + 1;
    this.parkingLevels.push({level: newLevel});
  }

  removeLevel(){
    this.parkingLevels.pop();
  }

  chunk = (arr, size) =>
  Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size)
  );

  //TODO: implement dynamic zone slots into view
  async getAllZoneSlots(accountKey: any) {
    return new Promise((resolve, reject) => {
      const zoneSlotsRef = firebase.firestore().collection('zones_slot');

      let query = zoneSlotsRef
        .where('account_key', '==', accountKey)

      query.onSnapshot((snapshots) =>{

        let dataArray = [];

        snapshots.forEach((results) => {
          dataArray.push(results.data());
        });
        console.log("zoneSlotUpdates", dataArray);
        resolve(dataArray);

      });

    })
  }
}
