import {Component, Input, OnInit} from "@angular/core";
import firebase from "firebase";
import {Account} from "../../../../models/account.model";

@Component({
  selector: "app-card-entrance",
  templateUrl: "./card-entrance.component.html",
})
export class CardEntranceComponent implements OnInit {

  activeEntrySession: number = 0;
  account: Account;

  constructor() {
    this.account = JSON.parse(localStorage.getItem('account'));
    this.getCountLogsEntry().then();
  }

  async ngOnInit() {
    await this.getCountLogsEntry();
  }

  getStartOfToday() {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    return firebase.firestore.Timestamp.fromDate(now);
  }

  async getCountLogsEntry(){

    const query = firebase.firestore().collection('logs');
    const observer = query
      .where('account_key', '==', this.account.account_key)
      .where('timestamp', '>=', this.getStartOfToday())
      .onSnapshot(querySnapshot => {
      console.log(`Received query snapshot of size of entry getStartOfToday => ${querySnapshot.size}`);
      this.activeEntrySession = querySnapshot.size;
    }, err => {
      console.log(`Encountered error: ${err}`);
    });

  }
}
