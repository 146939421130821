import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from 'src/services/auth.service';
import { tap, map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SuperAdminGuard implements CanActivate {
  account: any = {};
  constructor(
    private auth: AuthService,
    private router: Router
  ) {
    this.account = JSON.parse(localStorage.getItem('account'))
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.auth.user$.pipe(
      take(1),
      map(user => !!(user && user.roles.superAdmin)),
      tap(isSuperAdmin => {
        if (!isSuperAdmin) {
          alert('Access denied. Must have permission to view content');
          //this.router.navigateByUrl(this.account.account_key + '/user/dashboard', {replaceUrl: true});
        }
      })
    );

  }

}
