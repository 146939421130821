import {Component, ElementRef, Input, OnInit, ViewChild} from "@angular/core";
import {UserService} from "src/services/user.service";
import {User, UserLogs} from "src/models/user.model";
import {Router} from "@angular/router";
import Swal from "sweetalert2";
import {AuthService} from "src/services/auth.service";
import {AngularFirestore} from "@angular/fire/firestore";
import {BehaviorSubject, Observable} from "rxjs";
import {FormControl} from "@angular/forms";
import firebase from "firebase";
import {Account} from "src/models/account.model";

@Component({
  selector: 'app-users-logs',
  templateUrl: './users-logs.component.html'
})
export class UsersLogsComponent implements OnInit {

  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }
  private _color = "light";

  @ViewChild("btnDropdownRef", { static: false }) btnDropdownRef: ElementRef;
  @ViewChild("popoverDropdownRef", { static: false })
  popoverDropdownRef: ElementRef;

  @Input() filterUserCode: string;

  userLogs: UserLogs[] = [];
  logs: UserLogs[] = [];
  userAuth: User;

  account: Account;

  //Models for Input fields
  nameValue: string;
  placeValue: string;

  //Data object for listing items
  tableData: any[] = [];

  //Save first document in snapshot of items received
  firstInResponse: any = [];

  //Save last document in snapshot of items received
  lastInResponse: any = [];

  //Keep the array of first document of previous pages
  prev_start_at: any = [];

  //Maintain the count of clicks on Next Prev button
  pagination_clicked_count = 0;

  //Disable next and prev buttons
  disable_next: boolean = false;
  disable_prev: boolean = false;

  filteredOptions: Observable<UserLogs[]>;

  searchControl = new FormControl();

  startAt: BehaviorSubject<string | null> = new BehaviorSubject('');

  constructor(
    private userService: UserService,
    protected router: Router,
    public auth: AuthService,
    private firestore: AngularFirestore) {

    this.loadItems();
    this.auth.user$.subscribe(user => this.userAuth = user);

  }

  search(searchText) {
    this.startAt.next(searchText);
  }

  ngOnInit(): void {

  }

  private _filter(value: string): UserLogs[] {
    const filterValue = value.toLowerCase();
    return this.tableData.filter(option => option.code.toLowerCase().includes(filterValue));
  }

  async edit(user: UserLogs){
    if(this.auth.canEdit(this.userAuth)){
      await this.router.navigateByUrl('/admin/user-logs/edit/' + user.code);
    }else{
      await Swal.fire(
        'Warning',
        "Sorry, access denied. Please contact admin.",
        'warning'
      )
    }
  }

  async add(){
    if(this.auth.canEdit(this.userAuth)){
      await this.router.navigateByUrl('/admin/user-log/add');
    }else{
      await Swal.fire(
        'Warning',
        "Sorry, access denied. Please contact admin.",
        'warning'
      )
    }
  }

  async delete(user: UserLogs){
    if(this.auth.canDelete(this.userAuth)){
      if(user.uid){
        await Swal.fire({
          title: 'Are you sure?',
          text: 'You will not be able to recover this user!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, keep it'
        }).then((result) => {
          if (result.isConfirmed) {
            this.userService.deleteUserLogs(user).then(() =>{
              Swal.fire(
                'Success',
                'User deleted!',
                'success'
              )
            });

          } else if (result.dismiss === Swal.DismissReason.cancel) {

          }

        })

      }
    }else{
      await Swal.fire(
        'Warning',
        "Sorry, access denied. Please contact admin.",
        'warning'
      )
    }

  }

  loadAllUserLogs(){
    this.firestore.collection('users_logs', ref => ref
      .orderBy('date_registered', 'desc')
    ).snapshotChanges().subscribe(response => {

      for (let item of response) {
        this.logs.push(item.payload.doc.data());
      }

    });
  }

  async checkUserCurrentStatus(code):Promise<any>{
    return new Promise(async (resolve) =>{
      const logRef = firebase.firestore().collection('logs');
      await logRef.where('code', '==', code)
        .orderBy('timestamp', 'desc')
        .limit(1)
        .get()
        .then(querySnapshot => {
          if (!querySnapshot.empty) {
            const queryDocumentSnapshot = querySnapshot.docs[0].data();
            resolve(queryDocumentSnapshot);
          }
        });

      // logRef.onSnapshot((snapshot) =>{
      //
      //   if(snapshot.empty){
      //     return
      //   }
      //
      //   let data;
      //   snapshot.forEach((doc) =>{
      //     data = doc.data();
      //   });
      //
      //   resolve(data);
      //
      // })

    });

  }

  loadItems() {
    this.firestore.collection('users_logs', ref => ref
      .limit(20)
      .orderBy('date_registered', 'desc')
    ).snapshotChanges()
      .subscribe((response: any) => {
        if (!response.length) {
          console.log("No Data Available");
          return false;
        }
        this.firstInResponse = response[0].payload.doc;
        this.lastInResponse = response[response.length - 1].payload.doc;

        this.tableData = [];
        for (let item of response) {

          let userLog = item.payload.doc.data();
          this.checkUserCurrentStatus(userLog.code).then((res) =>{
            userLog.status = res.status;
          });

          this.tableData.push(userLog);

        }

        //Initialize values
        this.userLogs = this.tableData;
        console.log(this.userLogs);

        this.prev_start_at = [];
        this.pagination_clicked_count = 0;
        this.disable_next = false;
        this.disable_prev = false;

        //Push first item to use for Previous action
        this.push_prev_startAt(this.firstInResponse);
      }, error => {
      });
  }

  //Show previous set
  prevPage() {
    this.disable_prev = true;
    this.firestore.collection('users_logs', ref => ref
      .orderBy('date_registered', 'desc')
      .startAt(this.get_prev_startAt())
      .endBefore(this.firstInResponse)
      .limit(20)
    ).get()
      .subscribe((response: any) => {
        this.firstInResponse = response.docs[0];
        this.lastInResponse = response.docs[response.docs.length - 1];

        this.tableData = [];
        for (let item of response.docs) {
          let userLog = item.data();
          this.checkUserCurrentStatus(userLog.code).then((res) =>{
            userLog.status = res.status;
          });

          this.tableData.push(userLog);
        }

        this.userLogs = this.tableData;

        //Maintaining page no.
        this.pagination_clicked_count--;

        //Pop not required value in array
        this.pop_prev_startAt(this.firstInResponse);

        //Enable buttons again
        this.disable_prev = false;
        this.disable_next = false;
      }, error => {
        this.disable_prev = false;
      });
  }

  nextPage() {
    this.disable_next = true;
    this.firestore.collection('users_logs', ref => ref
      .limit(20)
      .orderBy('date_registered', 'desc')
      .startAfter(this.lastInResponse)
    ).get()
      .subscribe((response: any) => {

        if (!response.docs.length) {
          this.disable_next = true;
          return;
        }

        this.firstInResponse = response.docs[0];

        this.lastInResponse = response.docs[response.docs.length - 1];
        this.tableData = [];
        for (let item of response.docs) {
          let userLog = item.data();
          this.checkUserCurrentStatus(userLog.code).then((res) =>{
            userLog.status = res.status;
          });

          this.tableData.push(userLog);
        }

        this.userLogs = this.tableData;

        this.pagination_clicked_count++;

        this.push_prev_startAt(this.firstInResponse);

        this.disable_next = false;

      }, error => {
        this.disable_next = false;
      });
  }

  //Add document
  push_prev_startAt(prev_first_doc) {
    this.prev_start_at.push(prev_first_doc);
  }

  //Remove not required document
  pop_prev_startAt(prev_first_doc) {
    this.prev_start_at.forEach(element => {
      if (prev_first_doc.data().id == element.data().id) {
        element = null;
      }
    });
  }

  //Return the Doc rem where previous page will startAt
  get_prev_startAt() {
    if (this.prev_start_at.length > (this.pagination_clicked_count + 1))
      this.prev_start_at.splice(this.prev_start_at.length - 2, this.prev_start_at.length - 1);
    return this.prev_start_at[this.pagination_clicked_count - 1];
  }

  async accountUser(user){

    const accountRef = firebase.firestore().collection('accounts');
    const query = await accountRef
      .where('account_key', '==', user.account_key)
      .limit(1)
      .get();

    if(query.empty){
      console.log("No Data Available");
      return false;
    }

    query.forEach((item) =>{

      let account = item.data()
      console.log("item.data() ==>", account);
      localStorage.setItem('account', JSON.stringify(account));
      this.router.navigate([user.account_key + '/user/logs/edit/' + user.code]);

    });

  }

}
