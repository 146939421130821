import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection, DocumentReference } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from "firebase/app";

import { Router } from '@angular/router';

import { Logs } from 'src/models/logs';
import { Observable } from 'rxjs';
import {first, map, take} from 'rxjs/operators';
import {Vehicles} from "../models/vehicle.model";
import { LogsMonitoring } from 'src/models/logs-monitoring.model';
import { log } from 'console';

@Injectable({
  providedIn: 'root'
})
export class LogsService {

  private logs: Observable<Logs[]>;
  private logsCollection: AngularFirestoreCollection<Logs>;

  private logsMonitoring : Observable<LogsMonitoring[]>;
  private logsMonitoringCollection: AngularFirestoreCollection<LogsMonitoring>;


  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router
  ) {

  }

  async ngOnInit(){
    await this.initializeLogs();
  }

  // =================== LOGS MONITORING =================== //
  getLogsMonitoring(): Observable<LogsMonitoring[]>{
    return this.logsMonitoring;
  }
  addLogsMonitoring(logsMonitoring: LogsMonitoring): Promise<void>{

    logsMonitoring.uid = this.afs.createId();

    const refLogsMonitoring: AngularFirestoreDocument<LogsMonitoring> = this.afs.doc(`logs_monitoring/${logsMonitoring.uid}`);
    return refLogsMonitoring.set(logsMonitoring);
  }

  // ================= END LOGS MONITORING ============== //


  getLogs():Observable<Logs[]> {
    return this.logs;
  }

  getLogsByPlateNumber(plate_number:string) :  Observable<Logs> {
    return this.logsCollection.doc<Logs>(plate_number).valueChanges().pipe(
      take(1),
      map(log =>{
        return log
      })
    );
  }

  getLogsById(id: string): Observable<Logs>{
    return this.logsCollection.doc<Logs>(id).valueChanges().pipe(
      take(1),
      map(log =>{
        return log
      })
    )
  }

  async getLogsByCode(code: string): Promise<Observable<Logs>>{
    return this.logsCollection.doc<Logs>(code).valueChanges().pipe(
      take(1),
      map(log =>{
        return log
      })
    )
  }

  // async getLogsByCode(code: string): Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>>{
  //
  //   const logRef = firebase.firestore().collection('logs');
  //   return await logRef.where('code', '==', code).get();
  //
  //   // let data = {};
  //   //
  //   // snapshot.forEach(doc => {
  //   //   console.log(doc.data());
  //   //   data = doc.data();
  //   // })
  //   //
  //   // return data;
  //
  //   // if (snapshot.empty){
  //   //   console.log('Vehicle already time-out.');
  //   //   return;
  //   // }
  //
  //   // return this.logsCollection.doc<Logs>(code).valueChanges().pipe(
  //   //   take(1),
  //   //   map(log =>{
  //   //     return log
  //   //   })
  //   // )
  // }

  async initializeLogs(){
    this.logsCollection = this.afs.collection<Logs>('logs');
    this.logs = await this.logsCollection.snapshotChanges().pipe(
      first(),
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return {id, ...data};
        })
      }));
  }

  addLogsById(log: Logs): Promise<void> {
    const logRef: AngularFirestoreDocument<Logs> = this.afs.doc(`logs/${log.id}`);
    return logRef.set(log)
  }

  addLogs(log: Logs): Promise<DocumentReference<Logs>> {
    // const logRef: AngularFirestoreDocument<Logs> = this.afs.collection('logs').doc(log.code);
    // return logRef.set(log);
    return this.logsCollection.add(log);
  }

  updateLogs(log: any): Promise<void>{
    return this.logsCollection.doc(log.id).update({
      status: log.status,
      time_in: log.time_in,
      time_out: log.time_out
    });
  }

  enSessionLog(log: any){
    return new Promise((resolve) => {
      const docRef = this.afs.collection<Logs>('logs').doc(log.id);
      return docRef.update(log).then(() => {
        docRef.get().subscribe((doc) => {
          resolve(doc.data());
        });
      });
    });
  }

  updateLog(log: any){
    return new Promise((resolve) => {
      const docRef = this.afs.collection<Logs>('logs').doc(log.id);
      return docRef.update(log).then(() => {
        docRef.get().subscribe((doc) => {
          resolve(doc.data());
        });
      });
    });
  }

  deleteLogs(log: Logs): Promise<void>{
    return this.afs
      .collection("logs")
      .doc(log.id)
      .delete();
  }

  getStartOfToday() {
    const now = new Date();
    now.setHours(0, 0, 0, 0); // +5 hours for Eastern Time
    return firebase.firestore.Timestamp.fromDate(now);
  }

  async createPlateByCode(vehicles: any) {
    const logRef = firebase.firestore().collection('logs');
    const query = await logRef
      .where('account_key', '==', vehicles.account_key)
      .where('code', '==', vehicles.code)
      .where('plate_number', '==', "")
      .limit(1)
      .get();

    if(query.empty){
      console.log('No data found');
      return;
    }

    query.docs.map((item) =>{
      item.ref.update(vehicles).then(() =>{
      });
    });
  }

  async updatePlateByCode(vehicles: any) {
    const logRef = firebase.firestore().collection('logs');
    const query = await logRef
      .where('account_key', '==', vehicles.account_key)
      .where('code', '==', vehicles.code)
      .where('plate_number', '!=', "")
      .limit(1)
      .get();

    if(query.empty){
      console.log('No data found');
      return;
    }

    query.docs.map((item) =>{
      item.ref.update(vehicles).then(() =>{
      });
    });
  }
}
