import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {filter, map, mergeMap} from "rxjs/operators";
import firebase from "firebase/app";

import { UserService  } from "src/services/user.service";
import { VehicleService } from "src/services/vehicle.service";
import { LogsService } from "src/services/log.service";
import { User } from "src/models/user.model";
import {AngularFirestore} from "@angular/fire/firestore";
import Swal from "sweetalert2";

import { AuthService } from "src/services/auth.service";
import { AngularFireAuth } from "@angular/fire/auth";

import { AccountService  } from "src/services/account.service";
import { PlanService  } from "src/services/plan.service";
import {Account, AccountUser, Plans} from "src/models/account.model";
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-accounts-add',
  templateUrl: './accounts-add.component.html'
})
export class AccountsAddComponent implements OnInit {

  public userForm: FormGroup;
  dropdownUserSettings:IDropdownSettings;
  dropdownPlanSettings:IDropdownSettings;

  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }
  private _color = "light";

  user: any = [];
  account: Account = <Account> {};
  users: User[] = [];
  plans: Plans[] = [];
  plan: Plans = <Plans> {};

  selectedUser: any = [];
  selectedPlan: any = {};
  ownerId: string = "";

  constructor(
    private firestore: AngularFirestore,
    protected route : ActivatedRoute,
    private userService: UserService,
    private logService: LogsService,
    private vehicleService: VehicleService,
    public formBuilder: FormBuilder,
    public router: Router,
    public authService: AuthService,
    public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    private planService: PlanService,
    private accountService: AccountService,
    ) {

    this.userForm = this.formBuilder.group({
      accountName: ['', ],
      email: [''],
      address: [''],
      phoneNumber: [''],
      accountUsers: [''],
      plans: [''],
      status: [false]
    });

    this.userService.getUsers().subscribe((res) =>{
      this.users = res;
    });

    this.planService.getPlans().subscribe((res) =>{
      this.plans = res;
      console.log("plans =>", this.plans);
    });
  }

  ngOnInit(): void {

    this.selectedUser = [];

    this.dropdownUserSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'fullName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableCheckAll: false,
      allowSearchFilter: true,
      searchPlaceholderText: "search user",
      noDataAvailablePlaceholderText: "user not found"
    };

    this.dropdownPlanSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name'
    };

  }

  onSelectedPlan(item: any) {
    console.log(item);
    //this.selectedPlan = item;
    let elementPlan = this.plans.map((obj) => {return obj.id; }).indexOf(item.id);
    this.plan = this.plans[elementPlan];
    console.log("elementPlan ==>", this.plan);
  }

  onSelectedUsers(item: any) {
    console.log(item);
    //this.selectedUser = item;
  }

  // removeUser(i){
  //   this.selectedUser.splice(this.selectedUser.length - i -1, 1);
  // }

  setOwnership(id){
    if(id){
      this.ownerId = id;
      this.selectedUser.findIndex((obj: any, i) => {
        this.selectedUser[i].isOwner = obj.id === id;
      });

    }
  }

  get getControl(){
    return this.userForm.controls;
  }

  async save(){

    const sUsers: any = [];

    let account: Account =  {
      account_key: this.generateRandomString(11),
      account_name: this.userForm.get('accountName').value,
      owner_id: this.ownerId,
      email_address: this.userForm.get('email').value,
      phone_number: this.userForm.get('phoneNumber').value,
      address: this.userForm.get('address').value,
      status: this.userForm.get('status').value,
      plans: this.plan,
      accountUsers: sUsers,
      dateCreated: new Date(),
      settings: {zones: []}
    }

    let isExist = this.selectedUser.findIndex((obj) => { return obj.id === account.owner_id; }) != -1;
    if(!isExist){
      console.log("walay ownership");
      await Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please assign ownership.'
      })
      return;
    }

    for ( let i = 0; i < this.selectedUser.length; i++ ){

      if(this.selectedUser.length > 1){
        this.selectedUser[i].isOwner = account.owner_id === this.selectedUser[i].id;
      }else{
        account.owner_id = this.selectedUser[i].id;
        this.selectedUser[i].isOwner = true;
      }

      let accountUser: AccountUser = {
        account_key: account.account_key,
        id: this.selectedUser[i].id,
        fullName: this.selectedUser[i].fullName,
        isOwner: this.selectedUser[i].isOwner
      }

      sUsers.push(accountUser);

      //await this.userService.updateUserAccountKey(account.account_key, accountUser.id);
      await this.accountService.addAccountUser(account.account_key, sUsers);

    }

    await Swal.fire({
      title: 'Creating account... please wait...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: ()=>{

        Swal.showLoading()

        this.accountService.addAccount(account).then((res) =>{

          console.log("success account saved ==>", res.id);

          Swal.fire({
            title: 'Account successfully created.',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: false,
            timer: 3000,
            icon: 'success',
            didClose: ()=>{
              this.selectedUser = [];
              this.plans = [];
              this.userForm.reset();
              Swal.hideLoading();
            }
          });

        });

      }
    });

  }

  generateRandomString(length: number) {
    let result = '';
    let characters = '0123456789';
    let charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

}
