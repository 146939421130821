import { Component, OnInit } from "@angular/core";
import {AuthService} from "src/services/auth.service";
import {User} from "src/models/user.model";
import {Account} from "src/models/account.model";
import {Router} from "@angular/router";
import {RefresherService} from "src/services/refresher.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
})
export class SidebarComponent implements OnInit {

  collapseShow = "hidden";
  userAuth: User;

  public account: Account;

  constructor(
    public auth: AuthService,
    public router: Router,
    private refresher: RefresherService
    ) {

    this.auth.user$.subscribe(user => this.userAuth = user);

  }

  ngOnInit() {
    this.account = JSON.parse(localStorage.getItem('account'));
  }

  toggleCollapseShow(classes) {
    this.collapseShow = classes;
  }

  routerLinks(router: any){
   this.router.navigateByUrl(router, {replaceUrl: true});
   this.refresher.notifyOther({loadDashboard: true});
  }

}
