import { Component, OnInit } from "@angular/core";
import { UserService } from "src/services/user.service";
import {FormBuilder, FormGroup} from "@angular/forms";
import {UserLeads} from "../../../../models/user.model";
import {AngularFirestore} from "@angular/fire/firestore";
import { Loader } from "@googlemaps/js-api-loader";
import firebase from "firebase";

@Component({
  selector: "app-onboarding",
  templateUrl: "./onboarding.component.html",
})
export class OnboardingComponent implements OnInit {

  public userForm: FormGroup;
  step1: boolean = true;
  step2: boolean = false;
  final: boolean = false;
  geocoder: any;
  coordinates: any;
  locationsAddress: string;
  map: any;
  submitting: boolean;

  constructor(
    private userService: UserService,
    public formBuilder: FormBuilder,
    private firestore: AngularFirestore,
  ) {

    this.userForm = this.formBuilder.group({
      companyName: [''],
      contactPerson: [''],
      contactNumber: [''],
      emailAddress: [''],
      companyAddress: [''],
      parkingName: [''],
      locationCoordinates: [''],
      openHours: [''],
      closeHours: [''],
      numberOfSpaces: [''],
      regularRate: [''],
      unit: [''],
      overnightRate: [''],
    });

  }

  ngOnInit(): void {
    this.loadUserData();
  }

  loadUserData(){
    let registrationForm = JSON.parse(localStorage.getItem('registration'));
    console.log("registrationForm =>", registrationForm);
    if(registrationForm){
      this.userForm = this.formBuilder.group({
        companyName: registrationForm.company_name,
        contactPerson: registrationForm.contact_person,
        contactNumber: registrationForm.contact_number,
        emailAddress: registrationForm.email_address,
        companyAddress: registrationForm.company_address,
        parkingName: registrationForm.parking_details.parking_name,
        locationCoordinates: registrationForm.parking_details.location_coordinates,
        openHours: registrationForm.parking_details.open_hours,
        closeHours: registrationForm.parking_details.close_hours,
        numberOfSpaces: registrationForm.parking_details.number_of_spaces,
        regularRate: registrationForm.parking_details.regular_rate,
        unit: [''],
        overnightRate: registrationForm.parking_details.overnight_rate,
      });
    }
  }

  loadMap(){
    const loader = new Loader({
      apiKey: "AIzaSyBhnSYpipnXOLfQp02AE1dzEtby8NKivQM",
      version: "weekly",
      libraries: ['places']
    });

    loader.load().then(() => {

      this.geocoder = new google.maps.Geocoder();

      this.map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
        center: { lat: 7.133003, lng: 125.4623052 },
        zoom: 10,
        mapId: 'd9c5527ef398ef24',
        fullscreenControl: false,
        mapTypeControl: false,
        rotateControl: false,
        streetViewControl: false,
        zoomControl: true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.LEFT_BOTTOM,
        },
      });

      // Create the search box and link it to the UI element.
      let createElementInputDiv = document.createElement('div') as HTMLElement;
      createElementInputDiv.setAttribute("id", "search-container");
      createElementInputDiv.style.width = "100%";
      createElementInputDiv.style.padding = "10px 15px";
      let input = document.getElementById("pac-input")  as HTMLInputElement;
      createElementInputDiv.appendChild(input);
      const options = {
        componentRestrictions: { country: 'ph' }
      };

      const autocomplete = new google.maps.places.Autocomplete(input, options);

      autocomplete.bindTo("bounds", this.map);
      // Specify just the place data fields that you need.
      autocomplete.setFields(["place_id", "geometry", "formatted_address", "name"]);

      this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(createElementInputDiv);

      const infowindow = new google.maps.InfoWindow();
      const infowindowContent = document.getElementById("infowindow-content") as HTMLElement;

      infowindow.setContent(infowindowContent);

      let marker = new google.maps.Marker({
        map: this.map,
        anchorPoint: new google.maps.Point(0, -29),
        draggable: true
      });

      google.maps.event.addListener(this.map, 'click', function() {
        infowindow.close();
      });

      google.maps.event.addListener(marker, 'dragend', () =>{
        this.geocodePosition(marker.getPosition(), marker, infowindow, this.map).then(() =>{
          input.value = "";
        });
      });

      autocomplete.addListener("place_changed", () => {
        infowindow.close();
        marker.setVisible(false);

        const place = autocomplete.getPlace();

        if (!place.geometry || !place.geometry.location) {
          // User entered the name of a Place that was not suggested and
          // pressed the Enter key, or the Place Details request failed.
          window.alert("No details available for input: '" + place.name + "'");
          return;
        }

        // If the place has a geometry, then present it on a map.
        if (place.geometry.viewport) {
          this.map.fitBounds(place.geometry.viewport);
        } else {
          this.map.setCenter(place.geometry.location);
          this.map.setZoom(17);
        }

        marker.setPosition(place.geometry.location);
        marker.setVisible(true);

        this.coordinates = marker.getPosition().toUrlValue(6);
        this.locationsAddress = place.formatted_address;

        infowindowContent.children["place-name"].textContent = place.name;
        infowindowContent.children["place-address"].textContent = place.formatted_address;
        infowindow.open(this.map, marker);

      });

    });
  }

  async geocodePosition(position, marker, infoWindow, map){
    await this.geocoder.geocode({
      location: position
    }, (response) => {
      if (response && response.length > 0) {
        marker.formatted_address = response[1].formatted_address;
      } else {
        marker.formatted_address = 'Cannot determine address at this location.';
      }
      infoWindow.setContent(marker.formatted_address + "<br>coordinates: " + marker.getPosition().toUrlValue(6));
      infoWindow.open(map, marker);

      this.coordinates = marker.getPosition().toUrlValue(6);
      this.locationsAddress = marker.formatted_address;

    })
  }

  next(step) {

    let companyDetails: UserLeads = {
      uid: this.firestore.createId(),
      company_name: this.userForm.get('companyName').value,
      contact_person: this.userForm.get('contactPerson').value,
      contact_number: this.userForm.get('contactNumber').value,
      email_address: this.userForm.get('emailAddress').value,
      company_address: this.userForm.get('companyAddress').value,
      date_created: new Date(),
      status: false,
      parking_details: {
        parking_name: this.userForm.get('parkingName').value,
        location_coordinates: this.coordinates,
        location_address: this.locationsAddress,
        open_hours: this.userForm.get('openHours').value,
        close_hours: this.userForm.get('closeHours').value,
        number_of_spaces: this.userForm.get('numberOfSpaces').value,
        regular_rate: this.userForm.get('regularRate').value,
        unit: null,
        overnight_rate: this.userForm.get('overnightRate').value,
      }
    }

    localStorage.setItem("registration", JSON.stringify(companyDetails));

    if(this.userForm.valid){

      if(step === "step1"){
        this.loadMap();
        this.step1 = false;
        this.step2 = true;
      }else{

        this.submitting = true;
        if(companyDetails.parking_details.location_coordinates){
          this.userService.registerUserLeads(companyDetails).then((res) =>{
            console.log("success register", res);

            this.sendGridEmailOnBoardingForAdmin(companyDetails);

            this.submitting = false;
            this.step1 = false;
            this.step2 = false;
            this.final = true;
            localStorage.removeItem("registration");

          });

        }else{
          alert("Please drag the marker in the map to get your parking location.")
        }

      }

    }
    else{
      alert("Please check all the required (*) fields.")
    }

  }

  back() {
    window.location.reload();
  }

  sendGridEmailOnBoardingForAdmin(data){

    let template = {
      to: 'info@parkbot.ph',
      // cc: 'alvingaluno@gmail.com',
      // bcc: 'jaro.navales@gmail.com',
      message: {
        subject: 'New user sign-ups!',
        html:
          '<p>We have new user sign-ups from on boarding form. Please review the details immediately. :)</p>' +
          '<p><strong>Company name</strong>: '+ data.company_name +'</p>'+
          '<p><strong>Contact person</strong>: '+ data.contact_person +'</p>'+
          '<p><strong>Contact number</strong>: '+ data.contact_number +'</p>'+
          '<p><strong>Email address</strong>: '+ data.email_address +'</p>'
      }
    }

    const emailRef = firebase.firestore().collection('emails');
    emailRef.add(template).then(() => {
      console.log('Queued email for delivery!');
      this.sendGridEmailOnBoardingForUser(data.email_address);
    });
  }

  sendGridEmailOnBoardingForUser(email){

    let template = {
      to: email,
      message: {
        subject: 'Thanks for signing up with Parkbot PH',
        html:
          '<p>Your information has been successfully submitted.</p>'+
          '<p>We value your privacy. All information you submitted will remain confidential.</p>'+
          '<p>Your message is being reviewed. We will notify you as soon as your parking space is approved.</p>'+
          '<p>In the meantime, you can check out our <a href="http://www.parkbot.ph" style="text-decoration: underline; color: #f74e64;">Parking Finder</a></p>'
      }
    }

    const emailRef = firebase.firestore().collection('emails');
    emailRef.add(template).then(() => console.log('Queued email for delivery!'));
  }
}
