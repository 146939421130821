import { Component, OnInit, Input } from "@angular/core";
import firebase from "firebase";

@Component({
  selector: "app-card-motorcycle",
  templateUrl: "./card-motorcycle.component.html",
})
export class CardMotorcycleComponent implements OnInit {

  entry: any = 0;
  exits: any = 0;
  active: any = 0;

  @Input()
  get statSubtitle(): string {
    return this._statSubtitle;
  }
  set statSubtitle(statSubtitle: string) {
    this._statSubtitle = statSubtitle === undefined ? "Motorcycle" : statSubtitle;
  }
  private _statSubtitle = "Motorcycle";

  @Input()
  get statTitle(): number {
    return <number>this._statTitle;
  }
  set statTitle(statTitle: number) {
    this._statTitle = statTitle === undefined ? statTitle : statTitle;
  }
  private _statTitle: string | number = this.statTitle;

  // The value must match one of up or down
  @Input()
  get statArrow(): string {
    return this._statArrow;
  }
  set statArrow(statArrow: string) {
    this._statArrow =
      statArrow !== "down" && statArrow !== "up" ? "up" : statArrow;
  }
  private _statArrow = "up";

  @Input()
  get statPercent(): string {
    return this._statPercent;
  }
  set statPercent(statPercent: string) {
    this._statPercent = statPercent === undefined ? "3.48" : statPercent;
  }
  private _statPercent = "3.48";

  // can be any of the text color utilities
  // from tailwindcss
  @Input()
  get statPercentColor(): string {
    return this._statPercentColor;
  }
  set statPercentColor(statPercentColor: string) {
    this._statPercentColor =
      statPercentColor === undefined ? "text-emerald-500" : statPercentColor;
  }
  private _statPercentColor = "text-emerald-500";

  @Input()
  get statDescripiron(): string {
    return this._statDescripiron;
  }
  set statDescripiron(statDescripiron: string) {
    this._statDescripiron =
      statDescripiron === undefined ? "Since last month" : statDescripiron;
  }
  private _statDescripiron = "Since last month";

  @Input()
  get statIconName(): string {
    return this._statIconName;
  }
  set statIconName(statIconName: string) {
    this._statIconName =
      statIconName === undefined ? "fa fa-motorcycle" : statIconName;
  }
  private _statIconName = "fa fa-motorcycle";

  // can be any of the background color utilities
  // from tailwindcss
  @Input()
  get statIconColor(): string {
    return this._statIconColor;
  }
  set statIconColor(statIconColor: string) {
    this._statIconColor =
      statIconColor === undefined ? "bg-blueGray-700" : statIconColor;
  }
  private _statIconColor = "bg-blueGray-700";

  constructor() {}

  async ngOnInit() {
    //await this.getCountLogsEntry();
    this._statTitle = 0;
  }

  async getCountLogsActiveSession(){
    if(this.entry !== null && this.exits !== null) this._statTitle = (this.entry - this.exits);
  }

  async getCountLogsEntry(){

    const query = firebase.firestore().collection('logs');
    query.where('status', '==', 'completed').onSnapshot(querySnapshot => {
      console.log(`Received query snapshot of size ${querySnapshot.size}`);
      this.entry = querySnapshot.size;

      this.getCountLogsExit();
      // ...
    }, err => {
      console.log(`Encountered error: ${err}`);
    });

  }

  async getCountLogsExit(){

    const query = firebase.firestore().collection('logs');
    const observer = query.where('status', '==', 'incomplete').onSnapshot(querySnapshot => {
      console.log(`Received query snapshot of size ${querySnapshot.size}`);
      this.exits = querySnapshot.size;
      this.getCountLogsActiveSession();
      // ...
    }, err => {
      console.log(`Encountered error: ${err}`);
    });

  }

}
