import { Component, OnInit } from '@angular/core';
import {AuthService} from "../../../../services/auth.service";
import {Account, AccountUser} from "../../../../models/account.model";
import {AccountService} from "../../../../services/account.service";
import firebase from "firebase";
import {Router} from "@angular/router";
import {UserService} from "src/services/user.service";

@Component({
  selector: 'app-verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrls: ['./verify-otp.component.css']
})
export class VerifyOtpComponent implements OnInit {

  otpCode: any;
  verify: any;
  phoneNumber: any;
  isLoading: boolean;
  errorMessage: any = "";

  constructor(
    public authService: AuthService,
    private accountService: AccountService,
    public router: Router,
    private userService: UserService,
  ) { }

  ngOnInit(): void {
    this.verify = JSON.parse(localStorage.getItem('verificationId') || '');
    this.phoneNumber = JSON.parse(localStorage.getItem('phoneNumber') || '');
  }

  onOtpChange(otpCode: any){
    this.otpCode = otpCode;
  }

  verifyOtpCode(){

    if(!this.otpCode || this.otpCode.length !== 6) {
      this.errorMessage = "Please enter the 6 digits otp code to continue.";
      return;
    }

    if(this.otpCode && this.phoneNumber && this.verify){

      this.isLoading = true;

      this.authService.phoneAuthVerification(this.verify, this.otpCode, this.phoneNumber).then(async (res) => {

        if(res.code === 'auth/invalid-verification-code'){
          this.isLoading = false;
          this.errorMessage = "Invalid OTP code. Please try again or resend.";
          return false;
        }

        if(res){
          const sUsers: any = [];
          let accountKeyGenerated = this.generateRandomString(11);

          let accountUser: AccountUser = {
            account_key: accountKeyGenerated,
            id: res.uid,
            fullName: null,
            isOwner: true
          }

          sUsers.push(accountUser);

          let account: Account = {
            account_key: accountUser.account_key,
            account_name: accountUser.fullName,
            owner_id: accountUser.id,
            email_address: res.email,
            phone_number: res.phoneNumber,
            phone_number_verified: res.phoneNumberVerified,
            address: null,
            status: false,
            plans: {
              id: '9ELVcFQzChsoctf6nKIN',
              name: 'Free Plan',
              price: 0,
              key: 'PL51866783687',
              description: 'Free Plan'
            },
            accountUsers: sUsers,
            dateCreated: new Date(),
            settings: {
              zones: []
            },
            vehicles: []
          }

          await this.accountService.addAccountUser(account.account_key, sUsers);
          await this.accountService.addAccount(account);
        }

      });
    }
  }

  generateRandomString(length: number) {
    let result = '';
    let characters = '0123456789';
    let charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

}
