import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument, DocumentReference } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ScheduledEmailReport } from 'src/models/email-schedule.model';

@Injectable({
  providedIn: 'root'
})
export class EmailSchedulingService {

  private emailSchedules: Observable<ScheduledEmailReport[]>;
  private emailSchedulesCollection: AngularFirestoreCollection<ScheduledEmailReport>;

  constructor(
    private afs: AngularFirestore,
  ) { 
    this.initializeEmailSchedules();
  }

  initializeEmailSchedules(){
    this.emailSchedulesCollection = this.afs.collection<ScheduledEmailReport>('scheduled_email_reports');
    this.emailSchedules = this.emailSchedulesCollection.snapshotChanges().pipe(
      map(actions =>{
        return actions.map(a =>{
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      }));
  }

  initializeEmailScheduleById(id: string) {
    if (id) {
      this.getEmailScheduleById(id).subscribe(vehicle => {
        return vehicle
      });
    }
  }

  addScheduleEmailReport(scheduledEmailReport: ScheduledEmailReport):  Promise<void>{
    const vehicleTypeRef: AngularFirestoreDocument<any> = this.afs.doc(`scheduled_email_reports/${scheduledEmailReport.id}`);
    return vehicleTypeRef.set(scheduledEmailReport);
  }

  updateScheduleEmailReport(scheduledEmailReport: ScheduledEmailReport, id) {
    return this.afs
      .collection("scheduled_email_reports")
      .doc(id)
      .update(scheduledEmailReport);
  }

  getEmailSchedules():Observable<ScheduledEmailReport[]> {
    return this.emailSchedules;
  }

  getEmailScheduleById(id: string): Observable<ScheduledEmailReport>{
    return this.emailSchedulesCollection.doc<ScheduledEmailReport>(id).valueChanges().pipe(
      take(1),
      map(schedule =>{
        return schedule
      })
    );
  }

  deleteScheduledEmailReport(scheduledEmailReport: ScheduledEmailReport) {
    return this.afs
      .collection("scheduled_email_reports")
      .doc(scheduledEmailReport.id)
      .delete();
  }


}
