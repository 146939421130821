import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-header-default",
  templateUrl: "./header-default.component.html",
})
export class HeaderDefaultComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
