import { BrowserModule } from "@angular/platform-browser";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

// Firebase services + enviorment module
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestoreModule } from '@angular/fire/firestore';
import {environment, firebaseConfig} from '../environments/environment';
import {DatePipe} from "@angular/common";
import { SearchPipe } from 'src/services/search.pipe';
import { SearchFilterPipe } from 'src/services/search-filter.pipe';

// Auth service
import { AuthService } from "src/services/auth.service";
import { QRCodeModule } from 'angularx-qrcode';
import {AngularFireStorageModule} from "@angular/fire/storage";
import {ProgressBarModule} from "angular-progress-bar";
import { NgOtpInputModule } from  'ng-otp-input';

// layouts
import { AdminComponent } from "./layouts/admin/admin.component";
import { AuthComponent } from "./layouts/auth/auth.component";

// admin views
import { MapsComponent } from "./views/admin/maps/maps.component";
import { TablesComponent } from "./views/admin/tables/tables.component";

// auth views
import { LoginComponent } from "./views/auth/login/login.component";
import { RegisterComponent } from "./views/auth/register/register.component";
import { OnboardingComponent } from "./views/auth/onboarding/onboarding.component";
import { ForgotPasswordComponent } from "./views/auth/forgot-password/forgot-password.component";

// no layouts views
import { IndexComponent } from "./views/index/index.component";
import { LandingComponent } from "./views/landing/landing.component";

// components for views and layouts

import { AdminNavbarComponent } from "./components/navbars/admin-navbar/admin-navbar.component";
import { AuthNavbarComponent } from "./components/navbars/auth-navbar/auth-navbar.component";
import { CardBarChartComponent } from "./components/cards/card-bar-chart/card-bar-chart.component";
import { CardLineChartComponent } from "./components/cards/card-line-chart/card-line-chart.component";
import { CardPageVisitsComponent } from "./components/cards/card-page-visits/card-page-visits.component";
import { CardProfileComponent } from "./components/cards/card-profile/card-profile.component";
import { CardSettingsComponent } from "./components/cards/card-settings/card-settings.component";
import { CardSocialTrafficComponent } from "./components/cards/card-social-traffic/card-social-traffic.component";
import { CardStatsComponent } from "./components/cards/card-stats/card-stats.component";
import { CardUserTableComponent } from "./components/cards/card-user-table/card-user-table.component";
import {CardVehicleTableComponent} from "./components/cards/card-vehicle-table/card-vehicle-table.component";
import { FooterAdminComponent } from "./components/footers/footer-admin/footer-admin.component";
import { FooterComponent } from "./components/footers/footer/footer.component";
import { FooterSmallComponent } from "./components/footers/footer-small/footer-small.component";
import { HeaderStatsComponent } from "./components/headers/header-stats/header-stats.component";
import { IndexNavbarComponent } from "./components/navbars/index-navbar/index-navbar.component";
import { MapExampleComponent } from "./components/maps/map-example/map-example.component";
import { IndexDropdownComponent } from "./components/dropdowns/index-dropdown/index-dropdown.component";
import { TableDropdownComponent } from "./components/dropdowns/table-dropdown/table-dropdown.component";
import { PagesDropdownComponent } from "./components/dropdowns/pages-dropdown/pages-dropdown.component";
import { NotificationDropdownComponent } from "./components/dropdowns/notification-dropdown/notification-dropdown.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { UserDropdownComponent } from "./components/dropdowns/user-dropdown/user-dropdown.component";
import {CardActiveSessionComponent} from "./components/cards/card-active-session/card-active-session.component";
import { UsersComponent } from './views/admin/users/users.component';
import { VehiclesComponent } from './views/admin/vehicles/vehicles.component';
import { SpacesComponent } from './views/admin/spaces/spaces.component';
import { ReportsComponent } from './views/admin/reports/reports.component';
//import {LogsComponent} from "./views/admin/logs/logs.component";
import { EditComponent } from './views/admin/users/edit/edit.component';
import {AddComponent} from "./views/admin/users/add/add.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CardEntranceComponent} from "./components/cards/card-entrance/card-entrance.component";
import {CardExitComponent} from "./components/cards/card-exit/card-exit.component";
import {CardLightVehiclesComponent} from "./components/cards/card-light-vehicles/card-light-vehicles.component";
import {CardMotorcycleComponent} from "./components/cards/card-motorcycle/card-motorcycle.component";
import {CardBikesComponent} from "./components/cards/card-bikes/card-bikes.component";
import {CardVehicleOthersComponent} from "./components/cards/card-vehicle-others/card-vehicle-others.component";
import {CardParkingSpacesComponent} from "./components/cards/card-parking-spaces/card-parking-spaces.component";
import {CardReportsComponent} from "./components/cards/card-reports/card-reports.component";
import {CardUserLogsComponent} from "./components/cards/card-user-logs/card-user-logs.component";
import {UsersLogsComponent} from "./views/admin/user-logs/users-logs.component";
import {UserLogsEditComponent} from "./views/admin/user-logs/edit/user-logs-edit.component";
import {UserLogsAddComponent} from "./views/admin/user-logs/add/user-logs-add.component";
import {VehiclesAddComponent} from "./views/admin/vehicles/add/vehicles-add.component";
import {VehiclesEditComponent} from "./views/admin/vehicles/edit/vehicles-edit.component";
import { QrCodeGeneratorComponent } from './views/admin/qr-code-generator/qr-code-generator.component';
import {CardQrCodeGeneratorComponent} from "./components/cards/card-qr-code-generator/card-qr-code-generator.component";
import {CardOccupancyChartComponent} from "./components/cards/card-occupancy-chart/card-occupancy-chart.component";
import { AccountsComponent } from './views/admin/accounts/accounts.component';
import {CardAccountsComponent} from "./components/cards/card-accounts/card-accounts.component";
import {AccountsAddComponent} from "./views/admin/accounts/add/accounts-add.component";
import {AccountsEditComponent} from "./views/admin/accounts/edit/accounts-edit.component";
import {PlansAddComponent} from "./views/admin/plans/add/plans-add.component";
import {PlansEditComponent} from "./views/admin/plans/edit/plans-edit.component";
import {NgToggleModule} from "ng-toggle-button";
import {NgMultiSelectDropDownModule} from "ng-multiselect-dropdown";
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import {VerifyEmailComponent} from "./views/auth/verify-email/verify-email.component";
import { PlansComponent } from './views/admin/plans/plans.component';
import { DashboardComponent } from "./views/admin/dashboard/dashboard.component";
import { PublicSpacesComponent } from './views/public/spaces/spaces.component';

//user component
import { UserDashboardComponent } from "./views/user/dashboard/dashboard.component";
import { UserComponent } from "./layouts/user/user.component";
import { UserVehiclesComponent } from './views/user/vehicles/vehicles.component';
import { UserReportsComponent } from './views/user/reports/reports.component';
import { UserVehiclesAddComponent } from "./views/user/vehicles/add/vehicles-add.component";
import { UserVehiclesEditComponent } from "./views/user/vehicles/edit/vehicles-edit.component";
import { UserLogComponent } from "./views/user/user-logs/users-logs.component";
import { UserLogEditComponent} from "./views/user/user-logs/edit/user-logs-edit.component";
import { UserLogAddComponent } from "./views/user/user-logs/add/user-logs-add.component";
import { SettingsComponent } from "./views/user/settings/settings.component";
import { AccountSettingTableComponent } from './components/cards/card-account-setting-table/card-account-setting-table.component';
import { ProfileComponent } from "./views/user/profile/profile.component";

import {
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
  OwlMomentDateTimeModule,
  OWL_DATE_TIME_FORMATS, DateTimeAdapter, MomentDateTimeAdapter, OWL_DATE_TIME_LOCALE
} from '@danielmoncada/angular-datetime-picker';
import { ExportLegacyDataComponent } from './views/admin/export-legacy-data/export-legacy-data.component';
import { LeadsComponent } from './views/admin/leads/leads.component';
// import { SearchComponent } from './views/admin/search/search.component';
import { NgAisModule } from 'angular-instantsearch';
import { CardSearchComponent } from './components/cards/card-search/card-search.component';
import { HttpClientModule } from '@angular/common/http';
import { HeaderDefaultComponent } from "./components/headers/header-default/header-default.component";
import { CardAvailableComponent } from "./components/cards/card-available/card-available.component";
import { CardRunningTotalComponent } from "./components/cards/card-running-total/card-running-total.component";
import { CardAmountCollectedComponent } from "./components/cards/card-amount-collected/card-amount-collected.component";
import { ZonesComponent } from './views/user/zones/zones.component';
import { ParkingDetailsComponent } from "./components/parking-details/parking-details.component";
import { PublicComponent } from './layouts/public/public.component';
import { PublicSidebarComponent } from './components/public-sidebar/public-sidebar.component';
import { CardOccupancyBarComponent } from './components/cards/card-occupancy-bar/card-occupancy-bar.component';

import firebase from 'firebase';
import { AddZoneComponent } from "./views/user/settings/add/add.component";
import { UpdateZoneComponent } from './views/user/settings/update/update.component';
import { VerifyOtpComponent } from './views/auth/verify-otp/verify-otp.component';
import { VehicleTypesComponent } from './views/admin/vehicle-types/vehicle-types.component';
import { EmployeeListComponent } from './views/user/employee-list/employee-list.component';
import { AddEmployeeComponent } from './views/user/employee-list/add-employee/add-employee.component';
import { UpdateEmployeeComponent } from './views/user/employee-list/update-employee/update-employee.component';
import { DetailEmployeeComponent } from './views/user/employee-list/detail-employee/detail-employee.component';
import { EmailAutomationComponent } from './views/admin/email-automation/email-automation.component';
import { EmailAutomationAddComponent } from './views/admin/email-automation/add/email-automation-add/email-automation-add.component';
import { EmailAutomationEditComponent } from './views/admin/email-automation/edit/email-automation-edit/email-automation-edit.component';

firebase.initializeApp(firebaseConfig);

export const MY_MOMENT_FORMATS = {
  parseInput: 'MM/DD/YYYY, LT',
  fullPickerInput: 'MM/DD/YYYY, LT',
  datePickerInput: 'MM/DD/YYYY',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};

@NgModule({
  declarations: [
    //user component
    UserVehiclesComponent,
    UserReportsComponent,
    UserVehiclesAddComponent,
    UserVehiclesEditComponent,
    UserLogEditComponent,
    UserLogAddComponent,
    UserLogComponent,
    UserDashboardComponent,
    UserComponent,

    AppComponent,
    DashboardComponent,
    CardBarChartComponent,
    CardLineChartComponent,
    IndexDropdownComponent,
    PagesDropdownComponent,
    TableDropdownComponent,
    NotificationDropdownComponent,
    UserDropdownComponent,
    SidebarComponent,
    FooterComponent,
    FooterSmallComponent,
    FooterAdminComponent,
    CardPageVisitsComponent,
    CardProfileComponent,
    CardSettingsComponent,
    CardSocialTrafficComponent,
    CardStatsComponent,
    CardUserTableComponent,
    CardActiveSessionComponent,
    HeaderStatsComponent,
    HeaderDefaultComponent,
    MapExampleComponent,
    AuthNavbarComponent,
    AdminNavbarComponent,
    IndexNavbarComponent,
    AdminComponent,
    AuthComponent,
    MapsComponent,
    SettingsComponent,
    AccountSettingTableComponent,
    TablesComponent,
    LoginComponent,
    ForgotPasswordComponent,
    RegisterComponent,
    IndexComponent,
    LandingComponent,
    ProfileComponent,
    UsersComponent,
    EditComponent,
    AddComponent,
    UsersLogsComponent,
    UserLogsEditComponent,
    UserLogsAddComponent,
    VehiclesComponent,
    VehiclesAddComponent,
    VehiclesEditComponent,
    SpacesComponent,
    ReportsComponent,
    CardVehicleTableComponent,
    CardEntranceComponent,
    CardExitComponent,
    CardLightVehiclesComponent,
    CardMotorcycleComponent,
    CardBikesComponent,
    CardVehicleOthersComponent,
    CardParkingSpacesComponent,
    CardReportsComponent,
    CardUserLogsComponent,
    SearchPipe,
    SearchFilterPipe,
    QrCodeGeneratorComponent,
    CardQrCodeGeneratorComponent,
    CardOccupancyChartComponent,
    CardSearchComponent,
    CardAccountsComponent,
    AccountsComponent,
    AccountsAddComponent,
    AccountsEditComponent,
    VerifyEmailComponent,
    PlansComponent,
    PlansAddComponent,
    PlansEditComponent,
    ExportLegacyDataComponent,
    OnboardingComponent,
    LeadsComponent,
    CardAvailableComponent,
    CardRunningTotalComponent,
    CardAmountCollectedComponent,
    ZonesComponent,
    ParkingDetailsComponent,
    PublicComponent,
    PublicSidebarComponent,
    CardOccupancyBarComponent,
    PublicSpacesComponent,
    AddZoneComponent,
    UpdateZoneComponent,
    VerifyOtpComponent,
    VehicleTypesComponent,
    EmployeeListComponent,
    AddEmployeeComponent,
    UpdateEmployeeComponent,
    DetailEmployeeComponent,
    EmailAutomationComponent,
    EmailAutomationAddComponent,
    EmailAutomationEditComponent



    // SearchComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    ProgressBarModule,
    ReactiveFormsModule,
    FormsModule,
    QRCodeModule,
    NgToggleModule,
    NgMultiSelectDropDownModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    OwlMomentDateTimeModule,
    SweetAlert2Module.forRoot(),
    NgAisModule.forRoot(),
    HttpClientModule,
    NgOtpInputModule,

  ],
  providers: [
    AuthService,
    DatePipe,
    { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
