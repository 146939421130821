import { Injectable } from '@angular/core';
import firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class GeotagService {

  constructor(

  ){

  }

  async getGeotagByLogId(id: string):Promise<any>{
    return new Promise((resolve, reject) => {
      const fileRef = firebase.firestore().collection('geotags');
      const query = fileRef
        .where('id', '==', id)
        .limit(5)
        .get();

      query.then((item) => {

        if(item.empty){
          resolve(null);
          return;
        }

        const array = [];
        for (let doc of item.docs) {
          array.push(doc.data());
        }

        Promise.all(array).then((response) => {
          resolve(response);
        });

      }).catch((error) => {
        console.log("Error getting document:", error);
        reject(error);
      });

    });

  }

}
