export const MonthsOfYear = [
    {id: 0, label:'January', enabled : true},
    {id: 1, label:'February', enabled : true},
    {id: 2, label:'March', enabled : true},
    {id: 3, label:'April', enabled : true},
    {id: 4, label:'May', enabled : true},
    {id: 5, label:'June', enabled : true},
    {id: 6, label:'July', enabled : true},
    {id: 7, label:'August', enabled : true},
    {id: 8, label:'September', enabled : true},
    {id: 9, label:'October', enabled : true},
    {id: 10, label:'November', enabled : true},
    {id: 11, label:'December', enabled : true},
    ];

export const Years = [
    {id: 0, label:'2023', enabled : true},
    {id: 1, label:'2024', enabled : true},
    {id: 2, label:'2025', enabled : true},
    {id: 3, label:'2026', enabled : true},
    {id: 4, label:'2027', enabled : true},
    {id: 5, label:'2028', enabled : true},
    {id: 6, label:'2029', enabled : true},
    {id: 7, label:'2030', enabled : true},
   
]