import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewChildren} from "@angular/core";
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import firebase from "firebase";
import {Account} from "src/models/account.model";
import Swal from "sweetalert2";
import * as moment from "moment";
import {AngularFirestore} from "@angular/fire/firestore";
import { CronJobsService } from 'src/services/cron-jobs.service';
import { LogsService } from 'src/services/log.service';
import {UserLogs} from "../../../models/user.model";
import {RefresherService} from "../../../services/refresher.service";

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']

})
export class UserComponent implements OnInit  {

  @ViewChildren("activeSessionElement") private activeSessionElement: ElementRef;

  date = new Date();
  isCurrentRouteDashboard: boolean;

  user: UserLogs = <UserLogs> {};
  account: Account;
  public activeSession: number = 0;
  public activeEntrySession: number = 0;
  public activeExitSession: number = 0;

  public logs = [];

  //Data object for listing items
  tableData: any[] = [];
  zones:any[]= [];
  selectedZone:any;
  currentZoneId:string;
  newZoneId:string;

  constructor(
    private router: Router,
    private logService: LogsService,
    private cronJobService: CronJobsService,
    private route: ActivatedRoute,
    private refresher: RefresherService
  ) {

    this.account = JSON.parse(localStorage.getItem('account'));
    this.zones = this.account.settings.zones;
    this.user = JSON.parse(localStorage.getItem('user'));
    this.router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd)
    ).subscribe((res) => {
      let currentZoneId = res.url.split('/').slice(-1)[0];
      if(currentZoneId != 'dashboard'){
        this.currentZoneId = currentZoneId;
      }
      this.isCurrentRouteDashboard = res.url.indexOf('dashboard') !== -1;
    });

    this.refresher.notifyObservable$.subscribe(async (res) => {
      if(res.loadDashboard){
        await this.loadData();
      }
    });

  }

  async ngOnInit(){
    await this.loadData();
    await this.getAccountByKey(this.account.account_key);
  }

  async ngAfterViewInit() {

  }

  async loadData(){

    if(this.currentZoneId){
      if(this.currentZoneId === 'reports'){
        this.selectedZone = this.zones[0];
      }else{
        this.selectedZone = this.zones.find(z => z.zone_id == this.currentZoneId);
      }
    }else{
      this.selectedZone = this.zones[0];
    }

    if(this.newZoneId == undefined){
      this.newZoneId =  this.selectedZone?.zone_id
    }

    if( this.newZoneId != this.currentZoneId){
      if(this.newZoneId != undefined){
        this.router.navigateByUrl(this.account.account_key+'/user/dashboard/'+this.newZoneId);
      }

      await this.getCountLogsActiveSessionCounter();
      await this.getCountLogsEntry();
      await this.getCountLogsExit();
    }else{

      if(this.newZoneId){
        this.router.navigateByUrl(this.account.account_key+'/user/dashboard/'+this.newZoneId);
      }

      await this.getCountLogsActiveSessionCounter();
      await this.getCountLogsEntry();
      await this.getCountLogsExit();

    }
  }

  async getAccountByKey(account_key: string) {
    const query = firebase.firestore().collection('accounts');
    query
      .where("account_key", "==", account_key)
      .limit(1).onSnapshot((snapshots) =>{

      if(snapshots.empty) return;

      snapshots.forEach((doc) => {
        let account = doc.data();
        account.account_id = doc.id;
        localStorage.removeItem('account');
        localStorage.setItem('account', JSON.stringify(account));
      });

    });

  }

  async onSelectZone(value:any) {
   this.newZoneId = value;
    this.loadData();
  }

  getStartOfToday() {
    const now = new Date();
    now.setHours(0, 0, 0, 0); // +5 hours for Eastern Time
    return firebase.firestore.Timestamp.fromDate(now);
  }

  async getCountLogsActiveSessionCounter(){

    if(this.selectedZone === undefined) return;

    const query = firebase.firestore().collection('logs');
    const observer = query
      .where('account_key', '==', this.account.account_key)
      .where('zone_id','==', this.selectedZone.zone_id)
      .where('status', '==', 'incomplete')
      .where('time_out', '==', null)
      .where('timestamp', '>=', this.getStartOfToday())
      .orderBy('timestamp', 'asc')
      .onSnapshot(async (documentSnapshots) => {
        this.activeSession = documentSnapshots.size;
      });
  }

  async getCountLogsEntry(){

    if(this.selectedZone === undefined) return;

    const query = firebase.firestore().collection('logs');
    const observer = query
      .where('account_key', '==', this.account.account_key)
      .where('zone_id','==', this.selectedZone.zone_id)
      .where('timestamp', '>=', this.getStartOfToday())
      .onSnapshot(querySnapshot => {
        // console.log(`Received query snapshot of size of entry getStartOfToday => ${querySnapshot.size}`);
        this.activeEntrySession = querySnapshot.size;
      }, err => {
        console.log(`Encountered error: ${err}`);
      });

  }

  async getCountLogsExit(){

    if(this.selectedZone === undefined) return;

    const query = firebase.firestore().collection('logs');
    const observer = query
      .where('account_key', '==', this.account.account_key)
      .where('zone_id','==', this.selectedZone.zone_id)
      .where('status', '==', 'completed')
      .where('timestamp', '>=', this.getStartOfToday())
      .onSnapshot(querySnapshot => {
        // console.log(`Received query snapshot of exit getStartOfToday size ${querySnapshot.size}`);
        this.activeExitSession = querySnapshot.size;
      }, err => {
        console.log(`Encountered error: ${err}`);
      });

  }

  async viewActiveSessionUsers() {
    await Swal.fire({
      title: '<strong>Active session</strong>',
      html: '<div class="swal-container">' +
        '<div class="block w-full overflow-x-auto">' +
        '<table class="items-center w-full bg-transparent border-collapse" style="background: #eeeeee;border-radius: 6px;color: #000000;">' +
        '<thead>' +
        '<th class="px-6 align-middle border border-solid py-3 text-sm  border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">Plate Number</th>' +
        '<th class="px-6 align-middle border border-solid py-3 text-sm  border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">Entry Time</th>' +
        '</thead>' +
        '<tbody style="background: #ffffff;" class="swal-inner-container">' +
        '</tbody>' +
        '</table>' +
        '</div>' +
        '</div>' +
        '<div class="relative flex flex-col min-w-0 break-words xl:w-12/12 mb-6 mt-10">'+
        '<div class="text-center action-btn">'+
        '<button class="bg-red-400 text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded\n' +
        '              shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150' +
        '              text-sm loadMore">Load more</button>'+
        '</div>' +
        '</div>',
      showCloseButton: true,
      showConfirmButton: false,
      didOpen(popup: HTMLElement) {

        const container = document.querySelector('.swal-inner-container');

        const getStartOfToday = () => {
          const now = new Date();
          now.setHours(0, 0, 0, 0); // +5 hours for Eastern Time
          return firebase.firestore.Timestamp.fromDate(now);
        }

        let account = JSON.parse(localStorage.getItem('account'));

        //Store last document
        let latestDoc = null;

        const getCountLogsActiveSession = async () => {

          const query = firebase.firestore().collection('logs');
          const observer = query
            .where('account_key', '==', account.account_key)
            .where('zone_id','==', this.selectedZone.zone_id.zone_id)
            .where('status', '==', 'incomplete')
            .where('time_out', '==', null)
            .where('timestamp', '>=', getStartOfToday())
            .orderBy('timestamp', 'asc')
            .startAfter(latestDoc || 0)
            .limit(10)
            .get()
            .then(async (documentSnapshots) => {

              if (!documentSnapshots.docs.length) {
                console.log("No Data Available");
                return false;
              }

              latestDoc = documentSnapshots.docs[documentSnapshots.docs.length - 1];

              let tableData = [];
              for (let item of documentSnapshots.docs) {
                const data = item.data();
                data.id = item.id;

                if (!data.plate_number) {
                  data.plate_number = data.code;
                }

                data.user_vehicleId = await getUserLogVehicle(data.code);

                tableData.push(data);
              }

              Promise.all(tableData).then((response) => {
                let logs = response.sort((a,b) => a.timestamp > b.timestamp ? 1 : -1);
                let template = '';
                logs.forEach((item) =>{
                  template += '<tr>' +
                  '<td class="border-t-0  align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-2 text-center" >'
                  + '<a href="'+ ['/'+item.account_key+'/user/vehicles/vehicles-edit/'+item.user_vehicleId]+'" class="text-red-500" target="_blank">'
                  + item.plate_number
                  + '</a>' +
                  '</td>'+
                  '<td class="border-t-0  align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-2 text-center">' + moment(item.time_in.seconds * 1000 ).format('hh:mm A') + '</td>'+
                  '</tr>'
                });

                container.innerHTML += template;

              }).catch((e) => {
                //Handle errors
              });

            }, err => {
              console.log(`Encountered error: ${err}`);
            });

        }

        const getUserLogVehicle = async (code) => {
          return new Promise( async (resolve) =>{
            const userRef = firebase.firestore().collection('users_vehicle')
            const query = await userRef
              .where('account_key', '==', account.account_key)
              .where("code", "==", code)
              .limit(1)
              .get()
              .then((documentSnapshots: any) =>{

                if (!documentSnapshots.docs.length) {
                  console.log("No Data Available");
                  return false;
                }

                let vehicle_id;
                for (let item of documentSnapshots.docs) {
                  if(item.data()){
                    vehicle_id = item.data().id;
                  }else{
                    vehicle_id = null;
                  }
                }

                resolve(vehicle_id);

              });

          });
        }

        getCountLogsActiveSession();

        const loadMore = document.querySelector('.action-btn button.loadMore');
        const handleClick = () =>{ getCountLogsActiveSession() }
        loadMore.addEventListener('click', handleClick);
      }
    });

  }

  async runExitMatching() {

    Swal.fire({
      title: 'Are you sure?',
      text: "Run checking for exit. This will take longer.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, proceed!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then(async (result) => {
      if (result.isConfirmed) {

        await Swal.fire({
          title: 'Run checking for exit. Please wait.',
          html: '<div>' +
            '<div class="inner-logs">' +
            '<span></span>/<b></b>' +
            '<p style="color: #13ce66;" class="done"></p>' +
            '</div>' +
            '</div>',
          //timer: 2000,
          timerProgressBar: true,
          allowOutsideClick: false,
          // showCloseButton: true,
          // showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();

            let account = JSON.parse(localStorage.getItem('account'));
            const container = Swal.getHtmlContainer().querySelector('.inner-logs');
            const span = Swal.getHtmlContainer().querySelector('span');
            const b = Swal.getHtmlContainer().querySelector('b');
            const done = Swal.getHtmlContainer().querySelector('.done');

            const subscribeExit = async () => {
              const query = firebase.firestore().collection('exit_logs');
              const observer = await query
                .where('account_key', '==', account.account_key)
                .where('updatedAt', '>=', this.getStartOfToday())
                .where('matched','==', false)
                .orderBy('updatedAt', 'desc')
                .get()
                .then(querySnapshot => {

                  if(querySnapshot.empty){
                    Swal.fire(
                      'Oops! No data found.',
                      'Please come back later.',
                      'error'
                    )
                    return;
                  }

                  let array = [];
                  querySnapshot.docChanges().forEach((change) => {
                    if (change.type === 'added') {
                      array.push(change.doc.data());
                    }
                  });

                  Promise.all(array).then((res) => {
                    span.textContent = '0';
                    b.textContent = array.length.toString();
                    processArray(res).then(() => {
                      done.textContent = 'ALL DONE!';
                      Swal.hideLoading();
                      console.log("all done");
                    });

                  });

                }, err => {
                  console.log(`Encountered error: ${err}`);
                });
            }

            // utility function that returns a promise that resolves after t milliseconds
            const delay = async (t) => {
              return new Promise(resolve => {
                setTimeout(resolve, t);
              });
            }

            const processArray = async (array) => {

              let tempArray = [];
              for (let i = 0; i < array.length; i++) {
                if (i % 500 === 0) {
                  console.log("wait 15 secs");
                  await delay(15 * 1000);
                }
                //console.log(array[i]);
                tempArray.push(array[i])
                span.textContent = tempArray.length.toFixed();
                await updateLogByCode(array[i]);
              }

              tempArray.push(span.textContent);
              container.innerHTML = tempArray.length.toFixed();
            }

            const updateLogByCode = async (log: any) => {
              const logRef = firebase.firestore().collection('logs');
              const query = await logRef
                .where('account_key', '==', log.account_key)
                 .where('zone_id','==', this.selectedZone.zone_id)
                .where('code', '==', log.code)
                .where('status', '==', 'incomplete')
                .where('time_out', '==', null)
                .where('timestamp', '>=', this.getStartOfToday())
                .limit(1)
                .get();

              if (query.empty) {
                console.log("COMPLETED");
                // update exit once matched
                //await updateLogExitByCode(log);
              } else {
                query.docs.map((item) => {
                  console.log("OK");
                  // update logs entry once matched
                  let data = {
                    time_out: log.time_out,
                    status: log.status,
                    updatedAt: log.updatedAt,
                    station_id_exit: log.station_id,
                    station_name_exit: log.station_name,
                    device_model_exit: log.device_model_exit,
                    device_uuid_exit: log.device_uuid_exit,
                  }
                  item.ref.update(data).then(() =>{
                    // update exit once matched
                    updateLogExitByCode(log);
                  })
                });
              }

            }

            const updateLogExitByCode = async (log: any) => {
              const logRef = firebase.firestore().collection('exit_logs');
              const query = await logRef
                .where('account_key', '==', log.account_key)
                .where('code', '==', log.code)
                .where('matched','==', false)
                .where('updatedAt', '>=', this.getStartOfToday())
                .orderBy('updatedAt', 'desc')
                .limit(1)
                .get();

              if (query.empty) {
                console.log("updateLogExitByCode COMPLETED");
              } else {
                query.docs.map((item) => {
                  console.log("updateLogExitByCode OK");
                  item.ref.update({ matched: true });
                });
              }

            }

            subscribeExit();

          }
        });

      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        await Swal.fire(
          'Cancelled',
          'You cancel the checking.',
          'error'
        )
      }
    });
  }
}
