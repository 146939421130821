import {Injectable, NgZone} from '@angular/core';
import { User } from "src/models/user.model";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from "@angular/router";
import { AngularFireAuth } from "@angular/fire/auth";
import {Observable, of} from "rxjs";
import { switchMap } from 'rxjs/operators';
import firebase from "firebase/app";
import { Account } from 'src/models/account.model';
import {AccountService} from "./account.service";
import {UserService} from "./user.service";

enum UserRole {
  SuperAdmin = 'superAdmin',
  Admin = 'admin',
  Editor = 'editor',
  Subscriber = 'subscriber',
  Standard = 'standard'
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  userData: any; // Save logged in user data
  user$: Observable<User>;

  accounts: Account[] = [];

  constructor(
    public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    public router: Router,
    private accountService: AccountService,
    private userService: UserService,
  ) {

    /* Saving user data in localstorage when
    logged in and setting up null when logged out */

    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.userData = user;
        localStorage.setItem('user', JSON.stringify(this.userData));
        JSON.parse(localStorage.getItem('user'));
      } else {
        localStorage.setItem('user', null);
        JSON.parse(localStorage.getItem('user'));
      }
    })

    //// Get auth data, then get firestore user document || null
    this.user$ = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          return this.afs.doc<User>(`users/${user.uid}`).valueChanges()
        } else {
          return of(null)
        }
      })
    )

  }

  // Sign in with email/password
  async signIn(email, password) {
    return this.afAuth.signInWithEmailAndPassword(email, password)
      .then((result) => {

        this.userService.getUserById(result.user.uid).subscribe((u) =>{

          if(u.roles.superAdmin){
            this.router.navigate(['/admin/accounts']);
            let user = { user_id: result.user.uid, timestamp: new Date()}
            this.userService.updateUserWithLogActivity(user);
            localStorage.setItem('user', JSON.stringify(result.user));
          }else{

            this.accountService.checkIfUserHasAccount(result.user.uid).then((account_user) =>{
              this.userService.addUserLogActivity(account_user);
              if(account_user && account_user.account_key && account_user.id){
                this.accountService.getAccountByKey(account_user.account_key).then((account) =>{
                  this.router.navigate([account_user.account_key + '/user/dashboard']);
                  localStorage.setItem('user', JSON.stringify(result.user));
                  localStorage.setItem('account', JSON.stringify(account));
                });
              }else{
                alert("Sorry, user has no assigned account. Please contact your administrator.");
                this.router.navigate(['/auth/login']);
              }

            }, (error) =>{
              console.log(error)
              alert("Sorry, user has no assigned account. Please contact your administrator.");
              this.router.navigate(['/auth/login']);
            });

          }

        });

      }).catch((error) => {
        alert(error.message);
        window.location.reload();
      })
  }

  //signIn with phone
  signInWithPhone(phone: string, recaptchaVerifier: any){
    this.afAuth.signInWithPhoneNumber(phone, recaptchaVerifier).then( (res) => {
      localStorage.setItem('verificationId', JSON.stringify(res.verificationId));
      localStorage.setItem('phoneNumber', JSON.stringify(phone));
      this.router.navigate(['auth/verify-otp']);
    }).catch(error =>{
      alert(error.message);
      setTimeout(() =>{
        window.location.reload();
      }, 3000)
    });
  }

  async phoneAuthVerification(verify, otpCode, phoneNumber): Promise<any>{
    return new Promise((resolve, reject) => {
      let credentials = firebase.auth.PhoneAuthProvider.credential( verify, otpCode)
      firebase.auth().signInWithCredential(credentials).then(async (result: any) => {

        this.userService.checkUserPhoneIfExist(phoneNumber).then(async (res: any) => {
          if (!res) {
            result.user.phoneNumberVerified = true;
            let userData = await this.setUserData("", result.user);
            await this.loginUserByPhone(userData);
            resolve(result.user);
          }else{
            await this.loginUserByPhone(res);
          }

        });
      }).catch((error) => {
        console.log("signInWithCredential error", error)
        resolve(error);
      });
    });
  }

  async loginUserByPhone(user) {
    if (user.roles.admin && user.roles.editor) {
      let userLog = {user_id: user.uid, timestamp: new Date()}
      await this.userService.updateUserWithLogActivity(userLog);
      localStorage.setItem('user', JSON.stringify(user));
      await this.router.navigate(['/admin/accounts']);
    } else {

      this.accountService.checkIfUserHasAccount(user.uid).then((account_user) => {
        this.userService.addUserLogActivity(account_user);
        if (account_user && account_user.account_key && account_user.id) {
          this.accountService.getAccountByKey(account_user.account_key).then((account) => {
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('account', JSON.stringify(account));
            this.router.navigate([account_user.account_key + '/user/profile']);
          });
        } else {
          alert("Sorry, user has no assigned account. Please contact your administrator.");
          this.router.navigate(['/auth/login']);
        }

      });

    }
  }

  // Sign up with email/password
  async signUp(name, email, password): Promise<any> {
    return new Promise((resolve, reject) => {
      this.afAuth.createUserWithEmailAndPassword(email, password)
        .then((result) => {
          /* Call the SendVerificationMail() function when new user sign
          up and returns promise */
          this.sendVerificationMail();
          this.setUserData(name, result.user);
          //this.router.navigate(['/auth/login']);
          resolve(result);
        }).catch((error) => {
        reject(error);
      });
    });
  }

  // Send email verification when new user sign up
  async sendVerificationMail() {
    return firebase.auth().currentUser.sendEmailVerification()
      .then(() => {
        this.router.navigate(['/auth/verify-email']);
      })
  }

  // Reset Forgot password
  async forgotPassword(passwordResetEmail): Promise<any> {
    return new Promise((resolve, reject) => {
      this.afAuth.sendPasswordResetEmail(passwordResetEmail)
        .then((res) => {
          resolve(true);
        }).catch((error) => {
          reject(error);
          //window.alert(error)
        })
    });
  }

  // Returns true when user is logged in and email is verified
  // get isLoggedIn(): boolean {
  //   const user = JSON.parse(localStorage.getItem('user'));
  //   return (user !== null && user.emailVerified !== false);
  // }

  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return user !== null && (user.phoneNumberVerified !== false || user.emailVerified !== false);
  }

  // Sign in with Google
  googleAuth() {
    return this.authLogin(new firebase.auth.GoogleAuthProvider());
  }

  // Auth logic to run auth providers
  async authLogin(provider) {
    return this.afAuth.signInWithPopup(provider)
      .then((result) => {
        this.router.navigate(['/admin/dashboard']);
        this.setUserData(result.user.displayName, result.user);
      }).catch((error) => {
        window.alert(error)
      })
  }

  /* Setting up user data when sign in with username/password,
  sign up with username/password and sign in with social auth
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  async setUserData(name, u) {
    return new Promise((resolve, reject) => {
      const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${u.uid}`);
      const data = {
        uid: u.uid,
        email: u.email,
        fullName: name,
        displayName: u.displayName,
        photoURL: u.photoURL,
        emailVerified: u.emailVerified,
        // phoneNumber: u.phoneNumber,
        // phoneNumberVerified: u.phoneNumberVerified,
        roles: {
          standard: true
        }
      }

      return userRef.set(data, {
        merge: true
      }).then(() =>{
        resolve(data);
      });

    });
  }

  canRead(user: User): boolean {
    const allowedRoles: UserRole[] = [
      UserRole.SuperAdmin,
      UserRole.Admin,
      UserRole.Editor,
      UserRole.Subscriber
    ]
    return AuthService.checkAuthorization(user, allowedRoles)
  }

  canEdit(user: User): boolean {
    const allowedRoles: UserRole[] = [
      UserRole.SuperAdmin,
      UserRole.Admin,
      UserRole.Editor
    ]
    return AuthService.checkAuthorization(user, allowedRoles)
  }

  isAdminCanEdit(user: User): boolean {
    const allowedRoles: UserRole[] = [
      UserRole.SuperAdmin,
      UserRole.Admin
    ]
    return AuthService.checkAuthorization(user, allowedRoles)
  }

  canDelete(user: User): boolean {
    const allowedRoles: UserRole[] = [
      UserRole.SuperAdmin
    ]
    return AuthService.checkAuthorization(user, allowedRoles)
  }

  isEditor(user: User): boolean {
    const allowedRoles: UserRole[] = [
      UserRole.Editor
    ]
    return AuthService.checkAuthorization(user, allowedRoles)
  }

  isSubscriber(user: User): boolean {
    const allowedRoles: UserRole[] = [
      UserRole.Subscriber
    ]
    return AuthService.checkAuthorization(user, allowedRoles)
  }

  isSuperAdmin(user: User): boolean {
    const allowedRoles: UserRole[] = [
      UserRole.SuperAdmin
    ]
    return AuthService.checkAuthorization(user, allowedRoles)
  }

// determines if user has matching role
  private static checkAuthorization(user: User, allowedRoles: string[]): boolean {
    if(!user) return false;
    for(const role of allowedRoles){
      if(user.roles[role]){
        return true;
      }
    }
    return false;
  }

  // Sign out
  async signOut() {
    return this.afAuth.signOut().then(() => {
      localStorage.removeItem('user');
      localStorage.removeItem('account');
      localStorage.removeItem('verificationId');
      localStorage.removeItem('phoneNumber');
      this.router.navigate(['/auth/login']);
    })
  }

}
