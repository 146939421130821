import {Component, OnInit, Input, ViewChild, ElementRef} from "@angular/core";
import {Account, Plans} from "src/models/account.model";
import {createPopper} from "@popperjs/core";
import {Router} from "@angular/router";
import Swal from "sweetalert2";
import {AuthService} from "src/services/auth.service";
import {User} from "src/models/user.model";
import { AccountService  } from "src/services/account.service";
import { PlanService  } from "src/services/plan.service";
import {combineLatest} from "rxjs";

@Component({
  selector: "app-card-accounts",
  templateUrl: "./card-accounts.component.html",
})
export class CardAccountsComponent implements OnInit {

  //@Input()
  // dropdownPopoverShow = false;
  @ViewChild("btnDropdownRef", { static: false }) btnDropdownRef: ElementRef;
  @ViewChild("popoverDropdownRef", { static: false })
  popoverDropdownRef: ElementRef;

  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }
  private _color = "light";

  accounts: Account[] = [];
  plans: Plans[] = [];
  userAuth: User;

  constructor(
    private accountService: AccountService,
    private planService: PlanService,
    protected router: Router,
    public auth: AuthService
  ) {

    this.auth.user$.subscribe(user => this.userAuth = user);

    combineLatest(this.accountService.getAccounts(), this.planService.getPlans())
      .subscribe(([accounts, plans]: any) => {

        plans.forEach((p, i) => {
          accounts.forEach((a, j) =>{
            if(a.plan_id == p.id){
              accounts[j].plan = p.name;
            }
          });
          console.log("accounts =>", this.accounts);
          console.log("plans =>", this.plans);
        });

        //this.plans = plans;
        this.accounts = accounts;

      });
  }

  ngOnInit(): void {}

  async edit(account: Account){
    if(this.auth.canEdit(this.userAuth)){
      await this.router.navigateByUrl('/admin/accounts/accounts-edit/' + account.uid);
    }else{
      await Swal.fire(
        'Warning',
        "Sorry, access denied. Please contact admin.",
        'warning'
      )
    }
  }

  async add(){
    if(this.auth.canEdit(this.userAuth)){
      await this.router.navigateByUrl('/admin/accounts/accounts-add');
    }else{
      await Swal.fire(
        'Warning',
        "Sorry, access denied. Please contact admin.",
        'warning'
      )
    }
  }

  async delete(account: Account){
    if(this.auth.canDelete(this.userAuth)){
      if(account.uid){

        await Swal.fire({
          title: 'Are you sure?',
          text: 'You will not be able to recover this account!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, keep it'
        }).then((result) => {
          if (result.isConfirmed) {

            this.accountService.deleteAccount(account).then(() =>{
              Swal.fire(
                'Success',
                'Account deleted!',
                'success'
              )
            });

          } else if (result.dismiss === Swal.DismissReason.cancel) {

          }

        })

      }
    }else{
      await Swal.fire(
        'Warning',
        "Sorry, access denied. Please contact admin.",
        'warning'
      )
    }
  }
}
