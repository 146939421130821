import { Component, OnInit } from '@angular/core';
import { AuthService } from "src/services/auth.service";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html'
})
export class ForgotPasswordComponent implements OnInit {

  form: FormGroup | any;
  message: string;

  constructor(
    public formBuilder: FormBuilder,
    public authService: AuthService
  ) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      passwordResetEmail: new FormControl('', [Validators.required, Validators.email, Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,63}$',),]),
    });
  }

  resetPassword(){
    if(this.form.valid){
      this.authService.forgotPassword(this.form.get('passwordResetEmail').value).then((res) =>{
        if(res){
          this.message = 'A unique link to reset your Parkbot PH password has been sent to your email.';
        }
      }, (error) =>{
        alert('There was an error sending email. Please try again or contact admin.')
      });
    }else{
      this.validateAllFormFields(this.form);
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

}
