import { Injectable } from '@angular/core';
import { AngularFirestore} from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from "firebase";
import {Account} from "../models/account.model";

@Injectable({
  providedIn: 'root'
})
export class ZonesSlotService {

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore
  ) {

  }

  async updateAccountByZoneSlot(slot_count: any, accountKey, zone_id: any) {

    const accountRef = firebase.firestore().collection('accounts');
    const query = await accountRef
      .where('account_key', '==', accountKey)
      .limit(1)
      .get();

    if(query.empty){
      console.log('No data found');
    }else{
      query.docs.map((item) =>{
        let itemData = item.data();
        let slots = itemData.settings.zones;

        slots.forEach((slot, index) =>{
          if(slot.zone_id === zone_id){
            slots[index].zone_slots = slot_count;
            let data: any = { settings : {zones: slots} };
            item.ref.update(data);
          }
        });

      });
    }

  }

  updateZonesSlot(id: string, data: any): Promise<void> {
    return this.afs
      .collection("zones_slot")
      .doc(id)
      .update(data);
  }

  async getZoneSlotById(log_id: any, slot_id: any, accountKey: any) {
    return new Promise(async (resolve, reject) => {
      const logRef = firebase.firestore().collection('zones_slot');
      const query = await logRef
        .where('account_key', '==', accountKey)
        .where('slot_id', '==', slot_id)
        .where('log_id', '==', log_id)
        .limit(1)
        .get();

      if (query.empty) {
        resolve(false);
      } else {
        resolve(true);
      }
    });
  }

  async updateZonesSlotBySlotNameAndZoneId(zoneId: any, slotName: any, data: any, accountKey: any) {
    return new Promise(async (resolve, reject) => {
      const logRef = firebase.firestore().collection('zones_slot');
      const query = await logRef
        .where('account_key', '==', accountKey)
        .where('zone_id', '==', zoneId)
        .where('slot_name', '==', slotName)
        .limit(1)
        .get();

      if (query.empty) {
        console.log('No data found');
        resolve(false)
      } else {
        query.docs.map((item) => {
          item.ref.update(data).then((updateResult) => {
            resolve(true)
          });
        });
      }

    });

  }

  async deleteSlots(zoneId: any, slotName: any, accountKey: any){
    const logRef = firebase.firestore().collection('zones_slot');
    const query = await logRef
      .where('account_key', '==', accountKey)
      .where('zone_id', '==', zoneId)
      .where('slot_name', '==', slotName)
      .get();

    if(query.empty){
      console.log('No data found');
    }else{
      query.docs.map((item) =>{
        logRef.doc(item.id).delete();
      });
    }
  }

}
