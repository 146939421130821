import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {filter, map, mergeMap} from "rxjs/operators";
import firebase from "firebase/app";

import { UserService  } from "src/services/user.service";
import { VehicleService } from "src/services/vehicle.service";
import { LogsService } from "src/services/log.service";
import { User } from "src/models/user.model";
import {AngularFirestore} from "@angular/fire/firestore";
import Swal from "sweetalert2";

import { AuthService } from "src/services/auth.service";
import { AngularFireAuth } from "@angular/fire/auth";

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html'
})
export class AddComponent implements OnInit {

  public userForm: FormGroup;

  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }
  private _color = "light";

  user: User = <User> {};
  userAuth: User;

  constructor(
    private firestore: AngularFirestore,
    protected route : ActivatedRoute,
    private userService: UserService,
    private logService: LogsService,
    private vehicleService: VehicleService,
    public formBuilder: FormBuilder,
    public router: Router,
    public authService: AuthService,
    public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    ) {

    this.userForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });

    this.authService.user$.subscribe(user => this.userAuth = user);
  }

  ngOnInit(): void {
  }

  get getControl(){
    return this.userForm.controls;
  }

  async save(){

    if(this.authService.canDelete(this.userAuth)){
      this.afAuth.createUserWithEmailAndPassword(this.userForm.value.email, this.userForm.value.password)
        .then((result) => {

          this.authService.setUserData(this.userForm.value.name, result.user);
          Swal.fire(
            'Success',
            'User successfully created!',
            'success'
          )

        }).catch((error) => {
        Swal.fire(
          'Server error',
          error.message,
          'warning'
        )
      });
    }else{
      await Swal.fire(
        'Warning',
        "You don't have the right to create a user. Please contact the admin.",
        'warning'
      )
    }

    // const data = {
    //   name: this.userForm.value.name,
    //   email: this.userForm.value.name,
    //   password: this.userForm.value.password
    // };
    //
    // if(this.userForm.valid){
    //   await this.userService.addUser(data).then(() =>{
    //
    //     Swal.fire(
    //       'Success',
    //       'User saved!',
    //       'success'
    //     )
    //
    //     //this.router.navigateByUrl('/admin/users/edit/' + data.code);
    //
    //     // if(res.vehicle_id !== null){
    //     //
    //     //   data.vehicle_id = res.vehicle_id
    //     //   this.vehicleService.addVehicle(data).then((res) =>{
    //     //     console.log(res);
    //     //   });
    //     //
    //     // }
    //
    //   });
    // }

  }

}
