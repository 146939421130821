import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

// layouts
import { AdminComponent } from "./layouts/admin/admin.component";
import { AuthComponent } from "./layouts/auth/auth.component";

// admin views
import { DashboardComponent } from "./views/admin/dashboard/dashboard.component";
import { MapsComponent } from "./views/admin/maps/maps.component";
import { TablesComponent } from "./views/admin/tables/tables.component";

// auth views
import { LoginComponent } from "./views/auth/login/login.component";
import { RegisterComponent } from "./views/auth/register/register.component";
import { OnboardingComponent } from "./views/auth/onboarding/onboarding.component";

// no layouts views
import { IndexComponent } from "./views/index/index.component";
import { LandingComponent } from "./views/landing/landing.component";

import { AuthGuard } from "src/guard/auth.guard";
import {ForgotPasswordComponent} from "./views/auth/forgot-password/forgot-password.component";
import {VerifyEmailComponent} from "./views/auth/verify-email/verify-email.component";
import {UsersComponent} from "./views/admin/users/users.component";
import {VehiclesComponent} from "./views/admin/vehicles/vehicles.component";
import {SpacesComponent} from "./views/admin/spaces/spaces.component";
import {ReportsComponent} from "./views/admin/reports/reports.component";
import {LogsComponent} from "./views/admin/logs/logs.component";
import {EditComponent} from "./views/admin/users/edit/edit.component";
import {AddComponent} from "./views/admin/users/add/add.component";
import {UsersLogsComponent} from "./views/admin/user-logs/users-logs.component";
import {UserLogsEditComponent} from "./views/admin/user-logs/edit/user-logs-edit.component";
import {UserLogsAddComponent} from "./views/admin/user-logs/add/user-logs-add.component";
import {VehiclesEditComponent} from "./views/admin/vehicles/edit/vehicles-edit.component";
import {VehiclesAddComponent} from "./views/admin/vehicles/add/vehicles-add.component";
import {QrCodeGeneratorComponent} from "./views/admin/qr-code-generator/qr-code-generator.component";
import {AccountsComponent} from "./views/admin/accounts/accounts.component";
import {AccountsEditComponent} from "./views/admin/accounts/edit/accounts-edit.component";
import {AccountsAddComponent} from "./views/admin/accounts/add/accounts-add.component";
import {ExportLegacyDataComponent} from "./views/admin/export-legacy-data/export-legacy-data.component";
import { LeadsComponent } from "./views/admin/leads/leads.component";
import {AdminGuard} from "../guard/admin.guard";
import {CanReadGuard} from "../guard/can-read.guard";
import {PlansComponent} from "./views/admin/plans/plans.component";
import {PlansEditComponent} from "./views/admin/plans/edit/plans-edit.component";
import {PlansAddComponent} from "./views/admin/plans/add/plans-add.component";
import {UserLogEditComponent} from "./views/user/user-logs/edit/user-logs-edit.component";
import {UserLogAddComponent} from "./views/user/user-logs/add/user-logs-add.component";
import {UserVehiclesComponent} from "./views/user/vehicles/vehicles.component";
import {UserVehiclesEditComponent} from "./views/user/vehicles/edit/vehicles-edit.component";
import {UserVehiclesAddComponent} from "./views/user/vehicles/add/vehicles-add.component";
import {UserReportsComponent} from "./views/user/reports/reports.component";
import {UserComponent} from "./layouts/user/user.component";
import {UserLogComponent} from "./views/user/user-logs/users-logs.component";
import { SettingsComponent } from "./views/user/settings/settings.component";
import { ProfileComponent } from "./views/user/profile/profile.component";
import { ZonesComponent } from "./views/user/zones/zones.component";
import {PublicSpacesComponent} from "./views/public/spaces/spaces.component";
import {PublicComponent} from "./views/public/public.component";
import { AddZoneComponent } from "./views/user/settings/add/add.component";
import { UpdateZoneComponent } from "./views/user/settings/update/update.component";
import {VerifyOtpComponent} from "./views/auth/verify-otp/verify-otp.component";
import {VehicleTypesComponent} from "./views/admin/vehicle-types/vehicle-types.component";
import {EmployeeListComponent} from "./views/user/employee-list/employee-list.component";
import {AddEmployeeComponent} from "./views/user/employee-list/add-employee/add-employee.component";
import {UpdateEmployeeComponent} from "./views/user/employee-list/update-employee/update-employee.component";
import {SuperAdminGuard} from "../guard/super-admin.guard";
import {CanEditGuard} from "../guard/can-edit.guard";
import { DetailEmployeeComponent } from "./views/user/employee-list/detail-employee/detail-employee.component";
import { EmailAutomationComponent } from "./views/admin/email-automation/email-automation.component";
import { EmailAutomationAddComponent } from "./views/admin/email-automation/add/email-automation-add/email-automation-add.component";
import { EmailAutomationEditComponent } from "./views/admin/email-automation/edit/email-automation-edit/email-automation-edit.component";
// import {SearchComponent} from "./views/admin/search/search.component";

const routes: Routes = [

  //public views
  {
    path: "public",
    component: PublicComponent,
    children: [
      { path: "maps", component: MapsComponent},
      { path: "", redirectTo: "maps", pathMatch: "full" },

    ]},
  // admin views
  {
    path: "admin",
    component: AdminComponent,
    children: [
      // users
      { path: "dashboard", component: DashboardComponent, canActivate: [SuperAdminGuard]  },
      { path: "user-logs", component: UsersLogsComponent, canActivate: [SuperAdminGuard] },
      { path: "user-logs/edit/:id", component: UserLogsEditComponent, canActivate: [SuperAdminGuard] },
      { path: "reports", component: ReportsComponent, canActivate: [SuperAdminGuard] },
      //{ path: "vehicles", component: VehiclesComponent, canActivate: [SuperAdminGuard] },
      { path: "vehicles/vehicles-edit/:id", component: VehiclesEditComponent, canActivate: [SuperAdminGuard] },

      // admin
      { path: "users", component: UsersComponent, canActivate: [SuperAdminGuard] },
      { path: "user/activities/:id", component: DetailEmployeeComponent, canActivate: [AdminGuard, SuperAdminGuard] },
      { path: "accounts", component: AccountsComponent, canActivate: [SuperAdminGuard] },
      { path: "accounts/accounts-edit/:id", component: AccountsEditComponent, canActivate: [SuperAdminGuard] },
      { path: "accounts/accounts-add", component: AccountsAddComponent, canActivate: [SuperAdminGuard] },
      { path: "plans", component: PlansComponent, canActivate: [SuperAdminGuard] },
      { path: "plans/plans-edit/:id", component: PlansEditComponent, canActivate: [SuperAdminGuard] },
      { path: "plans/plans-add", component: PlansAddComponent, canActivate: [SuperAdminGuard] },
      { path: "users/edit/:id", component: EditComponent, canActivate: [SuperAdminGuard] },
      { path: "users/add", component: AddComponent, canActivate: [SuperAdminGuard] },
      { path: "qr-code-generator", component: QrCodeGeneratorComponent, canActivate: [SuperAdminGuard] },
      { path: "user-logs/add", component: UserLogsAddComponent, canActivate: [SuperAdminGuard] },
      { path: "vehicles/vehicles-add", component: VehiclesAddComponent, canActivate: [SuperAdminGuard] },
      { path: "leads", component: LeadsComponent, canActivate: [SuperAdminGuard] },
      { path: "vehicle-types", component: VehicleTypesComponent, canActivate: [SuperAdminGuard] },
      { path: "spaces", component: SpacesComponent, canActivate: [SuperAdminGuard] },
      { path: "tables", component: TablesComponent, canActivate: [SuperAdminGuard] },

      { path: "email-automation", component: EmailAutomationComponent, canActivate: [SuperAdminGuard] },
      { path: "email-automation/add", component: EmailAutomationAddComponent, canActivate: [SuperAdminGuard] },
      { path: "email-automation/edit/:id", component: EmailAutomationEditComponent, canActivate: [SuperAdminGuard] },


      { path: "", redirectTo: "dashboard", pathMatch: "full" },
    ],
    canActivate: [AuthGuard, SuperAdminGuard]
  },

  //user views
  {
    path: ":account_key/user",
    component: UserComponent,
    children: [
      { path: "dashboard/:zone_id", component: DashboardComponent, canActivate: [CanReadGuard]  },
      { path: "dashboard", component: DashboardComponent, canActivate: [CanReadGuard]  },
      { path: "profile", component: ProfileComponent, canActivate: [CanEditGuard]  },
      { path: "logs", component: UserLogComponent, canActivate: [CanReadGuard] },
      { path: "logs/edit/:id", component: UserLogEditComponent, canActivate: [CanEditGuard] },
      { path: "logs/add", component: UserLogAddComponent, canActivate: [SuperAdminGuard] },
      //{ path: "vehicles", component: UserVehiclesComponent, canActivate: [CanReadGuard] },
      { path: "vehicles/vehicles-edit/:id", component: UserVehiclesEditComponent, canActivate: [CanReadGuard] },
      { path: "vehicles/vehicles-add", component: UserVehiclesAddComponent, canActivate: [SuperAdminGuard] },
      //{ path: "search", component: SearchComponent, canActivate: [CanEditGuard] },
      { path: "reports", component: UserReportsComponent, canActivate: [CanEditGuard] },
      { path: "settings", component: SettingsComponent, canActivate: [SuperAdminGuard] },
      { path: "settings/zone-add", component: AddZoneComponent, canActivate: [SuperAdminGuard] },
      { path: "settings/zone-update/:zone_id", component: UpdateZoneComponent, canActivate: [SuperAdminGuard] },
      { path: "employees", component: EmployeeListComponent, canActivate: [CanEditGuard] },
      { path: "employees/add", component: AddEmployeeComponent, canActivate: [SuperAdminGuard] },
      { path: "employees/edit/:id", component: UpdateEmployeeComponent, canActivate: [AdminGuard] },
      { path: "zones", component: ZonesComponent, canActivate: [AdminGuard] },
      { path: "", redirectTo: "dashboard", pathMatch: "full" },
    ],
    canActivate: [AuthGuard, CanReadGuard]
  },

  // auth views
  {
    path: "auth",
    component: AuthComponent,
    children: [
      { path: "login", component: LoginComponent },
      { path: "register", component: RegisterComponent },
      { path: "forgot-password", component: ForgotPasswordComponent },
      { path: "verify-email", component: VerifyEmailComponent },
      { path: "verify-otp", component: VerifyOtpComponent },
      { path: "onboarding", component: OnboardingComponent },
      { path: "", redirectTo: "login", pathMatch: "full" },
    ],
  },

  //no layout views
  {
    path: "spaces/:account_key",
    component: PublicSpacesComponent
  },
  // { path: "profile", component: ProfileComponent },
  // { path: "landing", component: LandingComponent },

  { path: "", component: AuthComponent,
    children: [
      { path: "login", component: LoginComponent },
      { path: "**", redirectTo: "login", pathMatch: "full" },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
