import { Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { Account, Settings, ParkingFee } from 'src/models/account.model';

import firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection, DocumentReference } from '@angular/fire/firestore';

import { AuthService } from 'src/services/auth.service';
import {Observable} from "rxjs";
import {map, take} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  private settings: Observable<Settings[]>;
  private settingsCollection: AngularFirestoreCollection<Settings>;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
    public auth: AuthService,
  ) {

  }

  // getSettingsByZones() :Observable<NSettings[]> {
  //   return this.nSettings;
  // }

  // getSettingsByZones(account_key: string): Observable<NSettings>{
  //   return this.nSettingsCollection.doc<NSettings>(account_key).valueChanges().pipe(
  //     take(1),
  //     map(user =>{
  //       return user
  //     })
  //   );

  // }

  getSettingsByZones(accountKey: string){
    return new Promise((resolve, reject) => {
      const query = firebase.firestore().collection('nsettings');
      const observer = query
        .where('account_key', '==', accountKey)
        .get();

      observer.then((snapshot) => {
        console.log("snapshot", snapshot.empty);
        if (snapshot.empty) {
          console.log("Sorry, no settings found on this account. Please contact your administrator.")
          resolve(false);
          return;
        }

        snapshot.forEach((doc) =>{
          console.log("doc.data() ==>", doc.data());
          let account = doc.data();
          account.account_id = doc.id;
          resolve(account);
        });

      });

    });

  }

  getSettingsByAccountKey(accountKey: string){
    return new Promise((resolve, reject) => {
      const query = firebase.firestore().collection('settings');
      const observer = query
        .where('account_key', '==', accountKey)
        .limit(1)
        .get();

      observer.then((snapshot) => {
        if (snapshot.empty) {
          console.log("Sorry, no settings found on this account. Please contact your administrator.")
          resolve(false);
          return;
        }

        snapshot.forEach((doc) =>{
          console.log("doc.data() ==>", doc.data());
          let account = doc.data();
          account.account_id = doc.id;
          resolve(account);
        });

      });

    });

  }

  addSettingsToAccount(id: string, settings: Settings) {
    return this.afs
      .collection("accounts")
      .doc(id)
      .set({
        settings: settings
      }, { merge: true });

  }

  // getSettingsToAccount(id: string, settings: any) {
  //   return this.afs
  //     .collection("accounts")
  //     .doc(id)
  //     .get().subscribe((res) =>{
  //
  //       if(!res.exists){
  //         console.log("not exist");
  //         return;
  //       }
  //
  //       const data: any = res.data();
  //       const zones: any = data.settings.zones;
  //       const zone: any = zones.filter(z => z.zone_id === settings.zone_id);
  //       const employee = zone[0].employees.find(e => e.employee_id === settings.employee_id);
  //       console.log(employee)
  //
  //     });
  //
  // }

  addUsernamePasswordSettingsToAccount(id: string, settings: any) {

    return this.afs
      .collection("accounts")
      .doc(id)
      .update({ 'settings.employees': settings });

  }

  addSettings(accountKey: string, settings: any): Promise<void> {

    const accountRef = this.afs.doc(`settings/${accountKey}`);
    return accountRef.set({
      settings: settings
    }, { merge: true });

  }


  addSettingZone(accountKey: string, settings: any): Promise<void> {
    try {
      const accountRef = this.afs.doc(`settings/${accountKey}`);
      return accountRef.set({
        settings: settings
      }, { merge: true });

    } catch (error) {
        console.log(error);
    }
  }

  updateSettings(settings: Settings, key: string) : Promise<void> {

    return this.afs
      .collection("settings")
      .doc(key)
      .set({settings: settings}, { merge: true });
  }



  // updateSettings(settings: Settings, id) {
  //   return this.afs
  //     .collection("settings")
  //     .doc(id)
  //     .update(settings);
  // }

  addZonesSlot(id:string, data: any): Promise<void> {
    const zoneslotRef: AngularFirestoreDocument<any> = this.afs.doc(`zones_slot/${id}`);
    return zoneslotRef.set(data);
  }

}
