import { Component, OnInit } from '@angular/core';
import firebase from "firebase";
import {Subscription} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {AccountService} from "../../../../services/account.service";

class AccountName {
  account_name: any;
  photoUrl:any;
  address: any;
  constructor (name, photoUrl, address) {
    this.account_name = name;
    this.photoUrl = photoUrl;
    this.address = address;
  }
  toString() {
    return this.account_name + ', ' + this.photoUrl + ', ' + this.address;
  }
}

class AccountSettings {
  zones: any;
  constructor (zones) {
    this.zones = zones;
  }
  toString() {
    return this.zones;
  }
}

@Component({
  selector: 'app-spaces',
  templateUrl: './spaces.component.html'
})
export class PublicSpacesComponent implements OnInit {

  activeSession: number = 0;
  availableSlots: number = 0;
  currentAccountKey: string;
  account: any;
  zones: any;
  showDetails: boolean = false;
  private routeSub: Subscription;

  constructor(private route: ActivatedRoute) { }

  async ngOnInit() {
    this.currentAccountKey = this.route.snapshot.url[1].path;
  }

  async ngAfterViewInit(){
    await this.getCountZoneSlotActiveSession(this.currentAccountKey);
    this.account = await this.getAccountByKeyFilter(this.currentAccountKey);
    this.zones = await this.getAccountSettingsByKeyFilter(this.currentAccountKey);
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }

  getAccountByKeyFilter(account_key: string):Promise<any>{

    let converter = {
      toFirestore: function(account) {
        return {
          account_name: account.account_name,
          photoUrl: account.photoUrl,
          address: account.address
        };
      },
      fromFirestore: function(snapshot, options){
        const data = snapshot.data(options);
        return new AccountName(data.account_name, data.photoUrl, data.address);
      }
    };

    return new Promise((resolve, reject) => {
      const query = firebase.firestore().collection('accounts');
      query
        .where("account_key", "==", account_key)
        .limit(1)
        .withConverter(converter)
        .get().then((snapshots) =>{

        if(snapshots.empty){
          return;
        }

        snapshots.forEach((doc) =>{
          let account = doc.data();
          resolve(account);
        });

      });
    });

  }

  getAccountSettingsByKeyFilter(id: string):Promise<any>{

    let converter = {
      toFirestore: function(accountSettings) {
        return { zones: accountSettings.zones };
      },
      fromFirestore: function(snapshot, options){
        const data = snapshot.data(options);
        return new AccountSettings(data.settings.zones);
      }
    };

    return new Promise((resolve, reject) => {
      const query = firebase.firestore().collection('settings');
      query.doc(id).withConverter(converter).get().then((res) =>{

        if(!res.exists) return;

        let accountSettings = res.data().zones;
        resolve(accountSettings);

      });
    });

  }

  async getCountZoneSlotActiveSession(account_key){
    const query = firebase.firestore().collection('zones_slot')
      .where('account_key', '==', account_key)
      .where('status', '==', 1)
      .onSnapshot((snapshot) =>{
        if(snapshot.empty){
          this.availableSlots = 0;
        }else{
          this.availableSlots = snapshot.size;
        }
      });
  }

  viewDetails(){
    this.showDetails = !this.showDetails;
  }

}
