export const TemplateOccupancyTimeHeader = [
'12:00 AM',
'01:00 AM',
'02:00 AM',
'03:00 AM',
'04:00 AM',
'05:00 AM',
'06:00 AM',
'07:00 AM',
'08:00 AM',
'09:00 AM',
'10:00 AM',
'11:00 AM',
'12:00 PM',
'01:00 PM',
'02:00 PM',
'03:00 PM',
'04:00 PM',
'05:00 PM',
'06:00 PM',
'07:00 PM',
'08:00 PM',
'09:00 PM',
'10:00 PM',
'11:00 PM',]

export const TemplateOccupancyTimeRage = [
    {date : '', time: '12:00 AM',  entry:0, exit:0 , activeSession: 0, occupancyPercentage: '' },
    {date : '', time: '01:00 AM',  entry:0, exit:0 , activeSession: 0, occupancyPercentage: '' },
    {date : '', time: '02:00 AM',  entry:0, exit:0 , activeSession: 0, occupancyPercentage: '' },
    {date : '', time: '03:00 AM',  entry:0, exit:0 , activeSession: 0, occupancyPercentage: '' },
    {date : '', time: '04:00 AM',  entry:0, exit:0 , activeSession: 0, occupancyPercentage: '' },
    {date : '', time: '05:00 AM',  entry:0, exit:0 , activeSession: 0, occupancyPercentage: '' },
    {date : '', time: '06:00 AM',  entry:0, exit:0 , activeSession: 0, occupancyPercentage: '' },
    {date : '', time: '07:00 AM',  entry:0, exit:0 , activeSession: 0, occupancyPercentage: '' },
    {date : '', time: '08:00 AM',  entry:0, exit:0 , activeSession: 0, occupancyPercentage: '' },
    {date : '', time: '09:00 AM',  entry:0, exit:0 , activeSession: 0, occupancyPercentage: '' },
    {date : '', time: '10:00 AM',  entry:0, exit:0 , activeSession: 0, occupancyPercentage: '' },
    {date : '', time: '11:00 AM',  entry:0, exit:0 , activeSession: 0, occupancyPercentage: '' },
    {date : '', time: '12:00 PM',  entry:0, exit:0 , activeSession: 0, occupancyPercentage: '' },
    {date : '', time: '01:00 PM',  entry:0, exit:0 , activeSession: 0, occupancyPercentage: '' },
    {date : '', time: '02:00 PM',  entry:0, exit:0 , activeSession: 0, occupancyPercentage: '' },
    {date : '', time: '03:00 PM',  entry:0, exit:0 , activeSession: 0, occupancyPercentage: '' },
    {date : '', time: '04:00 PM',  entry:0, exit:0 , activeSession: 0, occupancyPercentage: '' },
    {date : '', time: '05:00 PM',  entry:0, exit:0 , activeSession: 0, occupancyPercentage: '' },
    {date : '', time: '06:00 PM',  entry:0, exit:0 , activeSession: 0, occupancyPercentage: '' },
    {date : '', time: '07:00 PM',  entry:0, exit:0 , activeSession: 0, occupancyPercentage: '' },
    {date : '', time: '08:00 PM',  entry:0, exit:0 , activeSession: 0, occupancyPercentage: '' },
    {date : '', time: '09:00 PM',  entry:0, exit:0 , activeSession: 0, occupancyPercentage: '' },
    {date : '', time: '10:00 PM',  entry:0, exit:0 , activeSession: 0, occupancyPercentage: '' },
    {date : '', time: '11:00 PM',  entry:0, exit:0 , activeSession: 0, occupancyPercentage: '' },
]
