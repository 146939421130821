import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/services/auth.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import firebase from "firebase";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
})
export class LoginComponent implements OnInit {

  emailForm: FormGroup | any;
  phoneNumberForm: FormGroup | any;
  errorMessage: any;
  phoneNumber: any;
  reCaptchaVerifier: any;
  loginMobileNumber: boolean = false;
  loginEmailPassword: boolean = true;
  isLoading: boolean;
  constructor(
    public authService: AuthService
  ) {}

  ngOnInit(): void {
    this.emailForm = new FormGroup({
      userEmail: new FormControl('', [Validators.required, Validators.email, Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}')]),
      userPassword: new FormControl('', Validators.required)
    });

    this.phoneNumberForm = new FormGroup({
      userPhoneNumber: new FormControl('', [Validators.required, Validators.pattern(/^9\d{2}\s?\d{3}\s?\d{4}$/)])
    });

    this.phoneNumberForm.get('userPhoneNumber').valueChanges.subscribe(value => {
      let formattedValue = value.replace(/\D/g, '').replace(/(\d{3})(?=\d)/g, '$1 ');
      if (formattedValue.length === 13 && formattedValue.charAt(13) === '') {
        formattedValue = formattedValue.slice(0, 11) + formattedValue.slice(12);
      }
      if (formattedValue.charAt(0) === '0') {
        formattedValue = formattedValue.slice(1);
      }
      this.phoneNumberForm.get('userPhoneNumber').patchValue(formattedValue, { emitEvent: false });
    });
  }

  async login(email, password) {
    if(this.emailForm.valid){
      //this.isLoading = true;
      await this.authService.signIn(email, password);
    }else{
      this.validateAllFormFields(this.emailForm);
    }
  }

  getOtp(){
    if(this.phoneNumberForm.valid) {
      this.isLoading = true;
      const phoneNumber = this.phoneNumberForm.get('userPhoneNumber').value.replace(/\s/g, '');
      const withAreaCodePhoneNumber = "+63" + phoneNumber;
      this.reCaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptchaContainer', {size: 'invisible'});
      this.authService.signInWithPhone(withAreaCodePhoneNumber, this.reCaptchaVerifier);
    }else{
      this.validateAllFormFields(this.phoneNumberForm);
    }
  }

  loginWithMobileNumber(){
    this.loginMobileNumber = true;
    this.loginEmailPassword = false;
  }

  loginWithEmailPassword(){
    this.loginEmailPassword = true;
    this.loginMobileNumber = false;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

}
