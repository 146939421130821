import { Injectable } from '@angular/core';

import { Router } from '@angular/router';
import {UserLogs, User, UserLeads, UserActivitySessions} from 'src/models/user.model';

import { AngularFireAuth } from '@angular/fire/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument,
  AngularFirestoreCollection
} from '@angular/fire/firestore';

import { AuthService } from 'src/services/auth.service';
import {BehaviorSubject, Observable} from "rxjs";
import {debounceTime, distinctUntilChanged, map, take} from "rxjs/operators";
import { switchMap } from 'rxjs/operators';
import firebase from "firebase/app";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private users_account: Observable<User[]>;
  private usersAccountCollection: AngularFirestoreCollection<User>;

  private users: Observable<User[]>;
  private userCollection: AngularFirestoreCollection<User>;

  private userLeads: Observable<UserLeads[]>;
  private userLeadsCollection: AngularFirestoreCollection<UserLeads>;

  public userId: any;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
    public auth: AuthService,
    private http: HttpClient
  ) {
    this.initializeUsersAccount();
    this.initializeUsers();
    this.initializeUserLeads();
    this.getIpAddress();
  }


  //====> USER LOGS ==========//

  getUserLogById(id: string): Observable<UserLogs>{
    return this.usersAccountCollection.doc<UserLogs>(id).valueChanges().pipe(
      take(1),
      map(user =>{
        return user
      })
    );
  }

  getUsersAccount():Observable<User[]> {
    return this.users_account;
  }

  initializeUsersAccount(){
    this.usersAccountCollection = this.afs.collection<User>('users');
    this.users_account = this.usersAccountCollection.snapshotChanges().pipe(
      map(actions =>{
        return actions.map(a =>{
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      }));
  }

  // getUserLogByCode(code: string): Observable<UserLogs>{
  //   return this.userLogsCollection.doc<UserLogs>(code).valueChanges().pipe(
  //     take(1),
  //     map(user =>{
  //       return user
  //     })
  //   );
  // }

  addUserLogs(user: UserLogs): Promise<void>{
    const userRef: AngularFirestoreDocument<UserLogs> = this.afs.doc(`users_logs/${user.code}`);
    return userRef.set(user);
  }

  updateUserLogs(user: UserLogs, code) {
    return this.afs
      .collection("users_logs")
      .doc(code)
      .update(user);
  }

  deleteUserLogs(user: UserLogs) {
    return this.afs
      .collection("users_logs")
      .doc(user.code)
      .delete();
  }

  // searchUserLogsByCode(start: BehaviorSubject<string>): Observable<any[]> {
  //   return start.pipe(
  //     switchMap(startText => {
  //       const endText = startText + '\uf8ff';
  //       return this.afs
  //         .collection('users_logs', ref => ref
  //           .orderBy('date_registered', 'desc')
  //           .limit(10)
  //           .startAt(startText)
  //           .endAt(endText)
  //         ).snapshotChanges().pipe(
  //           debounceTime(200),
  //           distinctUntilChanged(),
  //           map(actions => {
  //             return actions.map(a => {
  //               const data = a.payload.doc.data() as {};
  //               const id = a.payload.doc.id;
  //               return { id, ...data };
  //             })
  //           })
  //         )
  //     })
  //   )
  // }

  //====> USER ==========//

  getUserById(id: string): Observable<User>{
    return this.userCollection.doc<User>(id).valueChanges().pipe(
      take(1),
      map(user =>{
        return user
      })
    );
  }

  getUserByCode(code: string): Observable<User>{
    return this.userCollection.doc<User>(code).valueChanges().pipe(
      take(1),
      map(user =>{
        return user
      })
    );
  }

  initializeUsers(){
    this.userCollection = this.afs.collection<User>('users', ref => ref.limit(10));
    this.users = this.userCollection.snapshotChanges().pipe(
      map(actions =>{
        return actions.map(a =>{
          const data: any = a.payload.doc.data();
          const lastActivity = data.timestamp ? new Date(data.timestamp.seconds * 1000) : null;
          const id = a.payload.doc.id;
          return { id, lastActivity, ...data };
        })
      }));
  }

  initializeUserById(id: string) {
    if (id) {
      this.getUserById(id).subscribe(user => {
        console.log(user);
        return user
      });
    }
  }

  getUsers():Observable<User[]> {
    return this.users;
  }

  addUser(user: User): Promise<void>{
    const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${user.uid}`);
    return userRef.set(user);
  }

  updateUser(user: User, id) {
    return this.afs
      .collection("users")
      .doc(id)
      .update(user);
  }

  updateUserAccountKey(accountKey: string, id) {
    return this.afs
      .collection("users")
      .doc(id)
      .update({
        account_key: accountKey
      });
  }

  deleteUser(user: User) {
    return this.afs
      .collection("users")
      .doc(user.uid)
      .delete();
  }

  getUsersByAccountKey():Promise<any>{
    return new Promise((resolve, reject) => {
      const query = firebase.firestore().collection('users');
      const observer = query.get();

      observer.then((snapshot) => {
        if (snapshot.empty) {
          console.log("No list of users");
          resolve(false);
          return;
        }

        snapshot.forEach((doc) =>{
          let user = doc.data();
          const id = user.id;
          resolve({ id, ...user });
        });

      });

    });

  }

  //============= User OnBoarding Registration =================//

  getUserLeads():Observable<UserLeads[]> {
    return this.userLeads;
  }

  initializeUserLeads(){
    this.userLeadsCollection = this.afs.collection<UserLeads>('user_leads');
    this.userLeads = this.userLeadsCollection.snapshotChanges().pipe(
      map(actions =>{
        return actions.map(a =>{
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      }));
  }

  registerUserLeads(user: UserLeads): Promise<void>{
    const userLeadsRef: AngularFirestoreDocument<UserLeads> = this.afs.doc(`user_leads/${user.uid}`);
    return userLeadsRef.set(user);
  }

  updateUserLeads(user: UserLeads, id) {
    return this.afs
      .collection("user_leads")
      .doc(id)
      .update(user);
  }

  ipAddress: any;

  // ADD USER LOG ACTIVITY
  async addUserLogActivity(user): Promise<void>{
    let userActivity: UserActivitySessions = {
      account_key: user.account_key,
      user_id: user.id,
      uid: this.afs.createId(),
      timestamp: new Date(),
      login_time: new Date(),
      logout_time: null,
      ip_address: this.ipAddress,
      application: 'PARKBOT WEB APP'
    }

    await this.updateUserWithLogActivity(userActivity);
    const userRef: AngularFirestoreDocument<UserActivitySessions> = this.afs.doc(`users_activity_sessions/${userActivity.uid}`);
    return userRef.set(userActivity);
  }

  async updateUserWithLogActivity(user: any) {
    let data = {
      timestamp: user.timestamp
    }
    return this.afs
      .collection("users")
      .doc(user.user_id)
      .update(data);
  }

  getIpAddress(){
    this.http.get('https://api.ipify.org/?format=json')
      .subscribe( (data: any) => {
        this.ipAddress = data.ip
      });
  }

  checkUserPhoneIfExist(phoneNumber: string){
    return new Promise((resolve, reject) => {
      const query = firebase.firestore().collection('users');
      const observer = query
        .where('phoneNumber', '==', phoneNumber)
        .limit(1)
        .get();

      observer.then((snapshot) => {
        if (snapshot.empty) {
          console.log("Sorry, user not found on this phone number");
          resolve(false);
          return;
        }

        snapshot.forEach((doc) =>{
          let user = doc.data();
          resolve(user);
        });

      });

    });

  }

}
