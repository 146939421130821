import {AfterViewInit, ChangeDetectorRef, Component, Input, OnInit} from "@angular/core";
import {Chart} from "chart.js";
import moment from 'moment';
import firebase from "firebase";
import {Logs} from "src/models/logs";
import {DatePipe} from "@angular/common";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {Account} from "src/models/account.model";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";

@Component({
  selector: "app-card-bar-chart",
  templateUrl: "./card-bar-chart.component.html",
})
export class CardBarChartComponent implements OnInit, AfterViewInit {

  @Input('showTotalTransac') showTotalTransac: boolean;
  @Input('showRevenue') showRevenue: boolean;

  date = new Date();
  logs: Logs = {};
  account: Account;

  timeInDataFirstLoad = [];
  timeInData = [];
  timeOutData = [];
  dataX = [];
  dataY = [];

  resultIn = [];
  resultOut = [];

  formDate: FormGroup;
  dateRange = new Date();
  chart: any;

  resetButtonEnable: boolean;

  totalTransaction = 0;
  totalRevenue = 0;
  zone_id:string;
  constructor(
    private router: Router,
    private datePipe: DatePipe,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef
    ) {
    this.account = JSON.parse(localStorage.getItem('account'));
    this.zone_id = this.router.routerState.snapshot.url.split('/').slice(-1)[0]

    this.router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd)
    ).subscribe(async (res) => {
      let newZoneId = res.url.split('/').slice(-1)[0];
      this.zone_id = newZoneId;
      if(this.zone_id) await this.getLogsData();
    });
  }

  async ngOnInit() {
    if(this.showTotalTransac == undefined) this.showTotalTransac = true;
    if(this.showRevenue == undefined) this.showRevenue = true;
  }

  async ngAfterViewInit() {
    if(this.zone_id) await this.getLogsData();
  }

  async getDateValue(value){
    this.dateRange = value;
    if(this.zone_id) await this.getSelectedDateLogs();
  }

  getStartOfToday() {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    return firebase.firestore.Timestamp.fromDate(now);
  }

  async getSelectedDateLogs(){

    //this.resetButtonEnable = true;
    this.timeInDataFirstLoad = [];

    const start = new Date(this.dateRange);
    start.setHours(0,0,0,0);
    const end = new Date(start.getTime());
    end.setHours(23,59,59,999);
    const logRef = firebase.firestore().collection('logs');
    logRef
      .where('account_key', '==', this.account.account_key)
      .where('zone_id','==', this.zone_id)
      .where('time_in', '>=', start)
      .where('time_in', '<=', end)
      .onSnapshot(querySnapshot => {
        this.totalTransaction = 0;
        this.totalRevenue = 0;
        this.timeInData = [];
        this.timeOutData = [];

        let timeInDataArray = [];
        let timeOutDataArray = [];

        // console.log(this.account);
        querySnapshot.forEach(doc => {
          if(doc.data().amount_paid){
            //parkingFee = parseFloat(this.calculateParkingFees(startTime, endTime));
            this.totalRevenue = this.totalRevenue +  parseFloat(doc.data().amount_paid);
          }
          if(doc.data().time_in !== null && doc.data().time_out !== null && this.account.account_key === doc.data().account_key){

            //Getting the  total revenue
            // console.log(doc.data())
            //Upon entry  automatically charge with the minimum rate.
              // this.totalRevenue = this.totalRevenue + this.account.settings.parkingFee.minimumRate;

              // const formattedTimeIn = moment.utc(doc.data().time_in*1000).format('HH:mm');
              // const formattedTimeOut = moment.utc(doc.data().time_out*1000).format('HH:mm');
              // let timeStart = new Date("01/01/2022 " + formattedTimeIn).getHours();
              // let timeEnd = new Date("01/01/2022 " + formattedTimeOut).getHours();
              // let hourDiff = timeEnd - timeStart;

            //NOTE: for more than a 1 hour of parking.
            // but not less than 2 hours to trigger the additional charges
            // if(hourDiff >= 2){
            //   //remove the first hour as its the base payment upon parking
            //   let addtionalHoursToPay = hourDiff - 1;
            //   //multiply the extended hours to the additional rate
            //   let additionalCharge = addtionalHoursToPay * this.account.settings.parkingFee.additionalRate;
            //   // add the  result of  addtional charge to the revenue;
            //   this.totalRevenue = this.totalRevenue + additionalCharge;
            // }
            //NOTE: for overnight parking
            // if( this.account.settings.parkingFee.weekDaysOpeningHour === null &&
            //     this.account.settings.parkingFee.weekDaysclosingHour === null &&
            //     this.account.settings.parkingFee.weekendsOpeningHour === null &&
            //     this.account.settings.parkingFee.weekendsclosingHour === null
            //   ){
            //     if(moment().isoWeekday() == 6 || moment().isoWeekday() == 7){
            //       //weekend
            //     }else{
            //       //weekday
            //     }
            //   }

            this.totalTransaction++;
          }

          let timeIn = (doc.data().time_in.seconds * 1000);
          let dateTimeIn = moment(timeIn).format('LLL');

          timeInDataArray.push(dateTimeIn);
          if(doc.data().time_out !== null){
            let timeOut = (doc.data().time_out.seconds * 1000);
            let dateTimeOut = moment(timeOut).format('LLL');
            timeOutDataArray.push(dateTimeOut);
          }

          this.cdr.detectChanges();
        });

        Promise.all([timeInDataArray, timeOutDataArray]).then(([timeInResults, timeOutResults]) =>{
          this.timeInData = timeInResults;
          this.timeOutData = timeOutResults;
          this.filterDateRange();
        });



      });

  }


  async getLogsData(){

    //this.resetButtonEnable = false;
    const logRef = firebase.firestore().collection('logs');
    logRef
      .where('account_key', '==', this.account.account_key)
      .where('zone_id','==', this.zone_id)
      .where('timestamp', '>=', this.getStartOfToday())
      .orderBy('timestamp', 'desc')
      .get()
      .then(query => {
        this.totalTransaction = 0;
        this.totalRevenue = 0;

        this.timeInData = [];
        this.timeOutData = [];

        let timeInDataArray = [];
        let timeOutDataArray = [];

        query.forEach(doc => {

          if(doc.data().amount_paid){
            //parkingFee = parseFloat(this.calculateParkingFees(startTime, endTime));
            this.totalRevenue = this.totalRevenue +  parseFloat(doc.data().amount_paid);
          }
          // console.log(doc.data())
          if(doc.data().time_in !== null && doc.data().time_out !== null && this.account.account_key === doc.data().account_key){
            //Getting the  total revenue

            //Upon entry  automatically charge with the minimum rate.
            // this.totalRevenue = this.totalRevenue + this.account.settings.parkingFee.minimumRate;

            // const formattedTimeIn = moment.utc(doc.data().time_in*1000).format('HH:mm');
            // const formattedTimeOut = moment.utc(doc.data().time_out*1000).format('HH:mm');
            // let timeStart = new Date("01/01/2022 " + formattedTimeIn).getHours();
            // let timeEnd = new Date("01/01/2022 " + formattedTimeOut).getHours();
            // let hourDiff = timeEnd - timeStart;

            //NOTE: for more than a 1 hour of parking.
            // but not less than 2 hours to trigger the additional charges
            // if(hourDiff >= 2){
            //   //remove the first hour as its the base payment upon parking
            //   let addtionalHoursToPay = hourDiff - 1;
            //   //multiply the extended hours to the additional rate
            //   let additionalCharge = addtionalHoursToPay * this.account.settings.parkingFee.additionalRate;
            //   // add the  result of  addtional charge to the revenue;
            //   this.totalRevenue = this.totalRevenue + additionalCharge;
            // }
            //NOTE: for overnight parking
            this.totalTransaction++;
          }

          // console.log("doc.data(): ",doc.data());
          let timeIn = (doc.data().time_in.seconds * 1000);
          let dateTimeIn = moment(timeIn).format('LLL');
          timeInDataArray.push(dateTimeIn);

          if(doc.data().time_out !== null){
            let timeout = (doc.data().time_out.seconds * 1000);
            let dateTimeOut = moment(timeout).format('LLL');
            timeOutDataArray.push(dateTimeOut);
          }

          this.cdr.detectChanges();
        });

        Promise.all([timeInDataArray, timeOutDataArray]).then(([timeInResults, timeOutResults]) =>{
          this.timeInData = timeInResults;
          this.timeOutData = timeOutResults;
          this.filterDateRange();
        });



      });
  }

  async resetGraph(){
    this.dateRange = new Date();
    if(this.zone_id) await this.getLogsData();
  }

  loadCharts(){
    let labelsIn = this.resultIn.map(x => moment(x.x, 'LT'));
    let dataYIn = this.resultIn.map(y => y.y);

    let labelsOut = this.resultOut.map(x => moment(x.x, 'LT'));
    let dataYOut = this.resultOut.map(y => y.y);


    let config = {
      type: "bar",
      data: {
        labels: labelsIn,
        datasets: [
          {
            label: "Entry",
            backgroundColor: "#08B19B",
            borderColor: "#08B19B",
          },
          {
            label: "Exit",
            backgroundColor: "#F74E64",
            borderColor: "#F74E64",
          }

        ],
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        tooltips: {
          mode: "index",
          intersect: true,
        },
        hover: {
          mode: "nearest",
          intersect: true,
        },
        legend: {
          labels: {
            fontColor: "rgba(0,0,0,.4)",
          },
          align: "end",
          position: "bottom",
        },
        scales: {
          xAxes: [
            {
              type: 'time',
              distribution: 'series',
              time: {
                unit: 'hour',
                displayFormats: {
                  quarter: 'MMM YYYY'
                }
              },
              stacked: false,
              display: true,
              scaleLabel: {
                display: true,
                labelString: "Hours",
              },
              gridLines: {
                borderDash: [2],
                borderDashOffset: [2],
                color: "rgba(33, 37, 41, 0.3)",
                zeroLineColor: "rgba(33, 37, 41, 0.3)",
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2],
              },
            },
          ]
        },
      },
    };

    let ctx: any = document.getElementById("bar-chart");
    ctx = ctx?.getContext("2d");
    if(ctx){
      this.chart = new Chart(ctx, config);
      //this.removeDataSet(this.chart, dataYIn, dataYOut,config);
      this.addDataSet(this.chart, dataYIn, dataYOut, config);
    }

  }

  //TODO: remove old data on graph
  removeDataSet(chart, dataYIn, dataYOut, config) {
    chart.data.labels.pop();
    chart.data.datasets.forEach((dataset) => {
      dataset.data.pop();
    });
    chart.update();
    this.addDataSet(chart, dataYIn, dataYOut ,config);
  }

   addDataSet(chart, dataYIn, dataYOut , config) {
    // console.log(chart.data.datasets);
    chart.data.datasets.forEach(dataset => {
      if(dataset.label === 'Entry'){
        dataset.data = dataYIn
      }else{
        dataset.data = dataYOut
      }
    });
    chart.update();
  }

  filterDateRange(){
    this.resultIn = [];
    this.resultOut = [];
    const byTwoHours = Array(12)
      .fill(0)
      .map((_, index) => `${String(index * 2).padStart(2, "00")}:00:00`) // index * 2

    const byTwoHoursLookupIn = byTwoHours.reduce(
      (acc, range) => ({ ...acc, [range]: [] }),{}
    )
    const byTwoHoursLookupOut = byTwoHours.reduce(
      (acc, range) => ({ ...acc, [range]: [] }),{}
    )
    this.timeInData.forEach((date) => {
      const hour = moment(date, "LLL").format("HH:00:00")
      for (let i = byTwoHours.length - 1; i >= 0; i--) {
        if (hour >= byTwoHours[i]) {
          byTwoHoursLookupIn[byTwoHours[i]].push(date);
          break
        }
      }
    });

    this.timeOutData.forEach((date) => {
      const hour = moment(date, "LLL").format("HH:00:00")
      for (let i = byTwoHours.length - 1; i >= 0; i--) {
        if (hour >= byTwoHours[i]) {
          byTwoHoursLookupOut[byTwoHours[i]].push(date);
          break
        }
      }
    });

    Object.entries(byTwoHoursLookupIn).forEach(([key, value] : any) => {
      this.resultIn.push({ x: key, y: value.length.toString()});
    });
    Object.entries(byTwoHoursLookupOut).forEach(([key, value] : any) => {
      this.resultOut.push({ x: key, y: value.length.toString()});
    });
    if (this.chart) this.chart.destroy();
    this.loadCharts();
  }

}
