import {Component, OnInit, Input, ViewChild, ElementRef} from "@angular/core";
import { UserService  } from "src/services/user.service";
import { VehicleService } from "src/services/vehicle.service";
import {Router} from "@angular/router";

import firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection, DocumentReference } from '@angular/fire/firestore';
import {FormControl} from "@angular/forms";
import {Observable} from "rxjs";
import {map, startWith} from "rxjs/operators";
import {AuthService} from "../../../../services/auth.service";
import {User} from "../../../../models/user.model";
import {Account} from "../../../../models/account.model";
import {AccountService} from "../../../../services/account.service";

@Component({
  selector: "app-card-vehicle-table",
  templateUrl: "./card-vehicle-table.component.html",
})
export class CardVehicleTableComponent implements OnInit {

  //@Input()
  // dropdownPopoverShow = false;
  @ViewChild("btnDropdownRef", { static: false }) btnDropdownRef: ElementRef;
  @ViewChild("popoverDropdownRef", { static: false })
  popoverDropdownRef: ElementRef;

  @Input() filterPlateNumber: string;

  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }
  private _color = "light";

  vehicles: any[] = [];

  //Models for Input fields
  nameValue: string;
  placeValue: string;

  //Data object for listing items
  tableData: any[] = [];

  //Save first document in snapshot of items received
  firstInResponse: any = [];

  //Save last document in snapshot of items received
  lastInResponse: any = [];

  //Keep the array of first document of previous pages
  prev_start_at: any = [];

  //Maintain the count of clicks on Next Prev button
  pagination_clicked_count = 0;

  //Disable next and prev buttons
  disable_next: boolean = false;
  disable_prev: boolean = false;

  filterData = {};

  userAuth: User;
  account: Account;

  constructor(
    private userService: UserService,
    private vehicleService: VehicleService,
    protected router: Router,
    private afAuth: AngularFireAuth,
    private firestore: AngularFirestore,
    public auth: AuthService,
    private accountService: AccountService,
  ) {

    this.auth.user$.subscribe(user => this.userAuth = user);

  }

  async ngOnInit() {
    await this.loadItems();
  }

  async loadItems() {

    const userRef = firebase.firestore().collection('users_vehicle');
    const query = await userRef
      // .where('account_key', '==', this.account.account_key)
      .where("deleted", "==", false)
      .limit(20)

    query.get().then((documentSnapshots: any) =>{

      if (!documentSnapshots.docs.length) {
        console.log("No Data Available");
        return false;
      }

      this.firstInResponse = documentSnapshots.docs[0];
      this.lastInResponse = documentSnapshots.docs[documentSnapshots.docs.length - 1];

      this.tableData = [];
      for (let item of documentSnapshots.docs) {
        this.tableData.push(item.data());
      }

      Promise.all(this.tableData).then((response) =>{
        //Initialize values
        this.vehicles = this.tableData.sort((a,b) => a.plate_number > b.plate_number ? 1 : -1);
        console.log(this.vehicles);
      }).catch((e) =>{
        //Handle errors
      });

      this.prev_start_at = [];
      this.pagination_clicked_count = 0;
      this.disable_next = false;
      this.disable_prev = false;

      //Push first item to use for Previous action
      this.push_prev_startAt(this.firstInResponse);

    }, error =>{

    });

  }

  //Show previous set
  prevPage() {
    this.disable_prev = true;
    this.firestore.collection('users_vehicle', ref => ref
      //.where('account_key', '==', this.account.account_key)
      .where("deleted", "==", false)
      .startAt(this.get_prev_startAt())
      .endBefore(this.firstInResponse)
      .limit(20)
    ).get()
      .subscribe(response => {
        this.firstInResponse = response.docs[0];
        this.lastInResponse = response.docs[response.docs.length - 1];

        this.tableData = [];
        for (let item of response.docs) {
          this.tableData.push(item.data());
        }

        this.vehicles = this.tableData.sort((a,b) => a.plate_number > b.plate_number ? 1 : -1);

        //Maintaining page no.
        this.pagination_clicked_count--;

        //Pop not required value in array
        this.pop_prev_startAt(this.firstInResponse);

        //Enable buttons again
        this.disable_prev = false;
        this.disable_next = false;
      }, error => {
        this.disable_prev = false;
      });
  }

  nextPage() {
    this.disable_next = true;
    this.firestore.collection('users_vehicle', ref => ref
      //.where('account_key', '==', this.account.account_key)
      .where("deleted", "==", false)
      .limit(20)
      .startAfter(this.lastInResponse)
    ).get()
      .subscribe(response => {

        if (!response.docs.length) {
          this.disable_next = true;
          return;
        }

        this.firstInResponse = response.docs[0];

        this.lastInResponse = response.docs[response.docs.length - 1];
        this.tableData = [];
        for (let item of response.docs) {
          this.tableData.push(item.data());
        }

        this.vehicles = this.tableData.sort((a,b) => a.plate_number > b.plate_number ? 1 : -1);

        this.pagination_clicked_count++;

        this.push_prev_startAt(this.firstInResponse);

        this.disable_next = false;
      }, error => {
        this.disable_next = false;
      });
  }

  //Add document
  push_prev_startAt(prev_first_doc) {
    this.prev_start_at.push(prev_first_doc);
  }

  //Remove not required document
  pop_prev_startAt(prev_first_doc) {
    this.prev_start_at.forEach(element => {
      if (prev_first_doc.data().id == element.data().id) {
        element = null;
      }
    });
  }

  //Return the Doc rem where previous page will startAt
  get_prev_startAt() {
    if (this.prev_start_at.length > (this.pagination_clicked_count + 1))
      this.prev_start_at.splice(this.prev_start_at.length - 2, this.prev_start_at.length - 1);
    return this.prev_start_at[this.pagination_clicked_count - 1];
  }

  async accountVehicle(vehicle: any) {
    this.accountService.getAccountByKey(vehicle.account_key).then((account: any) =>{
      localStorage.setItem('account', JSON.stringify(account));
      this.router.navigate([vehicle.account_key + '/user/vehicles/vehicles-edit/', vehicle.id]);
    });
  }
}
