import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {filter, map, mergeMap} from "rxjs/operators";
import { UserService  } from "src/services/user.service";
import { LogsService } from "src/services/log.service";
import {User, UserLogs} from "src/models/user.model";
import {combineLatest} from "rxjs";
import firebase from "firebase/app";
import {Logs} from "src/models/logs";
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {VehicleService} from "src/services/vehicle.service";
import Swal from "sweetalert2";
import {Vehicles} from "src/models/vehicle.model";
import {AuthService} from "src/services/auth.service";
import * as moment from 'moment';
import {Account} from "../../../../../models/account.model";

@Component({
  selector: 'app-user-logs-edit',
  templateUrl: './user-logs-edit.component.html'
})
export class UserLogEditComponent implements OnInit {

  public userForm: FormGroup;
  public vehicleForm: FormGroup;
  userVehicles: FormArray;

  plate_number = new FormControl('');
  vehicle_type = new FormControl('');
  vehicle_model = new FormControl('');
  vehicle_color = new FormControl('');
  date_registered = new FormControl(new Date());

  date: any;

  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }
  private _color = "light";

  user: UserLogs = <UserLogs> {};
  vehicles: Vehicles = <Vehicles> {};
  logs: Logs[] = [];

  userAuth: User;
  account: Account;

  constructor(
    protected route : ActivatedRoute,
    private userService: UserService,
    private logService: LogsService,
    private vehicleService: VehicleService,
    public formBuilder: FormBuilder,
    public router: Router,
    public auth: AuthService
    ) {

    this.auth.user$.subscribe(user => this.userAuth = user);
    this.account = JSON.parse(localStorage.getItem('account'));

  }

  async ngOnInit() {

    this.userVehicles = this.formBuilder.array([

    ]);

    this.userForm = this.formBuilder.group({
      name: [''],
      code: [''],
      vehicles: this.userVehicles
    });

    this.vehicleForm = this.formBuilder.group({
      vehicles: this.userVehicles
    });

    this.userForm.patchValue(this.user);
    this.vehicleForm.patchValue(this.vehicles);

    this.route.paramMap
      .pipe(
        map(pm => pm.get('id')),
        filter(id => id != null),
        mergeMap(id => this.userService.getUserLogById(id)),
      ).subscribe((user) =>{

      this.user = user;
      console.log("user logs get by id =>", this.user);

      this.getLogsByCode(this.user.code);

      this.date = this.user.date_registered;

      this.userForm = this.formBuilder.group({
        name: this.user.name,
        code: this.user.code,
        userVehicle_id: this.user.userVehicle_id
      })

      this.userForm.patchValue(user);

      this.vehicleService.getUsersVehicleById(user.userVehicle_id).subscribe((res: any)=>{
        this.userVehicles.clear();
        let userVehicle = this.createUserVehicles();
        userVehicle.patchValue(res);
        this.userVehicles.push(userVehicle);
      });

      // if(user.userVehicle_id !== ""){
      //   this.vehicleService.getUsersVehicleById(user.userVehicle_id).subscribe((res: any)=>{
      //     this.userVehicles.clear();
      //     let userVehicle = this.createUserVehicles();
      //     userVehicle.patchValue(res);
      //     this.userVehicles.push(userVehicle);
      //   });
      // }else{
      //   this.userVehicles.clear();
      //   let userVehicle = this.createUserVehicles();
      //   userVehicle.patchValue(this.vehicles);
      //   this.userVehicles.push(userVehicle);
      // }

    });

  }

  createUserVehicles(): FormGroup {
    return this.formBuilder.group({
      code: this.user.code,
      plate_number: [''],
      vehicle_type: [''],
      vehicle_model: [''],
      vehicle_color: [''],
      date_registered: [new Date()],
    });
  }

  async addVehicles() {
    if(this.auth.canEdit(this.userAuth)){
      this.userVehicles.push(this.createUserVehicles());
    }else{
      await Swal.fire(
        'Warning',
        "Sorry, access denied. Please contact admin.",
        'warning'
      )
    }
  }

  async getLogsByCode(code){

    const logRef = firebase.firestore().collection('logs');
    const snapshot = await logRef
      .where('code', '==', code)
      .orderBy('timestamp', 'desc')
      .get();
    snapshot.forEach((doc) => {

      let duration;
      let parkingFee;
      let data = doc.data();
      data.id = doc.id;

      if(doc.data().time_in.seconds && doc.data().time_out?.seconds){

        let startTime = moment(moment.unix(doc.data().time_in?.seconds), "HH:mm:ss a");
        let endTime = moment(moment.unix(doc.data().time_out?.seconds), "HH:mm:ss a");
        let min = moment.utc(moment(endTime, "HH:mm:ss").diff(moment(startTime, "HH:mm:ss"))).format("mm");
        let sec = moment.utc(moment(endTime, "HH:mm:ss").diff(moment(startTime, "HH:mm:ss"))).format("ss");
        let hour = endTime.diff(startTime, 'hours');

        let hourString = hour + "hr, ";
        let minString = min + "min and ";
        let secString = sec + "sec";

        if(hour === 0 && min !== "00"){
          duration = minString + secString;
        }else if(hour === 0 && min === "00"){
          duration = sec + " seconds";
        }else{
          duration = hourString + minString + secString;
        }

        // parkingFee = this.calculateParkingFees(startTime, endTime);
        parkingFee = data.amount_paid

      }

      data.duration = duration;
      data.parkingFee = parkingFee;
      this.logs.push(data);
      console.log("user logs =>",this.logs );
    })

  }

  calculateParkingFees(parkDateStr, returnDateStr){
    const parkDate = new Date(parkDateStr).getTime();
    const returnDate = new Date(returnDateStr).getTime();
    const totalMSParked = returnDate - parkDate;
    const totalHoursParked = totalMSParked / (1000 * 3600);
    return `${this.getCost(totalHoursParked)}`;
  }

  getCost(totalHoursParked){

    let minDurationTime = this.account.settings.parkingFee.minimumDurationTime; // 2 hours
    let minRate = this.account.settings.parkingFee.minimumRate; // P20
    let addRate = this.account.settings.parkingFee.additionalRate; // P10
    let overnightRate = this.account.settings.parkingFee.overnightRate; // P100

    let parkingCosts = [
      // [Maximum number of hours, Associated cost]
      [0],[0.25],[0.5],[0.75],[1],[2],[3],[4],[5],[6],[7],[8],
      [9],[10],[11],[12],[13],[14],[15],[16],[17],[18],[19],[20],
      [21],[22],[23],[24, overnightRate]
    ];

    for (let i = 0; i < parkingCosts.length; i++){
      if(parkingCosts[i][0] !== 24){

        // if(parkingCosts[i][0] <= minDurationTime){
        //   parkingCosts[i].splice(1, 0, minRate);
        //   return;
        // }

        if(parkingCosts[i][0] <= minDurationTime){
          parkingCosts[i].splice(1, 0, minRate);
        }
        else{
          parkingCosts[i].splice(i, 0, minRate += addRate);
        }
      }
    }

    console.log("parkingCosts ==>", parkingCosts)

    if (totalHoursParked > 24) {
      const days = Math.ceil(totalHoursParked / 24);
      return overnightRate + addRate * (days - 1);
    }

    const found = parkingCosts.find(([maxHours]) => totalHoursParked <= maxHours);
    return found[1];

  };

  async save(){

    if(this.userForm.valid){
      await this.userService.updateUserLogs(this.userForm.value, this.user.code).then(() =>{
        if(this.userForm.value.vehicle_id !== ""){

          this.vehicleForm.value.vehicles[0].date_registered = new Date();

          this.vehicleService.updateVehicle(this.vehicleForm.value, this.userForm.value.vehicle_id).then(() =>{
            Swal.fire(
              'Success',
              'User logs saved!',
              'success'
            )
          });
        }else{
          this.vehicleService.addVehicle(this.vehicleForm.value).then((res) =>{
            console.log("vehicle =>", res.id);
            if(res.id !== null){
              this.userForm.value.vehicle_id = res.id;
              this.userService.updateUserLogs(this.userForm.value, this.user.code).then(() =>{
                Swal.fire(
                  'Success',
                  'User logs saved!',
                  'success'
                )
              })
            }
          });
        }
      });
    }

  }

  async delete(log: Logs){
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.isConfirmed) {
        this.logService.deleteLogs(log).then(() =>{
          const index: number = this.logs.indexOf(log);
          if (index !== -1) {
            this.logs.splice(index, 1);
            Swal.fire(
              'Deleted!',
              'Log deleted!',
              'success'
            )
          }
        });
      }
    });
  }

  async kickOut(log: Logs){
    Swal.fire({
      title: 'Please confirm to kick out!',
      text: "Code: "+ log.code +"",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      confirmButtonText: 'Kick out!',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        this.logService.enSessionLog(log).then((doc:any) =>{

          const index: number = this.logs.indexOf(log);
          if (index !== -1) {

            let time_in = (doc.time_in?.seconds * 1000);
            let dateTimeIn = moment(time_in);

            let time_out = (doc.time_out?.seconds * 1000);
            let dateTimeOut = moment(time_out);

            this.logs[index].duration = dateTimeIn.from(dateTimeOut).replace('ago','');
            this.logs[index].status = doc.status;
            this.logs[index].time_out = doc.time_out;

            Swal.fire(
              'Success',
              'Code: '+ log.code +' was kicked out on '+ moment(new Date(time_out), 'YYYY/MM/DD HH:mm') +'',
              'success'
            )
          }

        });
      }
    });
  }

}
