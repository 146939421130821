import {Component, OnInit, Input, ViewChild, ElementRef} from "@angular/core";
import { UserService  } from "src/services/user.service";
import { UserLogs, User, UserLeads } from "src/models/user.model";
import {Router} from "@angular/router";
import Swal from "sweetalert2";
import {AuthService} from "src/services/auth.service";

@Component({
  selector: 'app-leads',
  templateUrl: './leads.component.html'
})
export class LeadsComponent implements OnInit {

  @ViewChild("btnDropdownRef", { static: false }) btnDropdownRef: ElementRef;
  @ViewChild("popoverDropdownRef", { static: false })
  popoverDropdownRef: ElementRef;

  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }
  private _color = "light";

  userLeads: UserLeads[] = [];
  userAuth: User;

  constructor(
    private userService: UserService,
    protected router: Router,
    public auth: AuthService
  ) {
    this.userService.getUserLeads().subscribe((res) =>{
      this.userLeads = res;
      console.log("userLeads =>", this.userLeads);
    });

    this.auth.user$.subscribe(user => this.userAuth = user);
  }

  ngOnInit(): void {
  }

  async search(leads){

    let parkingDetails = leads.parking_details;
    await Swal.fire({
      title: 'Parking details',
      allowEscapeKey: true,
      allowOutsideClick: true,
      showCloseButton: true,
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      width: 850,
      padding: '1.5em',
      heightAuto: false,
      html:
        '<div style="text-align: left !important;">' +
        '<p><strong>Parking name</strong>: '+ parkingDetails.parking_name +'</p>' +
        '<p><strong>Location address</strong>: '+ parkingDetails.location_address +'</p>' +
        '<p><strong>Location coordinates</strong>: '+ parkingDetails.location_coordinates +'</p>' +
        '<p><strong>Open hours</strong>: '+ parkingDetails.open_hours +'</p>' +
        '<p><strong>Close hours</strong>: '+ parkingDetails.close_hours +'</p>' +
        '<p><strong>Number of spaces</strong>: '+ parkingDetails.number_of_spaces +'</p>' +
        '<p><strong>Regular rate</strong>: '+ parkingDetails.regular_rate +'</p>' +
        '<p><strong>Overnight rate</strong>: '+ parkingDetails.overnight_rate +'</p>' +
        '</div>',
    });

  }

}
