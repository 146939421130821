import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection, DocumentReference } from '@angular/fire/firestore';
import { Account } from 'src/models/account.model';
import { Router } from '@angular/router';
import {AccountService} from "../../../../services/account.service";
import {AuthService} from "../../../../services/auth.service";
import {User} from "../../../../models/user.model";

@Component({
  selector: 'app-card-search',
  templateUrl: './card-search.component.html',
})
export class CardSearchComponent implements OnInit {
  account: Account;
  plateList = [];
  searchVehicle = [];
  searchPlateOrCode = "";
  searchViaCode = false;
  searchViaPlate = false;
  noPlateFound = false;
  filterUserCode = "";
  userAuth: User;
  constructor(
    private firestore: AngularFirestore,
    protected router: Router,
    private accountService: AccountService,
    public auth: AuthService
  ) {

    this.account = JSON.parse(localStorage.getItem('account')) || null;
    this.auth.user$.subscribe(user => this.userAuth = user);

  }

  async ngOnInit() {

  }
  findData = (event) => {
    event = event.toUpperCase();
    this.searchPlateOrCode = event;
    this.searchViaCode = false;
    this.searchViaPlate = false;
    if(event.length >= 1){

      this.noPlateFound = false;
      this.searchViaPlate = true;
      this.searchVehicle = []
      this.searchRequestBy(event,"plate_number");

      // if(event.includes('PBPH')){
      //   this.searchViaCode = true;
      //   this.searchVehicle  = [];
      //
      //   this.searchRequestBy(event,"code");
      //
      //   // if(this.account && this.account.account_key){
      //   //   this.searchRequestByAccountKey(event,"code");
      //   // }else{
      //   //   this.searchRequestBy(event,"code");
      //   // }
      // }else{
      //   this.searchViaPlate = true;
      //   this.searchVehicle = []
      //
      //   this.searchRequestBy(event,"plate_number");
      //
      //   // if(this.auth.canDelete(this.userAuth)){ //if(this.account && this.account.account_key){
      //   //   this.searchRequestBy(event,"plate_number");
      //   // }else if(this.auth.canEdit(this.userAuth)){
      //   //   this.searchRequestByAccountKey(event,"plate_number");
      //   // }
      //
      // }
    }
  }

  // searchRequestByAccountKey(text:any,type:string){
  //   let vehicles = []
  //   const inputStart = text.trim();
  //   firebase.firestore().collection('vehicles')
  //   .where('account_key', '==', this.account.account_key)
  //   .where(type, '>=', inputStart)
  //   .where(type, '<=', inputStart+ '\uf8ff')
  //   .limit(10)
  //   .get()
  //   .then(querySnapshot => {
  //           querySnapshot.forEach(doc => {
  //             vehicles.push(doc.data());
  //           })
  //           this.searchVehicle = this.getUniqueListBy(vehicles,type)
  //   });
  //
  // }

  searchRequestBy(text:any,type:string){
    let vehicles = []
    const inputStart = text.trim();
    firebase.firestore().collection('vehicles')
      .where(type, '>=', inputStart)
      .where(type, '<=', inputStart+ '\uf8ff')
      .limit(10)
      .get()
      .then(querySnapshot => {

        if(querySnapshot.empty){
          this.noPlateFound = true;
          this.searchViaPlate = false;
          this.searchViaCode = false;
          this.searchVehicle = [];
        }else{

          querySnapshot.forEach(doc => {
            vehicles.push(doc.data());
          });

          this.searchVehicle = this.getUniqueListBy(vehicles,type);

        }

      });

  }

  getUniqueListBy(arr, key) {
    return [...new Map(arr.map(item => [item[key], item])).values()]
}

  searchVehicleDetails(){
    if(this.searchVehicle.length > 0 && this.filterUserCode !== ""){
      if(this.account && this.auth.canEdit(this.userAuth)){
        localStorage.setItem('account', JSON.stringify(this.account));
        this.router.navigateByUrl(this.account.account_key + '/user/vehicles/vehicles-edit/' + this.searchPlateOrCode );
      }
    }
  }
}
