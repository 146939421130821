import {ChangeDetectionStrategy, Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import * as moment from "moment";
import firebase from "firebase";
import 'jspdf-autotable';
import {UserService} from "../../../../services/user.service";
import {User, UserLogs} from "../../../../models/user.model";
import {VehicleService} from "../../../../services/vehicle.service";
import Swal from "sweetalert2";
import {SwalComponent, SwalPortalTargets} from "@sweetalert2/ngx-sweetalert2";
import html2pdf from "html2pdf-jspdf2";
import {AngularFirestore} from "@angular/fire/firestore";
import {DatePipe} from "@angular/common";
import {Account} from "../../../../models/account.model";
import {AuthService} from "../../../../services/auth.service";
import {LocalStorage} from 'ttl-localstorage';
import {AccountService} from 'src/services/account.service';
import {ngxCsv} from 'ngx-csv/ngx-csv';
import {TemplateOccupancyTimeHeader, TemplateOccupancyTimeRage} from 'src/app/constant/template-occupancy-time-list';
import {MonthsOfYear, Years} from 'src/app/constant/months-of-year';
import {LogsService} from 'src/services/log.service';
import {SendEmailsService} from "../../../../services/send-emails.service";
import {AngularFireStorage} from '@angular/fire/storage';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserReportsComponent implements OnInit {

  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }
  private _color = "light";

  @ViewChild('tableContent')

  public readonly tableContent!: SwalComponent;



  public dateTimeRangeLogs: Date[];
  public dateTimeRangeErrorLogs: Date[];
  dateRange = new Date();
  timeInData = [];
  employees: any = [];
  filteredByEmployee = [];
  form: FormGroup;
  user: UserLogs = <UserLogs>{};

  account: Account;
  userAuth: User;
  allEmployees:any = [];


  reportsGeneratedCounts: number = 0;

  headerRowTable = [['No.', 'Plate Number', 'Start', 'End']];

  employeeSelected = 'all';
  totalPaymentCollected = 0;

  selectedZone:any;
  zones: any;

  selectedReportType:any;

  vehicleTypes: any = [];

  displayDailyCalendar = false;
  displayWeekelyCalendar = false;
  displayMonthlyCalendar = false;

  weeklyDateRanges: { start: string; end: string }[] = [];

  hideDateRage = true;
  selectedWeeklyRange : string = '';
  selectedMonth: string = ''
  selectedYear:string = '';

  monthsOfYear = MonthsOfYear;
  years = Years;

  minDate: Date;
  maxDate: Date;
  currentMonth = this.dateRange.getMonth();
  currentYear = this.dateRange.getFullYear();
  selectedDateRangeType:string = '';

  public startAt = new Date();
  public filterDates = (d: Date): boolean => {
    const day = d.getDay();
    // Prevent Saturday and Sunday from being selected.
    return day !== 0 && day !== 6;
  }

  constructor(
    public formBuilder: FormBuilder,
    private userService: UserService,
    private vehicleService: VehicleService,
    public readonly swalTargets: SwalPortalTargets,
    private firestore: AngularFirestore,
    private datePipe: DatePipe,
    public auth: AuthService,
    private afs: AngularFirestore,
    private accountService: AccountService,
    private logsService : LogsService,
    private sendEmailsService: SendEmailsService,
    private storage: AngularFireStorage
  ) {

    const now = new Date();
    // this.minDate = new Date(now.getFullYear(), now.getMonth(), 1);
    // this.maxDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);

    this.account = JSON.parse(localStorage.getItem('account'));

    this.zones = this.account.settings.zones;

    this.user = JSON.parse(localStorage.getItem('user'));

    this.auth.user$.subscribe(async user => {
      this.userAuth = user;

      if(this.userAuth.roles.superAdmin){
        if(this.account.settings.zones.length > 0){
          for(let zone of this.account.settings.zones){

            if(zone.employees.length > 0){
              let index = zone.employees.findIndex(e => e.employee_id == 'all')
              if(index == -1){
                this.allEmployees.push(
                  { date_created : null,
                    employee_id: 'all',
                    first_name: 'All',
                    last_name: '',
                    position: ''
                  }
                )
              }
              for(let employee of zone.employees){
                this.allEmployees.push(employee);
              }

              this.form.patchValue({
                employee : 'all'
              })

            }
          }
        }
      }
      //await this.getMonitoringEmailLogs();
    });


    this.vehicleService.getVehicleTypes().then((vehicleTypes) => {
      vehicleTypes.forEach(vehicle => {
        this.vehicleTypes.push(vehicle.vehicle_type_name);
      });

    });

    // TODO: Need to add check if the month report is generated.
    for(let month of this.monthsOfYear){
      if(this.currentMonth == month.id){
        let lastDate = this.getLastDateOfMonth(this.currentYear,this.currentMonth);
        if(lastDate == this.dateRange.getDate()){
          month.enabled  = true;
        }else{
          month.enabled = false;
        }
      }else{
        month.enabled = false;
      }

      if(this.currentMonth > month.id){
        month.enabled = true
      }else{
        month.enabled = false;
      }
    }

  }

  async onSelectZone(value:any) {
    this.selectedZone = this.account.settings.zones.find(z => z.zone_name.toLowerCase() == value.toLowerCase());
    this.employees = this.selectedZone.employees;

    if(this.selectedZone){
      let index = this.employees.findIndex(e => e.employee_id == 'all')


      if(index == -1){
        this.employees.push(
          { date_created : null,
            employee_id: 'all',
            first_name: 'All',
            last_name: '',
            position: ''
          }
        )

        this.form.patchValue({
          employee : 'all'
        })

      }
    }
  }

  async ngOnInit() {
    this.generateWeeklyDateRanges();
    this.form = this.formBuilder.group({
      dateRangeLogsFrom: [''],
      dateRangeLogsTo: [''],
      reportType:[''],
      zone:[''],
      employee:[''],
      selectedDay: '',
      selectedWeeklyRange:[''],
      selectedMonth: [''],
      selectedYear:[''],
      dateRangeType:['']
    });

    if(this.zones.length == 1){
      this.onSelectZone(this.zones[0].zone_name);
    }
  }

  async changeEmployee(employeeId: any) {
    this.employeeSelected = employeeId;
  }

  async onSelectReportType(value:any){
    if(this.userAuth.roles.superAdmin){
      this.employees = this.allEmployees;
      this.selectedReportType = value;
    }else{
      this.selectedReportType = value;
    }
  }

  async printLogs(action,type){

    if(this.selectedReportType !== 'general'){

      if(this.zones.length === 1){
        this.form.value.zone = this.zones[0].zone_name;
      }

      if(this.form.value.employee == '' || this.form.value.reportType == '' || this.form.value.zone == ''){
        await Swal.fire({
          title: 'Ooops!',
          html: 'Please select employee, zone and report type its required fields.',
          allowEscapeKey: true,
          allowOutsideClick: true,
          showConfirmButton: false,
          showCancelButton: true,
          showCloseButton: true,
          cancelButtonText: 'Ok',
          icon: 'warning',
        });
        return
      }
    }

    if(!this.selectedDateRangeType){
      await Swal.fire({
        title: 'Ooops!',
        html: 'Please select date range type and dates (from date & to date)',
        allowEscapeKey: true,
        allowOutsideClick: true,
        showConfirmButton: false,
        showCancelButton: true,
        showCloseButton: true,
        cancelButtonText: 'Ok',
        icon: 'warning',
      });
      return
    }

    if(this.employeeSelected !== ''){
      this.form.value.employee = this.employeeSelected;
    }


    if(this.form.valid){
      switch (this.selectedDateRangeType) {
        case 'daily':
          if(this.form.value.selectedDay != ''){
            this.form.value.dateRangeLogsFrom = this.form.value.selectedDay;
            this.form.value.dateRangeLogsTo = this.form.value.selectedDay;
            this.print(action,type,this.form.value.dateRangeLogsFrom, this.form.value.dateRangeLogsTo);
          }else{
            await Swal.fire({
              title: 'Ooops!',
              html: 'Please select day',
              allowEscapeKey: true,
              allowOutsideClick: true,
              showConfirmButton: false,
              showCancelButton: true,
              showCloseButton: true,
              cancelButtonText: 'Ok',
              icon: 'warning',
            });
            return
          }

          break;
        case 'weekly':
            let dateRangeObject = this.weeklyDateRanges[this.form.value.selectedWeeklyRange];
            if(dateRangeObject){
              this.print(action,type, dateRangeObject.start, dateRangeObject.end);
            }else{
              await Swal.fire({
                title: 'Ooops!',
                html: 'Please select week range',
                allowEscapeKey: true,
                allowOutsideClick: true,
                showConfirmButton: false,
                showCancelButton: true,
                showCloseButton: true,
                cancelButtonText: 'Ok',
                icon: 'warning',
              });
              return
            }
          break;
        case 'monthly':
          let monthObject = this.monthsOfYear[this.form.value.selectedMonth];
          let yearObject = this.years[this.form.value.selectedYear];
          if(monthObject && yearObject){

            const currentDate = new Date();
            const currentYear = parseInt(yearObject.label);

            let start = `${currentYear}-${monthObject.id + 1}-01`;
            let end = `${currentYear}-${monthObject.id + 1}-31`

            this.print(action,type, start, end);
          }else{
            await Swal.fire({
              title: 'Ooops!',
              html: 'Please select month and year',
              allowEscapeKey: true,
              allowOutsideClick: true,
              showConfirmButton: false,
              showCancelButton: true,
              showCloseButton: true,
              cancelButtonText: 'Ok',
              icon: 'warning',
            });


            return
          }
          break;
      }
    }
  }

  async print(action, type, dateFrom, dateTo) {
    let start = new Date(dateFrom);
    let end = new Date(dateTo);
    if (this.auth.canEdit(this.userAuth)) {

      if (action === 'logs') {

        if (start && !end) {
          await Swal.fire({
            title: 'Ooops!',
            html: 'Please select date range type and dates (from date & to date)',
            allowEscapeKey: true,
            allowOutsideClick: true,
            showConfirmButton: false,
            showCancelButton: true,
            showCloseButton: true,
            cancelButtonText: 'Ok',
            icon: 'warning',
          });
          return
        }

        //check reports generated count settings
        // const rgcParse = JSON.parse(localStorage.getItem('rgc'));
        // const rgc = rgcParse?.v;
        // // console.log("rgc ==>", rgc)
        //
        // if(!this.auth.canDelete(this.userAuth)){
        //   if(rgc !== 0 && rgc > 3){
        //     await Swal.fire({
        //       title: 'Ooops!',
        //       html: 'You reached your limit generating reports. This plan is limited only to three (3) reports document per day. Come back again tomorrow. Thank you!',
        //       allowEscapeKey: true,
        //       allowOutsideClick: true,
        //       showConfirmButton: false,
        //       showCancelButton: true,
        //       showCloseButton: true,
        //       cancelButtonText: 'Ok',
        //       icon: 'warning',
        //     });
        //     return
        //   }
        // }

        // console.log(" this.dateTimeRangeLogs:",  this.dateTimeRangeLogs);


        let diff = moment(end).diff(start, 'day');
        // console.log(diff);

        //NOTE: hide for the moment
        // if (!this.auth.canDelete(this.userAuth)) {
        //   if (diff > 0) {
        //     await Swal.fire({
        //       title: 'Ooops!',
        //       html: 'You can only export one day or per day on this plan (Example: 12/1/2021 ~ 12/1/2021). Thank you. ',
        //       allowEscapeKey: true,
        //       allowOutsideClick: true,
        //       showConfirmButton: false,
        //       showCancelButton: true,
        //       showCloseButton: true,
        //       cancelButtonText: 'Ok',
        //       icon: 'warning',
        //     });
        //     return
        //   }
        // }

      }

      await Swal.fire({
        title: 'Generating reports... Please wait.',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        showCancelButton: false,
        showCloseButton: false,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
          //this.loadDataTable();
          if (type === "csv") {
            this.getLogsDataForCSV(start,end)
          } else if (type === "pdf") {
            this.getLogsDataForPdf(action,start,end);
          }
        }
      });

    } else {
      await Swal.fire({
        title: 'Ooops!',
        html: 'You have no rights to generate reports. Please contact your administrator. Thank you!',
        allowEscapeKey: true,
        allowOutsideClick: true,
        showConfirmButton: false,
        showCancelButton: true,
        showCloseButton: true,
        cancelButtonText: 'Ok',
        icon: 'warning',
      });
    }

  }

  private pushStringAtIndex(arr, index, str) {
    if (index < 0) {
      throw new Error("Invalid index provided.");
    }
    const diff = index - arr.length + 1;
    if (diff > 0) {
      arr.push(...Array(diff).fill(''));
    }

    arr.splice(index, 0, str);
  }

  private getTotalTransaction(dataSet: any): string {
    let reArrageCount:any[] = [];
    let grandTotal: number = 0;
    dataSet.headers.values.forEach((value) => {
      grandTotal += value.count;
      this.pushStringAtIndex(reArrageCount, value.indexHeaderLabel, value.count);
    });


    reArrageCount = reArrageCount.filter(item => item !== '');
    let stringCount = reArrageCount.join(',');
    return stringCount+','+ grandTotal;
  }

  private getTotalSales(dataSet: any): string{
    let reArrageSales:any[] = [];
    let grandTotal: number = 0;
    dataSet.headers.values.forEach((value) => {
      grandTotal += value.sales;
      this.pushStringAtIndex(reArrageSales, value.indexHeaderLabel, value.sales);
    });

    reArrageSales = reArrageSales.filter(item => item !== '');
    let stringSales = reArrageSales.join(',');
    return stringSales+','+ grandTotal;
  }


  private getGroupCsvRowsAverageParkingDuration(dataSet: any, numberOfVehicles:any): string {
    let reArrageAverageParkingDuration:any[] = [];
    dataSet.headers.values.forEach((value) => {
      let timeInHours = value.time_duration_minute / 60;
      let averageTimeInHours = (timeInHours / value.count).toFixed(2);
      this.pushStringAtIndex(reArrageAverageParkingDuration, value.indexHeaderLabel, averageTimeInHours);
    });

    reArrageAverageParkingDuration = reArrageAverageParkingDuration.filter(item => item !== '');

    let stringSales = reArrageAverageParkingDuration.join(',');
    return stringSales;
  }

  private getGroupRowAverageParkingOccupancy(dataSet:any){

    let occupancyPercentage = [];
    for(let occupancy of dataSet.timeOccupancyData){
      occupancyPercentage.push(occupancy.occupancyPercentage);
    }
    return occupancyPercentage.join(',');

  }

  private processTransactions(transactionRecords:any, type:string ){

    let totalTransaction :number = 0;

    let data:any[] = [];

    let headersLabels:any[] = [];

    let indexHeaderLabel:any;

    for(let key in transactionRecords){

      let name = transactionRecords[key].processedBy;

      if(headersLabels.length == 0){
        headersLabels.push(name);
      }else{
        let index = headersLabels.findIndex(hl=> hl == name);
        if(index == -1){
          headersLabels.push(name);
        }
      }

      indexHeaderLabel = headersLabels.findIndex(hl => hl == transactionRecords[key].processedBy);

      totalTransaction += transactionRecords[key].count;

      let value = {
        count: transactionRecords[key].count,
        sales : transactionRecords[key].total_payment,
        name: name,
        indexHeaderLabel: indexHeaderLabel,
        date: moment(transactionRecords[key].formattedDate).format("MMM DD YYYY"),
      }

      if(data.length == 0){
        data.push(
          {
            date: moment(transactionRecords[key].formattedDate).format("MMM DD YYYY"),
            headers:
              {
                values: [value]
              }

          }
        )

      }else{
        let indexDate = data.findIndex(d => d.date == moment(transactionRecords[key].formattedDate).format("MMM DD YYYY"))
        if(indexDate > -1){
          let indexName =  data[indexDate]['headers']['values'].findIndex(h => h.name == transactionRecords[key].name);
          if(indexName > -1){
            data[indexDate]['headers']['values'][indexName].count += transactionRecords[key].count;
            data[indexDate]['headers']['values'][indexName].sales += transactionRecords[key].total_payment;

          }else{
            data[indexDate]['headers']['values'].push(value)
          }
        }else{
          data.push(
            {
              date: moment(transactionRecords[key].formattedDate).format("MMM DD YYYY"),
              headers:
                {
                  values: [value]
                }
            }
          )
        }
      }
    }
    for(let unfilteredData of data){
      let values = unfilteredData['headers']['values'];

      for (let index = 0; index < headersLabels.length; index++) {
        const label = headersLabels[index];
        let labelIndex = values.findIndex(v=> v['name'].toLowerCase() == label.toLowerCase())
        if(labelIndex == -1){
          values.push({
            count: 0,
            sales : 0,
            name: label,
            indexHeaderLabel: index,
            date: unfilteredData.date,
          })
        }
      }



  }
    return {
      "totalTransaction": totalTransaction,
      "dataTransaction" : data,
      "headerTransaction": headersLabels
    };

  }

  private processSales(salesRecords:any,type:any){

    let totalTransaction :number = 0;
    let totalSales:number = 0;

    let data:any[] = [];

    let headersLabels:any[] = [];

    let indexHeaderLabel:any;

    for(let key in salesRecords){
      if(salesRecords[key].name != ""){
        let name = salesRecords[key].name;

        if(headersLabels.length == 0){
          headersLabels.push(name);
        }else{
          let index = headersLabels.findIndex(hl=> hl == name);
          if(index == -1){
            headersLabels.push(name);
          }
        }

        indexHeaderLabel = headersLabels.findIndex(hl => hl == salesRecords[key].name);

        totalTransaction += salesRecords[key].count;
        totalSales += salesRecords[key].total_payment_amount;

        let value = {
          count: salesRecords[key].count,
          sales : salesRecords[key].total_payment_amount,
          name: name,
          indexHeaderLabel: indexHeaderLabel,
          date: moment(salesRecords[key].formattedDate).format("MMM DD YYYY"),
        }

        if(data.length == 0){
          data.push(
            {
              date: moment(salesRecords[key].formattedDate).format("MMM DD YYYY"),
              headers:
                {
                  values: [value]
                }

            }
          )

        }else{
          let indexDate = data.findIndex(d => d.date == moment(salesRecords[key].formattedDate).format("MMM DD YYYY"))
          if(indexDate > -1){
            let indexName =  data[indexDate]['headers']['values'].findIndex(h => h.name == salesRecords[key].name);
            if(indexName > -1){
              data[indexDate]['headers']['values'][indexName].count += salesRecords[key].count;
              data[indexDate]['headers']['values'][indexName].sales += salesRecords[key].total_payment_amount;

            }else{
              data[indexDate]['headers']['values'].push(value)
            }
          }else{
            data.push(
              {
                date: moment(salesRecords[key].formattedDate).format("MMM DD YYYY"),
                headers:
                  {
                    values: [value]
                  }
              }
            )
          }
        }
      }

    }


    for(let unfilteredData of data){
      let values = unfilteredData['headers']['values'];

      for (let index = 0; index < headersLabels.length; index++) {
        const label = headersLabels[index];
        let labelIndex = values.findIndex(v=> v['name'].toLowerCase() == label.toLowerCase())
        if(labelIndex == -1){
          values.push({
            count: 0,
            sales : 0,
            name: label,
            indexHeaderLabel: index,
            date: unfilteredData.date,
          })
        }
      }
    }

    return {
      "totalSales": totalSales,
      "dataSales" : data,
      "headerSales": headersLabels
    };
  }

  private processVehicleTypeTransaction(vehicleTypeRecords:any){

    let totalVehicleTypeTransaction :number = 0;
    let totalSales:number = 0;

    let data:any[] = [];

    let headersLabels:any[] = [];

    let indexHeaderLabel:any;

    for(let key in vehicleTypeRecords){

      let name = vehicleTypeRecords[key].vehicle_type_custom_name;

      if(headersLabels.length == 0){
        headersLabels.push(name);
      }else{
        let index = headersLabels.findIndex(hl=> hl == name);
        if(index == -1){
          headersLabels.push(name);
        }
      }
      indexHeaderLabel = headersLabels.findIndex(hl => hl == vehicleTypeRecords[key].vehicle_type_custom_name);

      if(indexHeaderLabel > -1){

        totalVehicleTypeTransaction += vehicleTypeRecords[key].count;
        totalSales += vehicleTypeRecords[key].total_payment_amount;

        let value = {
          count: vehicleTypeRecords[key].count,
          sales: vehicleTypeRecords[key].total_payment_amount,
          name: name,
          indexHeaderLabel: indexHeaderLabel,
          date: moment(vehicleTypeRecords[key].formattedDate).format("MMM DD YYYY"),
        }

        if(data.length == 0){
          data.push(
            {
              date: moment(vehicleTypeRecords[key].formattedDate).format("MMM DD YYYY"),
              headers:
                {
                  values: [value]
                }

            }
          )

        }else{
          let indexDate = data.findIndex(d => d.date == moment(vehicleTypeRecords[key].formattedDate).format("MMM DD YYYY"))
          if(indexDate > -1){

            let indexName =  data[indexDate]['headers']['values'].findIndex(h => h.name == vehicleTypeRecords[key].vehicle_type_custom_name);

            if(indexName > -1){
              data[indexDate]['headers']['values'][indexName].count += vehicleTypeRecords[key].count;
              data[indexDate]['headers']['values'][indexName].sales += vehicleTypeRecords[key].total_payment_amount;

            }else{
              data[indexDate]['headers']['values'].push(value)
            }
          }else{
            data.push(
              {
                date: moment(vehicleTypeRecords[key].formattedDate).format("MMM DD YYYY"),
                headers:
                  {
                    values: [value]
                  }
              }
            )
          }
        }
      }
    }

    for(let unfilteredData of data){
      let values = unfilteredData['headers']['values'];

      for (let index = 0; index < headersLabels.length; index++) {
        const label = headersLabels[index];
        let labelIndex = values.findIndex(v=> v['name'].toLowerCase() == label.toLowerCase())
        if(labelIndex == -1){
          values.push({
            count: 0,
            sales : 0,
            name: label,
            indexHeaderLabel: index,
            date: unfilteredData.date,
          })
        }
      }

  }
    return {
      "totalVehicleTypeTransaction": totalVehicleTypeTransaction,
      "dataVehicleTypeTransaction" : data,
      "headersLabel": headersLabels
    };


  }

  private processAverageDurationPerTransactionType(averageDurationPerTransactionTypeRecords:any){


    let totalAverageDurationPerTransactionType :number = 0;

    let data:any[] = [];

    let headersLabels:any[] = [];

    let indexHeaderLabel:any;

    for(let key in averageDurationPerTransactionTypeRecords){

      let name = averageDurationPerTransactionTypeRecords[key].vehicle_type_custom_name;

      if(headersLabels.length == 0){
        headersLabels.push(name);
      }else{
        let index = headersLabels.findIndex(hl=> hl == name);
        if(index == -1){
          headersLabels.push(name);
        }
      }

      indexHeaderLabel = headersLabels.findIndex(hl => hl == averageDurationPerTransactionTypeRecords[key].vehicle_type_custom_name);

      totalAverageDurationPerTransactionType += averageDurationPerTransactionTypeRecords[key].count;

      let value = {
        count: averageDurationPerTransactionTypeRecords[key].count,
        time_duration_minute : averageDurationPerTransactionTypeRecords[key].time_duration_minute,
        name: name,
        indexHeaderLabel: indexHeaderLabel,
        date: moment(averageDurationPerTransactionTypeRecords[key].formattedDate).format("MMM DD YYYY"),
      }

      if(data.length == 0){
        data.push(
          {
            date: moment(averageDurationPerTransactionTypeRecords[key].formattedDate).format("MMM DD YYYY"),
            headers:
              {
                values: [value]
              }

          }
        )

      }else{
        let indexDate = data.findIndex(d => d.date == moment(averageDurationPerTransactionTypeRecords[key].formattedDate).format("MMM DD YYYY"))
        if(indexDate > -1){
          let indexName =  data[indexDate]['headers']['values'].findIndex(h => h.name == averageDurationPerTransactionTypeRecords[key].name);
          if(indexName > -1){
            data[indexDate]['headers']['values'][indexName].count += averageDurationPerTransactionTypeRecords[key].count;
            data[indexDate]['headers']['values'][indexName].time_duration_minute += averageDurationPerTransactionTypeRecords[key].time_duration_minute;

          }else{
            data[indexDate]['headers']['values'].push(value)
          }
        }else{
          data.push(
            {
              date: moment(averageDurationPerTransactionTypeRecords[key].formattedDate).format("MMM DD YYYY"),
              headers:
                {
                  values: [value]
                }
            }
          )
        }
      }
    }
    for(let unfilteredData of data){
      let values = unfilteredData['headers']['values'];

      for (let index = 0; index < headersLabels.length; index++) {
        const label = headersLabels[index];
        let labelIndex = values.findIndex(v=> v['name'].toLowerCase() == label.toLowerCase())
        if(labelIndex == -1){
          values.push({
            count: 0,
            time_duration_minute : 0,
            name: label,
            indexHeaderLabel: index,
            date: unfilteredData.date,
          })
        }
      }



  }
    return {
      "totalAverageDurationPerTransactionType": totalAverageDurationPerTransactionType,
      "dataTotalAverageDurationPerTransactionType" : data,
      "headersLabel": headersLabels
    };


  }

  async calculate(logs:any, type: string) {

    let transactionRecords:any[] = [];
    let salesRecords:any[] = [];
    let transactionVehicleTypeRecords:any[] = [];
    let averageDurationPerTransactionTypeRecords :any[] = [];

    let transactionData:any
    let salesData:any;
    let transactionVehicleTypeData: any;
    let averageDurationPerTransactionTypeData: any;
    for (const entry of logs) {
      // if(entry.initial_payment != ""){
        const { updatedAt,
                total_payment,
                initial_payment,
                initial_payment_recieved_by_id,
                initial_payment_recieved_by,
                additional_payment,
                additional_payment_recieved_by,
                additional_payment_recieved_by_id,
                vehicle_type_custom_name,
                time_duration_hour,
                time_duration_minute,
                processedBy,
                plate_number,
                fullDateTimeStamp,
                paidBy_id,
                processedBy_id,
                paid
              } = entry;

        switch (type) {
          case 'dsa':

              //For Transaction Data
              if(processedBy !== ""){

                let formattedDate = moment(fullDateTimeStamp).format('MM/DD/YYYY')
                let nameDateIndex = transactionRecords.
                findIndex(r => r.formattedDate == moment(entry.fullDateTimeStamp).format('MM/DD/YYYY')
                &&  r.processedBy == entry.processedBy)

                if ( transactionRecords.length > 0) {
                  if(nameDateIndex > -1){
                    transactionRecords[nameDateIndex].total_payment += total_payment;
                    transactionRecords[nameDateIndex].count += 1;
                  }else{
                    transactionRecords.push({
                      formattedDate,
                      total_payment,
                      count: 1,
                      initial_payment_recieved_by_id,
                      initial_payment_recieved_by,
                      vehicle_type_custom_name,
                      time_duration_hour,
                      time_duration_minute,
                      processedBy,
                      fullDateTimeStamp,
                      additional_payment_recieved_by_id,
                      paidBy_id,
                      processedBy_id
                    });
                  }

                } else {
                  transactionRecords.push({
                    formattedDate,
                    total_payment,
                    count: 1,
                    initial_payment_recieved_by_id,
                    initial_payment_recieved_by,
                    vehicle_type_custom_name,
                    time_duration_hour,
                    time_duration_minute,
                    processedBy,
                    fullDateTimeStamp,
                    additional_payment_recieved_by_id,
                    paidBy_id,
                    processedBy_id
                  });
                }

              }

              //For Sales Data
              let formattedDateSales = moment(fullDateTimeStamp).format('MM/DD/YYYY')

              if (salesRecords.length > 0) {

                if(initial_payment_recieved_by != additional_payment_recieved_by && additional_payment_recieved_by == ""){
                  let initialPaymentRecievedbyOnlyIndex = salesRecords.findIndex(r => r.formattedDate == moment(entry.fullDateTimeStamp).format('MM/DD/YYYY') && entry.initial_payment_recieved_by == r.name);
                  if(initialPaymentRecievedbyOnlyIndex > -1){
                    salesRecords[initialPaymentRecievedbyOnlyIndex].total_payment_amount += initial_payment;
                    salesRecords[initialPaymentRecievedbyOnlyIndex].count += 1;
                  }else{
                    salesRecords.push({
                      formattedDate: formattedDateSales,
                      total_payment_amount: initial_payment,
                      count: 1,
                      name: initial_payment_recieved_by,
                      });
                  }
                }

                if(initial_payment_recieved_by == additional_payment_recieved_by){

                  let sameUserIndex = salesRecords.
                  findIndex(r => r.formattedDate == moment(entry.fullDateTimeStamp).format('MM/DD/YYYY') && entry.initial_payment_recieved_by == r.name);
                  if(sameUserIndex > -1){
                    salesRecords[sameUserIndex].total_payment_amount += total_payment;
                    salesRecords[sameUserIndex].count += 1;
                  }else{
                    salesRecords.push({
                      formattedDate: formattedDateSales,
                      total_payment_amount: initial_payment + additional_payment,
                      count: 1,
                      name: initial_payment_recieved_by,
                    });
                  }

                }

                if(initial_payment_recieved_by != additional_payment_recieved_by && additional_payment_recieved_by != ""){

                  //INITAIL
                  let initialUserIndex = salesRecords.findIndex(r => r.formattedDate == moment(entry.fullDateTimeStamp).format('MM/DD/YYYY') && entry.initial_payment_recieved_by == r.name);
                  if(initialUserIndex > -1){
                    salesRecords[initialUserIndex].total_payment_amount += initial_payment;
                    salesRecords[initialUserIndex].count += 1;
                  }else{
                    salesRecords.push({
                      formattedDate: formattedDateSales,
                      total_payment_amount: initial_payment,
                      count: 1,
                      name: initial_payment_recieved_by,
                      });
                  }


                  //ADDITONAL
                  let additionalUserIndex = salesRecords.findIndex(r => r.formattedDate == moment(entry.fullDateTimeStamp).format('MM/DD/YYYY') && entry.additional_payment_recieved_by == r.name);
                  if(additionalUserIndex > -1){
                    salesRecords[additionalUserIndex].total_payment_amount += additional_payment;
                    salesRecords[additionalUserIndex].count += 1;
                  }else{
                    salesRecords.push({
                      formattedDate: formattedDateSales,
                      total_payment_amount: additional_payment,
                      count: 1,
                      name: additional_payment_recieved_by,
                    });
                  }
                }

              } else {

                if(initial_payment_recieved_by != additional_payment_recieved_by && additional_payment_recieved_by == ""){

                  salesRecords.push({
                    formattedDate: formattedDateSales,
                    total_payment_amount: initial_payment,
                    count: 1,
                    name: initial_payment_recieved_by,
                    });
                }

                if(initial_payment_recieved_by == additional_payment_recieved_by){
                  salesRecords.push({
                    formattedDate: formattedDateSales,
                    total_payment_amount: total_payment,
                    count: 1,
                    name: initial_payment_recieved_by,
                  });
                }

                if(initial_payment_recieved_by != additional_payment_recieved_by && additional_payment_recieved_by != ""){
                  //INITIAL
                  salesRecords.push({
                    formattedDate: formattedDateSales,
                    total_payment_amount: initial_payment,
                    count: 1,
                    name: initial_payment_recieved_by,
                    });

                  //ADDITIONAL
                  salesRecords.push({
                    formattedDate: formattedDateSales,
                    total_payment_amount: additional_payment,
                    count: 1,
                    name: additional_payment_recieved_by,
                  });
                }

              }
            break;
          case 'dstt':

            let formattedDate = moment(fullDateTimeStamp).format('MM/DD/YYYY')

              let sanitizeVehicleName = entry.vehicle_type_custom_name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();

              let nameDateIndex:any;
              nameDateIndex = transactionVehicleTypeRecords.findIndex(r => r.formattedDate == moment(entry.fullDateTimeStamp).format('MM/DD/YYYY')
              &&  r.vehicle_type_custom_name == entry.sanitizeVehicleName)

              if(transactionVehicleTypeRecords.length > 0){
                if(nameDateIndex > -1){
                  transactionVehicleTypeRecords[nameDateIndex].count += 1;
                  transactionVehicleTypeRecords[nameDateIndex].total_payment_amount += additional_payment + initial_payment;
                }else{
                  transactionVehicleTypeRecords.push({ formattedDate, total_payment_amount : additional_payment + initial_payment, count: 1, initial_payment_recieved_by_id, initial_payment_recieved_by, additional_payment, vehicle_type_custom_name: sanitizeVehicleName, time_duration_hour, time_duration_minute, processedBy, fullDateTimeStamp });
                }
              } else {
                transactionVehicleTypeRecords.push({ formattedDate, total_payment_amount : additional_payment + initial_payment , count: 1, initial_payment_recieved_by_id, initial_payment_recieved_by, additional_payment, vehicle_type_custom_name: sanitizeVehicleName, time_duration_hour, time_duration_minute,  processedBy, fullDateTimeStamp });
              }
              break;
          case 'apdtt':
                if(processedBy !== ""){

                  let formattedDate = moment(fullDateTimeStamp).format('MM/DD/YYYY')
                  let nameDateIndex = averageDurationPerTransactionTypeRecords.
                  findIndex(r => r.formattedDate == moment(entry.fullDateTimeStamp).format('MM/DD/YYYY')
                  &&  r.vehicle_type_custom_name == entry.vehicle_type_custom_name)

                  if ( averageDurationPerTransactionTypeRecords.length > 0) {
                    if(nameDateIndex > -1){
                      let hoursInMinute = time_duration_hour * 60;
                      let totalMinutes =  hoursInMinute + time_duration_minute
                      averageDurationPerTransactionTypeRecords[nameDateIndex].count += 1;
                      averageDurationPerTransactionTypeRecords[nameDateIndex].time_duration_minute += totalMinutes;
                    }else{

                      let hoursInMinute = time_duration_hour * 60;
                      let totalMinutes =  hoursInMinute + time_duration_minute

                      let initialObjt = {
                        formattedDate,
                        total_payment,
                        count: 1,
                        initial_payment_recieved_by_id,
                        initial_payment_recieved_by,
                        vehicle_type_custom_name,
                        time_duration_hour,
                        time_duration_minute: totalMinutes,
                        processedBy,
                        fullDateTimeStamp
                      }

                      averageDurationPerTransactionTypeRecords.push(initialObjt);
                    }

                  } else {
                    let hoursInMinute = time_duration_hour * 60;
                    let totalMinutes =  hoursInMinute + time_duration_minute

                    let initialObjt = {
                      formattedDate,
                      total_payment,
                      count: 1,
                      initial_payment_recieved_by_id,
                      initial_payment_recieved_by,
                      vehicle_type_custom_name,
                      time_duration_hour,
                      time_duration_minute: totalMinutes,
                      processedBy,
                      fullDateTimeStamp
                    }

                    averageDurationPerTransactionTypeRecords.push(initialObjt);
                  }

                }
          break;
          default:
            break;
        }
      // }
    }

    switch (type) {
      case 'dsa':
          transactionData = this.processTransactions(transactionRecords,type);
          salesData = this.processSales(salesRecords,type)
        break;
      case 'dstt':
          transactionVehicleTypeData = this.processVehicleTypeTransaction(transactionVehicleTypeRecords)
        break;
        case 'apdtt':
          averageDurationPerTransactionTypeData = this.processAverageDurationPerTransactionType(averageDurationPerTransactionTypeRecords)
        break;
      default:
        break;
    }

    return {
      transaction : transactionData,
      sales:salesData,
      vehicleTypeTransaction: transactionVehicleTypeData,
      averageDurationPerTransactionType: averageDurationPerTransactionTypeData,
    };

  }

  async filterOccupancyLogsPerDate(logs:any){

    let records:any[] = [];

    for(var log of logs){

      let formattedDate = moment(log.updatedAt).format('MMM DD YYYY')

      if(records.length == 0){
        records.push({
          date: formattedDate,
          vehichelActivity : [log],
          vehicleEntry: [],
          vehicleExit: [],
          timeOccupancyData: []
        })
      }else{
        let indexByDate = records.findIndex(r=> r.date == formattedDate);
        if(indexByDate > -1 && records[indexByDate].date == formattedDate){
          records[indexByDate]['vehichelActivity'].push(log);
        }else{
          records.push({
            date: formattedDate,
            vehichelActivity : [log],
            vehicleEntry: [],
            vehicleExit: [],
            timeOccupancyData: []
          })
        }

      }

    }

    return records;

  }

  async setOccupancyLogPerTimeOfDate(records:any){

    for(let recordLogs of records){

      for(let log of recordLogs.vehichelActivity){

        let formattedDate = moment(log.updatedAt).format('MMM DD YYYY');

        if(formattedDate == recordLogs.date){

          let entry = (log.entry_time?.seconds * 1000);

          let dateTimeInHour = moment(entry).format('hh');
          let dateTimeInMeridiem = moment(entry).format('A');
          let timeIn = dateTimeInHour+':00 '+dateTimeInMeridiem;

          if(recordLogs.vehicleEntry.length == 0 ){
            recordLogs.vehicleEntry.push({
              time: timeIn,
              entry: 1,
              activeSession: 0,
              occupancyPercentage: ''

            })
          }else{
            let indexVeicleEntry = recordLogs.vehicleEntry.findIndex(rv=> rv.time == timeIn)
            if(indexVeicleEntry > -1){
              recordLogs.vehicleEntry[indexVeicleEntry].entry += 1;
            }else{
              recordLogs.vehicleEntry.push({
                time: timeIn,
                entry: 1,
                activeSession: 0,
                occupancyPercentage: ''

              })
            }
          }


          let exit = (log.exit_time?.seconds * 1000);

          let dateTimeOutHour = moment(exit).format('hh');
          let dateTimeOutMeridiem = moment(exit).format('A');
          let timeOut = dateTimeOutHour+':00 '+dateTimeOutMeridiem;

          if(recordLogs.vehicleExit.length == 0 ){
            recordLogs.vehicleExit.push({
              time: timeOut,
              exit: 1,
              activeSession: 0,
              occupancyPercentage: ''

            })
          }else{
            let indexVeicleExit = recordLogs.vehicleExit.findIndex(rv=> rv.time == timeOut)
            if(indexVeicleExit > -1){
              recordLogs.vehicleExit[indexVeicleExit].exit += 1;
            }else{
              recordLogs.vehicleExit.push({
                time: timeOut,
                exit: 1,
                activeSession: 0,
                occupancyPercentage: ''

              })
            }
          }

        }
      }

      recordLogs.timeOccupancyData = await this.mergeDateTimeOccupancyData(recordLogs.vehicleEntry, recordLogs.vehicleExit);

    }


    return {
      filteredData : records,
      headersLabel:TemplateOccupancyTimeHeader,
    };

  }

  async mergeDateTimeOccupancyData (entry:any, exit:any){

    let headerLabels:any =  TemplateOccupancyTimeHeader;


    for(let template of TemplateOccupancyTimeRage){
      let indexEntry = entry.findIndex(e=> e.time == template.time);
      if(indexEntry == -1){
        entry.push({
          time: template.time,
          entry: 0,
          activeSession: 0,
          occupancyPercentage: ''

        })
      }

      let indexExit = exit.findIndex(e=> e.time == template.time);
      if(indexExit == -1){
        exit.push({
          time: template.time,
          exit: 0,
          activeSession: 0,
          occupancyPercentage: ''

        })
      }
    }

     let mergeDateTimeOccupancyDataResult = entry.map(item1 => {
      const correspondingItem = exit.find(item2 => item2.time === item1.time);
      if (correspondingItem) {
        return { ...item1, ...correspondingItem };
      }
      return item1;
    });

      // Sort the merged array by time in order
      mergeDateTimeOccupancyDataResult.sort((a, b) => {
        const timeA = new Date(`2023-01-01 ${a.time}`).getTime();
        const timeB = new Date(`2023-01-01 ${b.time}`).getTime();
        return timeA - timeB;
      });

      for (let index = 0; index < headerLabels.length; index++) {
        const label = headerLabels[index];
        let mergeDateTimeOccupancyDataResultIndex =  mergeDateTimeOccupancyDataResult.findIndex(vAPT => vAPT.time == label);

        if(mergeDateTimeOccupancyDataResultIndex > -1){
            let entryCount = mergeDateTimeOccupancyDataResult[mergeDateTimeOccupancyDataResultIndex].entry;
            let exitCount = mergeDateTimeOccupancyDataResult[mergeDateTimeOccupancyDataResultIndex].exit;

              if(index == 0){
                mergeDateTimeOccupancyDataResult[mergeDateTimeOccupancyDataResultIndex].activeSession =  entryCount - exitCount;
              }else{
                let previousTimeIndex = mergeDateTimeOccupancyDataResultIndex - 1;
                let totalVehicleEntered =  mergeDateTimeOccupancyDataResult[previousTimeIndex].activeSession + entryCount;
                mergeDateTimeOccupancyDataResult[mergeDateTimeOccupancyDataResultIndex].activeSession =  totalVehicleEntered - exitCount;
              }

              let occupancyPercentage = (mergeDateTimeOccupancyDataResult[mergeDateTimeOccupancyDataResultIndex].activeSession / this.selectedZone.zone_slots) * 100;
              mergeDateTimeOccupancyDataResult[mergeDateTimeOccupancyDataResultIndex].occupancyPercentage = occupancyPercentage.toFixed(2).toString()+'%';
            }


      }

      return mergeDateTimeOccupancyDataResult;
  }

  async getLogsDataForCSV(start, end) {

    let logs:any;

    start.setHours(0, 0, 0, 0);
    end.setHours(23, 59, 59, 999);

    if(this.selectedReportType == 'general'){
        logs = await firebase.firestore().collection('logs')
        .where('account_key', '==', this.account.account_key)
        .where('time_in', '>=', start)
        .where('time_in', '<=', end).get();
    }else if(this.selectedReportType != 'general' && this.selectedZone != undefined){
      logs = await firebase.firestore().collection('logs')
      .where('account_key', '==', this.account.account_key)
      .where('zone_id','==', this.selectedZone.zone_id)
      .where('time_in', '>=', start)
      .where('time_in', '<=', end).get();
    }

    if(logs.empty) {
      await Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'No data found.',
      })
      return
    }

    let dataLogs;
    let arrayLogs = [];

    let logsMonitoring = {
      account_key: this.account.account_key,
      timestamp : new Date(),
      total_count : logs.docs.length,
      type :  this.selectedReportType + ' - csv' ,
      user_id : this.userAuth.uid
    }

    await this.logsService.addLogsMonitoring(logsMonitoring);

    for (let log of logs.docs) {

      let duration;
      let parkingFee;
      let time_duration_hour;
      let time_duration_minute;
      let time_entry_date_format;
      let time_exit_date_format;

      dataLogs = log.data();

      if(this.employeeSelected !== '' && this.employeeSelected !== 'all'){

        if(dataLogs.additional_payment_recieved_by_id !== this.employeeSelected &&
          dataLogs.initial_payment_recieved_by_id !== this.employeeSelected &&
          dataLogs.paidBy_id !== this.employeeSelected
          ){
            continue;
          }

      }

      if(dataLogs.initial_payment_recieved_date != null){
        let initailPaymentseconds = (dataLogs.initial_payment_recieved_date.seconds * 1000);
        dataLogs.initial_payment_recieved_date = moment(initailPaymentseconds).format("MM/DD/YYYY h:mm A");
      }else{
        dataLogs.initial_payment_recieved_date = "";
      }

      if(dataLogs.additional_payment_recieved_date != null){
        let initailPaymentseconds = (dataLogs.additional_payment_recieved_date.seconds * 1000);
        dataLogs.additional_payment_recieved_date = moment(initailPaymentseconds).format("MM/DD/YYYY h:mm A");
      }else{
        dataLogs.additional_payment_recieved_date = "";
      }

      let fullDateTimeStamp = new Date(dataLogs.time_in.seconds * 1000)

      let timeIn = (dataLogs.time_in.seconds * 1000);
      let dateTimeIn = moment(timeIn).format('MM/DD/YYYY hh:mm A'); //full format: L hh:mm A
      let dateTimeOut = "";

      if (dataLogs.time_out) {

        if(dataLogs.actual_end_time == undefined){
          let timeOut = (dataLogs.time_out.seconds * 1000);
          dateTimeOut = moment(timeOut).format('MM/DD/YYYY hh:mm A'); //full format: L hh:mm A
        }else{
          let actualEndTime = (dataLogs.actual_end_time.seconds * 1000);
          dateTimeOut = moment(actualEndTime).format('MM/DD/YYYY hh:mm A'); //full format: L hh:mm A
        }

      } else {
        dateTimeOut = "incomplete";

      }

      if (dataLogs.time_in.seconds && dataLogs.time_out?.seconds) {

        time_entry_date_format = dataLogs.time_in;

        if(dataLogs.actual_end_time == undefined){
          time_exit_date_format = dataLogs.time_out;
        }else{
          time_exit_date_format = dataLogs.actual_end_time;
        }

        let startTime = moment(moment.unix(dataLogs.time_in?.seconds), "HH:mm:ss a");

        let endTime :any;
        if(dataLogs.actual_end_time == undefined){
          endTime = moment(moment.unix(dataLogs.time_out?.seconds), "HH:mm:ss a");
        }else{
          endTime = moment(moment.unix(dataLogs.actual_end_time?.seconds), "HH:mm:ss a");
        }

        let min = moment.utc(moment(endTime, "HH:mm:ss").diff(moment(startTime, "HH:mm:ss"))).format("mm");
        let sec = moment.utc(moment(endTime, "HH:mm:ss").diff(moment(startTime, "HH:mm:ss"))).format("ss");
        let hour = endTime.diff(startTime, 'hours');

        let hourString = hour + "hr ";
        let minString = min + "min ";
        //let secString = sec + " and sec";

        if (hour === 0 && min !== "00") {
          duration = minString //+ secString;
        } else if (hour === 0 && min === "00") {
          duration = sec + " seconds";
        } else {
          duration = hourString + minString //+ secString;
        }

        time_duration_hour = hour;
        time_duration_minute = parseInt(min);

        if (dataLogs.amount_paid && dataLogs.amount_paid !== 0) {
          parkingFee = parseFloat(dataLogs.amount_paid);
        } else {
          parkingFee = 0;
        }

      } else {

        duration = "incomplete";

        if (dataLogs.amount_paid && dataLogs.amount_paid !== 0) {
          parkingFee = parseFloat(dataLogs.amount_paid);
        } else {
          parkingFee = 0;
        }

      }


      if (!dataLogs.amount_due) {
        dataLogs.amount_due = 0;
      }

      if (!dataLogs.zone_name) {
        if (dataLogs.station_name) {
          dataLogs.zone_name = dataLogs.station_name;
        } else {
          dataLogs.zone_name = "";
        }
      }

      if (dataLogs.processedBy == undefined) {
        dataLogs.processedBy = "";
      }else{
        dataLogs.processedBy = dataLogs.processedBy;
      }

      if (dataLogs.end_session_by == undefined) {
        dataLogs.end_session_by = "";
      }else{
        dataLogs.end_session_by = dataLogs.end_session_by;
      }


      if(dataLogs.additional_payment_recieved_by == undefined){
        dataLogs.additional_payment_recieved_by = "";
      }

      if(dataLogs.initial_payment_recieved_by == undefined){
        dataLogs.initial_payment_recieved_by = "";
      }

      if(dataLogs.paid){

        if(this.selectedReportType  == 'general'){
            dataLogs.total_payment =  dataLogs.initial_payment + dataLogs.additional_payment;
        }else{
          if(this.employeeSelected == 'all'){
            if(dataLogs.additional_payment_recieved_by_id == dataLogs.initial_payment_recieved_by_id){
              dataLogs.total_payment =  dataLogs.initial_payment + dataLogs.additional_payment;
            }else{
              dataLogs.total_payment =  dataLogs.initial_payment;
            }
          }else{
            if(dataLogs.additional_payment_recieved_by_id == this.employeeSelected){
              dataLogs.total_payment =  dataLogs.initial_payment + dataLogs.additional_payment;
            }else{
              dataLogs.total_payment =  dataLogs.initial_payment;
            }
          }
        }

      }else{

        if(dataLogs.initial_payment_recieved_by != ""){
          dataLogs.total_payment = dataLogs.initial_payment;
        }
        if(dataLogs.initial_payment == 0){
          dataLogs.total_payment = 0;
        }
      }

      if(dataLogs.total_payment == undefined){
        dataLogs.total_payment = 0;
      }

      dataLogs.fullDateTimeStamp = moment(fullDateTimeStamp).format("MM/DD/YYYY h:mm A");;
      dataLogs.timestamp = dateTimeIn;
      dataLogs.time_in = dateTimeIn;
      dataLogs.time_out = dateTimeOut;
      dataLogs.entry_time = time_entry_date_format;
      dataLogs.exit_time = time_exit_date_format

      dataLogs.time_duration_hour = time_duration_hour;
      dataLogs.time_duration_minute = time_duration_minute;
      dataLogs.duration = duration;
      dataLogs.parkingFee = parkingFee;


      if(dataLogs.updatedAt !== undefined){
        dataLogs.updatedAt = new Date(dataLogs.updatedAt.seconds * 1000);
      }

      if (!dataLogs.plate_number) {
        dataLogs.plate_number = dataLogs.code;
      }

      dataLogs.transaction_type_name = '';
      switch(dataLogs.transaction_type){
        case 0:
          dataLogs.transaction_type_name = 'Regular';
          break;
        case 1:
          dataLogs.transaction_type_name = 'Valet (Guest)';

          break;
        case 2:
          dataLogs.transaction_type_name = 'Doctor (Paid)';

          break;
        case 3:
          dataLogs.transaction_type_name = 'Doctor (Free)';
          break;
      }

      //console.log(dataLogs);

      arrayLogs.push(dataLogs);

    }


    await this.filterByEmployee(arrayLogs);

    Promise.all([arrayLogs]).then((response) => {
      this.timeInData = arrayLogs;
      let newArray = [];
      // Iterate through the source array
      for (let i = 0; i < this.timeInData.length; i++) {

        if(this.selectedReportType == 'general'){
          // Extract fields from the source object
          const { fullDateTimeStamp, zone_name ,slot_name ,plate_number, vehicle_type_custom_name, transaction_type_name, time_in, processedBy, time_out, end_session_by, duration, total_payment, initial_payment, initial_payment_recieved_by, initial_payment_recieved_date, additional_payment, additional_payment_recieved_by, additional_payment_recieved_date } = this.timeInData[i];

          // Create a new object with only fields
          const newObject = { fullDateTimeStamp,  zone_name, slot_name, plate_number, vehicle_type_custom_name , transaction_type_name, time_in, processedBy, time_out, end_session_by, duration, total_payment, initial_payment, initial_payment_recieved_by, initial_payment_recieved_date, additional_payment, additional_payment_recieved_by, additional_payment_recieved_date };

          // Push the new object into the target array
          newArray.push(newObject);


        }else{

          // Extract fields from the source object
          const {
            fullDateTimeStamp,
            zone_name,
            slot_name,
            plate_number,
            vehicle_type_custom_name,
            transaction_type_name,
            time_in,
            processedBy,
            time_out,
            end_session_by,
            duration,
            total_payment,
            initial_payment,
            initial_payment_recieved_by,
            initial_payment_recieved_date,
            additional_payment,
            additional_payment_recieved_by,
            additional_payment_recieved_date,
            updatedAt,
            time_duration_hour,
            time_duration_minute,
            entry_time,
            exit_time,
            paid

          } = this.timeInData[i];

          // Create a new object with only fields
          const newObject = {
            fullDateTimeStamp,
            zone_name,
            slot_name,
            plate_number,
            vehicle_type_custom_name,
            transaction_type_name,
            time_in,
            processedBy,
            time_out,
            end_session_by,
            duration,
            total_payment,
            initial_payment,
            initial_payment_recieved_by,
            initial_payment_recieved_date,
            additional_payment,
            additional_payment_recieved_by,
            additional_payment_recieved_date,
            updatedAt,
            time_duration_hour,
            time_duration_minute,
            entry_time,
            exit_time,
            paid
          };

          // Push the new object into the target array
          newArray.push(newObject);
        }

        console.log("logs", newArray)

      }

      // console.log("new array", newArray);
      this.generateCsv(newArray,start, end);

    }).catch((e) => {
      //Handle errors
    });

  }

  async generateCsv(logs,from, to) {
    let csvStringTransaction:any;
    let csvStringSales:any;
    let csvStringVehicleTypeTransaction:any;
    let csvStringVehicleTypeTransactionSales:any;

    let csvStringAverageParkingDuration:any;
    let csvStringAverageParkingOccupancy:any;

    let csvDataStrings:any;
    let sheetNames:any;

    let employeeFilterStr = "Employee: All\n";
    if(this.employeeSelected != 'all'){
      let employee = this.employees.find(emp => emp.employee_id === this.employeeSelected);
      employeeFilterStr =  'Employee: '+ employee.first_name + " "+ employee.last_name+'\n';
    }

    if(this.selectedReportType  != 'general'){
      let data:any;

      let start = moment(from).format("MM-DD-YYYY");
      let end  = moment(to).format("MM-DD-YYYY");

      let reportName = '';
      switch (this.selectedReportType) {
        case 'dsa':
            reportName = 'Daily Summary per Attendant';
            data = await this.calculate(logs, 'dsa');
          break;
        case 'dstt':
            reportName = 'Daily Summary per Transaction Type';
            data = await this.calculate(logs, 'dstt');
          break;
        case 'apdtt':
            reportName = 'Average Parking Durationper Transaction Type';
            data = await this.calculate(logs,'apdtt');
            break;
        case 'apo':
            reportName = 'Average Parking Occupancy';
            let logsPerDate = await this.filterOccupancyLogsPerDate(logs);
            data = await this.setOccupancyLogPerTimeOfDate(logsPerDate);
         break;
      }

      let employeeFilterStr = "Employee: All\n";
      if(this.employeeSelected != 'all'){
        let employee = this.employees.find(emp => emp.employee_id === this.employeeSelected);
        employeeFilterStr =  'Employee: '+ employee.first_name + " "+ employee.last_name+'\n';
      }

      switch (this.selectedReportType) {
        case 'dsa':

          let transaction = data.transaction;
          let sales = data.sales;

          csvStringTransaction =
            this.account.account_name+' \n'+
            'Parking Zone: '+ this.selectedZone.zone_name+ '\n'+
            employeeFilterStr+
            'Period Covered : '+ start + ' to '+ end +' \n'+
            'Report Type: '+reportName+' \n\n'+
            'Total Transaction:  '+transaction.totalTransaction+'\n';

            csvStringTransaction += 'Date,'+transaction.headerTransaction.join(',')+',Grand Total\n';
            // csvStringTransaction += 'Total Transaction Count per Attendant \n'
            transaction.dataTransaction.forEach((dataSet) => {

              csvStringTransaction += dataSet.date+','+this.getTotalTransaction(dataSet);
              csvStringTransaction += '\n'
            });
            csvStringTransaction += '\n\n';


            csvStringSales =
             this.account.account_name+' \n'+
            'Parking Zone: '+ this.selectedZone.zone_name+ '\n'+
            employeeFilterStr+
            'Period Covered : '+ start + ' to '+ end +' \n'+
            'Report Type: '+reportName+' \n\n'+
            'Total Sales:  '+sales.totalSales+'\n\n';


            csvStringSales += 'Date,'+sales.headerSales.join(',')+',Grand Total\n';
            // csvStringSales += 'Total Sales per Attendant \n'
            sales.dataSales.forEach((dataSet) => {
              csvStringSales += dataSet.date+','+this.getTotalSales(dataSet);
              csvStringSales += '\n'
            });
            csvStringSales += '\n\n';

            csvDataStrings =  [csvStringTransaction, csvStringSales];
            sheetNames = ['Transaction', 'Sales'];

          break;
        case 'dstt':

          let vehicleTypeTransaction = data.vehicleTypeTransaction;

          csvStringVehicleTypeTransaction =
          this.account.account_name+' \n'+
          'Parking Zone: '+ this.selectedZone.zone_name+ '\n'+
          employeeFilterStr+
          'Period Covered : '+ start + ' to '+ end +' \n'+
          'Report Type: '+reportName+' \n\n'+

          'Total Transaction Count Per Vehicle Type \n';


          csvStringVehicleTypeTransaction += 'Date,'+vehicleTypeTransaction.headersLabel.join(',')+',Total\n';

          vehicleTypeTransaction.dataVehicleTypeTransaction.forEach((dataSet) => {
            csvStringVehicleTypeTransaction += dataSet.date+','+this.getTotalTransaction(dataSet);
            csvStringVehicleTypeTransaction += '\n'
          });
          csvStringVehicleTypeTransaction += '\n\n';


          csvStringVehicleTypeTransactionSales =
          this.account.account_name+' \n'+
          'Parking Zone: '+ this.selectedZone.zone_name+ '\n'+
          employeeFilterStr+
          'Period Covered : '+ start + ' to '+ end +' \n'+
          'Report Type: '+reportName+' \n\n'+

          'Total Sales Per Vehicle Type\n';

          csvStringVehicleTypeTransactionSales += 'Date,'+vehicleTypeTransaction.headersLabel.join(',')+',Total\n';

          vehicleTypeTransaction.dataVehicleTypeTransaction.forEach((dataSet) => {
            csvStringVehicleTypeTransactionSales += dataSet.date+','+this.getTotalSales(dataSet);
            csvStringVehicleTypeTransactionSales += '\n'
          });
          csvStringVehicleTypeTransactionSales += '\n\n';

          csvDataStrings = [csvStringVehicleTypeTransaction, csvStringVehicleTypeTransactionSales];
          sheetNames = ['Summary per Transaction Type', 'Summary per Transaction Type Sales'];

          break;
        case 'apdtt':
          let averageDurationPerTransaction = data.averageDurationPerTransactionType;
          csvStringAverageParkingDuration =
           this.account.account_name+' \n'+
          'Parking Zone: '+ this.selectedZone.zone_name+ '\n'+
          employeeFilterStr+
          'Period Covered : '+ start + ' to '+ end +' \n'+
          'Report Type: '+reportName+' \n\n';

          csvStringAverageParkingDuration += 'Date,'+averageDurationPerTransaction.headersLabel.join(',')+'\n';

          averageDurationPerTransaction.dataTotalAverageDurationPerTransactionType.forEach((dataSet) => {
            csvStringAverageParkingDuration += dataSet.date+','+this.getGroupCsvRowsAverageParkingDuration(dataSet,averageDurationPerTransaction.headersLabel.length);
            csvStringAverageParkingDuration += '\n'
          });
          csvStringAverageParkingDuration += '\n\n';

          csvDataStrings = [csvStringAverageParkingDuration];
          sheetNames = ['Average Parking Duration'];
          break;
        case 'apo':
          csvStringAverageParkingOccupancy =
          this.account.account_name+' \n'+
          'Parking Zone: '+ this.selectedZone.zone_name+ '\n'+
          employeeFilterStr+
          'Period Covered : '+ start + ' to '+ end +' \n'+
          'Report Type: '+reportName+' \n\n';

          csvStringAverageParkingOccupancy += 'Date,'+data.headersLabel.join(',')+'\n';

          data.filteredData.forEach((dataSet) => {

            csvStringAverageParkingOccupancy += dataSet.date+','+this.getGroupRowAverageParkingOccupancy(dataSet);
            csvStringAverageParkingOccupancy += '\n'
          });
          csvStringAverageParkingOccupancy += '\n\n';



          csvDataStrings = [csvStringAverageParkingOccupancy];
          sheetNames = ['Average Parking Occupancy'];

          break;

        default:
          break;
      }

      setTimeout(() => {

        // Create a CSV file
        const date = moment().format("YYYY-MM-DD h:mm:ss a");

        const csvBlob = this.generateMultiSheetCSV(csvDataStrings, sheetNames);

        this.downloadCSVFile(csvBlob, '('+start+' to '+end+') '+reportName+' '+date+'.csv');

        //send email report
        //this.sendEmailReportByFile(csvBlob, '('+start+' to '+end+') '+reportName+' '+date+'.csv', reportName);

        this.reportsGeneratedCounts += 1;
        LocalStorage.put('rgc', Number(this.reportsGeneratedCounts), 86400);
        this.timeInData = [];
        this.dateTimeRangeLogs = [];
        this.dateTimeRangeErrorLogs = [];
        this.form.reset();
        Swal.hideLoading();
        Swal.close();
      }, 5000);

    }else{
      const date = moment().format("YYYY-MM-DD h:mm:ss a");

      let options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalseparator: '.',
        showLabels: false,
        showTitle: false,
        useBom: false,
        noDownload: false,
        headers: ["Date","Zone Name","Slot Number","Plate Number","Vehicle Type", "Transaction Type", "Start", "Started by" , "End", "Ended by", "Duration", "Total payment", "Initial payment", "Processed by", "Received initial payment ", "Additional payment", "Processed by", "Received additional payment"]
      };

      const accountName = this.account.account_name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
      const filename = "generated_csv_" + accountName + "_" + date;
      logs  = logs.sort((a, b) => a.zone_name.localeCompare(b.zone_name));
      const ngxCsvReturnValue =  new ngxCsv(logs, filename, options);

      const csvBlob = new Blob([ngxCsvReturnValue.getCsv()], { type: 'text/csv' });

      //await this.sendEmailReportByFile(csvBlob, filename +'.csv', 'General Report');

      setTimeout(() => {
        this.reportsGeneratedCounts += 1;
        LocalStorage.put('rgc', Number(this.reportsGeneratedCounts), 86400);
        this.timeInData = [];
        this.dateTimeRangeLogs = [];
        this.dateTimeRangeErrorLogs = [];
        this.form.reset();
        Swal.hideLoading();
        Swal.close();
      }, 5000);

    }

  }

  async uploadFileToFirebase(blob: Blob, filename: string): Promise<string> {

    const storageRef = this.storage.ref(`parking_file_reports/${this.account.account_name}/${this.selectedZone.zone_name}_${filename}`);
    const uploadTask = storageRef.put(blob);

    // Wait for the upload to complete
    await uploadTask.task;

    // Get the download URL of the uploaded file
    return await storageRef.getDownloadURL().toPromise();
  }

  async sendEmailReportByFile(blob, fileName, reportName){
    const downloadURL = await this.uploadFileToFirebase(blob, fileName);

    // Send email with the download link
    const file = {
      accountName: this.account.account_name,
      selectedZone: this.selectedZone.zone_name,
      fileName: fileName,
      reportType: reportName,
      downloadURL: downloadURL,
      email: this.account.email_address,
      date: moment().format("LL h:mm:ss a")
    }

    this.sendEmailsService.sendEmailReports(file).then((res) => {
      Swal.fire({
        title: 'Report successfully generated.',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: 'Great',
        icon: 'success',
        didClose: () => {}
      });
    });
  }

  async downloadCSVFile(blob, filename) {

    const url = URL.createObjectURL(blob);

    // Create a temporary anchor element
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = filename;

    // Trigger a click event to start the download
    anchor.click();

    // Remove the temporary anchor element
    URL.revokeObjectURL(url);

  }

  generateMultiSheetCSV(csvDataStrings, sheetNames) {
    if (csvDataStrings.length !== sheetNames.length) {
      throw new Error('The number of data strings must match the number of sheet names.');
    }

    // Combine the CSV data strings into a single CSV file
    const csvFileContent = csvDataStrings.join('\r\n\r\n');

    // Create a Blob with the CSV file content
    const csvBlob = new Blob([csvFileContent], { type: 'text/csv' });

    return csvBlob;
}

  async getLogsDataForPdf(action,start, end) {
    let logs:any;

    start.setHours(0, 0, 0, 0);
    end.setHours(23, 59, 59, 999);

    if(this.selectedReportType == 'general'){
      logs = await firebase.firestore().collection('logs')
      .where('account_key', '==', this.account.account_key)
      .where('time_in', '>=', start)
      .where('time_in', '<=', end).get();
    }else{
      if(this.employeeSelected == 'all'){

        logs = await firebase.firestore().collection('logs')
        .where('account_key', '==', this.account.account_key)
        .where('zone_id','==', this.selectedZone.zone_id)
        .where('time_in', '>=', start)
        .where('time_in', '<=', end).get();

      }else{

        logs = await firebase.firestore().collection('logs')
        .where('account_key', '==', this.account.account_key)
        .where('zone_id','==', this.selectedZone.zone_id)
        .where('initial_payment_recieved_by_id','==', this.employeeSelected)
        .where('processedBy_id','==', this.employeeSelected)
        .where('time_in', '>=', start)
        .where('time_in', '<=', end).get();
      }
    }

    if (logs.empty) {
      await Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'No data found.',
      })
      return
    }

    let dataLogs;
    let arrayLogs = [];

    let logsMonitoring = {
      account_key: this.account.account_key,
      timestamp : new Date(),
      total_count : logs.docs.length,
      type :  this.selectedReportType + ' - pdf',
      user_id : this.userAuth.uid
    }

    await this.logsService.addLogsMonitoring(logsMonitoring);


    for (let log of logs.docs) {

      let duration;
      let parkingFee;

      dataLogs = log.data();

      let timeIn = (dataLogs.time_in.seconds * 1000);
      let dateTimeIn = moment(timeIn).format('MM/DD/YYYY hh:mm A'); //full format: L hh:mm A
      let dateTimeOut = "";

      if (dataLogs.time_out) {
        let timeOut = (dataLogs.time_out.seconds * 1000);
        dateTimeOut = moment(timeOut).format('MM/DD/YYYY hh:mm A'); //full format: L hh:mm A
      } else {
        dateTimeOut = "incomplete";
      }

      if (dataLogs.time_in.seconds && dataLogs.time_out?.seconds) {

        let startTime = moment(moment.unix(dataLogs.time_in?.seconds), "HH:mm:ss a");
        let endTime = moment(moment.unix(dataLogs.time_out?.seconds), "HH:mm:ss a");
        let min = moment.utc(moment(endTime, "HH:mm:ss").diff(moment(startTime, "HH:mm:ss"))).format("mm");
        let sec = moment.utc(moment(endTime, "HH:mm:ss").diff(moment(startTime, "HH:mm:ss"))).format("ss");
        let hour = endTime.diff(startTime, 'hours');

        let hourString = hour + "hr ";
        let minString = min + "min ";
        //let secString = sec + " and sec";

        if (hour === 0 && min !== "00") {
          duration = minString //+ secString;
        } else if (hour === 0 && min === "00") {
          duration = sec + " seconds";
        } else {
          duration = hourString + minString //+ secString;
        }

        if (dataLogs.amount_paid && dataLogs.amount_paid !== 0) {
          parkingFee = parseFloat(dataLogs.amount_paid);
        } else {
          parkingFee = 0;
        }

      } else {

        duration = "incomplete";

        if (dataLogs.amount_paid && dataLogs.amount_paid !== 0) {
          parkingFee = parseFloat(dataLogs.amount_paid);
        } else {
          parkingFee = 0;
        }

      }

      if (!dataLogs.amount_due) {
        dataLogs.amount_due = 0;
      }

      if (!dataLogs.zone_name) {
        if (dataLogs.station_name) {
          dataLogs.zone_name = dataLogs.station_name;
        } else {
          dataLogs.zone_name = "";
        }
      }

      if (dataLogs.processedBy == undefined ) {
        dataLogs.processedBy = "";
      }else{
        dataLogs.processedBy = dataLogs.processedBy;
      }

      if (dataLogs.end_session_by == undefined ) {
        dataLogs.end_session_by = "";
      }else{
        dataLogs.end_session_by = dataLogs.end_session_by;
      }


      // if(dataLogs.paid){
      //   // dataLogs.total_payment =  dataLogs.initial_payment + dataLogs.additional_payment;

      //   if(this.employeeSelected == 'all'){
      //     if(dataLogs.additional_payment_recieved_by_id == dataLogs.initial_payment_recieved_by_id){
      //       dataLogs.total_payment =  dataLogs.initial_payment + dataLogs.additional_payment;
      //     }else{
      //       dataLogs.total_payment =  dataLogs.initial_payment;
      //     }
      //   }else{
      //     if(dataLogs.additional_payment_recieved_by_id == this.employeeSelected){
      //       dataLogs.total_payment =  dataLogs.initial_payment + dataLogs.additional_payment;
      //     }else{
      //       dataLogs.total_payment =  dataLogs.initial_payment;
      //     }
      //   }

      // }

      if(dataLogs.initial_payment_recieved_date != null){
        let initailPaymentseconds = (dataLogs.initial_payment_recieved_date.seconds * 1000);
        dataLogs.initial_payment_recieved_date = moment(initailPaymentseconds).format("MM/DD/YYYY h:mm A");
      }else{
        dataLogs.initial_payment_recieved_date = "";
      }

      if(dataLogs.additional_payment_recieved_date != null){
        let initailPaymentseconds = (dataLogs.additional_payment_recieved_date.seconds * 1000);
        dataLogs.additional_payment_recieved_date = moment(initailPaymentseconds).format("MM/DD/YYYY h:mm A");
      }else{
        dataLogs.additional_payment_recieved_date = "";
      }

      let fullDateTimeStamp = (dataLogs.timestamp.seconds * 1000);

      dataLogs.fullDateTimeStamp = moment(fullDateTimeStamp).format("MM/DD/YYYY h:mm A");;

      dataLogs.timestamp = dateTimeIn;
      dataLogs.time_in = dateTimeIn;
      dataLogs.time_out = dateTimeOut;

      dataLogs.duration = duration;
      dataLogs.parkingFee = parkingFee;

      let code = dataLogs.code;

      if (!dataLogs.plate_number) {
        dataLogs.plate_number = dataLogs.code;
      }

      dataLogs.transaction_type_name = '';
      switch(dataLogs.transaction_type){
        case 0:
          dataLogs.transaction_type_name = 'Self Drive';
          break;
        case 1:
          dataLogs.transaction_type_name = 'Valet Drive';

          break;
        case 2:
          dataLogs.transaction_type_name = 'VIP Paid';

          break;
        case 3:
          dataLogs.transaction_type_name = 'VIP Free';
          break;
      }

      arrayLogs.push(dataLogs);

      // console.log(dataLogs);
    }

    await this.filterByEmployee(arrayLogs);

    if (this.employeeSelected !== 'all') arrayLogs = arrayLogs.filter((log: any) => { return log.initial_payment_recieved_by_id === this.employeeSelected || log.additional_payment_recieved_by_id === this.employeeSelected })

    Promise.all([arrayLogs]).then((response) => {
      this.timeInData = arrayLogs;
      this.loadDataTable(action, start, end);

    }).catch((e) => {
      //Handle errors
    });

  }

  async filterByEmployee(arrayLogs: any) {

    this.filteredByEmployee = [];

    if (this.employeeSelected !== 'all') {
      let totalInitialPayment = 0;
      let totalAdditionalPayment = 0;
      arrayLogs.map(logs => {
        //initial payment total
        if (this.employeeSelected === logs.initial_payment_recieved_by_id) {
          //totalInitialPayment = totalInitialPayment + logs.initial_payment;
          totalInitialPayment += logs.initial_payment;
        }
        if (this.employeeSelected === logs.additional_payment_recieved_by_id) {
          //totalAdditionalPayment = totalAdditionalPayment + logs.additional_payment;
          totalAdditionalPayment += logs.additional_payment;
        }
      })

      let index = this.employees.findIndex(emp => emp.employee_id === this.employeeSelected);

      this.totalPaymentCollected = totalInitialPayment + totalAdditionalPayment;

      this.filteredByEmployee.push(
        '<tr class="avoid-this-row">',
        ('<td class="border-t-0 align-middle border-l-0 border-r-0 text-xs py-1 px-2  whitespace-nowrap text-left" style="width: 15px;font-size:10px;">' + this.employees[index].first_name + " " + this.employees[index].last_name + '</td>'),
        ('<td class="border-t-0  align-middle border-l-0 border-r-0 text-xs py-1 px-2  whitespace-nowrap text-left" style="width: 15px;font-size:10px;">' + totalInitialPayment.toFixed(2) + '</td>'),
        ('<td class="border-t-0  align-middle border-l-0 border-r-0 text-xs py-1 px-2   whitespace-nowrap text-left" style="width: 15px;font-size:10px;">' + totalAdditionalPayment.toFixed(2) + '</td>'),
        ('<td class="border-t-0 align-middle border-l-0 border-r-0 text-xs py-1 px-2  whitespace-nowrap  text-left" style="width: 15px;font-size:10px;">' + this.totalPaymentCollected.toFixed(2) + '</td>'),
        '</tr>');

    }
  }

  async loadDataTable(action,start, end) {

    let table;
    if (action === 'logs') {
      table = this.createTableForLogs(start,end)
    } else {
      table = this.createTableForErrorLogs(start, end);
    }

    if(this.userAuth.roles.superAdmin){
      await Swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        showCloseButton: true,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Download to PDF',
        cancelButtonText: 'Cancel',
        width: 950,
        padding: '0px',
        heightAuto: false,
        html: table,
        customClass: { htmlContainer: 'swal-custom-class' },
        didClose: () => {
          this.timeInData = [];
          this.dateTimeRangeLogs = [];
          this.dateTimeRangeErrorLogs = [];
          this.form.reset();
          Swal.hideLoading();
        }
      }).then((result: any) => {
        if (result.isConfirmed) {

          let element = document.getElementById('swal2-html-container');
          let option = {
            margin: [30, 0, 30, 0],
            filename: 'generated-report.pdf',
            image: { type: 'jpeg', quality: 0.95 },
            html2canvas: {
              scale: 2,
              top: 30,
              bottom: 30,
              logging: true,
              letterRendering: true,
              useCORS: true,
            },
            jsPDF: {
              unit: 'mm',
              format: 'a4',
              orientation: 'portrait'
            },
            pageBreak: { mode: ['avoid-all', 'css', 'legacy'], before: '.outer_canvas', after: '.avoid-this-row' }
          };

          Swal.fire({
            title: 'Downloading... Please wait...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: false,
            didOpen: () => {

              Swal.showLoading();

              html2pdf().from(element).set(option).toPdf().get('pdf').then((pdf) => {
                let totalPages = pdf.internal.getNumberOfPages();
                //print current pdf width & height to console
                console.log("getHeight:" + pdf.internal.pageSize.getHeight());
                console.log("getWidth:" + pdf.internal.pageSize.getWidth());
                for (let i = 1; i <= totalPages; i++) {
                  pdf.setPage(i);
                  pdf.setFontSize(10);
                  pdf.setTextColor(150);
                  pdf.text('Page ' + i + ' of ' + totalPages, pdf.internal.pageSize.getWidth() - 40,
                    pdf.internal.pageSize.getHeight() - 15);
                }
              }).save().then(() => {
                Swal.fire({
                  title: 'Finished!',
                  allowEscapeKey: false,
                  allowOutsideClick: true,
                  showConfirmButton: false,
                  showCancelButton: true,
                  cancelButtonText: 'Great!',
                  icon: 'success',
                  didClose: () => {
                    this.reportsGeneratedCounts += 1;
                    LocalStorage.put('rgc', Number(this.reportsGeneratedCounts), 86400);
                    this.timeInData = [];
                    this.dateTimeRangeLogs = [];
                    this.dateTimeRangeErrorLogs = [];
                    this.form.reset();
                    Swal.hideLoading();
                  }
                });
              });
            }
          });
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel || result.dismiss === 'close'
        ) {

          // if parkbot admin
          if (!this.auth.canDelete(this.userAuth)) {

            Swal.fire({
              title: 'Are you sure?',
              html: "Are you sure, you want to cancel this reports?", //Please be reminded that this account plan is limited to three (3) document per day. Thank you.
              allowEscapeKey: false,
              allowOutsideClick: false,
              showCancelButton: true,
              showConfirmButton: true,
              confirmButtonText: 'Download to PDF',
              cancelButtonText: 'Yes, cancel please',
              icon: 'question',
              didClose: () => {
                this.reportsGeneratedCounts += 1;
                LocalStorage.put('rgc', Number(this.reportsGeneratedCounts), 86400);
                this.timeInData = [];
                this.dateTimeRangeLogs = [];
                this.dateTimeRangeErrorLogs = [];
                this.form.reset();
              }
            }).then((result) => {
              if (result.isConfirmed) {

                let element = document.getElementById('swal2-html-container');
                let option = {
                  margin: [30, 0, 30, 0],
                  filename: 'generated-report.pdf',
                  image: { type: 'jpeg', quality: 0.95 },
                  html2canvas: {
                    scale: 2,
                    top: 30,
                    bottom: 30,
                    logging: true,
                    letterRendering: true,
                    useCORS: true,
                  },
                  jsPDF: {
                    unit: 'mm',
                    format: 'a4',
                    orientation: 'portrait'
                  },
                  pageBreak: { mode: ['avoid-all', 'css', 'legacy'], before: '.outer_canvas', after: '.avoid-this-row' }
                };

                Swal.fire({
                  title: 'Downloading... Please wait...',
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  showConfirmButton: false,
                  didOpen: () => {

                    Swal.showLoading();

                    html2pdf().from(element).set(option).toPdf().get('pdf').then((pdf) => {
                      let totalPages = pdf.internal.getNumberOfPages();
                      //print current pdf width & height to console
                      console.log("getHeight:" + pdf.internal.pageSize.getHeight());
                      console.log("getWidth:" + pdf.internal.pageSize.getWidth());
                      for (let i = 1; i <= totalPages; i++) {
                        pdf.setPage(i);
                        pdf.setFontSize(10);
                        pdf.setTextColor(150);
                        pdf.text('Page ' + i + ' of ' + totalPages, pdf.internal.pageSize.getWidth() - 40,
                          pdf.internal.pageSize.getHeight() - 15);
                      }
                    }).save().then(() => {
                      Swal.fire({
                        title: 'Finished!',
                        allowEscapeKey: false,
                        allowOutsideClick: true,
                        showConfirmButton: false,
                        showCancelButton: true,
                        cancelButtonText: 'Great!',
                        icon: 'success',
                        didClose: () => {
                          this.reportsGeneratedCounts += 1;
                          LocalStorage.put('rgc', Number(this.reportsGeneratedCounts), 86400);
                          this.timeInData = [];
                          this.dateTimeRangeLogs = [];
                          this.dateTimeRangeErrorLogs = [];
                          this.form.reset();
                          Swal.hideLoading();
                        }
                      });
                    });
                  }
                });
              }
            });

          }

        }
      });
    }else{
      const pdfOptions = {
        margin: 10,
        filename: "generated-report.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      };
      await html2pdf().from(table).set(pdfOptions).save();
    }

  }

  displayFilteredDisplay() {
    if (this.employeeSelected !== 'all') {
      return [
        '<div class="block w-full overflow-x-auto mb-12">' +
        '<table class="table-block items-center w-full bg-transparent border-collapse" style="background: #eeeeee;border-radius: 6px;color: #000000;">' +
        '<thead>' +
        '<th class="px-2 align-middle border border-solid text-xs pt-0 py-3 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" style="width: 15px;font-size:10px;">Processed by</th>' +
        '<th class="px-2 align-middle border border-solid text-xs pt-0 py-3 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" style="width: 15px;font-size:10px;">Total Initial Payment</th>' +
        '<th class="px-2 align-middle border border-solid text-xs pt-0 py-3 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" style="width: 15px;font-size:10px;">Total Additional Payment</th>' +
        '<th class="px-2 align-middle border border-solid text-xs pt-0 py-3 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" style="width: 15px;font-size:10px;">Total Payment Collected</th>' +
        '</thead>' +
        '<tbody style="background: #ffffff;">' +
        this.filteredByEmployee.join('') +
        '</tbody>' +
        '</table>' +
        '</div>'
      ];
    } else {
      return [];
    }
  }

  createTableForLogs(start, end) {

    let arrayData = [];
    let totalFeeCollected = 0;

    let startPeriodCovered = this.datePipe.transform(start, 'shortDate');
    let endPeriodCovered = this.datePipe.transform(end, 'shortDate');
    let currentDate = this.datePipe.transform(new Date(), 'shortDate');

    for (let i = 0; i < this.timeInData.length; i++) {

      if (!this.timeInData[i].additional_payment_recieved_by) {
        this.timeInData[i].additional_payment_recieved_by = '';
      }

      if (!this.timeInData[i].initial_payment_recieved_by) {
        this.timeInData[i].initial_payment_recieved_by = '';
      }

      if(!this.timeInData[i].paid){
        if(this.timeInData[i].initial_payment_recieved_by != ""){
          this.timeInData[i].parkingFee = this.timeInData[i].initial_payment;
        }
        if(this.timeInData[i].initial_payment == 0){
          this.timeInData[i].parkingFee = 0;
        }
      }else{

        this.timeInData[i].parkingFee =  this.timeInData[i].initial_payment + this.timeInData[i].additional_payment;

      }

      let initial_payment = this.timeInData[i].initial_payment == undefined ? "" : this.timeInData[i].initial_payment.toFixed(2);
      let additional_payment = this.timeInData[i].additional_payment == undefined ? "" : this.timeInData[i].additional_payment.toFixed(2);

      totalFeeCollected += this.timeInData[i].parkingFee;

      if(this.timeInData[i].paid){

        if(this.selectedReportType  == 'general'){
            this.timeInData[i].total_payment =  this.timeInData[i].initial_payment + this.timeInData[i].additional_payment;
        }else{
          if(this.employeeSelected == 'all'){
            if(this.timeInData[i].additional_payment_recieved_by_id == this.timeInData[i].initial_payment_recieved_by_id){
              this.timeInData[i].total_payment =  this.timeInData[i].initial_payment + this.timeInData[i].additional_payment;
            }else{
              this.timeInData[i].total_payment =  this.timeInData[i].initial_payment;
            }
          }else{
            if(this.timeInData[i].additional_payment_recieved_by_id == this.employeeSelected){
              this.timeInData[i].total_payment =  this.timeInData[i].initial_payment + this.timeInData[i].additional_payment;
            }else{
              this.timeInData[i].total_payment =  this.timeInData[i].initial_payment;
            }
          }
        }

      }else{

        if(this.timeInData[i].initial_payment_recieved_by != ""){
          this.timeInData[i].total_payment = this.timeInData[i].initial_payment;
        }
        if(this.timeInData[i].initial_payment == 0){
          this.timeInData[i].total_payment = 0;
        }
      }

      if(this.timeInData[i].total_payment == undefined){
        this.timeInData[i].total_payment = "";
      }
      
      //PDF
      arrayData.push(
        '<tr class="avoid-this-row">',
        // ('<td class="border-t-0 align-middle border-l-0 border-r-0 text-xs py-1 px-2  whitespace-nowrap text-left" style="width: 15px;font-size:10px;">' + this.timeInData[i].inOrderNumberEntry + '</td>'),
        ('<td class="border-t-0 align-middle border-l-0 border-r-0 text-xs py-1 px-2  whitespace-nowrap text-left" style="width: 15px;font-size:10px;">' + this.timeInData[i].fullDateTimeStamp + '</td>'),
        ('<td class="border-t-0  align-middle border-l-0 border-r-0 text-xs py-1 px-2  whitespace-nowrap text-left" style="width: 15px;font-size:10px;">' + this.timeInData[i].zone_name + '</td>'),
        ('<td class="border-t-0  align-middle border-l-0 border-r-0 text-xs py-1 px-2  whitespace-nowrap text-left" style="width: 15px;font-size:10px;">' + this.timeInData[i].slot_name + '</td>'),
        ('<td class="border-t-0  align-middle border-l-0 border-r-0 text-xs py-1 px-2  whitespace-nowrap text-left" style="width: 15px;font-size:10px;">' + this.timeInData[i].plate_number + '</td>'),
        ('<td class="border-t-0  align-middle border-l-0 border-r-0 text-xs py-1 px-2  whitespace-nowrap text-left" style="width: 15px;font-size:10px;">' + this.timeInData[i].vehicle_type_custom_name + '</td>'),
        ('<td class="border-t-0  align-middle border-l-0 border-r-0 text-xs py-1 px-2  whitespace-nowrap text-left" style="width: 15px;font-size:10px;">' + this.timeInData[i].transaction_type_name + '</td>'),
        ('<td class="border-t-0  align-middle border-l-0 border-r-0 text-xs py-1 px-2   whitespace-nowrap text-left" style="width: 15px;font-size:10px;">' + this.timeInData[i].time_in + '</td>'),
        ('<td class="border-t-0 align-middle border-l-0 border-r-0 text-xs py-1 px-3 whitespace-nowrap  text-left" style="width: 15px;font-size:10px;">' + this.timeInData[i].processedBy + '</td>'),
        ('<td class="border-t-0 align-middle border-l-0 border-r-0 text-xs py-1 px-2  whitespace-nowrap  text-left" style="width: 15px;font-size:10px;">' + this.timeInData[i].time_out + '</td>'),
        ('<td class="border-t-0 align-middle border-l-0 border-r-0 text-xs py-1 px-3 whitespace-nowrap  text-left" style="width: 15px;font-size:10px;">' + this.timeInData[i].end_session_by + '</td>'),
        ('<td class="border-t-0 align-middle border-l-0 border-r-0 text-xs py-1 px-2  whitespace-nowrap  text-left" style="width: 15px;font-size:10px;">' + this.timeInData[i].duration + '</td>'),
        ('<td class="border-t-0 align-middle border-l-0 border-r-0 text-xs py-1 px-2 whitespace-nowrap  text-left" style="width: 15px;font-size:10px;">' + Number(this.timeInData[i].total_payment).toFixed(2)+ '</td>'),
        ('<td class="border-t-0 align-middle border-l-0 border-r-0 text-xs py-1 px-2 whitespace-nowrap  text-left" style="width: 15px;font-size:10px;">' + initial_payment + '</td>'),
        ('<td class="border-t-0 align-middle border-l-0 border-r-0 text-xs py-1 px-2  whitespace-nowrap  text-left" style="width: 15px;font-size:10px;">' + this.timeInData[i].initial_payment_recieved_by + '</td>'),
        ('<td class="border-t-0 align-middle border-l-0 border-r-0 text-xs py-1 px-2 whitespace-nowrap  text-left" style="width: 15px;font-size:10px;">' +  this.timeInData[i].initial_payment_recieved_date + '</td>'),
        ('<td class="border-t-0 align-middle border-l-0 border-r-0 text-xs py-1 px-2 whitespace-nowrap  text-left" style="width: 15px;font-size:10px;">' + additional_payment + '</td>'),
        ('<td class="border-t-0 align-middle border-l-0 border-r-0 text-xs py-1 px-2  whitespace-nowrap  text-left" style="width: 15px;font-size:10px;">' + this.timeInData[i].additional_payment_recieved_by + '</td>'),
        ('<td class="border-t-0 align-middle border-l-0 border-r-0 text-xs py-1 px-2  whitespace-nowrap  text-left" style="width: 15px;font-size:10px;">' + this.timeInData[i].additional_payment_recieved_date + '</td>'),
        '</tr>');
    }

    return [
      '<div class="flex flex-wrap w-full overflow-x-auto mb-4 outer_canvas mt-4">' +
      '<div class="w-full lg:w-6/12">',
      '<div class="flex flex-wrap w-full">',
      '<div class="py-3 mr-2">',
      '<img src="' + this.account.photoUrl + '" alt="header_logo" class="float-left" style="width:28px;">',
      '</div>',
      '<div class="w-full lg:w-8/12 py-2">',
      '<h2 class="text-sm text-left" style="color: #000000;">' + this.account.account_name + '</strong></h2>',
      '<h2 class="text-xs text-left" style="color: #000000;">' + this.account.address + '</strong></h2>',
      '</div>',
      '</div>',
      '</div>',
      '<div class="w-full lg:w-6/12">',
      '<div class="float-right">' +
      '<img src="/assets/img/parkbot_logo_header.png" alt="header_logo" class="float-right" style="width: 90px;">',
      '</div>',
      '</div>',
      '</div>',
      '<div class="block w-full overflow-x-auto">',
      '<h2 class="whitespace-nowrap font-semibold px-6 align-middle pt-0 pb-2 text-sm" style="background: #eeeeee;border-radius: 6px;color: #000000;overflow: inherit;">' +
      '<span class="text-left float-left">Parking Report</span>' +
      '<span class="text-right float-right"><i class="text-xs" style="vertical-align: top;position: relative;top: 2px;right: 2px;">Date covered: </i>' + startPeriodCovered + ' ~ ' + endPeriodCovered + '</span>' +
      '</h2>',
      '<div class="flex flex-wrap px-6">',
      '<div class="w-full lg:w-6/12">',
      '<h3 class="text-sm text-left py-2 float-left" style="color: #000000;">Total Transaction: <strong>' + (this.timeInData.length) + '</strong></h3>',
      '</div>',
      '<div class="w-full lg:w-6/12">',
      '<h3 class="text-sm text-left py-2 float-right" style="color: #000000;">Total Payment Collected: <strong>' + '₱' + totalFeeCollected.toFixed(2) + '</strong></h3>',
      '</div>',
      '</div>',
      '</div>',
      this.displayFilteredDisplay(),
      '<div class="block w-full overflow-x-auto mb-12">',
      '<table class="table-block items-center w-full bg-transparent border-collapse" style="background: #eeeeee;border-radius: 6px;color: #000000;">',
      '<thead>',
      '<th class="px-2 align-middle border border-solid text-xs pt-0 py-3 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" style="width: 15px;font-size:10px;">Date</th>',
      '<th class="px-2 align-middle border border-solid text-xs pt-0 py-3 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" style="width: 15px;font-size:10px;">Zone Name</th>',
      '<th class="px-2 align-middle border border-solid text-xs pt-0 py-3 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" style="width: 15px;font-size:10px;">Slot Number</th>',
      '<th class="px-2 align-middle border border-solid text-xs pt-0 py-3 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" style="width: 15px;font-size:10px;">Plate Number</th>',
      '<th class="px-2 align-middle border border-solid text-xs pt-0 py-3 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" style="width: 15px;font-size:10px;">Vehicle Type</th>',
      '<th class="px-2 align-middle border border-solid text-xs pt-0 py-3 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" style="width: 15px;font-size:10px;">Transaction Type</th>',
      '<th class="px-2 align-middle border border-solid text-xs pt-0 py-3 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" style="width: 15px;font-size:10px;">Start</th>',
      '<th class="px-3 align-middle border border-solid text-xs pt-0 py-3 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" style="width: 15px;font-size:10px;">Started by</th>',
      '<th class="px-2 align-middle border border-solid text-xs pt-0 py-3 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" style="width: 15px;font-size:10px;">End</th>',
      '<th class="px-3 align-middle border border-solid text-xs pt-0 py-3 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" style="width: 15px;font-size:10px;">Ended by</th>',
      '<th class="px-2 align-middle border border-solid text-xs pt-0 py-3 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" style="width: 15px;font-size:10px;">Duration</th>',
      '<th class="px-2 align-middle border border-solid text-xs pt-0 py-3 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" style="width: 15px;font-size:10px;">Total Payment</th>',
      '<th class="px-2 align-middle border border-solid text-xs pt-0 py-3 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" style="width: 15px;font-size:10px;">Initial Payment</th>',
      '<th class="px-2 align-middle border border-solid text-xs pt-0 py-3 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" style="width: 15px;font-size:10px;">Processed By</th>',
      '<th class="px-2 align-middle border border-solid text-xs pt-0 py-3 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" style="width: 15px;font-size:10px;">Received initial payment</th>',  
      '<th class="px-2 align-middle border border-solid text-xs pt-0 py-3 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" style="width: 15px;font-size:10px;">Additional Payment</th>',
      '<th class="px-2 align-middle border border-solid text-xs pt-0 py-3 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" style="width: 15px;font-size:10px;">Processed By</th>',
      '<th class="px-2 align-middle border border-solid text-xs pt-0 py-3 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" style="width: 15px;font-size:10px;">Received additional payment</th>',
      
      '</thead>',
      '<tbody style="background: #ffffff;">',
      arrayData.join(''),
      '</tbody>',
      '</table>',
      '</div>'
    ].join('');
  }

  createTableForErrorLogs(start, end) {

    let arrayData = [];

    let startPeriodCovered = this.datePipe.transform(start, 'shortDate');
    let endPeriodCovered = this.datePipe.transform(end, 'shortDate');
    let currentDate = this.datePipe.transform(new Date(), 'shortDate');

    for (let i = 0; i < this.timeInData.length; i++) {

      arrayData.push(
        '<tr class="avoid-this-row">',
        ('<td class="border-t-0 align-middle border-l-0 border-r-0 text-xs py-1 px-2  whitespace-nowrap text-left" style="width: 15px;font-size:10px;">' + this.timeInData[i].inOrderNumberEntry + '</td>'),
        ('<td class="border-t-0  align-middle border-l-0 border-r-0 text-xs py-1 px-2  whitespace-nowrap text-left" style="width: 15px;font-size:10px;">' + this.timeInData[i].account_key + '</td>'),
        ('<td class="border-t-0  align-middle border-l-0 border-r-0 text-xs py-1 px-2  whitespace-nowrap text-left" style="width: 15px;font-size:10px;">' + this.timeInData[i].code + '</td>'),
        ('<td class="border-t-0 align-middle border-l-0 border-r-0 text-xs py-1 px-2 whitespace-nowrap  text-left" style="width: 15px;font-size:10px;">' + this.timeInData[i].processedBy + '</td>'),
        ('<td class="border-t-0 align-middle border-l-0 border-r-0 text-xs py-1 px-2 whitespace-nowrap  text-left" style="width: 15px;font-size:10px;">' + this.timeInData[i].status + '</td>'),
        ('<td class="border-t-0 align-middle border-l-0 border-r-0 text-xs py-1 px-2 whitespace-nowrap  text-left" style="width: 15px;font-size:10px;">' + this.timeInData[i].timestamp + '</td>'),
        '</tr>');
    }

    console.log("1:",arrayData);


    return [
      '<div class="flex flex-wrap w-full overflow-x-auto mb-4 outer_canvas mt-4">' +
      '<div class="w-full lg:w-6/12">',
      '<div class="flex flex-wrap w-full">',
      '<div class="py-3 mr-2">',
      '<img src="' + this.account.photoUrl + '" alt="header_logo" class="float-left" style="width:28px;">',
      '</div>',
      '<div class="w-full lg:w-8/12 py-2">',
      '<h2 class="text-sm text-left" style="color: #000000;">' + this.account.account_name + '</strong></h2>',
      '<h2 class="text-xs text-left" style="color: #000000;">' + this.account.address + '</strong></h2>',
      '</div>',
      '</div>',
      '</div>',
      '<div class="w-full lg:w-6/12">',
      '<div class="float-right">' +
      '<img src="/assets/img/parkbot_logo_header.png" alt="header_logo" class="float-right" style="width: 90px;">',
      '</div>',
      '</div>',
      '</div>',
      '<div class="block w-full overflow-x-auto">',
      '<h2 class="whitespace-nowrap font-semibold px-6 align-middle pt-0 pb-2 text-sm" style="background: #eeeeee;border-radius: 6px;color: #000000;overflow: inherit;">' +
      '<span class="text-left float-left">Error Logs Report</span>' +
      '<span class="text-right float-right"><i class="text-xs" style="vertical-align: top;position: relative;top: 2px;right: 2px;">Date covered: </i>' + startPeriodCovered + ' ~ ' + endPeriodCovered + '</span>' +
      '</h2>',
      '<div class="flex flex-wrap px-6">',
      '<div class="w-full lg:w-6/12">',
      '<h3 class="text-sm text-left py-2 float-left" style="color: #000000;">Total Transaction: <strong>' + (this.timeInData.length) + '</strong></h3>',
      '</div>',
      '</div>',
      '</div>',
      '<div class="block w-full overflow-x-auto mb-12">',
      '<table class="table-block items-center w-full bg-transparent border-collapse" style="background: #eeeeee;border-radius: 6px;color: #000000;">',
      '<thead>',
      '<th class="px-0 align-middle border border-solid text-xs pt-0 py-3 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" style="width: 15px;font-size:10px;">#</th>',
      '<th class="px-0 align-middle border border-solid text-xs pt-0 py-3 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" style="width: 15px;font-size:10px;">Account Key</th>',
      '<th class="px-0 align-middle border border-solid text-xs pt-0 py-3 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" style="width: 15px;font-size:10px;">Code</th>',
      '<th class="px-0 align-middle border border-solid text-xs pt-0 py-3  border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" style="width: 15px;font-size:10px;">Processed By</th>',
      '<th class="px-0 align-middle border border-solid text-xs pt-0 py-3 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" style="width: 15px;font-size:10px;">Status</th>',
      '<th class="px-0 align-middle border border-solid text-xs pt-0 py-3 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" style="width: 15px;font-size:10px;">DateTime</th>',
      '</thead>',
      '<tbody style="background: #ffffff;">',
      arrayData.join(''),
      '</tbody>',
      '</table>',
      '</div>'
    ].join('');
  }

  getStartOfToday() {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    return firebase.firestore.Timestamp.fromDate(now);
  }

  latestDocEmails = null;

  // async getMonitoringEmailLogs() {
  //
  //   const container = document.querySelector('.monitoringLogs');
  //
  //   const query = firebase.firestore().collection('logs_monitoring');
  //   const observer = await query
  //     .where('account_key', '==', this.account.account_key)
  //     .where('createdAt', '>=', this.getStartOfToday())
  //     .where('pong', '==', true)
  //     .orderBy('createdAt', 'asc')
  //     .startAfter(this.latestDocEmails || 0)
  //     .limit(10)
  //     .get()
  //     .then(async (documentSnapshots) => {
  //
  //       if (!documentSnapshots.docs.length) {
  //         console.log("No Data Available");
  //         return false;
  //       }
  //
  //       this.latestDocEmails = documentSnapshots.docs[documentSnapshots.docs.length - 1];
  //
  //       let tableData = [];
  //       let template = '';
  //       for (let item of documentSnapshots.docs) {
  //         const data = item.data();
  //         data.id = item.id;
  //         tableData.push(data);
  //       }
  //
  //       Promise.all(tableData).then((response) => {
  //         let monitoringLogs = tableData; //.sort((a,b) => (b.createdAt.seconds * 1000) - (a.createdAt.seconds * 1000) );
  //         // console.log('monitoringLogs', monitoringLogs);
  //         monitoringLogs.forEach((item) => {
  //           template +=
  //             '<div class="rounded-t bg-white mt-6 mb-6 px-6 py-6" style="font-size:14px;">' +
  //             '<div class="text-left flex justify-between">' +
  //             '<p style="margin-right: 15px;">Gate: ' + item.gate_device + '</p>' +
  //             '<p style="margin-right: 15px;">Attendant: ' + item.gate_keeper + '</p>' +
  //             '<p style="margin-right: 15px;">Status: ' + item.status + '</p>' +
  //             '<p style="margin-right: 15px;">Timestamp: ' + item.timestamp + '</p>' +
  //             '<p style="margin-right: 15px;">Current Transaction: ' + item.current_transactions + '</p>' +
  //             '</div>' +
  //             '</div>'
  //         });
  //
  //         container.innerHTML += template;
  //         // console.log('tableData', monitoringLogs);
  //
  //       });
  //
  //     }, err => {
  //       console.log(`Encountered error: ${err}`);
  //     });
  //
  // }

  // async loadMore() {
  //   //await this.getMonitoringEmailLogs();
  // }

  // async ping() {
  //   await this.sendPing();
  //   //await this.getMonitoringEmailLogs();
  // }
  //
  // sendPing(): Promise<void> {
  //
  //   let data = {
  //     account_key: this.account.account_key,
  //     id: this.afs.createId(),
  //     createdAt: new Date(),
  //     ping: true,
  //     status: 'Ping'
  //   }
  //
  //   return this.afs
  //     .collection("logs_monitoring")
  //     .doc(data.id)
  //     .set(data, { merge: true });
  //
  // }

  async onSelectMonth(event:any){
    this.selectedMonth = event.target.value;
  }

  async onSelectYear(event:any){
    this.selectedYear = event.target.value;
  }

  async changeDateType(event:any){
    this.selectedDateRangeType = event.target.value;
    switch (event.target.value) {
      case 'daily':
        this.displayDailyCalendar = true;
        this.displayWeekelyCalendar = false;
        this.displayMonthlyCalendar = false;
        break;
      case 'weekly':
        this.displayDailyCalendar = false;
        this.displayWeekelyCalendar = true;
        this.displayMonthlyCalendar = false;
        break;
      case 'monthly':
        this.displayDailyCalendar = false;
        this.displayWeekelyCalendar = false;
        this.displayMonthlyCalendar = true;
        break;
    }
  }

  generateWeeklyDateRanges() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    //const currentMonth = currentDate.getMonth();
    //always start the week in 1st week of january
    const currentMonth = 0;
    const startDate = new Date(currentYear, currentMonth, 1);
    const endDate = new Date(currentYear, currentMonth + 1, 0);

    while (startDate <= endDate) {
      const weekStartDate = new Date(startDate);
      const weekEndDate = new Date(startDate);
      weekEndDate.setDate(weekEndDate.getDate() + 6);

      this.weeklyDateRanges.push({
        start: this.formatDate(weekStartDate),
        end: this.formatDate(weekEndDate)
      });

      startDate.setDate(startDate.getDate() + 7);
    }
  }

  formatDate(date: Date): string {
    return date.toISOString().split('T')[0];
  }

  getLastDateOfMonth(year, month) {
    // Create a new Date object for the first day of the next month
    const nextMonth = new Date(year, month, 1);

    // Subtract one day to get the last day of the current month
    nextMonth.setDate(nextMonth.getDate() - 1);

    return nextMonth.getDate();
  }

}
