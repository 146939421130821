import {Component, OnInit, Input, ViewChild, ElementRef} from "@angular/core";
import {Account, Plans} from "src/models/account.model";
import {createPopper} from "@popperjs/core";
import {Router} from "@angular/router";
import Swal from "sweetalert2";
import {AuthService} from "src/services/auth.service";
import {User} from "src/models/user.model";
import { AccountService  } from "src/services/account.service";
import { PlanService  } from "src/services/plan.service";
import {combineLatest} from "rxjs";

@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.css']
})
export class PlansComponent implements OnInit {

  @ViewChild("btnDropdownRef", { static: false }) btnDropdownRef: ElementRef;
  @ViewChild("popoverDropdownRef", { static: false })
  popoverDropdownRef: ElementRef;

  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }
  private _color = "light";

  plans: Plans[] = [];
  userAuth: User;

  constructor(
    private accountService: AccountService,
    private planService: PlanService,
    protected router: Router,
    public auth: AuthService
  ) {
    this.auth.user$.subscribe(user => this.userAuth = user);

    this.planService.getPlans().subscribe((plans) =>{
      this.plans = plans;
    });
  }

  ngOnInit(): void {
  }

  async edit(plan: Plans){
    if(this.auth.canEdit(this.userAuth)){
      await this.router.navigateByUrl('/admin/plans/plans-edit/' + plan.id);
    }else{
      await Swal.fire(
        'Warning',
        "Sorry, access denied. Please contact admin.",
        'warning'
      )
    }
  }

  async add(){
    if(this.auth.canEdit(this.userAuth)){
      await this.router.navigateByUrl('/admin/plans/plans-add');
    }else{
      await Swal.fire(
        'Warning',
        "Sorry, access denied. Please contact admin.",
        'warning'
      )
    }
  }

  async delete(plan: Plans){
    if(this.auth.canDelete(this.userAuth)){
      if(plan.id){

        await Swal.fire({
          title: 'Are you sure?',
          text: 'You will not be able to recover this Plan!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, keep it'
        }).then((result) => {
          if (result.isConfirmed) {

            this.planService.deletePlan(plan).then(() =>{
              Swal.fire(
                'Success',
                'Plan deleted!',
                'success'
              )
            });

          } else if (result.dismiss === Swal.DismissReason.cancel) {

          }

        })

      }
    }else{
      await Swal.fire(
        'Warning',
        "Sorry, access denied. Please contact admin.",
        'warning'
      )
    }
  }

}
