import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
})
export class UserDashboardComponent implements OnInit {
  constructor() {

  }

  ngOnInit() {}
}
