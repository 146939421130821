export enum ZoneSlotAssignment {
  Automatic = 1, // automatic
  Manual = 2, // manual
}

export enum SessionStatusType {
  Expired = 0, // expired session
  Active = 1, // active session
  Completed = 2 // completed session
}
