// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
};

export const firebaseConfig = {

  // STAGING SITE
  // apiKey: "AIzaSyDOsJu1tOGTRvs9tOPeSQpcC05PpF679I0",
  // authDomain: "parkbotph-staging.firebaseapp.com",
  // projectId: "parkbotph-staging",
  // storageBucket: "parkbotph-staging.appspot.com",
  // messagingSenderId: "462181268030",
  // appId: "1:462181268030:web:5c37951655186046b8af6f",
  // measurementId: "G-BDQLZJSLQ9"

  // LIVE SITE
  apiKey: "AIzaSyDH1lKVYFSGgQCamc9-00XIn3b2Lci-QyY",
  authDomain: "parkbot-ph-prd.firebaseapp.com",
  projectId: "parkbot-ph-prd",
  storageBucket: "parkbot-ph-prd.appspot.com",
  messagingSenderId: "787780322172",
  appId: "1:787780322172:web:426cb63a2eff9afd9cdaf9",
  measurementId: "G-J2GV5FQX23"

}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
