import { Component, OnInit } from '@angular/core';
import {ZonesSlotService} from "../../../../services/zones-slot.service";
import {FormBuilder, FormGroup} from "@angular/forms";
import {User} from "../../../../models/user.model";
import {Account} from "../../../../models/account.model";
import {AuthService} from "../../../../services/auth.service";
import Swal from "sweetalert2";
import {AccountService} from "../../../../services/account.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-zones',
  templateUrl: './zones.component.html',
  styleUrls: ['./zones.component.css']
})
export class ZonesComponent implements OnInit {

  public formGroup: FormGroup;

  user: User = <User> {};
  account: Account;
  userAuth: User;

  zones: any;
  selectedZone:any;

  constructor(
    private zoneSlotService: ZonesSlotService,
    public formBuilder: FormBuilder,
    public auth: AuthService,
    private accountService: AccountService,
    public router: Router,
  ) {

    this.auth.user$.subscribe(user => this.userAuth = user);
    this.account = JSON.parse(localStorage.getItem('account'));

  }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      slots: [''],
    });

    this.zones = this.account.settings.zones;
  }

  async resetSlots() {
    if(this.auth.isSuperAdmin(this.userAuth)){

      if(!this.selectedZone || this.selectedZone.zone_id === null || this.selectedZone.zone_id === ""){
        await Swal.fire({
          title: 'Please select zone',
          text: 'Select zone to continue...',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Ok',
          cancelButtonText: 'Cancel'
        });

        return;
      }

      let slots = this.formGroup.get('slots').value.split('\n').filter(line => line.trim() !== '');

      if(!slots || slots.length === 0) return;

      await Swal.fire({
        title: 'Are you sure?',
        text: 'You want to reset this slots? Please confirm.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel'
      }).then(async (result) => {
        if (result.isConfirmed) {

          await Swal.fire({
            title: 'Processing... Please wait.',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: false,
            didOpen: async () => {

              Swal.showLoading();

              let slotData;

              for (const slotName of slots) {
                if (slotName) {
                  const updateZonesSlotData = {
                    status: 1,
                    log_id: null,
                    isExpired: false,
                    allowanceTimeDuration: null,
                    endTime: null,
                    updatedAt: new Date()
                  }

                  slotData = await this.zoneSlotService.updateZonesSlotBySlotNameAndZoneId(this.selectedZone.zone_id, slotName, updateZonesSlotData, this.account.account_key);
                }
              }

              if(slotData){
                await Swal.fire({
                  title: 'Reset slots finished.',
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  showCancelButton: false,
                  showConfirmButton: true,
                  icon: 'success',
                  didClose: () => {
                    this.formGroup.get('slots').reset();
                    Swal.hideLoading();
                  }
                });
              }else{
                await Swal.fire({
                  title: 'Slot name not found.',
                  html: 'Slot name on this zone is not found. Please check the slot name and zone properly.',
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  showConfirmButton: true,
                  showCancelButton: false,
                  icon: 'warning',
                  didClose: () => {
                    this.formGroup.get('slots').reset();
                    Swal.hideLoading();
                  }
                });
              }

            }
          });

        } else if (result.dismiss === Swal.DismissReason.cancel) {

        }

      });
    }
  }

  async onSelectZone(value:any) {
    this.selectedZone = this.account.settings.zones.find(z => z.zone_name.toLowerCase() == value.toLowerCase());
  }

  async deleteSlot(){
    if(this.auth.isSuperAdmin(this.userAuth)){

      if(!this.selectedZone || this.selectedZone.zone_id === null || this.selectedZone.zone_id === ""){
        await Swal.fire({
          title: 'Please select zone',
          text: 'Select zone to continue...',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Ok',
          cancelButtonText: 'Cancel'
        });

        return;
      }

      let slots = this.formGroup.get('slots').value.toUpperCase().split('\n').filter(line => line.trim() !== '');

      if(!slots || slots.length === 0) return;

      await Swal.fire({
        title: 'Are you sure?',
        text: 'You want to delete this slots? Please confirm.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel'
      }).then(async (result) => {
        if (result.isConfirmed) {

          await Swal.fire({
            title: 'Processing... Please wait.',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: false,
            didOpen: async () => {

              Swal.showLoading();

              for (const slotName of slots) {
                if (slotName) {
                  await this.zoneSlotService.deleteSlots(this.selectedZone.zone_id, slotName, this.account.account_key);
                }
              }

              // update slot count on account
              let slotCount;
              let index = this.zones.findIndex(i => i.zone_id == this.selectedZone.zone_id);
              if(index !== -1){
                slotCount = this.zones[index].zone_slots - slots.length;
              }

              await this.zoneSlotService.updateAccountByZoneSlot(slotCount, this.account.account_key, this.selectedZone.zone_id);

              await Swal.fire({
                title: 'Delete slots finished.',
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                timer: 3000,
                icon: 'success',
                didClose: () => {
                  this.formGroup.get('slots').reset();
                  Swal.hideLoading();

                  this.accountService.getAccountByKey(this.account.account_key).then((res) =>{
                    localStorage.removeItem('account');
                    localStorage.setItem('account', JSON.stringify(res));
                    this.router.navigateByUrl('/'+this.account.account_key+'/user/dashboard/'+this.selectedZone.zone_id,{replaceUrl: true})
                  });
                }
              });

            }
          });

        } else if (result.dismiss === Swal.DismissReason.cancel) {

        }

      });

    }
  }

}
