import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {filter, map, mergeMap} from "rxjs/operators";
import { UserService  } from "src/services/user.service";
import { LogsService } from "src/services/log.service";
import { User } from "src/models/user.model";
import {combineLatest} from "rxjs";
import firebase from "firebase/app";
import {Logs} from "src/models/logs";
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {VehicleService} from "src/services/vehicle.service";
import Swal from "sweetalert2";
import {Vehicles} from "../../../../../models/vehicle.model";
import {Account} from "../../../../../models/account.model";
import {AuthService} from "../../../../../services/auth.service";

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html'
})
export class EditComponent implements OnInit {

  public userForm: FormGroup;

  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }
  private _color = "light";

  user: User = <User> {};
  account: Account;
  userAuth: User;

  constructor(
    protected route : ActivatedRoute,
    private userService: UserService,
    private logService: LogsService,
    private vehicleService: VehicleService,
    public formBuilder: FormBuilder,
    public router: Router,
    public auth: AuthService
    ) {


    this.auth.user$.subscribe(user => this.userAuth = user);

      this.userForm = new FormGroup({
        fullName: new FormControl(''),
        displayName: new FormControl(''),
        email: new FormControl(''),
        roles: new FormGroup({
          superAdmin: new FormControl(false),
          admin: new FormControl(false),
          editor: new FormControl(false),
          subscriber: new FormControl(false),
          standard: new FormControl(false),
        })
      });

  }

  async ngOnInit() {

    this.route.paramMap
      .pipe(
        map(pm => pm.get('id')),
        filter(id => id != null),
        mergeMap(id => this.userService.getUserById(id)),
      ).subscribe((user) =>{

      this.user = user;
      console.log("user get by id =>", this.user);

      //DETAILS
      this.userForm.get("fullName").setValue(this.user.fullName);
      this.userForm.get("displayName").setValue(this.user.displayName);
      this.userForm.get("email").setValue(this.user.email);

      //ROLES
      this.userForm.get("roles.superAdmin").setValue(this.user.roles.superAdmin || false);
      this.userForm.get("roles.admin").setValue(this.user.roles.admin || false);
      this.userForm.get("roles.editor").setValue(this.user.roles.editor || false);
      this.userForm.get("roles.subscriber").setValue(this.user.roles.subscriber || false);
      this.userForm.get("roles.standard").setValue(this.user.roles.standard || false);
    });

  }

  async save(){

    if(this.auth.isSuperAdmin(this.userAuth)){
      this.userForm.value.roles = {
        superAdmin: this.userForm.get("roles.superAdmin").value || false,
        admin: this.userForm.get("roles.admin").value || false,
        editor: this.userForm.get("roles.editor").value || false,
        subscriber: this.userForm.get("roles.subscriber").value || false,
        standard: this.userForm.get("roles.standard").value || false,
      }

      if(this.userForm.value.fullName){
        this.userForm.value.displayName = this.userForm.value.fullName;
      }

      if(this.userForm.valid){
        await this.userService.updateUser(this.userForm.value, this.user.uid).then(() =>{
          Swal.fire(
            'Success',
            'User successfully saved',
            'success'
          )
        });
      }
    }else{
      await Swal.fire({
        title: 'Ooops!',
        html: 'You have no rights to update the user. Please contact your administrator. Thank you.',
        allowEscapeKey: true,
        allowOutsideClick: true,
        showConfirmButton: false,
        showCancelButton: true,
        showCloseButton: true,
        cancelButtonText: 'Ok',
        icon: 'warning',
      });
    }


  }

  async delete(user: User) {
    if (this.auth.isSuperAdmin(this.userAuth)) {
      this.userService.deleteUser(user).then(() => {
        Swal.fire(
          'Success',
          'User deleted!',
          'success'
        )
      });
    } else {
      await Swal.fire({
        title: 'Ooops!',
        html: 'You have no rights to update the user. Please contact your administrator. Thank you.',
        allowEscapeKey: true,
        allowOutsideClick: true,
        showConfirmButton: false,
        showCancelButton: true,
        showCloseButton: true,
        cancelButtonText: 'Ok',
        icon: 'warning',
      });
    }
  }

}
