import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {filter, map, mergeMap} from "rxjs/operators";
import firebase from "firebase/app";

import { UserService  } from "src/services/user.service";
import { VehicleService } from "src/services/vehicle.service";
import { LogsService } from "src/services/log.service";
import { User } from "src/models/user.model";
import {AngularFirestore} from "@angular/fire/firestore";
import Swal from "sweetalert2";

import { AuthService } from "src/services/auth.service";
import { AngularFireAuth } from "@angular/fire/auth";

import { AccountService  } from "src/services/account.service";
import { PlanService  } from "src/services/plan.service";
import {Account, AccountUser, Plans} from "src/models/account.model";
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-plans-add',
  templateUrl: './plans-add.component.html'
})
export class PlansAddComponent implements OnInit {

  public userForm: FormGroup;
  dropdownUserSettings:IDropdownSettings;
  dropdownPlanSettings:IDropdownSettings;

  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }
  private _color = "light";

  plans: Plans[] = [];

  constructor(
    private firestore: AngularFirestore,
    protected route : ActivatedRoute,
    public formBuilder: FormBuilder,
    public router: Router,
    public authService: AuthService,
    public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    private planService: PlanService,
    ) {

    this.userForm = this.formBuilder.group({
      name: ['', ],
      description: [''],
      price: ['']
    });

  }

  ngOnInit(): void {

  }


  async save(){

    let plan: Plans =  {
      key: this.generateRandomString(11),
      name: this.userForm.get('name').value,
      description: this.userForm.get('description').value,
      price: this.userForm.get('price').value
    }

    await Swal.fire({
      title: 'Creating plan... please wait...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: ()=>{
        Swal.showLoading()
        this.planService.addPlan(plan).then((res: any) =>{
          console.log("success plan saved ==>", res.id);
          Swal.fire({
            title: 'Plan successfully created.',
            timer: 2000,
            icon: 'success',
            didClose: ()=>{
              this.userForm.reset();
              Swal.hideLoading();
            }
          });
        });
      }
    });

  }

  generateRandomString(length: number) {
    let result = 'PL';
    let characters = '1234567890';
    let charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

}
