import {Component, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute, Router} from "@angular/router";
import {filter, finalize, map, mergeMap} from "rxjs/operators";
import {AccountService} from "src/services/account.service";
import {Account, AccountUser, Plans} from "src/models/account.model";
import {combineLatest, Observable} from "rxjs";
import {FormArray, FormBuilder, FormGroup} from "@angular/forms";
import Swal from "sweetalert2";
import {IDropdownSettings} from "ng-multiselect-dropdown";
import {User} from "../../../../../models/user.model";
import {PlanService} from "../../../../../services/plan.service";
import {UserService} from "../../../../../services/user.service";
import {AngularFireStorage} from "@angular/fire/storage";
import {AuthService} from "../../../../../services/auth.service";
import imageToBase64 from 'image-to-base64/browser';

@Component({
  selector: 'app-accounts-edit',
  templateUrl: './accounts-edit.component.html'
})
export class AccountsEditComponent implements OnInit {

  public userForm: FormGroup;

  dropdownUserSettings:IDropdownSettings;
  dropdownPlanSettings:IDropdownSettings;

  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }
  private _color = "light";

  account: Account = <Account> {};
  userAuth: User;

  user: User = <User> {};
  users: User[] = [];
  plans: Plans[] = [];

  accountPlans: FormArray;
  accountUsers: FormArray;

  selectedUser: any = [];
  selectedPlan = [];
  plan: Plans = {};
  ownerId: string = "";

  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadURL: Observable<string>;
  photoUrl: any;

  toggleButtonConfig = {
    value: false
  }

  constructor(
    protected route : ActivatedRoute,
    private accountService: AccountService,
    private planService: PlanService,
    private userService: UserService,
    public formBuilder: FormBuilder,
    public router: Router,
    private storage: AngularFireStorage,
    public auth: AuthService,
    private domSanitizer: DomSanitizer
    ) {

    this.auth.user$.subscribe(user => this.userAuth = user);

  }

  async ngOnInit() {

    this.userForm = this.formBuilder.group({
      accountName: [''],
      email: [''],
      address: [''],
      phoneNumber: [''],
      status: [''],
      photoUrl: [''],
      accountUsers: [''],
      plans: ['']
    });

    this.userForm.patchValue(this.account);

    this.route.paramMap
      .pipe(
        map(pm => pm.get('id')),
        filter(id => id != null),
        mergeMap(id => this.accountService.getAccountById(id)),
      ).subscribe(async (account) => {

      this.account = account;

      combineLatest(this.userService.getUsersAccount(), this.planService.getPlans())
        .subscribe(async ([users, plans]) => {

          this.users = users;
          this.plans = plans;
          this.selectedPlan = [this.account.plans];
          this.selectedUser = this.account.accountUsers;

          this.userForm = this.formBuilder.group({
            accountName: this.account.account_name,
            email: this.account.email_address,
            phoneNumber: this.account.phone_number,
            address: this.account.address,
            status: this.account.status,
            photoUrl: this.account.photoUrl,
            plans: this.account.plans,
            accountUsers: this.account.accountUsers
          });

          this.userForm.patchValue(account);
        });

      this.dropdownUserSettings = {
        singleSelection: false,
        idField: 'id',
        textField: 'fullName',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        allowSearchFilter: true,
        searchPlaceholderText: "search user",
        noDataAvailablePlaceholderText: "user not found",
      };

      this.dropdownPlanSettings = {
        singleSelection: true,
        idField: 'id',
        textField: 'name',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
      };

    });

  }

  getPlan(id):Promise<any>{
    return new Promise((resolve) =>{
      this.planService.getPlanById(id).subscribe((plan) =>{
        resolve(plan);
      });
    });
  }

  onSelectedPlan(item: any) {
    let elementPlan = this.plans.map((obj) => {return obj.id; }).indexOf(item.id);
    this.account.plans = this.plans[elementPlan];
  }

  onSelectedUsers(item: any) {
    console.log(item);
  }

  setOwnership(id){
    if(id){
      this.account.owner_id = id;
      this.selectedUser.findIndex((obj: any, i) => {
        this.selectedUser[i].isOwner = obj.id === id;
      });

    }
  }

  get getControl(){
    return this.userForm.controls;
  }

  createAccountPlans(): FormGroup {
    return this.formBuilder.group({
      plans: [<Plans>{}]
    })
  }

  addPlans() {
    this.accountPlans.push(this.createAccountPlans());
  }

  createAccountUser(): FormGroup {
    return this.formBuilder.group({
      accountUsers: [<AccountUser>{}]
    })
  }

  addAccountUsers() {
    this.accountUsers.push(this.createAccountUser());
  }

  async save(){

    if(this.userForm.valid){

      const sUsers: any = [];
      let account: Account =  {
        account_key: this.account.account_key,
        account_name: this.userForm.value.accountName,
        owner_id: this.account.owner_id,
        email_address: this.userForm.value.email,
        phone_number: this.userForm.value.phoneNumber,
        address: this.userForm.value.address,
        status: this.userForm.value.status,
        photoUrl: this.account.photoUrl ? this.account.photoUrl : null,
        plans: this.account.plans,
        accountUsers: sUsers
      }

      let isExist = this.selectedUser.findIndex((obj) => { return obj.id === account.owner_id; }) != -1;
      if(!isExist){
        await Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Please assign ownership.'
        })
        return;
      }

      for ( let i = 0; i < this.selectedUser.length; i++ ){

        if(this.selectedUser.length > 1){
          this.selectedUser[i].isOwner = account.owner_id === this.selectedUser[i].id;
        }else{
          account.owner_id = this.selectedUser[i].id;
          this.selectedUser[i].isOwner = true;
        }

        let accountUser: AccountUser = {
          account_key: account.account_key,
          id: this.selectedUser[i].id,
          fullName: this.selectedUser[i].fullName,
          isOwner: this.selectedUser[i].isOwner
        }

        sUsers.push(accountUser);

        //await this.userService.updateUserAccountKey(account.account_key, accountUser.id);
        await this.accountService.addAccountUser(account.account_key, sUsers);

      }

      await Swal.fire({
        title: 'Saving... please wait...',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: ()=>{

          Swal.showLoading()

          this.accountService.updateAccount(account, this.account.id).then(() =>{
            Swal.fire({
              title: 'Account successfully created.',
              timer: 2000,
              icon: 'success',
              didClose: ()=>{
                Swal.hideLoading();
              }
            });
          });

        }

      });

    }else{
      await Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please check all the required fields.'
      })
    }

  }

  delete(account: Account){
    this.accountService.deleteAccount(account).then(() =>{
      Swal.fire(
        'Success',
        'User deleted!',
        'success'
      )
    });
  }

  uploadImage($event: Event) {

    if(this.auth.canDelete(this.userAuth)){
      let currentDate = Date.now();
      const element = $event.target as HTMLInputElement;
      const file: File = (element.files as FileList)[0];
      const filePath = `company_logo/${currentDate}`;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(`company_logo/${currentDate}`, file);

      //this.percentage = task.percentageChanges();
      task.snapshotChanges()
        .pipe(
          finalize(() => {
            this.downloadURL = fileRef.getDownloadURL();
            this.downloadURL.subscribe(url => {
              if (url) {
                this.account.photoUrl = url;
                // imageToBase64(url).then((res) =>{
                //   this.account.photoUrl = "data:image/jpeg;base64, " + res;
                //   //this.photoUrl = this.domSanitizer.bypassSecurityTrustUrl(base64) as string;
                // });
              }
            });
          })
        )
        .subscribe(url => {
          if (url) {
            console.log(url);
          }
        });
    }else{
      alert("You have no right to upload image. Please contact your administrator. Thank you!");
    }

  }

}
