import {ChangeDetectionStrategy, Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import * as moment from "moment";
import firebase from "firebase";
import 'jspdf-autotable';
import {UserService} from "../../../../services/user.service";
import {User, UserLogs} from "../../../../models/user.model";
import {Vehicles} from "../../../../models/vehicle.model";
import {VehicleService} from "../../../../services/vehicle.service";
import Swal from "sweetalert2";
import {SwalComponent, SwalPortalTargets} from "@sweetalert2/ngx-sweetalert2";
import html2pdf from "html2pdf-jspdf2";
import {AngularFirestore} from "@angular/fire/firestore";
import {DatePipe} from "@angular/common";
import {AuthService} from "../../../../services/auth.service";
import { LocalStorage } from 'ttl-localstorage';
import {Account} from "../../../../models/account.model";
import { LogsService } from 'src/services/log.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportsComponent implements OnInit {

  @ViewChild('tableContent')
  public readonly tableContent!: SwalComponent;

  timeInData = [];
  form: FormGroup;
  user: UserLogs = <UserLogs> {};

  headerRowTable = [['No.', 'Plate Number', 'Entry Time', 'Exit Time']];

  public dateTimeRange: Date[];
  dateRange = new Date();

  //TODO: put it in settings plan
  reportsGeneratedCounts: number = 0;

  account: Account;
  userAuth: User;

  // public startAt = new Date();
  // public min = new Date();
  // public max = new Date()

  public filterDates = (d: Date): boolean => {
    const day = d.getDay();
    // Prevent Saturday and Sunday from being selected.
    return day !== 0 && day !== 6;
  }

  constructor(
    public formBuilder: FormBuilder,
    private userService: UserService,
    private vehicleService: VehicleService,
    public readonly swalTargets: SwalPortalTargets,
    private firestore: AngularFirestore,
    private datePipe: DatePipe,
    public auth: AuthService,
    private logsService : LogsService,
    ) {

    this.account = JSON.parse(localStorage.getItem('account'));
    this.auth.user$.subscribe(user => this.userAuth = user);

  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      dateRange: []
    });
  }

  async print(){

    console.log(this.dateTimeRange);

    if(this.auth.canEdit(this.userAuth)){

      if(!this.dateTimeRange) {
        await Swal.fire({
          title: 'Ooops!',
          html: 'Please select dates (from date & to date)',
          allowEscapeKey: true,
          allowOutsideClick: true,
          showConfirmButton: false,
          showCancelButton: true,
          showCloseButton: true,
          cancelButtonText: 'Ok',
          icon: 'warning',
        });
        return
      }

      //check reports generated count settings
      const rgcParse = JSON.parse(localStorage.getItem('rgc'));
      const rgc = rgcParse?.v;
      console.log("rgc ==>", rgc)

      if(!this.auth.canDelete(this.userAuth)){
        if(rgc !== 0 && rgc > 3){
          await Swal.fire({
            title: 'Ooops!',
            html: 'You reached your limit generating reports. This plan is limited only to three (3) reports document per day. Come back again tomorrow. Thank you!',
            allowEscapeKey: true,
            allowOutsideClick: true,
            showConfirmButton: false,
            showCancelButton: true,
            showCloseButton: true,
            cancelButtonText: 'Ok',
            icon: 'warning',
          });
          return
        }
      }

      let dateFrom = this.dateTimeRange[0];
      let dateTo = this.dateTimeRange[1];
      let diff= moment(dateTo).diff(dateFrom, 'day');
      console.log(diff);

      //NOTE: hide for the moment
      // if(!this.auth.canDelete(this.userAuth)){
      //   if(diff > 0){
      //     await Swal.fire({
      //       title: 'Ooops!',
      //       html: 'You can only export one day or per day on this plan (Example: 12/1/2021 ~ 12/1/2021). Thank you. ',
      //       allowEscapeKey: true,
      //       allowOutsideClick: true,
      //       showConfirmButton: false,
      //       showCancelButton: true,
      //       showCloseButton: true,
      //       cancelButtonText: 'Ok',
      //       icon: 'warning',
      //     });
      //     return
      //   }
      // }

      await Swal.fire({
        title: 'Generating reports... Please wait.',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        showCancelButton: false,
        showCloseButton: false,
        timerProgressBar: true,
        didOpen: ()=>{
          Swal.showLoading();
          //this.loadDataTable();
          this.getLogsData();
        }
      });

    }else{
      await Swal.fire({
        title: 'Ooops!',
        html: 'You have no right to generate reports. Please contact your administrator. Thank you!',
        allowEscapeKey: true,
        allowOutsideClick: true,
        showConfirmButton: false,
        showCancelButton: true,
        showCloseButton: true,
        cancelButtonText: 'Ok',
        icon: 'warning',
      });
    }

  }


  async getLogsData(){

    const start = new Date(this.dateTimeRange[0]);
    start.setHours(0,0,0,0);

    const end = new Date(this.dateTimeRange[1]);
    end.setHours(23,59,59,999);

    let logs = await firebase.firestore().collection('logs')
      .where('account_key', '==', this.account.account_key)
      .where('time_in', '>=', start)
      .where('time_in', '<=', end).get();

    if(logs.empty){
      await Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'No data found.',
      })
      return
    }

    let dataLogs;
    let arrayLogs = [];

    let logsMonitoring = {
      account_key: this.account.account_key, 
      timestamp : new Date(),
      total_count : logs.docs.length,   
      type :  'table' 
    }

    await this.logsService.addLogsMonitoring(logsMonitoring);

    for(let log of logs.docs){

      let duration;
      let parkingFee;
      dataLogs = log.data();

      let timeIn = (dataLogs.time_in.seconds * 1000);
      let dateTimeIn = moment(timeIn).format('hh:mm A');
      let dateTimeOut = "";

      if(dataLogs.time_out){
        let timeOut = (dataLogs.time_out.seconds * 1000);
        dateTimeOut = moment(timeOut).format('hh:mm A');
      }else{
        dateTimeOut = "incomplete";
      }

      if(dataLogs.time_in.seconds && dataLogs.time_out?.seconds){

        let startTime = moment(moment.unix(dataLogs.time_in?.seconds), "HH:mm:ss a");
        let endTime = moment(moment.unix(dataLogs.time_out?.seconds), "HH:mm:ss a");
        let min = moment.utc(moment(endTime, "HH:mm:ss").diff(moment(startTime, "HH:mm:ss"))).format("mm");
        let sec = moment.utc(moment(endTime, "HH:mm:ss").diff(moment(startTime, "HH:mm:ss"))).format("ss");
        let hour = endTime.diff(startTime, 'hours');

        let hourString = hour + "hr, ";
        let minString = min + "min and ";
        let secString = sec + "sec";

        if(hour === 0 && min !== "00"){
          duration = minString + secString;
        }else if(hour === 0 && min === "00"){
          duration = sec + " seconds";
        }else{
          duration = hourString + minString + secString;
        }

        if(dataLogs.amount_paid){
          //parkingFee = parseFloat(this.calculateParkingFees(startTime, endTime));
          parkingFee = parseFloat(dataLogs.amount_paid);
        }else{
          parkingFee = 0;
        }

      }else{
        duration = "incomplete";
        parkingFee = 0;
      }

      dataLogs.timestamp = dateTimeIn;
      dataLogs.time_in = dateTimeIn;
      dataLogs.time_out = dateTimeOut;

      dataLogs.duration = duration;
      dataLogs.parkingFee = parkingFee;

      let code = dataLogs.code;

      if(!dataLogs.plate_number) {
        dataLogs.plate_number = dataLogs.code;
      }

      arrayLogs.push(dataLogs);

      // await this.getUserLogVehicle(code).then((vehicles) =>{
      //   console.log("vehicles =>>>>", vehicles);
      //
      //   if(vehicles.plate_number) {
      //     dataLogs.plate_number = vehicles.plate_number;
      //   }else if(vehicles.code){
      //     dataLogs.plate_number = vehicles.code;
      //   }else{
      //     dataLogs.plate_number = vehicles.user_id;
      //   }
      //
      //   arrayLogs.push(dataLogs);
      //
      // });

    }

    Promise.all(arrayLogs).then((response) =>{
      this.timeInData = arrayLogs;
      console.log("this.timeInData =>>>>", this.timeInData);
      this.loadDataTable();
    }).catch((e) =>{
      //Handle errors
    });

  }

  async getUserLogVehicle(code):Promise<any>{
    return new Promise( async (resolve) =>{

      //const userRef = firebase.firestore().collection('users_logs')
      const userRef = firebase.firestore().collection('users_vehicle')
      const query = await userRef
        .where('account_key', '==', this.account.account_key)
        .where("code", "==", code)

      query.get().then((documentSnapshots: any) =>{

        if (!documentSnapshots.docs.length) {
          console.log("No Data Available");
          return false;
        }

        let vehicles = [];
        for (let item of documentSnapshots.docs) {
          if(item.data()){
            vehicles = item.data();
          }else{
            vehicles = null;
          }
        }

        resolve(vehicles);

      });

    });
  }

  async loadDataTable(){

    await Swal.fire({
      allowEscapeKey: false,
      allowOutsideClick: false,
      showCloseButton: true,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Download to PDF',
      cancelButtonText: 'Cancel',
      width: 850,
      padding: '1.5em',
      heightAuto: false,
      html: this.createTable(),
      customClass: {htmlContainer: 'swal-custom-class'},
      didClose: ()=>{
        this.timeInData = [];
        this.dateTimeRange = [];
        this.form.reset();
        Swal.hideLoading();
      }
    }).then((result: any) => {
      if (result.isConfirmed) {

        let element =  document.getElementById('swal2-html-container');
        let option = {
          margin:       [30, 0, 30, 0],
          filename:     'generated-report.pdf',
          image:        { type: 'jpg', quality: 0.98 },
          html2canvas:  {
            scale: 2,
            top: 30,
            bottom: 30,
            logging: true,
            letterRendering: true,
            useCORS: true,
          },
          jsPDF:{
            unit: 'mm',
            format: 'a4',
            orientation: 'portrait'
          },
          pageBreak: { mode: ['avoid-all', 'css', 'legacy'] , before: '.outer_canvas', after: '.avoid-this-row' }
        };

        Swal.fire({
          title: 'Downloading... Please wait...',
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: false,
          didOpen: ()=>{

            Swal.showLoading();

            html2pdf().from(element).set(option).toPdf().get('pdf').then( (pdf) => {
              let totalPages = pdf.internal.getNumberOfPages();
              //print current pdf width & height to console
              console.log("getHeight:" + pdf.internal.pageSize.getHeight());
              console.log("getWidth:" + pdf.internal.pageSize.getWidth());
              for (let i = 1; i <= totalPages; i++) {
                pdf.setPage(i);
                pdf.setFontSize(10);
                pdf.setTextColor(150);
                pdf.text('Page ' + i + ' of ' + totalPages, pdf.internal.pageSize.getWidth() - 42,
                  pdf.internal.pageSize.getHeight() - 15);
              }
            }).save().then(()=>{
              Swal.fire({
                title: 'Finished!',
                allowEscapeKey: false,
                allowOutsideClick: true,
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonText: 'Great!',
                icon: 'success',
                didClose: ()=>{
                  this.reportsGeneratedCounts += 1;
                  LocalStorage.put('rgc', Number(this.reportsGeneratedCounts), 86400);
                  this.timeInData = [];
                  this.dateTimeRange = [];
                  this.form.reset();
                  Swal.hideLoading();
                }
              });
            });
          }
        });
      }else if (
        result.dismiss === Swal.DismissReason.cancel || result.dismiss === 'close'
      ) {

        // if parkbot admin
        if(!this.auth.canDelete(this.userAuth)) {

          Swal.fire({
            title: 'Are you sure?',
            html: "Are you sure, you want to cancel this reports? Please be reminded that this account plan is limited to three (3) document per day. Thank you.",
            allowEscapeKey: false,
            allowOutsideClick: false,
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: 'Download to PDF',
            cancelButtonText: 'Yes, cancel please',
            icon: 'question',
            didClose: ()=>{
              this.reportsGeneratedCounts += 1;
              LocalStorage.put('rgc', Number(this.reportsGeneratedCounts), 86400);
              this.timeInData = [];
              this.dateTimeRange = [];
              this.form.reset();
            }
          }).then((result) => {
            if (result.isConfirmed) {

              let element =  document.getElementById('swal2-html-container');
              let option = {
                margin:       [30, 0, 30, 0],
                filename:     'generated-report.pdf',
                image:        { type: 'jpg', quality: 0.98 },
                html2canvas:  {
                  scale: 2,
                  top: 30,
                  bottom: 30,
                  logging: true,
                  letterRendering: true,
                  useCORS: true,
                },
                jsPDF:{
                  unit: 'mm',
                  format: 'a4',
                  orientation: 'portrait'
                },
                pageBreak: { mode: ['avoid-all', 'css', 'legacy'] , before: '.outer_canvas', after: '.avoid-this-row' }
              };

              Swal.fire({
                title: 'Downloading... Please wait...',
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                didOpen: ()=>{

                  Swal.showLoading();

                  html2pdf().from(element).set(option).toPdf().get('pdf').then( (pdf) => {
                    let totalPages = pdf.internal.getNumberOfPages();
                    //print current pdf width & height to console
                    console.log("getHeight:" + pdf.internal.pageSize.getHeight());
                    console.log("getWidth:" + pdf.internal.pageSize.getWidth());
                    for (let i = 1; i <= totalPages; i++) {
                      pdf.setPage(i);
                      pdf.setFontSize(10);
                      pdf.setTextColor(150);
                      pdf.text('Page ' + i + ' of ' + totalPages, pdf.internal.pageSize.getWidth() - 42,
                        pdf.internal.pageSize.getHeight() - 15);
                    }
                  }).save().then(()=>{
                    Swal.fire({
                      title: 'Finished!',
                      allowEscapeKey: false,
                      allowOutsideClick: true,
                      showConfirmButton: false,
                      showCancelButton: true,
                      cancelButtonText: 'Great!',
                      icon: 'success',
                      didClose: ()=>{
                        this.reportsGeneratedCounts += 1;
                        LocalStorage.put('rgc', Number(this.reportsGeneratedCounts), 86400);
                        this.timeInData = [];
                        this.dateTimeRange = [];
                        this.form.reset();
                        Swal.hideLoading();
                      }
                    });
                  });
                }
              });
            }
          });

        }

      }
    });

  }

  createTable(){

    let arrayData = [];
    let totalFeeCollected = 0;

    let startPeriodCovered = this.datePipe.transform(this.dateTimeRange[0],'shortDate');
    let endPeriodCovered = this.datePipe.transform(this.dateTimeRange[1],'shortDate');
    let currentDate = this.datePipe.transform(new Date(),'shortDate');

    for (let i = 0; i < this.timeInData.length; i++) {

      totalFeeCollected += this.timeInData[i].parkingFee;

      arrayData.push(
        '<tr class="avoid-this-row">',
        ('<td class="border-t-0 align-middle border-l-0 border-r-0 text-xs py-1 px-6  whitespace-nowrap text-left">' + (i + 1) + '</td>'),
        ('<td class="border-t-0  align-middle border-l-0 border-r-0 text-xs py-1 px-6  whitespace-nowrap text-left">' + this.timeInData[i].plate_number + '</td>'),
        ('<td class="border-t-0  align-middle border-l-0 border-r-0 text-xs py-1 px-6   whitespace-nowrap text-left">' + this.timeInData[i].time_in + '</td>'),
        ('<td class="border-t-0 align-middle border-l-0 border-r-0 text-xs py-1 px-6  whitespace-nowrap  text-left">' + this.timeInData[i].time_out + '</td>'),
        ('<td class="border-t-0 align-middle border-l-0 border-r-0 text-xs py-1 px-6  whitespace-nowrap  text-left">' + this.timeInData[i].duration + '</td>'),
        ('<td class="border-t-0 align-middle border-l-0 border-r-0 text-xs py-1 px-6  whitespace-nowrap  text-left">' + '₱'+this.timeInData[i].parkingFee.toFixed(2) + '</td>'),
        '</tr>');
    }

    return [
      '<div class="flex flex-wrap w-full overflow-x-auto mb-4 outer_canvas mt-4">' +
      '<div class="w-full lg:w-6/12">',
      '<div class="flex flex-wrap w-full">',
      '<div class="py-3 mr-2">',
      '<img src="'+ this.account.photoUrl +'" alt="header_logo" class="float-left" style="width:28px;">',
      '</div>',
      '<div class="w-full lg:w-8/12 py-2">',
      '<h2 class="text-sm text-left" style="color: #000000;">'+ this.account.account_name +'</strong></h2>',
      '<h2 class="text-xs text-left" style="color: #000000;">'+ this.account.address +'</strong></h2>',
      '</div>',
      '</div>',
      '</div>',
      '<div class="w-full lg:w-6/12">',
      '<div class="float-right">' +
      '<img src="/assets/img/parkbot_logo_header.png" alt="header_logo" class="float-right" style="width: 140px;">',
      '</div>',
      '</div>',
      '</div>',
      '<div class="block w-full overflow-x-auto">',
      '<h2 class="whitespace-nowrap font-semibold px-6 align-middle pt-0 pb-2 text-sm" style="background: #eeeeee;border-radius: 6px;color: #000000;overflow: inherit;">' +
      '<span class="text-left float-left">Parking Report</span>' +
      '<span class="text-right float-right"><i class="text-xs" style="vertical-align: top;position: relative;top: 2px;right: 2px;">Date covered: </i>' + startPeriodCovered + ' ~ ' + endPeriodCovered + '</span>' +
      '</h2>',
      '<div class="flex flex-wrap px-6">',
      '<div class="w-full lg:w-6/12">',
      '<h3 class="text-sm text-left py-2 float-left" style="color: #000000;">Total Transaction: <strong>'+ (this.timeInData.length) +'</strong></h3>',
      '</div>',
      '<div class="w-full lg:w-6/12">',
      '<h3 class="text-sm text-left py-2 float-right" style="color: #000000;">Total Fee Collected: <strong>'+ '₱'+totalFeeCollected.toFixed(2) +'</strong></h3>',
      '</div>',
      '</div>',
      '</div>',
      '<div class="block w-full overflow-x-auto mb-12">',
      '<table class="table-block items-center w-full bg-transparent border-collapse" style="background: #eeeeee;border-radius: 6px;color: #000000;">',
      '<thead>',
      '<th class="px-6 align-middle border border-solid text-xs pt-0 py-3 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">No</th>',
      '<th class="px-6 align-middle border border-solid text-xs pt-0 py-3 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">Plate Number</th>',
      '<th class="px-6 align-middle border border-solid text-xs pt-0 py-3 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">Entry Time</th>',
      '<th class="px-6 align-middle border border-solid text-xs pt-0 py-3  border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">Exit Time</th>',
      '<th class="px-6 align-middle border border-solid text-xs pt-0 py-3 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">Duration</th>',
      '<th class="px-6 align-middle border border-solid text-xs pt-0 py-3 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">Parking Fee</th>',
      '</thead>',
      '<tbody style="background: #ffffff;">',
      arrayData.join(''),
      '</tbody>',
      '</table>',
      '</div>'
    ].join('');
  }

  calculateParkingFees(parkDateStr, returnDateStr){
    const parkDate = new Date(parkDateStr).getTime();
    const returnDate = new Date(returnDateStr).getTime();
    const totalMSParked = returnDate - parkDate;
    const totalHoursParked = totalMSParked / (1000 * 3600);
    return `${this.getCost(totalHoursParked)}`;
  }

  getCost(totalHoursParked){

    let minDurationTime = this.account.settings.parkingFee.minimumDurationTime; // 2 hours
    let minRate = this.account.settings.parkingFee.minimumRate; // P20
    let addRate = this.account.settings.parkingFee.additionalRate; // P10
    let overnightRate = this.account.settings.parkingFee.overnightRate; // P100

    let parkingCosts = [
      // [Maximum number of hours, Associated cost]
      [0],[0.25],[0.5],[0.75],[1],[2],[3],[4],[5],[6],[7],[8],
      [9],[10],[11],[12],[13],[14],[15],[16],[17],[18],[19],[20],
      [21],[22],[23],[24, overnightRate]
    ];

    for (let i = 0; i < parkingCosts.length; i++){
      if(parkingCosts[i][0] !== 24){

        // if(parkingCosts[i][0] <= minDurationTime){
        //   parkingCosts[i].splice(1, 0, minRate);
        //   return;
        // }

        if(parkingCosts[i][0] <= minDurationTime){
          parkingCosts[i].splice(1, 0, minRate);
        }
        else{
          parkingCosts[i].splice(i, 0, minRate += addRate);
        }
      }
    }

    console.log("parkingCosts ==>", parkingCosts)

    if (totalHoursParked > 24) {
      const days = Math.ceil(totalHoursParked / 24);
      return overnightRate + addRate * (days - 1);
    }

    const found = parkingCosts.find(([maxHours]) => totalHoursParked <= maxHours);
    return found[1];

  };

}
