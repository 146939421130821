import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {filter, map, mergeMap} from "rxjs/operators";
import { UserService  } from "src/services/user.service";
import { LogsService } from "src/services/log.service";
import {User, UserLogs} from "src/models/user.model";
import {combineLatest} from "rxjs";
import firebase from "firebase/app";
import {Logs} from "src/models/logs";
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {VehicleService} from "src/services/vehicle.service";
import Swal from "sweetalert2";
import {Vehicles} from "src/models/vehicle.model";
import {AuthService} from "src/services/auth.service";
import * as moment from 'moment';

@Component({
  selector: 'app-user-logs-edit',
  templateUrl: './user-logs-edit.component.html'
})
export class UserLogsEditComponent implements OnInit {

  public userForm: FormGroup;
  public vehicleForm: FormGroup;
  userVehicles: FormArray;

  plate_number = new FormControl('');
  vehicle_type = new FormControl('');
  vehicle_model = new FormControl('');
  vehicle_color = new FormControl('');

  date: any;

  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }
  private _color = "light";

  user: UserLogs = <UserLogs> {};
  vehicles: Vehicles = <Vehicles> {};
  logs: Logs[] = [];

  userAuth: User;

  constructor(
    protected route : ActivatedRoute,
    private userService: UserService,
    private logService: LogsService,
    private vehicleService: VehicleService,
    public formBuilder: FormBuilder,
    public router: Router,
    public auth: AuthService
    ) {

    this.auth.user$.subscribe(user => this.userAuth = user);

  }

  async ngOnInit() {

    this.userVehicles = this.formBuilder.array([

    ]);

    this.userForm = this.formBuilder.group({
      name: [''],
      code: [''],
      vehicles: this.userVehicles
    });

    this.vehicleForm = this.formBuilder.group({
      vehicles: this.userVehicles
    });

    this.userForm.patchValue(this.user);
    this.vehicleForm.patchValue(this.vehicles);

    this.route.paramMap
      .pipe(
        map(pm => pm.get('id')),
        filter(id => id != null),
        mergeMap(id => this.userService.getUserLogById(id)),
      ).subscribe((user) =>{

      this.user = user;
      console.log("user logs get by id =>", this.user);

      this.getLogsByCode(this.user.code);

      this.date = this.user.date_registered;

      this.userForm = this.formBuilder.group({
        name: this.user.name,
        code: this.user.code,
        vehicle_id: this.user.userVehicle_id
      })

      this.userForm.patchValue(user);

      if(user.userVehicle_id !== ""){
        this.vehicleService.getVehicleById(user.userVehicle_id).subscribe((res: any)=>{
          console.log(res.vehicles);
          this.userVehicles.clear();
          if(res.vehicles !== undefined){
            for(let i = 0; i < res.vehicles.length; i++) {
              let userVehicle = this.createUserVehicles();
              userVehicle.patchValue(res.vehicles[i]);
              this.userVehicles.push(userVehicle);
            }
          }else{
            let userVehicle = this.createUserVehicles();
            userVehicle.patchValue(res);
            this.userVehicles.push(userVehicle);
          }
        });
      }else{
        const vehicles = [{}];
        this.userVehicles.clear();
        for(let i = 0; i < vehicles.length; i++) {
          let userVehicle = this.createUserVehicles();
          userVehicle.patchValue(vehicles[i]);
          this.userVehicles.push(userVehicle);
        }
      }

    });

  }

  createUserVehicles(): FormGroup {
    return this.formBuilder.group({
      code: this.user.code,
      plate_number: [''],
      vehicle_type: [''],
      vehicle_model: [''],
      vehicle_color: [''],
      date_registered: [new Date()],
    });
  }

  async addVehicles() {
    if(this.auth.canEdit(this.userAuth)){
      this.userVehicles.push(this.createUserVehicles());
    }else{
      await Swal.fire(
        'Warning',
        "Sorry, access denied. Please contact admin.",
        'warning'
      )
    }
  }

  async getLogsByCode(code){

    const logRef = firebase.firestore().collection('logs');
    const snapshot = await logRef
      .where('code', '==', code)
      .orderBy('timestamp', 'desc')
      .get();
    snapshot.forEach((doc) => {

      let duration;
      let data = doc.data();
      data.id = doc.id;

      if(doc.data().time_in.seconds && doc.data().time_out?.seconds){
        let time_in = (doc.data().time_in?.seconds * 1000);
        let dateTimeIn = moment(time_in);

        let time_out = (doc.data().time_out?.seconds * 1000);
        let dateTimeOut = moment(time_out);

        let startTime = moment(moment.unix(doc.data().time_in?.seconds), "HH:mm:ss a");
        let endTime = moment(moment.unix(doc.data().time_out?.seconds), "HH:mm:ss a");
        let min = moment.utc(moment(endTime, "HH:mm:ss").diff(moment(startTime, "HH:mm:ss"))).format("mm");
        let sec = moment.utc(moment(endTime, "HH:mm:ss").diff(moment(startTime, "HH:mm:ss"))).format("ss");

        duration = endTime.diff(startTime, 'hours') + "hr, " + min + "min and " + sec + "sec";

      }

      data.duration = duration;
      this.logs.push(data);
      console.log("user logs =>",this.logs );
    })

  }

  async save(){

    if(this.userForm.valid){
      await this.userService.updateUserLogs(this.userForm.value, this.user.code).then(() =>{
        if(this.userForm.value.vehicle_id !== ""){
          this.vehicleService.updateVehicle(this.vehicleForm.value, this.userForm.value.vehicle_id).then(() =>{
            Swal.fire(
              'Success',
              'User logs saved!',
              'success'
            )
          });
        }else{
          this.vehicleService.addVehicle(this.vehicleForm.value).then((res) =>{
            console.log("vehicle =>", res.id);
            if(res.id !== null){
              this.userForm.value.vehicle_id = res.id;
              this.userService.updateUserLogs(this.userForm.value, this.user.code).then(() =>{
                Swal.fire(
                  'Success',
                  'User logs saved!',
                  'success'
                )
              })
            }
          });
        }
      });
    }

  }

  async delete(log: Logs){
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.isConfirmed) {
        this.logService.deleteLogs(log).then(() =>{
          const index: number = this.logs.indexOf(log);
          if (index !== -1) {
            this.logs.splice(index, 1);
            Swal.fire(
              'Deleted!',
              'Log deleted!',
              'success'
            )
          }
        });
      }
    });
  }

  async kickOut(log: Logs){
    Swal.fire({
      title: 'Please confirm to kick out!',
      text: "Code: "+ log.code +"",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      confirmButtonText: 'Kick out!',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        this.logService.enSessionLog(log).then((doc:any) =>{

          const index: number = this.logs.indexOf(log);
          if (index !== -1) {

            let time_in = (doc.time_in?.seconds * 1000);
            let dateTimeIn = moment(time_in);

            let time_out = (doc.time_out?.seconds * 1000);
            let dateTimeOut = moment(time_out);

            this.logs[index].duration = dateTimeIn.from(dateTimeOut).replace('ago','');
            this.logs[index].status = doc.status;
            this.logs[index].time_out = doc.time_out;

            Swal.fire(
              'Success',
              'Code: '+ log.code +' was kicked out on '+ moment(new Date(time_out), 'YYYY/MM/DD HH:mm') +'',
              'success'
            )
          }

        });
      }
    });
  }

}
