import { Component, OnInit } from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import html2pdf from "html2pdf-jspdf2";
import Swal from "sweetalert2";

@Component({
  selector: 'app-qr-code-generator',
  templateUrl: './qr-code-generator.component.html',
  styleUrls: ['./qr-code-generator.component.scss'],
})
export class QrCodeGeneratorComponent implements OnInit {

  public qrCodeForm: FormGroup;
  public qrPAssCodeArray: any = [];
  public qrStickerCodeArray: any = [];
  public qrPlateStickerCodeArray: any = [];
  codeValue: string = "";
  qrStickerCodeValue: any = {
    code: "",
    text: ""
  }
  generateRandomStringValue: string = "";

  isQrcodeSticker: boolean = false;
  isQrPlateSticker: boolean = false;
  licensePlates: any[] = [];
  licensePlatesExceeds: any = "";

  constructor(
    public formBuilder: FormBuilder
    ) { }

  ngOnInit(): void {

    this.qrCodeForm = this.formBuilder.group({
      quantity: [''],
    });

    //this.generateBulk();
    //this.getRandomQrCodes();

  }

  generateBulk(type){

    this.isQrcodeSticker = type === 'qr-sticker';

    if(this.qrCodeForm.value.quantity){
      if(this.isQrcodeSticker){
        if(this.qrCodeForm.value.quantity <= 45){
          if(this.qrStickerCodeArray && this.qrStickerCodeArray.length){
            alert("Only 45 QR Codes are limit every generate.");
            return;
          }

          for(let qty = 0; qty < this.qrCodeForm.get('quantity').value; qty++){
            console.log('Inside the loop:' + qty);
            this.getRandomQrCodes(type);
          }
        }else{
          alert("Only 45 QR Codes are limit every generate.");
        }
      }else{
        if(this.qrCodeForm.value.quantity <= 50){

          if(this.qrPAssCodeArray && this.qrPAssCodeArray.length){
            alert("Only 50 QR Codes are limit every generate.");
            return;
          }

          for(let qty = 0; qty < this.qrCodeForm.get('quantity').value; qty++){
            console.log('Inside the loop:' + qty);
            this.getRandomQrCodes(type);
          }
        }else{
          alert("Only 50 QR Codes are limit every generate.");
        }
      }
    }else{
      alert("Please enter quantity to generate bulk.");
    }
  }

  getRandomQrCodes(type){

    this.isQrcodeSticker = type === 'qr-sticker';

    if(this.isQrcodeSticker){

      if(this.qrStickerCodeArray && this.qrStickerCodeArray.length >= 50){
        alert("Only 50 QR Codes are limit every generate.");
        return;
      }

      this.generateRandomStringValue = this.generateRandomString(6);

      this.qrStickerCodeValue = {
        code: this.generateRandomStringValue,
        text: this.generateRandomStringValue.replace('PBPH', '')
      }

      this.qrStickerCodeArray.push(this.qrStickerCodeValue);
    }else{
      if(this.qrPAssCodeArray && this.qrPAssCodeArray.length >= 50){
        alert("Only 50 QR Codes are limit every generate.");
        return;
      }

      this.generateRandomStringValue = this.generateRandomString(6);
      this.codeValue = this.generateRandomStringValue;

      this.qrPAssCodeArray.push(this.codeValue);
    }

    console.log("generateRandomString qrPAssCodeArray ==>", this.qrPAssCodeArray);
    console.log("generateRandomString qrStickerCodeArray ==>", this.qrStickerCodeArray);
    console.log("generateRandomString ==>", this.generateRandomStringValue);
  }

  generatePlateForSticker(type) {
    this.isQrPlateSticker = type == 'qr-plate-sticker';

    const lines = this.qrCodeForm.get('quantity').value.split('\n').filter(line => line.trim() !== '');

    // Add "~parkbotph" to the end of each license plate
    const plates = lines.map(plate => plate.toUpperCase().replace(/\s/g, '') + "~parkbotph");

    // Check if the number of plates exceeds 45, and limit it if necessary
    if (plates.length > 45) {
      this.licensePlatesExceeds = "The number of plates exceeds the limit of 45. Truncating the list to 45 plates.";
      this.licensePlates = plates.slice(0, 45);
    } else {
      this.licensePlates = plates;
    }

    this.licensePlates.forEach((item) =>{

      this.qrStickerCodeValue = {
        code: item,
        text: item.replace("~parkbotph", "")
      }

      this.qrPlateStickerCodeArray.push(this.qrStickerCodeValue);
    });


    console.log("qrStickerCodeValue", this.qrStickerCodeValue);
    console.log("qrPlateStickerCodeArray", this.qrPlateStickerCodeArray);
  }


  generateRandomString(length: number) {

    let result = 'PBPH' + '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return result;
  }

  async print() {

    let element =  document.getElementById('qrCodeContainer');
    let option = {
      margin:       0,
      filename:     'parkBot-qrCodes-generated.pdf',
      image:        { type: 'png', quality: 1 },
      html2canvas:  {
        scale: 4,
        logging: true,
        letterRendering: true,
        useCORS: true,
        // onClone: function (doc) {
        //   doc.querySelector(".qrCodeDiv").style.display = "block";
        // },
      },
      jsPDF:{
        unit: 'pt',
        format: 'a4',
        orientation: 'landscape'
      },
      pageBreak: { mode: 'avoid-all', before: '.outer_canvas' }
    };

    // if(this.qrPAssCodeArray.length === 0) {
    //   alert("There are no code to print. Please try again.");
    //   return;
    // }else if(this.qrStickerCodeArray.length === 0){
    //   alert("There are no code to print. Please try again.");
    //   return;
    // }

    await Swal.fire({
      title: 'Generating PDF... Please wait.',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: ()=>{

        Swal.showLoading()

        html2pdf().set(option).from(element).save().then(()=>{
          Swal.fire({
            title: 'Finished!',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: false,
            timer: 3000,
            icon: 'success',
            didClose: ()=>{
              this.reset();
              Swal.hideLoading();
            }
          });
        });

      }
    });

  }

  reset(){
    this.qrPAssCodeArray = [];
    this.qrStickerCodeArray = [];
    this.qrPlateStickerCodeArray = [];
    this.licensePlates = [];
    this.isQrcodeSticker = !this.isQrcodeSticker;
    this.isQrPlateSticker = !this.isQrPlateSticker;
    this.codeValue = null;
    this.generateRandomStringValue = "";
    this.licensePlatesExceeds = "";
    this.qrCodeForm.get('quantity').reset();
  }

}
