import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/services/auth.service";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import firebase from "firebase";
import {Account, AccountUser} from "../../../../models/account.model";
import {AccountService} from "../../../../services/account.service";
import {Router} from "@angular/router";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
})
export class RegisterComponent implements OnInit {

  form: FormGroup | any;
  errorMessage: any;
  phoneNumber: any;
  reCaptchaVerifier: any;
  isLoading: boolean;

  constructor(
    public formBuilder: FormBuilder,
    public authService: AuthService,
    private accountService: AccountService,
    public router: Router,
  ) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      userFullName: new FormControl('', Validators.required),
      userEmail: new FormControl('', [Validators.required, Validators.email, Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,63}$',),]),
      userPassword: new FormControl('', Validators.required)
    });

    // this.form.get('userPhoneNumber').valueChanges.subscribe(value => {
    //   let formattedValue = value.replace(/\D/g, '').replace(/(\d{3})(?=\d)/g, '$1 ');
    //   if (formattedValue.length === 13 && formattedValue.charAt(13) === '') {
    //     formattedValue = formattedValue.slice(0, 11) + formattedValue.slice(12);
    //   }
    //   if (formattedValue.charAt(0) === '0') {
    //     formattedValue = formattedValue.slice(1);
    //   }
    //   this.form.get('userPhoneNumber').patchValue(formattedValue, { emitEvent: false });
    // });
  }

  getOtp(){
    // if(this.form.valid) {
    //   this.isLoading = true;
    //   const phoneNumber = this.form.get('userPhoneNumber').value.replace(/\s/g, '');
    //   const withAreaCodePhoneNumber = "+63" + phoneNumber;
    //   this.reCaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptchaContainer', {size: 'invisible'});
    //   this.authService.signInWithPhone(withAreaCodePhoneNumber, this.reCaptchaVerifier);
    // }else{
    //   this.validateAllFormFields(this.form);
    // }
  }

  async submit(){
    this.errorMessage = "";
    if(this.form.valid){
      this.authService.signUp(this.form.get('userFullName').value, this.form.get('userEmail').value, this.form.get('userPassword').value).then((res: any) => {
        console.log("this.authService.signUp =>", res);

        // const sUsers: any = [];
        // let accountKeyGenerated = this.generateRandomString(11);
        //
        // let accountUser: AccountUser = {
        //   account_key: accountKeyGenerated,
        //   id: res.user.uid,
        //   fullName: this.form.get('userFullName').value,
        //   isOwner: true
        // }
        //
        // sUsers.push(accountUser);
        //
        // let account: Account = {
        //   account_key: accountUser.account_key,
        //   account_name: accountUser.fullName,
        //   owner_id: accountUser.id,
        //   email_address: this.form.get('userEmail').value,
        //   phone_number: null,
        //   phone_number_verified: false,
        //   address: null,
        //   status: false,
        //   plans: {
        //     id: '9ELVcFQzChsoctf6nKIN',
        //     name: 'Free Plan',
        //     price: 0,
        //     key: 'PL51866783687',
        //     description: 'Free Plan'
        //   },
        //   accountUsers: sUsers,
        //   dateCreated: new Date(),
        //   settings: {
        //     zones: []
        //   },
        //   vehicles: []
        // }
        //
        // this.accountService.addAccountUser(account.account_key, sUsers);
        // this.accountService.addAccount(account);
        this.sendGridEmailOnBoardingForAdmin(this.form.get('userFullName').value, this.form.get('userEmail').value);

      }, err =>{
        console.log(err);
        if(err.code === 'auth/email-already-in-use'){
          this.errorMessage = "This email address is already exist. Please use another one.";
        }
      });
    }else{
      this.validateAllFormFields(this.form);
    }
  }

  generateRandomString(length: number) {
    let result = '';
    let characters = '0123456789';
    let charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  sendGridEmailOnBoardingForAdmin(name, email){

    let template = {
      to: 'info@parkbot.ph',
      cc: 'alvingaluno@gmail.com',
      bcc: 'jaro.navales@gmail.com',
      message: {
        subject: 'New user sign-up!',
        html:
          '<p>We have new user sign-ups from registration form. Please review the details immediately. :)</p>' +
          '<p><strong>Full name</strong>: '+ name +'</p>'+
          '<p><strong>Email address</strong>: '+ email +'</p>'
      }
    }

    const emailRef = firebase.firestore().collection('emails');
    emailRef.add(template).then(() => {
      console.log('Queued email for delivery!');
      this.sendGridEmailOnBoardingForUser(email);
    });
  }

  sendGridEmailOnBoardingForUser(email){

    let template = {
      to: email,
      message: {
        subject: 'Thanks for signing up with Parkbot PH',
        html:
          '<p>Your information has been successfully submitted.</p>'+
          '<p>We value your privacy. All information you submitted will remain confidential.</p>'+
          '<p>Your message is being reviewed. We will notify you as soon as your parking space is approved.</p>'+
          '<p>In the meantime, you can check out our <a href="http://www.parkbot.ph" style="text-decoration: underline; color: #f74e64;">Parking Finder</a></p>'
      }
    }

    const emailRef = firebase.firestore().collection('emails');
    emailRef.add(template).then(() => console.log('Queued email for delivery!'));
  }

}
