import { Injectable } from '@angular/core';
import {Router} from "@angular/router";
import firebase from "firebase";
import { LogsService } from './log.service';

@Injectable({
  providedIn: 'root'
})
export class CronJobsService {

  account: any;

  constructor(
    private logService: LogsService
  ) { }

  async checkAndMatch(){
    const CronJob = require('cron').CronJob;
    const job = new CronJob({
      cronTime: '0 21 * * *',
      onTick: async () => {

        //Your code is to be executed on every 15min
        this.account = JSON.parse(localStorage.getItem('account'));
        //await this.logService.subscribeExit(this.account);

      },
      start: true,
      runOnInit: false
    });
  }
}
